import React, { useContext } from "react";
import BackgroundImage from "../../assets/images/background.svg";
import Logo from "../../assets/images/logo.svg";
// import CoachLogo from "../../assets/images/coach-logo.svg";
import { Row, Col, Form, Input, Button } from "antd";
import "./signIn.scss";
import {
  SigninContext,
  SigninContextProvider,
} from "../../context/signin-context";
import { UserContext } from "../../context/user-context";
import { useNavigate } from "react-router-dom";
const SignInPage = () => {
  const navigate = useNavigate();
  const {darkButtonBackground} = useContext(UserContext)
  const [form] = Form.useForm();
  const { signIn } = useContext(SigninContext);
  return (
    <Row
      className="signin-main"
      style={{
        // backgroundImage: `url(${BackgroundImage})`,
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor:"#F7F8FA"
      }}
    >
      <div className="form-div">
        <img src={Logo} />
        {/* <img src={CoachLogo} /> */}
        <h1>Sign In</h1>
        <span className="span">
          Enter your email and password to login.
        </span>
        <Form form={form} name="dynamic_rule" onFinish={signIn}>
          {" "}
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter email",
              },
            ]}
          >
            <Input placeholder="Email" className="custom-input" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter password",
              },
            ]}
          >
            <Input.Password placeholder="Password" type="password" className="custom-input" />
          </Form.Item>
          <span className="forgot-pwd" onClick={()=>navigate("/reset-password")}>Forgot Password?</span>
          <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
            <Button htmlType="submit" className="dark-base-btn" style={{background : darkButtonBackground}}>
              Sign in
            </Button>
          </Form.Item>
        </Form>{" "}
      </div>
    </Row>
  );
};

const SignIn = () => {
  return (
    <SigninContextProvider>
      <SignInPage />
    </SigninContextProvider>
  );
};
export default SignIn;
