import React, { useContext, useState } from "react";
import BackgroundImage from "../../assets/images/background.svg";
import Logo from "../../assets/images/logo.svg";
import CoachLogo from "../../assets/images/coach-logo.svg";
import { Row, Col, Form, Input, Button, Tabs } from "antd";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-number-input";

import "./no-subscription.scss";
import {
  SigninContext,
  SigninContextProvider,
} from "../../context/signin-context";
import { useNavigate } from "react-router-dom/dist";
import { UserContext } from "../../context/user-context";
const NoSubscriptionPage = () => {
  const {darkButtonBackground} = useContext(UserContext)
  const navigate = useNavigate();
  return (
    <Row
      className="no-subscription-main"
      style={{
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="form-div">
        <img src={Logo} />
        <img src={CoachLogo} />
        <h1>No Active Subscriptions</h1>
        <span className="span">
        You do not have any active subscriptions. Please contact the Ballogy admin to activate a subscription for you.
        </span>
        <Button htmlType="submit" className="dark-base-btn" style={{background : darkButtonBackground}}>
        <a href='mailto:support@ballogy.com' >              Contact Admin
        </a>
            </Button>
            <Button htmlType="submit" className="grey-base-btn" onClick={()=> navigate({ pathname: `../coach/login` })}>
              Logout
            </Button>
      </div>
    </Row>
  );
};

const LogIn = () => {
  return (
    <SigninContextProvider>
      <NoSubscriptionPage />
    </SigninContextProvider>
  );
};
export default LogIn;
