import React, { useEffect, useState } from "react";
import { message, Form, Button, Modal, Select, Dropdown, Menu } from "antd";
import { getData, formPostData, jsonPostData, deleteItem, patchData } from "../../util/api-calls";
const { confirm } = Modal;
export const AdminUserContext = React.createContext({});
export const AdminUserContextProvider = ({ children = {} }) => {
  const [form] = Form.useForm();
  const [imgSrc , setImgSrc] = useState()
  const [gender , setGender] = useState()
  const [imageUrl , setImageUrl] = useState()
  const [loading , setLoading] = useState(false)
  const [addUserModal , setAddUserModal] = useState(false)
  const [ editUserModal, setEditUserModal] = useState(false)
  const [roleList , setRoleList] = useState([])
  const [usersList , setUsersList] = useState([])
  const [sort , setSort] = useState(true)
  const [search , setSearch] = useState("")
  const [role , setRole] = useState("")
  const [selectedUser , setSelectedUser] = useState({})
  const [roleId , setRoleId] = useState()
  const updateUser = (e) => {
    let data = {
    full_name: e.full_name,
    phone: e.phone,
    role_id : roleId,
    username : e.username 
    }
    patchData(`admin/user/account/${selectedUser.id}` , data).then((res)=>{
      setEditUserModal(false)
      getUsersList();
    }).catch((err)=>{
      console.log(err)
    })
  }
  const createUser = (e) =>{
    jsonPostData(`admin/user/account` ,e).then((res)=>{
      message.success({
        content:"User created successfully."
      })
      form.resetFields();
      setAddUserModal(false)
      getUsersList();
    }).catch((err)=>{
      message.error({
        content:err.response.data.details
      })
    })
  }

  const getSingleUser = (id) => {
    getData(`admin/user/account/${id}`).then((res)=>{
      setSelectedUser(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }

  const getUsersList = () => {
    setLoading(true)
    getData(`admin/user/account?search=${search}&sort=${sort}&role_id=${role}`).then((res)=>{
      setUsersList(res.data)
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{
      setLoading(false)
    })
  }
  const getRoleList = () => {
    getData(`admin/role`).then((res)=>{
      setRoleList(res.data.results)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const deleteUser = (id) => {
    deleteItem(`admin/user/account/${id}`).then((res)=>{
      getUsersList();
    }).catch((err)=>{
      console.log(err)
    })
  }
  React.useEffect(()=>{
    getRoleList();
  } , [
]) 
useEffect(()=>{
  getUsersList();
},[search , role , sort])
  const defaultContext = {
    gender,
    setImageUrl,
    setGender,
    imgSrc,
    setImgSrc,
    loading,
    addUserModal,
    setAddUserModal,
    createUser,
    form,
    roleList,
    usersList,
    sort,
    setSort,
    setSearch,
    setRole,
    setUsersList,
    editUserModal,
    setEditUserModal,
    selectedUser,
    setSelectedUser,
    getSingleUser,
    deleteUser,
    updateUser,
    setRoleId
  };
  return (
    <AdminUserContext.Provider value={defaultContext}>
      {children}
    </AdminUserContext.Provider>
  );
};
