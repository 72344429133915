import React, { useEffect, useState } from "react";
import { getDataTraining } from "../../util/api-calls";
export const FWDetailContext = React.createContext({});
export const FWDetailContextProvider = ({ children = {} }) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [fWDetailModal, setFWDetailModal] = useState(false);
  const [selectedFW, setSelectedFW] = useState();
  const [fwData, setFwData] = useState({});
  const [assignedFWData, setAssignedFwData] = useState({});

  const [loading , setLoading] = useState(false)
  const [fWAssignedModal,setFWAssignedModal] = useState(false)
  const getFWDetail = () => {
    setLoading(true)
    getDataTraining(`admin/all/feature/workout/${selectedFW}/detail`)
      .then((res) => {
        setFwData(res.data);
      })
      .catch((err) => {
        console.log(err);
      }).finally(()=>{
        setLoading(false)
      })
  };
  const getAssignedFWDetail = () => {
    setLoading(true)
    getDataTraining(`admin/group/${params.id}/feature-workout/${selectedFW}`)
    .then((res) => {
      setAssignedFwData(res.data);
    })
    .catch((err) => {
      console.log(err);
    }).finally(()=>{
      setLoading(false)
    })
  }
  useEffect(() => {
    if (selectedFW && fWDetailModal === true) {
      getFWDetail();
    }if(fWAssignedModal === true && selectedFW){
      getAssignedFWDetail();
    }
  }, [selectedFW]);
  const defaultContext = {
    fWDetailModal,
    setFWDetailModal,
    selectedFW,
    setSelectedFW,
    fwData,
    setFwData,
    loading,
    fWAssignedModal,
    setFWAssignedModal,
    assignedFWData , 
    setAssignedFwData
  };
  return (
    <FWDetailContext.Provider value={defaultContext}>
      {children}
    </FWDetailContext.Provider>
  );
};
