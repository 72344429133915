import React, { useContext } from "react";
import { jsonPostData } from "../util/api-calls";
import CryptoJs from "crypto-js";
import { UserContext } from "./user-context";
import { useNavigate } from "react-router-dom";

export const SigninContext = React.createContext({});
export const SigninContextProvider = ({ children = {} }) => {
  const navigate = useNavigate()
  const {setUserAccessToken,setUser} = useContext(UserContext)
    const signIn = (e) => {
        let formData = new FormData;
        formData.append('email' , e.email)
        formData.append('password' , e.password)
        jsonPostData(`admin/login`,formData
          )
        .then((res)=>{
            setUser(res.data.user);
            setUserAccessToken(res.data.access_token)
            localStorage.setItem(
              "u_info",
              CryptoJs.AES.encrypt(JSON.stringify(res.data), "sec").toString()
            );
            localStorage.setItem("u_id" , res.data.user.id )
            navigate("/home/users");
              window.location.reload();
        })
        .catch((err)=>{
            console.log(err)
        })
    }
  React.useEffect(()=>{
  } , [
]) 
  const defaultContext = {
    signIn
  };
  return (
    <SigninContext.Provider value={defaultContext}>
      {children}
    </SigninContext.Provider>
  );
};
