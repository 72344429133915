import React, { useEffect, useState } from "react";
import { getDataCoach, getDataTest, getDataTrainingCoach } from "../../util/coach-api-calls";
import { useNavigate } from "react-router-dom";
export const UserDetailContext = React.createContext({});
export const UserDetailContextProvider = ({ children = {} }) => {
  const navigate = useNavigate()
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [userData , setUserData] = useState({})
  const [editUserModal , setEditUserModal] = useState(false)
  const [loading , setLoading] = useState(false)
  const [profileStartDate , setProfileStartDate] = useState("") 
  const [profileEndDate , setProfileEndDate] = useState("")
  const [search , setSearch] = useState("")
  const [allProfiles , setAllProfiles] = useState([])
  const [pageNumber, setPageNumber] = useState(1);
  const [ profileType , setProfileType] = useState("player")
  const [gender , setGender] = useState("male")
  const [shootingHand , setShootingHand ] = useState("right")
  const [imageUpload , setImageUpload] = useState(false)
  const [sportsList, setSportsList] = useState([]);
  const [editProfile, showEditProfile] = useState(false);
  const [selectedProfile , setSelectedProfile] = useState({})
  const [positionPg , setPositionPg] = useState(false)
  const [positionSg , setPositionSg] = useState(false)
  const [positionSf , setPositionSf] = useState(false)
  const [positionPf , setPositionPf] = useState(false)
  const [positionC , setPositionC] = useState(false)
  const [imageUrl, setImageUrl] = useState();
  const [imgSrc, setImgSrc] = useState();
  const [bsaSubmissions , setBsaSubmissions] = useState([])
  const [phoneNumber, setPhoneNumber] = useState()
  const [ selectedGroup ,  setSelectedGroup] = useState("")
  const [groupWorkouts , setGroupWorkouts] = useState([])
  const [workoutType , setWorkoutType] = useState("active")
  const [showAllWorkouts, setShowAllWorkouts] = useState(false);
  const [status , setStatus ] = useState("")
  const [sort , setSort] = useState("desc")
  const [profileDetail, setProfileDetail] = useState({});
  const [ viewSubmissions , setViewSubmissions] = useState(false)
  const getSingleUser = () => {
    getDataCoach(`admin/app/user/account/${params.profile_id}`).then((res)=>{
      setUserData(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const getSingleProfile = (id) => {
    console.log("chjschsjgc")
    getDataCoach(`admin/app/user/profile/${params.profile_id}`)
    .then((res)=>{
      setSelectedProfile(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const getSportList = () => {
    setLoading(true);
    getDataCoach(`sport`)
      .then((res) => {
        setSportsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // const getProfileGroups = () => {
  //   getDataCoach(`admin/joined/groups/profile/${params.profile_id}`).then((res)=>{
  //     setProfileGroups(res.data)
  //   }).catch((err)=>{
  //     console.log(err)
  //   })
  // }
  // console.log(params)
  const getUserProfile = () => {
    getDataCoach(`user/profile/${params.profile_id}`)
      .then((res) => {
        setProfileDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getUserProfile();
  }, []);
  const getProfileWorkouts = (groupOwner) => {
    setLoading(true)
    getDataTrainingCoach(`admin/profile/${params.profile_id}/group/${params.id}/all/workouts?is_owner=${params.profile_type === "owner" ? true : false}`).then((res)=>{
      setGroupWorkouts(res.data)
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{
      setLoading(false)
    })
  }
  const getProfileBsa = () => {
    getDataTest(`admin/profile/${params.profile_id}/bsa/list/?test_status=${status}&sort=${sort}`).then((res)=>{
      setBsaSubmissions(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  useEffect(()=>{
    getSportList();
    // getProfileGroups();
  },[])

  const defaultContext = {
    getSingleUser,
    userData,
    editUserModal , 
    setEditUserModal,
    allProfiles,
    profileEndDate,profileStartDate,search,pageNumber,setPageNumber,
    gender , setGender , shootingHand , setShootingHand , imageUpload , profileType , setProfileType,
    sportsList ,editProfile ,showEditProfile,getSingleProfile , selectedProfile,setPositionPg , positionPg,
    // getProfileGroups,
    positionSg,setPositionSg ,
    positionSf , setPositionSf , 
    positionPf , setPositionPf,
    positionC,setPositionC,setImageUrl,
    imgSrc,setImgSrc , selectedGroup ,  setSelectedGroup,
    setImageUpload,phoneNumber , setPhoneNumber,
    setProfileStartDate,setProfileEndDate,setSearch,setAllProfiles,    getProfileBsa,bsaSubmissions,
    groupWorkouts , setShowAllWorkouts , setWorkoutType , showAllWorkouts , workoutType,loading , 
    status , sort , 
    setStatus , setSort,getProfileWorkouts,
    viewSubmissions,setViewSubmissions,profileDetail
    
  };

  return (
    <UserDetailContext.Provider value={defaultContext}>
      {children}
    </UserDetailContext.Provider>
  );
};
