import { Navigate } from 'react-router-dom'
import CryptoJS from 'crypto-js';
import AdminModule from '../admin-module';
const PrivateRoutes = () => {
    let tokenData = "";
    let decryptStr = "";
    if (localStorage.getItem("u_info") !== null) {
      decryptStr = CryptoJS.AES.decrypt(
        localStorage.getItem("u_info"),
        "sec"
      ).toString(CryptoJS.enc.Utf8);
    }
    if (decryptStr !== "") {
      tokenData = JSON.parse(decryptStr);
    }
    const accessToken = tokenData.access_token;
  let auth = {'token':accessToken}
return (
    auth.token ? <AdminModule/> : <Navigate to='/login'/>
  )
}
export default PrivateRoutes;