import React, { useEffect, useState } from "react";
import { message, Form, Button, Modal, Select, Dropdown, Menu } from "antd";
import { getData, formPostData, jsonPostData } from "../../util/api-calls";
const { confirm } = Modal;
export const AccountSettingsContext = React.createContext({});
export const AccountSettingsContextProvider = ({ children = {} }) => {
  const [imgSrc , setImgSrc] = useState()
  const [gender , setGender] = useState()
  const [imageUrl , setImageUrl] = useState()
  const [loading , setLoading] = useState(false)
  const [accountDetail , setAccountDetail] = useState({})
  const [changePasswordModal,setChangePasswordModal] = useState(false)
  let user_id = localStorage.getItem("u_id")
  const getAccountDetails = () => {
    getData(`admin/user/account/${user_id}`).then((res)=>{
      setAccountDetail(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const changePassword = (e) => {
    let data = {
      "password": e.current_password,
      "new_password": e.password,
      "confirm_password": e.confirm_password
  }
    jsonPostData(`admin/change/password` , data).then((res)=>{
      message.success({
        content:res.data.details
      })
      setChangePasswordModal(false)
    }).catch((err)=>{
      message.error({
        content:err.response.data.message
      })
    })
  }
  React.useEffect(()=>{
    getAccountDetails();
  } , [
]) 
  const defaultContext = {
    gender,
    setImageUrl,
    setGender,
    imgSrc,
    setImgSrc,
    loading,
    accountDetail,
    changePasswordModal,
    setChangePasswordModal,
    changePassword
  };
  return (
    <AccountSettingsContext.Provider value={defaultContext}>
      {children}
    </AccountSettingsContext.Provider>
  );
};
