import React from "react";
import "./notifications.scss"
import {Row , Col , Switch , Divider} from "antd"

const Notifications = () =>{
    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
      };
    return(
        <div className="notifications-main">
            <h3>Notification Settings</h3>
            <Row className="notification-list">
                <Col span={20}><h3>Add new Account</h3></Col>
                <Col span={4}> <Switch defaultChecked onChange={onChange}  className="custom-switch"/></Col>
                <Divider dashed/> 
                <Col span={20}><h3>Add new profile</h3></Col>
                <Col span={4}> <Switch defaultChecked onChange={onChange}  className="custom-switch"/></Col>
                <Divider dashed/> 
                <Col span={20}><h3>Add new combine measurements</h3></Col>
                <Col span={4}> <Switch defaultChecked onChange={onChange}  className="custom-switch"/></Col>
                <Divider dashed/> 
                <Col span={20}><h3>New challenges/workouts added</h3></Col>
                <Col span={4}> <Switch defaultChecked onChange={onChange}  className="custom-switch"/></Col>
                <Divider dashed/> 
                <Col span={20}><h3>New group created</h3></Col>
                <Col span={4}> <Switch defaultChecked onChange={onChange}  className="custom-switch"/></Col>
                <Divider dashed/> 
                <Col span={20}><h3>New subscription by user</h3></Col>
                <Col span={4}> <Switch defaultChecked onChange={onChange}  className="custom-switch"/></Col>
                <Divider dashed/> 
                <Col span={20}><h3>Subscription updated by user</h3></Col>
                <Col span={4}> <Switch defaultChecked onChange={onChange}  className="custom-switch"/></Col>
                <Divider dashed/> 
                <Col span={20}><h3>New BSA submission by user</h3></Col>
                <Col span={4}> <Switch defaultChecked onChange={onChange}  className="custom-switch"/></Col>
                <Divider dashed/> 
                <Col span={20}><h3>New task added by admin</h3></Col>
                <Col span={4}> <Switch defaultChecked onChange={onChange}  className="custom-switch"/></Col>
                <Divider dashed/> 
                <Col span={20}><h3>New task added by user</h3></Col>
                <Col span={4}> <Switch defaultChecked onChange={onChange}  className="custom-switch"/></Col>
                <Divider dashed/> 
                <Col span={20}><h3>Task update</h3></Col>
                <Col span={4}> <Switch defaultChecked onChange={onChange}  className="custom-switch"/></Col>
                <Divider dashed/> 
                <Col span={20}><h3>Home rotator added</h3></Col>
                <Col span={4}> <Switch defaultChecked onChange={onChange}  className="custom-switch"/></Col>
                <Divider dashed/> 
                <Col span={20}><h3>Home rotator status or details updated</h3></Col>
                <Col span={4}> <Switch defaultChecked onChange={onChange}  className="custom-switch"/></Col>

            </Row>
        </div>
    )
}
export default Notifications;