import React, { useContext, useEffect, useRef, useState } from "react";
import { Steps } from "antd";
import "./rotator-detail.scss";
import {
  EditRotatorContext,
  EditRotatorContextProvider,
} from "../../../context/home-rotator-context/edit-rotator-context";
import ReactPlayer from "react-player";
import { getDateWithTime } from "../../../util/config";
import { UserContext } from "../../../context/user-context";

const RotatorDetailPage = () => {
  const {selectedSport} = useContext(UserContext)
  const { rotatorDetail } = useContext(EditRotatorContext);
  const [current, setCurrent] = useState(0);
  const onChange = (value) => {
    setCurrent(value);
  };
  const regex = /(<([^>]+)>)/gi;

  return (
    <div className="edit-rotator-main rotator-detail">
      {/* <h4 className="label">Sport</h4>
      <h4 className="value">
        {rotatorDetail.sport && rotatorDetail.sport.name}
      </h4> */}
      <div className="tasks-list">
        <h3>Rotators</h3>
        <Steps
          current={current}
          onChange={onChange}
          // direction="vertical"
          className={"rotator-form-steps " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
          items={[
            {
              description: "Home Screen",
              // description,
              // disabled: true,
            },
            {
              description: "Landing Screen",
              // description,
            },
          ]}
        />
        {/* 

<h4 className="label">Button URL</h4>
<h4 className="value">{}</h4> */}{" "}
        {current === 0 ? (
          <>
            <h4 className="label">Title</h4>
            <h4 className="value">{rotatorDetail.title}</h4>
            {/* <Checkbox className="custom-checkbox" checked={true}>
            Visible to coaches only
            </Checkbox> */}

            <h4 className="label">Media</h4>
            {rotatorDetail.media_image ? (
              <div style={{ width: 500 }}>
                <img src={rotatorDetail.media_image} />
              </div>
            ) : (
              <h4 className="value">No media added</h4>
            )}

            {/*             
            <h4 className="label">Autochange Rotator After</h4>
            <h4 className="value">5 second(s)</h4> */}

            <h4 className="label">Button Text</h4>
            <h4 className="value">{rotatorDetail.action_button_text}</h4>

            <h3>Time Limit</h3>

            <h4 className="label">Start Date</h4>
            <h4 className="value">
              {getDateWithTime(rotatorDetail.start_date)}
            </h4>

            <h4 className="label">End Date</h4>
            <h4 className="value">{getDateWithTime(rotatorDetail.end_date)}</h4>

            <h4 className="label">Presented by</h4>
            <h4 className="value">
              {rotatorDetail.sponsor ? rotatorDetail.sponsor.name : "N/A"}
            </h4>

            <h4 className="label">Sponsor URL</h4>
            <h4 className="value">
              {rotatorDetail.sponsor ? rotatorDetail.sponsor.website : "N/A"}
            </h4>
          </>
        ) : (
          <>
            <h4 className="label">Title</h4>
            <h4 className="value">{rotatorDetail.detail_title}</h4>
            {/* <Checkbox className="custom-checkbox" checked={true}>
Visible to coaches only
</Checkbox> */}

            <h4 className="label">Media</h4>
            <div style={{ width: 500 }}>
              {rotatorDetail.detail_image ? (
                <img src={rotatorDetail.detail_image} />
              ) : rotatorDetail.detail_media_url ? (
                <ReactPlayer
                  url={rotatorDetail.detail_media_url}
                  controls
                  width={"100%"}
                  height={"100%"}
                />
              ) : (
                <h4 className="value">No media added</h4>
              )}
            </div>
            {/* <h4 className="label">Autochange Rotator After</h4>
            <h4 className="value">5 second(s)</h4> */}

            <h4 className="label">Body Text</h4>
            <h4 className="value">
              {rotatorDetail.detail_content
                ? rotatorDetail.detail_content.replace(regex, "")
                : "N/A"}
            </h4>

            <h4 className="label">Button Text</h4>
            <h4 className="value">{rotatorDetail.detail_action_button_text}</h4>

            <h4 className="label">Button URL</h4>
            <h4 className="value">{rotatorDetail.detail_action_button_link}</h4>
          </>
        )}
      </div>
    </div>
  );
};

const RotatorDetail = () => {
  return (
    <EditRotatorContextProvider>
      <RotatorDetailPage />
    </EditRotatorContextProvider>
  );
};

export default RotatorDetail;
