import React, { useEffect, useState } from "react";
import { getData, formPostData, jsonPostData, patchData } from "../../util/api-calls";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import dayjs from "dayjs";
export const CombineMeasurementContext = React.createContext({});
export const CombineMeasurementContextProvider = ({ children = {} }) => {
    const [editModal , setEditModal] = useState(false)
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    const entries = urlParams.entries();
    const params = paramsToObject(entries);
    function paramsToObject(entries) {
      const result = {};
      for (const [key, value] of entries) {
        result[key] = value;
      }
      return result;
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allProfileMeasurements, setAllProfileMeasurements] = useState({});
    const [measurementDate , setMeasurementDate] = useState("")
    const [singleProfileData , setSingleProfileData] = useState([])
    const getCombineMeasurements = () => {
      setLoading(true);
      getData(`admin/profile/${params.id}/cm`)
        .then((res) => {
          if (res && res.status === 200) {
            setAllProfileMeasurements(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    const getSpecificProfileCm = () => {
        getData(`admin/profile/${params.id}/cm/${measurementDate}`).then((res)=>{
        setSingleProfileData(res.data)
      })
        .catch((err)=>{
            console.log(err)
        })
    }
    const submitCombineMeasurements = (values) => {
        let data = [
          {
            measurement_id: 1,
            value:
              (values.height_feet ? values.height_feet * 12 : "") +
              (values.height_inch ? values.height_inch : "") +
              (values.height_dec ? "." + values.height_dec : ""),
            type: "1",
            cm_date: dayjs(values.date).format("YYYY-MM-DD"),
          },
          {
            measurement_id: 2,
            value: values.weight_lbs ? values.weight_lbs : "",
            type: "1",
            cm_date: dayjs(values.date).format("YYYY-MM-DD"),
          },
          {
            measurement_id: 3,
            value:
              (values.wingspan_feet ? values.wingspan_feet * 12 : "") +
              (values.wingspan_inch ? values.wingspan_inch : "") +
              (values.wingspan_dec ? "." + values.wingspan_dec : ""),
            type: "1",
            cm_date: dayjs(values.date).format("YYYY-MM-DD"),
          },
          {
            measurement_id: 4,
            value:
              (values.maxVertical_inch ? values.maxVertical_inch : "") +
              (values.maxVertical_dec ? "." + values.maxVertical_dec : ""),
    
            type: "1",
            cm_date: dayjs(values.date).format("YYYY-MM-DD"),
          },
          {
            measurement_id: 5,
            value:
              (values.standingVertical_inch ? values.standingVertical_inch : "") +
              (values.standingVertical_dec
                ? "." + values.standingVertical_dec
                : ""),
            type: "1",
            cm_date: dayjs(values.date).format("YYYY-MM-DD"),
          },
          {
            measurement_id: 6,
            value:
              (values.court_sec ? values.court_sec : "") +
              (values.court_dec ? "." + values.court_dec : ""),
    
            type: "1",
            cm_date: dayjs(values.date).format("YYYY-MM-DD"),
          },
          {
            measurement_id: 7,
            value:
              (values.lane_sec ? values.lane_sec : "") +
              (values.lane_dec ? "." + values.lane_dec : ""),
            type: "1",
            cm_date: dayjs(values.date).format("YYYY-MM-DD"),
          },
        ];
        data = data.filter((pl) => pl.value !== "");
        jsonPostData(`admin/profile/${params.id}/cm`, data)
          .then((res) => {
            setIsModalOpen(false)
          })
          .catch((err) => {
            if(err && err.response.status === 500){
              message.error({
                content:"You cannot add multiple data on same date."
              })
            }
          });
      };
      const updateCombineMeasurements = (values) => {
        let data = [
          {
            measurement_id: 1,
            value:
              (values.height_feet ? values.height_feet * 12 : "") +
              (values.height_inch ? values.height_inch : "") +
              (values.height_dec ? "." + values.height_dec : ""),
            type: "1",
            cm_date: dayjs(values.date).format("YYYY-MM-DD"),
          },
          {
            measurement_id: 2,
            value: values.weight_lbs ? values.weight_lbs : "",
            type: "1",
            cm_date: dayjs(values.date).format("YYYY-MM-DD"),
          },
          {
            measurement_id: 3,
            value:
              (values.wingspan_feet ? values.wingspan_feet * 12 : "") +
              (values.wingspan_inch ? values.wingspan_inch : "") +
              (values.wingspan_dec ? "." + values.wingspan_dec : ""),
            type: "1",
            cm_date: dayjs(values.date).format("YYYY-MM-DD"),
          },
          {
            measurement_id: 4,
            value:
              (values.maxVertical_inch ? values.maxVertical_inch : "") +
              (values.maxVertical_dec ? "." + values.maxVertical_dec : ""),
    
            type: "1",
            cm_date: dayjs(values.date).format("YYYY-MM-DD"),
          },
          {
            measurement_id: 5,
            value:
              (values.standingVertical_inch ? values.standingVertical_inch : "") +
              (values.standingVertical_dec
                ? "." + values.standingVertical_dec
                : ""),
            type: "1",
            cm_date: dayjs(values.date).format("YYYY-MM-DD"),
          },
          {
            measurement_id: 6,
            value:
              (values.court_sec ? values.court_sec : "") +
              (values.court_dec ? "." + values.court_dec : ""),
    
            type: "1",
            cm_date: dayjs(values.date).format("YYYY-MM-DD"),
          },
          {
            measurement_id: 7,
            value:
              (values.lane_sec ? values.lane_sec : "") +
              (values.lane_dec ? "." + values.lane_dec : ""),
            type: "1",
            cm_date: dayjs(values.date).format("YYYY-MM-DD"),
          },
        ];
        data = data.filter((pl) => pl.value !== "");
        patchData(`admin/profile/${params.id}/cm/${measurementDate}`, data)
          .then((res) => {
            setEditModal(false)
            getCombineMeasurements();
          })
          .catch((err) => {
            console.log(err);
            if(err && err.response.status === 500){
              message.error({
                content:"You cannot add multiple data on same date."
              })
            }
          });
      };
    useEffect(() => {
      if (!isModalOpen) {
        getCombineMeasurements();
      }
    }, [isModalOpen]);
  const defaultContext = {
    editModal,
    setEditModal,
    allProfileMeasurements,
    isModalOpen,
    setIsModalOpen,
    loading,
    submitCombineMeasurements,
    setMeasurementDate,
    getSpecificProfileCm,
    singleProfileData,
    measurementDate,
    updateCombineMeasurements
  };
  return (
    <CombineMeasurementContext.Provider value={defaultContext}>
      {children}
    </CombineMeasurementContext.Provider>
  );
};
