import React, { useEffect, useState } from "react";
import { message, Form, Button, Modal, Select, Dropdown, Menu } from "antd";
import { getData, formPostData } from "../../util/api-calls";
const { confirm } = Modal;
export const AccountSettingsContext = React.createContext({});
export const AccountSettingsContextProvider = ({ children = {} }) => {
  const [imgSrc , setImgSrc] = useState()
  const [gender , setGender] = useState()
  const [imageUrl , setImageUrl] = useState()
  const [loading , setLoading] = useState(false)
  const [accountDetail , setAccountDetail] = useState({})
  let user_id = localStorage.getItem("u_id")
  const getAccountDetails = () => {
    getData(`admin/user/account/${user_id}`).then((res)=>{
      setAccountDetail(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  React.useEffect(()=>{
    getAccountDetails();
  } , [
]) 
  const defaultContext = {
    gender,
    setImageUrl,
    setGender,
    imgSrc,
    setImgSrc,
    loading,
    accountDetail
  };
  return (
    <AccountSettingsContext.Provider value={defaultContext}>
      {children}
    </AccountSettingsContext.Provider>
  );
};
