import { useLocation, Link, useNavigate } from "react-router-dom";
import "./breadcrumb.scss";
import { Button, message } from "antd";
import Exit from "../../assets/images/exit-up.svg";
import Plus from "../../assets/images/plus.svg";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/user-context";
export default function Breadcrumbs() {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const {accountDetail , darkButtonBackground , color , lightButtonBackground , selectedSport} = useContext(UserContext)
  const navigate = useNavigate();
  const [buttonText, setButtonText] = useState("");
  const [buttonIcon, setButtonIcon] = useState();
  const location = useLocation();
  let currentLink = "";
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink += `/${crumb}`;
      if(crumb !== "home"){
      let newCrumb = crumb.replace("-", " ");
      return (
        newCrumb !== "coach" &&
        <div className="crumb" key={newCrumb}>
          <Link to={currentLink}>{decodeURI(newCrumb)}</Link>
        </div>
      );
    }
    });
  let url = window.location.href.split("/");
  let pageName = url.pop() || url.pop(); // handle potential trailing slash
  let currentUrl = window.location.href;
  let crumbArray = location.pathname.split("/").filter((crumb) => crumb !== "");
  let lastElement = crumbArray[crumbArray.length - 1];
  const info = () => {
    message.info("You don't have permissions. Contact to Admin");
  };
const checkGroupPermission = () => {
  if(accountDetail.role !== null){
  for (let item of accountDetail.role?.role_permission) {
    if(item.module.name === "Groups" && item.permission.code_name === "ADD"){
      if(item.value === false){
        info();
      }
      else{
        navigate(`groups/create-new-group`)
      }
    }
  }
  }
  else{
    navigate(`groups/create-new-group`)
  }
}
const checkRotatorPermission = () => {
  if(accountDetail.role !== null){
  for (let item of accountDetail.role?.role_permission) {
    if( item.module.name === "Rotators" && item.permission.code_name === "ADD"){
      if(item.value === false){
        info();
      }
      else{
        navigate(`home-rotator/create-new`)      
      }
    }
  }
}
else{
  navigate(`home-rotator/create-new`)      
}
}
const checkWorkoutPermission = () => {
  if(accountDetail.role !== null){
  for (let item of accountDetail.role?.role_permission) {
    if( item.module.name === "Challenges / Workouts" && item.permission.code_name === "ADD"){
      if(item.value === false){
        info();
      }
      else{
        navigate(`workouts/create-new-workout`)      
      }
    }
    }
  }
  else{
    navigate(`workouts/create-new-workout`)      
  }
}
const checkFeaturedWorkoutPermission = () => {
  if(accountDetail.role !== null){
  for (let item of accountDetail.role?.role_permission) {
    if( item.module.name === "Challenges / Workouts" && item.permission.code_name === "ADD"){
      if(item.value === false){
        info();
      }
      else{
        navigate(`workouts/create-new-featured-workout`)      
      }
    }
    }
  }
  else{
    navigate(`workouts/create-new-featured-workout`)      
  }
}
  return (
    <div className="breadcrumbs">
      <div>
      {params.profile_type && <FaArrowLeftLong className= "left-arrow" onClick={() => navigate(-1)} style={{position:"absolute",marginTop:5 , color : color , stroke : color}}/>}
        <h1 style={{marginLeft : params.profile_type ? 40 : 0 }}>
          {currentUrl.includes("groups") && params.id
            ? decodeURI(lastElement) : 
             crumbs.length > 1 && crumbs[1] !== undefined && crumbs[1].key === "tasks" ? "Task Library" 
            : currentUrl.includes("home-rotator") && lastElement === "create-new" ? "Create New" : 
            lastElement === "edit-rotator" ? "Edit Rotator": 
            crumbs[1].key  === "workouts" && params.tab === "featured-workouts" ? "Featured Workouts" :
            crumbs[1].key  === "workouts" && params.tab === "workouts" ? "Workouts" :
            crumbs.length > 1 && crumbs[1] !== undefined && crumbs[1].key
            }
        </h1>
        <div style={{ display: "flex" , marginLeft : params.profile_type ? 40 : 0 }}>
          {params.tab === "featured-workouts" ? 
          <div className="crumb">
          <Link to={"workouts?tab=featured-workouts"}>featured workouts</Link>
        </div> :
        params.tabs ==="featured-workouts" ?
        <div className="crumb">
        <Link to={"submissions?tabs=featured-workouts"}>featured workouts</Link>
      </div>
         : params.tab === "workouts" ?         
         <div className="crumb" >
         <Link to={"workouts?tab=workouts"}>workouts</Link>
       </div> : crumbs}</div>
      </div>
      {pageName === "users" ? (
        <Button
        icon={<img src={Exit} className={selectedSport === 1 ? "orange-theme-svg" : "blue-theme-svg" }/>}
        className="red-base-btn"
        style={{ marginLeft: "auto" ,color : color , background : lightButtonBackground }}
      >
          Export
        </Button>
      ) : pageName === "groups" && !url.includes("coach") ? (
        <Button
          icon={<img src={Plus} />}
          className="dark-base-btn"
          style={{ marginLeft: "auto" , background : darkButtonBackground}}
          onClick={checkGroupPermission}
        >
          Create Group
        </Button>
      ) 
      // : pageName === "tasks" ? (
      //   <Button
      //     icon={<img src={Plus} />}
      //     className="dark-base-btn"
      //     style={{ marginLeft: "auto" }}
      //     // onClick= {()=>setNewTaskModal(true)}
      //   >
      //     Create Task
      //   </Button>
      // )
       : pageName === "home-rotator" ? (
        <Button
          icon={<img src={Plus} />}
          className="dark-base-btn"
          style={{ marginLeft: "auto" , background : darkButtonBackground}}
          onClick={checkRotatorPermission}
        >
          Create New
        </Button>
      ) 
      : pageName.includes("workouts") && params.tab === "featured-workouts" && !params.id? (
        <Button
        icon={<img src={Plus} />}
        className="dark-base-btn"
        style={{ marginLeft: "auto" , background : darkButtonBackground }}
        onClick={checkFeaturedWorkoutPermission}
        // onClick={() => params.tab === 'workouts' ? navigate(`challenges/create-new-workout`) : navigate(`challenges/create-new-challenge`)}
      >
        Create Featured Workout
        {/* Create {params.tab === 'workouts' ? "Workout" : "Challenge"} */}
      </Button>
      )
      :  pageName.includes("workouts") && params.tab === "workouts" ? (
        <Button
        icon={<img src={Plus} />}
        className="dark-base-btn"
        style={{ marginLeft: "auto" , background : darkButtonBackground }}
        onClick={checkWorkoutPermission}
        // onClick={() => params.tab === 'workouts' ? navigate(`challenges/create-new-workout`) : navigate(`challenges/create-new-challenge`)}
      >
        Create Workout
        {/* Create {params.tab === 'workouts' ? "Workout" : "Challenge"} */}
      </Button>
      )
      : (
        ""
      )}
    </div>
  );
}
