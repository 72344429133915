import React, { useContext } from "react";
import { Row, Spin} from "antd";
import Info from "../../assets/images/information-2.svg";
import "./notification-window.scss";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { UserContext } from "../../context/user-context";
import { useNavigate } from "react-router-dom";
import { getDataNotification } from "../../util/api-calls";
TimeAgo.addDefaultLocale(en);
const NotificationList = ({loading , getNotifications}) => {
  const navigate = useNavigate();
  const {notificationData , selectedSport , setSelectedNotification } = useContext(UserContext)
  const timeAgo = new TimeAgo("en-US");
  const readNotification = (id)=> {
    getDataNotification(`admin/notification/${id}`).then((res)=>{
    }).catch((err)=>{
      console.log(err)
    })
  }
  const goToDetail = (item) => {
    readNotification(item.id)
    getNotifications();
    setSelectedNotification(item.notification_data[0].object_data)
    navigate({
      pathname: `./submissions/${item.title}`,
      search: `id=${item.notification_data[0].object_data.feature_workout_id}&tabs=featured-workouts`,
    });

  }
  return (
    <Row className="notification-panel-main">
      <div className="heading-row">
        {" "}
        <h3>Notifications</h3>
      </div>
      {loading && loading == true ? (
          <Spin
            className={"loader " + (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")}
            size="large"
            style={{
              position: "absolute",
              left: "46%",
              marginTop:"50%"
            }}
          />
        ) : (
      <div className={"notification-list " + (selectedSport === 1 ? "basketball-notif" : "football-notif")}>
        {notificationData?.results?.length > 0 ? notificationData?.results?.map((item , index)=>{
          return(
            <div style={{ display: "flex" , padding : "0 30px" , marginBottom:10 }} key={index} onClick={()=> goToDetail(item)} className={item.status === "read" ? "read" : "unread"} >
            <img src={Info} className={selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon"}/>
            <div className="content">
              {item.description}
              <span className="time">
                {timeAgo.format(new Date(item.created))}
              </span>
            </div>
          </div>
          )
        }) : <span style={{    position: "absolute",
          left: "34%",
          marginTop: "50%"}}>No new notification</span>}
      </div>
        )
      }
    </Row>
  );
};
export default NotificationList;
