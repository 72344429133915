import React, { useContext, useState } from "react";
import {
  Select,
  Modal,
  Form,
} from "antd";
import DownArrow from "../../../assets/images/down-arrow.svg";
import { PurchasesContext } from "../../../context/user-context/purchses-context";
import { UserContext } from "../../../context/user-context";
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const oneTimeOptions = [];
for (let i = 1; i <= 10; i++) {
  oneTimeOptions.push({
    value: i.toString(10),
    label: i.toString(10),
  });
}

const CreditModal = ({ creditModal, setCreditModal }) => {
  const {selectedSport} = useContext(UserContext)
  const {addCredits , form} = useContext(PurchasesContext)
  const handleOk = (e) => {};
  const handleCancel = () => {
    setCreditModal(false);
  };
  return (
    <Modal
      wrapClassName={"credit-modal " + (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")}
      open={creditModal}
      onOk={addCredits}
      onCancel={handleCancel}
      okText={"Add"}
      cancelText={"Cancel"}
      width={"624px"}
    >
      <h1>Add Credits</h1>
      <Form
        form={form}
        name="dynamic_rule"
        style={{
          padding: "22px 30px ",
        }}
        className="sport-form"
      >
        <Form.Item
          {...formItemLayout}
          name="bsa-credit"
          label="BSA Credit"
          className="bsa-credit-div"
        >
          <Select
            showSearch
            placeholder="Select"
            optionFilterProp="children"
            // onChange={onChangeSelect}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            className="select-input"
            popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
            style={{ height: 38 }}
            suffixIcon={<img src={DownArrow} />}
            options={oneTimeOptions}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreditModal;
