import { UserContext } from "../../../context/user-context";
import React, { useContext, useEffect, useState } from "react";
import "./assigned-fw-modal.scss";
import { LockOutlined } from "@ant-design/icons";
import { FaArrowLeftLong } from "react-icons/fa6";
import Expand from "../../../assets/images/expand.svg";
import CollapseIcon from "../../../assets/images/collapse.svg";
import { Modal, Col, Row, Spin, Tabs, Table, Progress, Collapse } from "antd";
import Avatar from "react-avatar";
import { getDate } from "../../../util/config";
import { useLocation, Link } from "react-router-dom";
import { FWDetailContext } from "../../../coach-context/group-context/FW-detail-context";
const style = {
  padding: "5px 0",
};
const AssignedFWModal = ({ fWAssignedModal, setFWAssignedModal }) => {
  const location = useLocation();
  const { Panel } = Collapse;
  const { selectedSport, color } = useContext(UserContext);
  const { loading, assignedFWData, setAssignedFwData } =
    useContext(FWDetailContext);
  const handleOk = () => {
    setFWAssignedModal(false);
  };
  const handleCancel = () => {
    setAssignedFwData({});
    setFWAssignedModal(false);
  };
  useEffect(() => {
    if (fWAssignedModal === false) {
      setAssignedFwData({});
    }
  }, [fWAssignedModal]);
  const onChange = (key) => {};

  const trainingTaskcolumns = [
    {
      title: "Completed By",
      dataIndex: "member",
      width: "50%",
      render: (text, record) => (
        <div span={20} className="member-div">
          {record.avatar !== null ? (
            <img src={record.avatar}></img>
          ) : (
            <Avatar
              name={record.name}
              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
              className="user-avatar"
            />
          )}
          <div className="name-div">
            <h3 className="user-name">{record.name}</h3>
            <h4 className="user-handle">@{record.username}</h4>
          </div>
        </div>
      ),
    },
    {
      title: "Reps",
      dataIndex: "reps",
      render: (text, record) => (
        <span>
          {record.reps_done}/{record.total_reps}
        </span>
      ),
    },
    {
      title: "Completion",
      dataIndex: "completion_percentage",
      render: (text, record) => <span>{record.completion_percentage}%</span>,
    },
  ];
  const scoredTaskColumns = [
    {
      title: "Completed By",
      dataIndex: "member",
      width: "50%",
      render: (text, record) => (
        <div span={20} className="member-div">
          {record.avatar !== null ? (
            <img src={record.avatar}></img>
          ) : (
            <Avatar
              name={record.name}
              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
              className="user-avatar"
            />
          )}
          <div className="name-div">
            <h3 className="user-name">{record.name}</h3>
            <h4 className="user-handle">@{record.username}</h4>
          </div>
        </div>
      ),
    },
    {
      title: "Score",
      dataIndex: "score",
      render: (text, record) => (
        <span>{record.score ? record.score : "N/A"}</span>
      ),
    },
    {
      title: "Completion",
      dataIndex: "completion_percentage",
      render: (text, record) => <span>{record.completion_percentage}%</span>,
    },
  ];
  const tabItems = [
    {
      key: "1",
      label: `Tasks`,
      children: (
        <Collapse
          onChange={onChange}
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            !isActive ? (
              <img src={Expand} />
            ) : (
              <img
                src={CollapseIcon}
                className={
                  "minus-icon " +
                  (selectedSport === 1
                    ? "orange-theme-icon"
                    : "blue-theme-icon")
                }
              />
            )
          }
          className="collapsible-rows"
        >
          {assignedFWData.tasks?.map((item, index) => {
            return (
              <Panel
                key={index}
                header={
                  <div className="collapse-label">
                    <Row style={{ width: "100%" }}>
                      <Col span={16} style={{ fontSize: 16 }}>
                        {item.title} ({item.skill_level})
                        <span>
                          {item.scored_task ? "Scored Task" : "Training Task"}
                        </span>
                        {item.lock && (
                          <div className="task-lock">
                            <LockOutlined
                              style={{
                                fontSize: 12,
                                color: "rgba(126, 130, 153, 1)",
                              }}
                            />
                          </div>
                        )}
                      </Col>
                      <Col
                        span={4}
                        style={{ marginTop: "-5px", padding: "0px 10px" }}
                      >
                        {" "}
                        {item.is_competed === true ? (
                          <div className="status">Completed</div>
                        ) : item.is_competed === false &&
                          item.completion_percentage === 0 ? (
                          <button
                            className="yellow-tag"
                            style={{ marginTop: "-3px" }}
                          >
                            Pending
                          </button>
                        ) : (
                          <Progress
                            percent={item.completion_percentage}
                            showInfo={false}
                            className={
                              selectedSport === 1
                                ? "orange-progress-bar"
                                : "blue-progress-bar"
                            }
                          />
                        )}
                      </Col>
                      <Col span={4}>
                        <div className="points">
                          Completed by: <span>{item.completed_by}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                }
              >
                <Table
                  rowKey={(obj) => obj.id}
                  columns={
                    item.scored_task === true
                      ? scoredTaskColumns
                      : trainingTaskcolumns
                  }
                  pagination={false}
                  dataSource={item.completed_by_members}
                  className="tasks-table"
                />
              </Panel>
            );
          })}
        </Collapse>
      ),
    },
  ];
  let currentLink = "";

  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink += `/${crumb}`;
      if (crumb !== "home" && crumb !== "groups") {
        let newCrumb = crumb.replace("-", " ");
        return (
          <div className="crumb" key={newCrumb}>
            <Link
              // to={currentLink}
              style={{ cursor: "text" }}
            >
              {decodeURI(newCrumb)}
            </Link>
          </div>
        );
      }
    });
  return (
    <Modal
      className="fw-assigned-modal"
      wrapClassName={
        "fw-assigned-modal " +
        (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")
      }
      open={fWAssignedModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Report"}
      cancelText={"Cancel"}
      width={"967px"}
      footer={false}
    >
      {loading && loading == true ? (
        <Spin
          className={
            "loader " +
            (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")
          }
          size="large"
          style={{
            position: "absolute",
            left: "49%",
            top: "50%",
          }}
        />
      ) : (
        <div className="workout-detail-main">
          <div className="heading-row">
            <FaArrowLeftLong
              className="left-arrow"
              style={{ margin: 0, color: color, stroke: color }}
              onClick={() => {
                setFWAssignedModal(false);
                setAssignedFwData({});
              }}
            />
            <h1>{assignedFWData.title}</h1>
          </div>
          <div className="breadcrumbs">
            <div style={{ display: "flex" }}>
              {crumbs}
              <div className="crumb" style={{ cursor: "text" }}>
                <a style={{ cursor: "text" }}>
                  Workouts - {assignedFWData?.title}
                </a>
              </div>
            </div>
          </div>
          <hr />
          <>
            <Row gutter={16} className="graph-row">
              <Col className="gutter-row left-col" span={16}></Col>
              <Col className="gutter-row right-col" span={12}>
                <div style={style} className="div-1">
                  <h2>Workout Information</h2>
                  <Row
                    gutter={16}
                    className="inner-row"
                    style={{ marginBottom: 10 }}
                  >
                    <Col className="gutter-row left-col" span={12}>
                      <div style={style} className="div-1">
                        <h2>{assignedFWData.total_tasks}</h2>
                        <span>Total Tasks</span>
                      </div>
                    </Col>
                    <Col className="gutter-row right-col" span={12}>
                      <div style={style} className="div-1">
                        <h2>{assignedFWData.total_reps}</h2>
                        <span>Total Reps</span>
                      </div>
                    </Col>
                  </Row>

                  <Row
                    gutter={16}
                    className="inner-row"
                    style={{ marginTop: 10 }}
                  >
                    <Col className="gutter-row left-col" span={24}>
                      <div style={style} className="div-1">
                        <h2>{assignedFWData.assignee}</h2>
                        <span>Members Doing These Tasks</span>
                      </div>
                    </Col>
                  </Row>
                  <div className="end-date">
                    Ends:
                    <span>
                      {assignedFWData.end_date
                        ? getDate(assignedFWData.end_date)
                        : ""}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <Tabs
              defaultActiveKey="1"
              items={tabItems}
              className={
                "workout-detail-tabs " +
                (selectedSport === 1 ? "orange-theme" : "blue-theme")
              }
            />
          </>
          {/* } */}
        </div>
      )}
    </Modal>
  );
};

export default AssignedFWModal;
