import React, { useState , useContext} from "react";
import {
  Select,
  Modal,
  Form,
  DatePicker
} from "antd";
import DownArrow from "../../../assets/images/down-arrow.svg";
import dayjs from "dayjs";
import Calendar from "../../../assets/images/calendar.svg";
import { UserContext } from "../../../context/user-context";
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};








const oneTimeOptions = [];
for (let i = 1; i <= 10; i++) {
  oneTimeOptions.push({
    value: i.toString(10),
    label: i.toString(10),
  });
}

const SubscriptionModal = ({ subscriptionModal, setSubscriptionModal }) => {
  const {selectedSport} = useContext(UserContext)
  const dateFormat = "ddd. MMMM D, YYYY";
  const [form] = Form.useForm();
  const handleOk = (e) => {};
  const handleCancel = () => {
    setSubscriptionModal(false);
  };
  return (
    <Modal
      wrapClassName={"subscription-modal " + (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")}
      open={subscriptionModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Add"}
      cancelText={"Cancel"}
      width={"624px"}
    >
      <div className="header">
      <h1>Add Subscription</h1>
      <span>Update combine measurements</span>
      </div>
      <Form
        form={form}
        name="dynamic_rule"
        style={{
          padding: "0 30px ",
        }}
        className="sport-form"
      >
        <Form.Item
          {...formItemLayout}
          name="start"
          label="Subscription Start"
        >
            <DatePicker
              className={"date-picker " + (selectedSport === 1 ? "orange-picker" : "blue-picker")}
              rootClassName={selectedSport === 1 ? "orange-picker" : "blue-picker"}
              defaultValue={dayjs("Jan 01, 2022", dateFormat)}
              format={dateFormat}
              suffixIcon={<img src={Calendar} />}
              style={{height:38}}
            />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="expiry"
          label="Subscription Expiry"
        >
            <DatePicker
              className={"date-picker " + (selectedSport === 1 ? "orange-picker" : "blue-picker")}
              rootClassName={selectedSport === 1 ? "orange-picker" : "blue-picker"}
              defaultValue={dayjs("Jan 01, 2022", dateFormat)}
              format={dateFormat}
              suffixIcon={<img src={Calendar} />}
              style={{height:38}}
            />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SubscriptionModal;
