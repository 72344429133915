import React, { useContext, useState } from "react";
import "./tasks.scss";
import TaskList from "../../Groups/tasks-list/tasks-list";
import { Select, Dropdown, Button } from "antd";
import DownArrow from "../../../assets/images/arrows.svg";
import ReplaceTask from "../replace-task-modal/replace-task-modal";
import EditTask from "../../Tasks/edit-task-modal/edit-task-modal"
import DeleteTaskModal from "../../Tasks/delete-task/delete-task-modal";
import { UserContext } from "../../../context/user-context";
import { getDateWithTimeWithoutDay } from "../../../util/config";
const Tasks = ({ tasks }) => {
  const { selectedSport } = useContext(UserContext)
  const [replaceTaskModal, setReplaceTaskModal] = useState(false)
  const [editTaskModal, setEditTaskModal] = useState(false)
  const [deleteTaskModal, setDeleteTaskModal] = useState(false)
  const items = [
    {
      key: "replace",
      label: "Replace",
    },
    {
      key: "edit",
      label: "Edit",
    },
    {
      key: "delete",
      label: "Delete",
    },
  ]
  return (
    <div className="tasks-main">
      <div style={{ display: "flex" }}>
        <h1>Overview</h1>
      </div>
      <div className="tasks-list">
        {tasks?.map((item, index) => {
          let tags = item.tags.split(",");

          return (
            <div className="list">
              <div style={{ display: "flex", width: "100%" }}>
                <h3>{item.title}</h3>
                {/* <Select
                      placeholder="Actions"
                      optionFilterProp="children"
                      popupClassName="action-select-dropdown"
                      onChange={onchange}
                      className="action-select"
                      placement="bottomRight"
                      options={[
                        {
                          value: "replace",
                          label: "Replace",
                        },
                        {
                          value: "edit",
                          label: "Edit",
                        },
                        {
                          value: "delete",
                          label: "Delete",
                        },
                      ]}
                    /> */}
                {/* <Dropdown
                  placeholder="Actions"
                  className={"custom-dropdown " + (selectedSport === 1 ? "orange-theme-dropdown" : "blue-theme-dropdown")}
                  menu={{
                    items,
        
                    onClick: ({ key }) => {
                      if (key === "replace") {
                        setReplaceTaskModal(true);
                      }else if(key === "edit"){
                        setEditTaskModal(true)
                      } 
                      else {
                        setDeleteTaskModal(true)
                        // setSelectedSport(record);
                        // setEditSportModal(true);
                      }
                    },
                  }}
                  overlayClassName={"custom-dropdown-menu " + (selectedSport === 1 ? "orange-theme-dropdown" : "blue-theme-dropdown")}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    Actions
                    <img src={DownArrow} />
                  </a>
                </Dropdown> */}
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div className="task-added">
                  Added on:
                  <span className="time"> {getDateWithTimeWithoutDay(item.created_at)} </span>by
                  <span className="name"> {item.created_by}</span>
                </div>
                <div className="score" style={{ marginLeft: "auto" }}>
                  {item.score_based_test === false ? "Training task" : "Scored task"}
                  <span className="green-tag" style={{ textTransform: "capitalize" }}> {item.skill_level}</span>
                </div>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div className="tags">
                  Tags:
                  {/* {selectedTask.tags.toLowerCase().split(",")} */}
                  {tags.length > 0 && !tags.includes("")
                    ? tags.map((item, index) => (
                      <Button
                        className="purple-tag"
                        key={index}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item}
                      </Button>
                    ))
                    : ""}
                </div>

                <div
                  className="task-added"
                  style={{ padding: "14px 0", marginLeft: "auto" }}
                >
                  Taken by:
                  <span className="name">{item.taken_by} Members</span>
                </div>
              </div>
            </div>
          )
        })}

      </div>
      <ReplaceTask replaceTaskModal={replaceTaskModal} setReplaceTaskModal={setReplaceTaskModal} />
      <EditTask editTaskModal={editTaskModal} setEditTaskModal={setEditTaskModal} />
      <DeleteTaskModal deleteTaskModal={deleteTaskModal} setDeleteTaskModal={setDeleteTaskModal} />
    </div>
  );
};
export default Tasks;
