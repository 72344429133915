import React, { useState, useRef, useContext } from "react";
import { Row, Col, Tabs, Select, Card, Button, Table } from "antd";
import "./purchases.scss";
import Video from "../../../assets/Snail.mp4";
import Play from "../../../assets/images/play.svg";
import CreditModal from "./credits-modal";
import BSA from "./bsa";
import Challenges from "./challenges";
import SubscriptionModal from "./subscription-modal";
import { PurchasesContext, PurchasesContextProvider } from "../../../context/user-context/purchses-context";
import TeamSubscriptionPurchase from "./team-subscription";
import { UserContext } from "../../../context/user-context";
const PurchasesPage = ({profileDetail}) => {
  const {selectedSport} = useState(UserContext)
  const {bsaPurchaseList , creditModal , setCreditModal} = useContext(PurchasesContext)
  const vidRef = useRef(null);
  const onChange = (key) => {
  };
  const items = [
    {
      key: "1",
      label: `BSA`,
      children: <BSA setCreditModal={setCreditModal}/>

    },
    {
      key: "2",
      label: `Team Subscription `,
      children: <TeamSubscriptionPurchase setCreditModal={setCreditModal} profileDetail={profileDetail}/>

    },
    // {
    //   key: "3",
    //   label: `Challenges`,
    //   children:<Challenges />
    // },
  ];
  return (
    <div className="purchases-main">
      <h1>Purchases</h1>
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        className={"purchases-tabs " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
      />
      <CreditModal creditModal={creditModal} setCreditModal={setCreditModal}/>
    </div>
  );
};
const Purchases = ({profileDetail}) => {
  return(
    <PurchasesContextProvider>
      <PurchasesPage profileDetail={profileDetail}/>
    </PurchasesContextProvider>
  )
}
export default Purchases;
