import React, { useContext, useState, Fragment, useEffect, useRef, useCallback } from "react";
import "./announcements.scss";
import dayjs from "dayjs";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Input,
  Button,
  Spin,
  Dropdown,
  Empty,
} from "antd";
import Calendar from "../../../assets/images/calendar.svg";
import Comments from "../../../assets/images/comment.svg";
import Avatar from "react-avatar";
import {
  AnnouncementsContextProvider,
  AnnouncementsContext,
} from "../../../coach-context/group-context/announcements-context";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { getTime, getDateWithTime } from "../../../util/config";
import CreateAnnouncement from "./create-announcement-modal";
import DownArrow from "../../../assets/images/down-arrow.svg";
import EditAnnouncement from "./edit-announcements-modal";
import DeleteAnnouncementModal from "./delete-announcement-modal";
import { TwilioService } from "../../../context/twilio-service";
import { UserContext } from "../../../context/user-context";

TimeAgo.addDefaultLocale(en);
const { TextArea } = Input;

const AnnouncementsPage = ({generalDetail}) => {
  const {darkButtonBackground ,  selectedSport} = useContext(UserContext)
  const chatClientChannel = useRef(null)
  const timeAgo = new TimeAgo("en-US");

  const {
    sort,
    setSort,
    announcementsList,
    totalAnnouncements,
    addComment,
    getPostComments,
    allComments,
    comment,
    commentValue,
    setCommentValue,
    modal,
    setModal,
    loading,
    editAnnouncementModal,
    setEditAnnouncementModal,
    setClickedAnnouncement,
    deleteModal,
    setDeleteModal, getComments, postId, setPostId,
    commentsData, setCommentsData, setTwillioId,
    accessToken, twillioId,
  } = useContext(AnnouncementsContext);
  const [commentList, setCommentList] = useState([])
  const [newListnerMessage, setNewListnerMessage] = useState(null);
  const [commentLine, setCommentLine] = useState([{ commentId: "", text: "" }]);
  const dateFormatList = "DD MMM YYYY";
  let commentCounter = 1;
  const onChangeSelect = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => console.log(value);
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const enterCommentLine = (e) => {
    if (e.charCode === 13) {
      setCommentLine([
        ...commentLine,
        { commentId: commentCounter++, text: commentValue },
      ]);
      setCommentValue("");
    }
  };

  const handleCommentValue = (e) => {
    setCommentValue(e.target.value);
  };
  const items = [
    {
      key: "edit",
      label: "Edit Announcement",
    },
    {
      key: "delete",
      label: "Delete Announcement",
    },
  ];
  useEffect(() => {
    // console.log(commentList)
    if (commentList) {
      const isExist = commentList.findIndex(
        element => element?.id == newListnerMessage?.id
      )
      if (isExist == -1 && postId
        // && submissionDetail && submissionDetailModal
      ) {
        let commentListArray = [...commentList, newListnerMessage]
        // getChat();
        getPostComments(postId)
        if (commentList.length > 0) {
          setCommentList(commentListArray)
        }
      }
    }
  }, [newListnerMessage,
    // submissionDetail
  ])
  const setChannelEvents = useCallback(channel => {
    chatClientChannel.current = channel
    chatClientChannel?.current.on("messageAdded", message => {
      const newMessage = TwilioService.getInstance().parseMessage(message)
      console.log("New ,essages", message, newMessage)
      setNewListnerMessage({
        text: message?.state?.body,
        created_at: message?.state?.timestamp,
        id: message?.state?.attributes?.comment_id,
        announcement: message?.state?.attributes?.announcement_id,
        profile: {
          id: message?.state?.attributes?.sender_id,
          name: message?.state?.attributes?.sender_username,
          avatar: message?.state?.attributes?.sender_avatar
        }
      })
    })
    return chatClientChannel.current
  }, [])
  useEffect(() => {
    if (accessToken !== "" && twillioId !== "") {
      // configureChannelEvent(commentsData.access_token, commentsData.twilio_sid)
      configureChannelEvent(accessToken, twillioId)
      console.log(commentsData)
      setCommentList(commentsData)
    }
  }, [accessToken, twillioId, commentsData])



  const configureChannelEvent = async (token, twilioSid) => {
    try {
      const client = await TwilioService.getInstance().getChatClient(token)
      try {
        const channel = await client.getChannelBySid(twilioSid)
        const currentChannel = await setChannelEvents(channel)
        console.log("Response", channel);
      } catch (error) {
        console.log("Inner Error", error)
      } finally {
        // setLoading(false);
      }
    } catch (error) {
      console.log("Error", error)
    }
  }
  const handleMessageAdded = () => {
    // Alert.
    // alert("Remove")
  }

  useEffect(() => {
    return () => {
      if (chatClientChannel?.current) {
        chatClientChannel?.current?.removeListener(
          "messageAdded",
          handleMessageAdded
        )
        TwilioService.getInstance().clientShutdown()
      }
    }
  }, [])
  // useEffect(()=>{
  //   if(announcementsList){
  //     console.log(announcementsList)
  //     for (let i = 0; i < announcementsList.length; i++) {
  //       console.log(announcementsList[i])
  //     }
  //     }
  // },[announcementsList])
  function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }
  return (
    <div className="announcements-main">
      <div style={{ display: "flex", marginBottom: 32 }}>
        <h1>Announcements</h1>
        {/* <button className="dark-base-btn" 
            // onClick={showModal}
            >
          <img src={Plus} />
          Add Workout
        </button> */}
        <Button className="dark-base-btn" onClick={() => setModal(true)} style={{background : darkButtonBackground}} disabled={generalDetail.group_expiry}>
          Create Announcement
        </Button>
      </div>
      {loading && loading == true ? (
        <Spin
          className={"loader " + (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")}
          size="large"
          style={{
            position: "absolute",
            left: "49%",
          }}
        />
      ) :
        announcementsList && announcementsList.length > 0 ?
          <>
            <hr />
            <div style={{ display: "flex", paddingTop: "40px", marginBottom: 32 }}>
              <h2>{announcementsList.length} Announcements</h2>
              {/*  <div style={{ marginLeft: "auto" }}>
          <Select
            placeholder="Newest"
            onChange={(e) => setSort(e)}
            value={sort}
            suffixIcon={<img src={DownArrow} />}
            className="select-1"
            options={[
              {
                value: true,
                label: "Newest",
              },
              {
                value: false,
                label: "Oldest",
              },
            ]}
          />

          <DatePicker
            // picker="month"
            placeholder="Select Date"
            className="month-picker"
            suffixIcon={<img src={Calendar} />}
            // defaultValue={dayjs("01 JAN 2015", dateFormatList)}
            format={dateFormatList}
          />
          <DatePicker
            onChange={onChange}
            picker="year"
            className="year-picker"
            placeholder="Year"
          />
        </div>*/}
            </div>

            {announcementsList?.length > 0 && announcementsList?.toReversed().map(function (item, index) {
              // let recentComment =
              //   Object.keys(comment).length > 0 && comment.post.split("- ");
              // getPostComments(item.id)
              return (
                <Row className="announcements" key={index}>
                  {/* <Col span={1}>
              <span className="dot"></span>
            </Col> */}
                  <Col span={24}>
                    <div style={{ display: "flex" }}>
                      <h3 className="title">{item.title}</h3>

                      <Dropdown
                        placeholder="Actions"
                        className={"custom-dropdown " + (selectedSport === 1 ? "orange-theme-dropdown" : "blue-theme-dropdown")}
                        disabled={generalDetail.group_expiry}
                        menu={{
                          items,

                          onClick: ({ key }) => {
                            setClickedAnnouncement(item)
                            if (key === "delete") {
                              setDeleteModal(true)
                            } else {
                              setEditAnnouncementModal(true);
                            }
                          },
                        }}
                        overlayClassName={"custom-dropdown-menu " + (selectedSport === 1 ? "orange-theme-dropdown" : "blue-theme-dropdown")}
                        >
                        <a onClick={(e) => e.preventDefault()}>
                          Actions
                          <img src={DownArrow} />
                        </a>
                      </Dropdown>
                    </div>
                    <div className="writer">
                      {/* Posted: */}
                      {/* <span className="time"> {getTime(item.created_at)}</span> */}
                      <span style={{ marginRight: "5px" }}>by</span>
                      <span
                        className="name"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.profile.username}
                      </span>
                    </div>
                    <div className="content">{item.text}</div>
                    <div className="writer">
                      Posted:
                      <span className="time">
                        {" "}
                        {getDateWithTime(item.schedule_start)}
                      </span>
                      {/* <span style={{ margin: "0 5px" }}>by</span> */}
                      {/* <span className="name" style={{textTransform:"capitalize"}}>{item.profile.username}</span> */}
                    </div>
                    <div className="writer">
                      Expiring:
                      <span className="time">
                        {" "}
                        {getDateWithTime(item.schedule_end)}
                      </span>
                      {/* <span style={{ margin: "0 5px" }}>by</span> */}
                      {/* <span className="name" style={{textTransform:"capitalize"}}>{item.profile.username}</span> */}
                    </div>
                    <div className="comments">
                      <img src={Comments} />
                      <div onClick={() => {
                        // getComments(item.id);
                        setTwillioId(item.chat_room.twilio_channel_sid)
                        setPostId(item.id)
                        getPostComments(item.id)
                      }}
                      style={{cursor:"pointer"}}>
                        {item.total_comments} comments
                      </div>
                    </div>
                    {/* {item.id === allComments.id ? (
                <ul className="comment-box">
                  {allComments.comments
                    .slice(0)
                    .reverse()
                    .map((item, index) => {
                      return (
                        <li
                          style={{ marginTop: "20px", display: "flex" }}
                          key={index}
                        >
                          {!item.sender.avatar ? (
                            <Avatar
                              name={item.sender.username}
                              color="#F15A29"
                              className="user-avatar"
                            />
                          ) : (
                            <img src={item.sender.avatar}></img>
                          )}
                          <div className="name-div">
                            <h3 className="user-name">
                              {item.sender.username}{" "}
                              <span className="time">
                                {timeAgo.format(new Date(item.created_at))}
                              </span>{" "}
                            </h3>
                            <h4 className="comment">{item.comment}</h4>
                          </div>
                          <span className="reply">Reply</span>
                        </li>
                      );
                      // }
                    })}
                </ul>
              ) : null} */}
                    {commentsData && commentsData.map((item1, index) => {
                      return (
                        item.id === item1.announcement_id &&
                        <div
                          className="comment-box"
                          style={{ marginTop: "20px", display: "flex" }}
                          key={index}
                        >
                          {item1.sender.avatar === null ? (
                            <Avatar
                              name={item1.sender.username}
                              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                              className="user-avatar"
                            />
                          ) : (
                            <img src={item1.sender.avatar}></img>
                          )}
                          <div className="name-div">
                            <h3 className="user-name">
                              {item1.sender.username}{" "}
                              <span className="time">
                                {timeAgo.format(convertUTCDateToLocalDate(new Date(item1.timestamp)))}
                              </span>{" "}
                            </h3>
                            <h4 className="comment">{item1.message}</h4>
                          </div>
                          {/* <span className="reply">Reply</span> */}
                        </div>)
                    })}
                    {/* {commentsData && Object.keys(commentsData).length > 0 
                //  && recentComment[1] === item1.title 
                ? (
                  <div
                    className="comment-box"
                    style={{ marginTop: "20px", display: "flex" }}
                  >
                    {item.sender.avatar === null ? (
                      <Avatar
                        name={item.sender.username}
                        color="#F15A29"
                        className="user-avatar"
                      />
                    ) : (
                      <img src={item.sender.avatar}></img>
                    )}
                    <div className="name-div">
                      <h3 className="user-name">
                        {comment.sender.username}{" "}
                        <span className="time">
                          {timeAgo.format(new Date(comment.created_at))}
                        </span>{" "}
                      </h3>
                      <h4 className="comment">{comment.comment}</h4>
                    </div>
                    <span className="reply">Reply</span>
                  </div>
                ) : null} */}
                    <div className="write-comment">Write your comments</div>
                    <TextArea
                      // onClick={()=>setPostId(item.id)}
                      onClick={() => {
                        setTwillioId(item.chat_room.twilio_channel_sid)
                        setPostId(item.id)
                        getPostComments(item.id)
                      }
                      }
                      // value={commentValue[[item.id]]}
                      value={commentValue}
                      onChange={handleCommentValue}
                      placeholder="Your response here..."
                      autoSize={{
                        minRows: 5,
                        maxRows: 6,
                      }}
                      onKeyPress={enterCommentLine}
                    />
                    <div style={{ textAlign: "end" }}>
                      <Button
                        className="dark-base-btn"
                        onClick={() => addComment(commentValue, item.id)}
                        style={{opacity:commentValue.length === 0 || generalDetail.group_expiry  ? 0.5 : 1 , cursor: commentValue.length === 0 || generalDetail.group_expiry ? "not-allowed" : "pointer" , background : darkButtonBackground }}
                        disabled={commentValue.length === 0 || generalDetail.group_expiry}
                      >
                        Send
                      </Button>
                    </div>
                  </Col>
                </Row>
              )
            })


            }
          </>
          : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ marginBlock: 130 }}
            />
          )
      }
      {editAnnouncementModal && (
        <EditAnnouncement
          editAnnouncementModal={editAnnouncementModal}
          setEditAnnouncementModal={setEditAnnouncementModal}
        />
      )}
      {modal &&
        <CreateAnnouncement modal={modal} setModal={setModal} />
      }
      {deleteModal &&
        <DeleteAnnouncementModal setDeleteModal={setDeleteModal} deleteModal={deleteModal} />
      }
    </div>
  );
};

const Announcements = ({generalDetail}) => {
  return (
    <AnnouncementsContextProvider>
      <AnnouncementsPage generalDetail={generalDetail}/>
    </AnnouncementsContextProvider>
  );
};
export default Announcements;
