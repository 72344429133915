import React, { useContext, useState } from "react";
import "./profiles-table.scss";
import Exit from "../../../assets/images/exit-up.svg";
import { Input, DatePicker, Table, Select, message, Dropdown } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Search from "../../../assets/images/search.svg";
import Calendar from "../../../assets/images/calendar.svg";
import RowVertical from "../../../assets/images/row-vertical.svg";
import dayjs from "dayjs";
import Filter from "../../../assets/images/filter.svg";
import Breadcrumbs from "../../../coach-module/breadcrumbs/breadcrumb";
import { useNavigate } from "react-router-dom";
import { UserModuleContext } from "../../../context/user-module-context/user-module-context";
import { getDateWithoutDay } from "../../../util/config";
import { UserContext } from "../../../context/user-context";
import DownArrow from "../../../assets/images/arrows.svg";

const { RangePicker } = DatePicker;
const dateFormat = "ddd. MMMM D, YYYY";

const Profile = () => {
  const {
    allProfiles,
    pageNumber,
    totalProfile,
    handleTableChange,
    setPageNumber,
    setSearch,
    setAllProfiles,
    profileLoading,
    setProfileEndDate,setProfileStartDate,setProfileSort,profileSort,
    profilePageSize,
    setProfilePageSize
  } = useContext(UserModuleContext);
  const {accountDetail , color , lightButtonBackground , selectedSport} = useContext(UserContext)
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSearch = (value) => console.log(value);
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const items = [
    {
      key: "detail",
      label: "Detail"
    },
    // {
    //   key: "edit",
    //   label: "Edit",
    // },
    // {
    //   key: "delete",
    //   label: "Delete",
    // },

  ];
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      ellipsis:true,
    },
    {
      title: "USERNAME",
      dataIndex: "username",
      ellipsis:true,
    },
    {
      title: "FULL NAME",
      dataIndex: "fullname",
      ellipsis:true,
      render: (text, record) => {
        return <span>{record.first_name + " " + record.last_name}</span>;
      },
    },
    {
      title: "DOB",
      dataIndex: "dob",
      ellipsis:true,
      render: (text, record) => {
        return <span>{getDateWithoutDay(record.date_of_birth)}</span>;
      },
    },
    {
      title: "GENDER",
      dataIndex: "gender",
    },
    {
      title: "SPORT(S)",
      dataIndex: "sports",
    },
    // {
    //   title: "ACTIONS",
    //   dataIndex: "acttion",
    //   render: (text) => (
    //     <Select
    //       placeholder="Actions"
    //       optionFilterProp="children"
    //       onChange={onChange}
    //       suffixIcon={<img src={DownArrow} />}
    //       filterOption={(input, option) =>
    //         (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
    //       }
    //       options={[
    //         {
    //           value: "jack",
    //           label: "Jack",
    //         },
    //         {
    //           value: "lucy",
    //           label: "Lucy",
    //         },
    //         {
    //           value: "tom",
    //           label: "Tom",
    //         },
    //       ]}
    //     />
    //   ),
    // },
    {
      title: "ACTIONS",
      dataIndex: "member_limit",
      key: "member_limit",
      width: 150,
      render: (text,record, key) => (
        <Dropdown
        placeholder="Actions"
        className={"custom-dropdown " + (selectedSport === 1 ? "orange-theme-dropdown" : "blue-theme-dropdown")}
        menu={{
          items,

          onClick: ({ key }) => {
            if (key === "delete") {
              // deleteRole(record.id);
            }else if(key === "detail"){
              gotoProfileDetail(record.id)
            }
             else {
              // setSelectedRole(record)
              // setEditRoleModal(true)
            }
          },
        }}
        overlayClassName={"custom-dropdown-menu " + (selectedSport === 1 ? "orange-theme-dropdown" : "blue-theme-dropdown")}
        >
        <a onClick={(e) => e.preventDefault()}>
          Actions
          <img src={DownArrow} />
        </a>
      </Dropdown>
      ),
    }
    
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const gotoProfileDetail = (record) => {
    navigate({
      pathname: `./profiles`,
      search: `?id=${record}`,
    });
  };
  const searchProfile = (e) => {
    if (e.target.value.length >= 3) {
      setPageNumber(1);
      setAllProfiles([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setAllProfiles((groups) => []);
      setSearch("");
    }
  };
  const info = () => {
    message.info("You don't have permissions. Contact to Admin");
  };
  const onChangeRangePicker = (e) => {
    if (e) {
      setProfileStartDate(e[0].format("YYYY-MM-DD"));
      setProfileEndDate(e[1].format("YYYY-MM-DD"));
    } else {
      setProfileStartDate("");
      setProfileEndDate("");
    }
  };
  return (
    <div className="profile-main">
      <div style={{ display: "flex" }}>
        <div>
          <h1>Profile</h1>
          {/* <h6>Home - Users</h6> */}
        </div>

        {/* <button className="red-base-btn" style={{ marginLeft: "auto" }}>
          <img src={Exit} />
          Export
        </button> */}
      </div>
      <div className="table-div">
        {/* <Search
          placeholder="input search text"
          onSearch={onSearch}
          style={{
            width: 200,
          }}
        /> */}
        <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchProfile}
          />
          <div className="right-div">
          {/* <Select
            placeholder="Newest"
            onChange={(e) => setProfileSort(e)}
            value={profileSort}
            suffixIcon={<img src={DownArrow} />}
            className="select-input"
            options={[
              {
                value: true,
                label: "Newest",
              },
              {
                value: false,
                label: "Oldest",
              },
            ]}
          /> */}
            <RangePicker
              className="range-picker"
              format={dateFormat}
              suffixIcon={<img src={Calendar} />}
              onChange={  onChangeRangePicker}
            
            />
            <button className="red-base-btn" style={{color : color , background : lightButtonBackground}}>
              <img src={RowVertical} className={selectedSport === 1 ? "orange-theme-svg" : "blue-theme-svg" }/>
              Manage Columns
            </button>
            <button className="red-base-btn" style={{color : color , background : lightButtonBackground}}>
              <img src={Filter} alt="filter" className={selectedSport === 1 ? "orange-theme-svg" : "blue-theme-svg" }/>
            </button>
            {/* <button
              className="dark-base-btn"
              onClick={accountDetail.role?.role_permission.filter(
                (e) =>
                  e.module.name === "Users" &&
                  e.permission.code_name === "ADD"
              ).length === 0 ? info :    () => navigate(`./create-profile`)}
            >
              Create Profile
            </button> */}
          </div>
        </div>
        <Table
          // rowSelection={rowSelection}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: () => gotoProfileDetail(record),
          //   };
          // }}
          onChange={handleTableChange}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current: pageNumber,
            pageSize: profilePageSize,
            total: totalProfile,
          }}
          loading={profileLoading}
          columns={columns}
          dataSource={allProfiles}
          className={"table-container " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
        />
      </div>
    </div>
  );
};

export default Profile;
