import React, { useContext, useEffect, useState } from "react";
import { getDataTraining } from "../../util/api-calls";
import { UserContext } from "../user-context";
export const FeatureWorkoutSubmissionContext = React.createContext({});
export const FeatureWorkoutSubmissionContextProvider = ({ children = {} }) => {
  const { selectedSport } = useContext(UserContext);
  const [sort, setSort] = useState(true);
  const [loading, setLoading] = useState(false);
  const [allFeaturedWorkouts, setAllFeaturedWorkouts] = useState([]);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalWorkouts, setTotalWorkouts] = useState(0);
  const [type, setType] = useState("");
  const [pageNumber,setPageNumber] = useState(1)
  const getAllFeatureWorkouts = () => {
    setLoading(true);
    getDataTraining(
      `admin/all/feature/workout?sports_id=${selectedSport}&search=${search}&order_desc=${sort}&start_date=${startDate}&end_date=${endDate}&offset=${offset}&limit=${limit}&fw_type=${type}`
    )
      .then((res) => {
        setAllFeaturedWorkouts(res.data.results);
        setTotalWorkouts(res.data.total_count);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedSport) {
      getAllFeatureWorkouts();
    }
  }, [sort, search, selectedSport, startDate, endDate, offset, type]);
  const defaultContext = {
    allFeaturedWorkouts,
    loading,
    sort,
    setSort,
    setSearch,
    setStartDate,
    setEndDate,
    totalWorkouts,
    limit,
    setLimit,
    setOffset,
    offset,
    setAllFeaturedWorkouts,
    setType,
    pageNumber,
    setPageNumber
  };
  return (
    <FeatureWorkoutSubmissionContext.Provider value={defaultContext}>
      {children}
    </FeatureWorkoutSubmissionContext.Provider>
  );
};
