import { Navigate } from 'react-router-dom'
import CryptoJS from 'crypto-js';
import CoachModule from '../coach-module';
const CoachPrivateRoutes = () => {
    let tokenData = "";
    let decryptStr = "";
    if (localStorage.getItem("coach_info") !== null) {
      decryptStr = CryptoJS.AES.decrypt(
        localStorage.getItem("coach_info"),
        "sec"
      ).toString(CryptoJS.enc.Utf8);
    }
    if (decryptStr !== "") {
      tokenData = JSON.parse(decryptStr);
    }
    const accessToken = tokenData.access_token;
  let auth = {'token':accessToken}
return (
    auth.token ? <CoachModule/> : <Navigate to='/coach/login'/>
  )
}
export default CoachPrivateRoutes;