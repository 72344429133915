import React, { useContext, useState } from "react";
import {
  Table,
  Input,
  Select,
  DatePicker,
  Dropdown,
  message,
  Tabs,
} from "antd";
import "./featured-workouts-submission-table.scss";
import Search from "../../../assets/images/search.svg";
import { useNavigate } from "react-router-dom";
import DownArrow from "../../../assets/images/arrows.svg";
import Calendar from "../../../assets/images/calendar.svg";
import {
  FeatureWorkoutSubmissionContext,
  FeatureWorkoutSubmissionContextProvider,
} from "../../../context/submission-context/fw-submission-context";
import { UserContext } from "../../../context/user-context";
const FWSubmissionTable = ({ activeTab }) => {
  const dateFormat = "ddd. MMMM D, YYYY";
  const { accountDetail, selectedSport } = useContext(UserContext);
  const {
    allFeaturedWorkouts,
    setSort,
    totalWorkouts,
    limit,
    offset,
    setLimit,
    setOffset,
    setAllFeaturedWorkouts,
    loading,
    setSearch,
    setStartDate,
    setEndDate,
    setType,
    pageNumber,
    setPageNumber
  } = useContext(FeatureWorkoutSubmissionContext);
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { RangePicker } = DatePicker;
  const items = [
    {
      key: "details",
      label: "View Details",
    },
  ];
  const info = () => {
    message.info("You don't have permissions. Contact to Admin");
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Sport",
      dataIndex: "sport_name",
      key: "sport_name",
    },
    {
      title: "tasks",
      dataIndex: "tasks",
      key: "tasks",
    },
    {
      title: "ACTIONS",
      dataIndex: "member_limit",
      key: "member_limit",
      width: 200,
      elipsis: true,
      render: (text, record, key) => {
        return (
          <Dropdown
            placeholder={
              record.status === "final"
                ? "Review Locked"
                : record.status === "rejected"
                ? "Review Rejected"
                : "Actions"
            }
            className={
              "custom-dropdown " +
              (selectedSport === 1
                ? "orange-theme-dropdown"
                : "blue-theme-dropdown")
            }
            disabled={
              record.status === "final" ||
              record.status === "rejected" ||
              record.status === "review"
            }
            menu={{
              items,
              onClick: ({ key }) => {
                if (key === "details") {
                  // if (
                  //   accountDetail.id !== record.assigned_person?.id &&
                  //   !accountDetail.is_superuser
                  // ) {
                  //   info();
                  // } else {
                    navigate({
                      pathname: `./${record.title}`,
                      search: `id=${record.id}&tabs=featured-workouts`,
                    });
                  }
                // }
              },
            }}
            overlayClassName={
              "submission-dropdown-menu custom-dropdown-menu " +
              (selectedSport === 1
                ? "orange-theme-dropdown"
                : "blue-theme-dropdown")
            }
          >
            <a onClick={(e) => e.preventDefault()}>
              {record.status === "final" ? (
                "Review Locked"
              ) : record.status === "rejected" ? (
                "Rejected"
              ) : (
                <>
                  Actions <img src={DownArrow} />
                </>
              )}
            </a>
          </Dropdown>
        );
      },
    },
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleTableChange = (pagination, filters, sorter) => {
    if (pagination.current !== pageNumber) {
      setPageNumber(pagination.current)
      setOffset(pagination.current - 1 +"0");
    }
  };
  const onChangeRangePicker = (e) => {
    if (e) {
      setStartDate(e[0].format("YYYY-MM-DD"));
      setEndDate(e[1].format("YYYY-MM-DD"));
    } else {
      setStartDate("");
      setEndDate("");
    }
  };
  const searchFeatureWorkout = (e) => {
    if (e.target.value.length >= 3) {
      setLimit(10)
      setOffset(0)
      setAllFeaturedWorkouts([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setAllFeaturedWorkouts((allFeaturedWorkouts) => []);
      setSearch("");
    }
  };
  const subItems = [
    {
      key: "",
      label: `All`,
    },
    {
      key: "archive",
      label: `Archive`,
    },
  ];
  const onChange = (key) => {
    setType(key)
  }
  return (
    <div className="submission-table-main">
            <Tabs defaultActiveKey="1" items={subItems} onChange={onChange} className={"custom-tabs " + (selectedSport === 1 ? "orange-theme" : "blue-theme")} destroyInactiveTabPane/>
      <div className="table-div">
        <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchFeatureWorkout}
          />
          <div className="right-div">
            <Select
              placeholder="Newest"
              optionFilterProp="children"
              suffixIcon={<img src={DownArrow} />}
              className="select-input"
              popupClassName={
                "custom-action-select-dropdown " +
                (selectedSport === 1
                  ? "orange-theme-select"
                  : "blue-theme-select")
              }
              onChange={(e) => setSort(e)}
              options={[
                {
                  value: true,
                  label: "Newest",
                },
                {
                  value: false,
                  label: "Oldest",
                },
              ]}
            />
            <RangePicker
              className="range-picker"
              format={dateFormat}
              suffixIcon={<img src={Calendar} />}
              onChange={onChangeRangePicker}
            />
          </div>
        </div>
        <Table
          onChange={handleTableChange}
          rowKey={(obj) => obj.id}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: false,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current:pageNumber,
            pageSize: limit,
            total: totalWorkouts,
          }}
          loading={loading}
          columns={columns}
          dataSource={allFeaturedWorkouts}
          className={
            "table-container " +
            (selectedSport === 1 ? "orange-theme" : "blue-theme")
          }
        />
      </div>
    </div>
  );
};

const FeaturedWorkoutsSubmissionsTable = () => {
  return (
    <FeatureWorkoutSubmissionContextProvider>
      <FWSubmissionTable />
    </FeatureWorkoutSubmissionContextProvider>
  );
};

export default FeaturedWorkoutsSubmissionsTable;
