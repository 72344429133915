import React, { useEffect, useState } from "react";
import { getData, formPostData, jsonPostData } from "../../util/api-calls";
import { useNavigate } from "react-router-dom";
import { Form, message } from "antd";
export const PurchasesContext = React.createContext({});
export const PurchasesContextProvider = ({ children = {} }) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [form] = Form.useForm();
  const [bsaPurchaseList, setBsaPurchaseList] = useState([]);
  const [teamSubPurchaseList, setTeamSubPurchaseList] = useState([]);
  const [creditModal, setCreditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bsaPageNumber , setBsaPageNumber] = useState(1)
  const [bsaPageSize , setBsaPageSize] = useState(10)
  const [totalBsaPurchases , setTotalBsaPurchases] = useState(0)
  const navigate = useNavigate();

  const getBsaPurchaseList = () => {
    setLoading(true);
    getData(`profile/bsa/purchase?profile_id=${params.id}&page=${bsaPageNumber}&page_size=${bsaPageSize}`).then((res)=>{
        setBsaPurchaseList(res.data.results);
        setTotalBsaPurchases(res.data.count)
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = (pagination, filters, sorter) => {
    if (pagination.current !== bsaPageNumber) {
      setBsaPageNumber(pagination.current);
    }
    if (pagination.pageSize !== bsaPageSize) {
      setBsaPageSize(pagination.pageSize);
    }
  };
  const getTeamSubList = (id) => {
    setLoading(true)
    getData(`profile/team/subscription?user_id=${id}`).then((res)=>{
      setTeamSubPurchaseList(res.data.results)
    }).catch((err)=>{

    }).finally(()=>{
      setLoading(false)
    })
  }
  const addCredits = () => {
    let data = {
      "player_id": params.id ,
      "purchase_from": "admin",
      "purchase_count": form.getFieldValue("bsa-credit")
  }
    jsonPostData(`profile/bsa/purchase`,data).then((res)=>{
      form.resetFields();
      setCreditModal(false)
      getBsaPurchaseList()
    }).catch((err)=>{
      console.log(err)
    })
  }
  const defaultContext = {
    bsaPurchaseList,
    loading,addCredits,
    teamSubPurchaseList,form,creditModal,setCreditModal , getBsaPurchaseList , getTeamSubList,
    bsaPageNumber , setBsaPageNumber , bsaPageSize , setBsaPageSize , totalBsaPurchases , setTotalBsaPurchases,handleTableChange
  };
  return (
    <PurchasesContext.Provider value={defaultContext}>
      {children}
    </PurchasesContext.Provider>
  );
};
