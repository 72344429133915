import React, { useContext, useEffect, useState } from "react";
import "./user-account-table.scss";
import Exit from "../../../assets/images/exit-up.svg";
import { Input, DatePicker, Table, Select, Tabs , Dropdown, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Search from "../../../assets/images/search.svg";
import Calendar from "../../../assets/images/calendar.svg";
import RowVertical from "../../../assets/images/row-vertical.svg";
import dayjs from "dayjs";
import Filter from "../../../assets/images/filter.svg";
import DownArrow from "../../../assets/images/down-arrow.svg";
import { useNavigate } from "react-router-dom";
import { UserModuleContext } from "../../../context/user-module-context/user-module-context";
import { getDateWithoutDay } from "../../../util/config";
import { UserContext } from "../../../context/user-context";
const { RangePicker } = DatePicker;
const dateFormat = "ddd. MMMM D, YYYY";

const UserAccountTable = () => {
  const {allUsers,getAllUsers,userPageNumber,setUserPageNumber,totalUsers,
    userStartDate , userEndDate , userSearch,
    setAllUsers , setUserSearch,
    userPageSize,
    setUserPageSize,
    userLoading,
    setUserLoading,
    deleteUser

  } = useContext(UserModuleContext)
  const {accountDetail , darkButtonBackground , color , lightButtonBackground , selectedSport} = useContext(UserContext)
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSearch = (value) => console.log(value);
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  useEffect(()=>{
    setUserLoading(true)
    getAllUsers();
  },[userPageNumber,    userStartDate , userEndDate , userSearch ,     userPageSize
  ])
  const handleTableChange = (pagination, filters, sorter) => {
    if (pagination.current !== userPageNumber) {
      setUserPageNumber(pagination.current);
    }
    if (pagination.pageSize !== userPageSize) {
      setUserPageSize(pagination.pageSize);
    }
  };
  const checkUserDeletePermission = (id) => {
    if(accountDetail.role !== null){
    for (let item of accountDetail.role?.role_permission) {
        if(item.module.name === "Users" && item.permission.code_name === "DELETE"){
          if(item.value === false){
            info();
          }
          else{
           deleteUser(id);
          }
        }
    }
      }else{
        deleteUser(id);

      }
}
  const items = [
    {
      key : "detail",
      label:"View Detail"
    },
    {
      key: "edit",
      label: "Edit",
    },
    {
      key: "delete",
      label: "Delete",
    },

  ];
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width:100,
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone",
      ellipsis:true,
      render:(text)=><span>{text === "" || text === null ? "N/A" : text}</span>
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      ellipsis:true,
    },
    {
      title: "profile type",
      dataIndex: "type",
    },
    {
      title: "added on",
      dataIndex: "created_at",
      render:(text , record)=> (
        <div>{getDateWithoutDay(record.created_at)}</div>

      )
    },
    // {
    //   title: 'status',
    //   dataIndex: 'status',
    // },
    {
      title: "status",
      dataIndex: "is_active",
      render: (text , record) => {return(
      record.is_active === true ? 
        <span className="green-tag">Active</span> :
        <span className="red-tag">Expired</span>
      )
      }
      //     <Select
      //     showSearch
      //     placeholder="Actions"
      //     optionFilterProp="children"
      //     onChange={onChange}
      //     onSearch={onSearch}
      //     filterOption={(input, option) =>
      //       (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      //     }
      //     options={[
      //       {
      //         value: 'jack',
      //         label: 'Jack',
      //       },
      //       {
      //         value: 'lucy',
      //         label: 'Lucy',
      //       },
      //       {
      //         value: 'tom',
      //         label: 'Tom',
      //       },
      //     ]}
      //   />,
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      key: "actions",
      width:150,
      render: (text,record, key) => (
        <Dropdown
        placeholder="Actions"
        className={"custom-dropdown " + (selectedSport === 1 ? "orange-theme-dropdown" : "blue-theme-dropdown")}
        menu={{
          items,

          onClick: ({ key }) => {
            if (key === "delete") {
              if(accountDetail.role !== null){
              for (let item of accountDetail.role?.role_permission) {
                if(item.module.name === "Users" && item.permission.code_name === "DELETE"){
                  if(item.value === false){
                    info();
                  }
                  else{
                    checkUserDeletePermission(record.id)
                    // deleteRole(record.id);                 
                  }
                }
              }
              }
            }else if (key === "detail") {
              gotoUserDetail(record)

            }
            else {
              if(accountDetail.role !== null){
              for (let item of accountDetail.role?.role_permission) {
                if(item.module.name === "Users" && item.permission.code_name === "EDIT"){
                  if(item.value === false){
                    info();
                  }
                  else{
                    navigate({
                      pathname: `./edit-user`,
                      search: `?id=${record.id}`,
                    })                   
                  }
                }
              }
              }         else{
                navigate({
                  pathname: `./edit-user`,
                  search: `?id=${record.id}`,
                })                   
              }
              // setSelectedRole(record)
              // setEditRoleModal(true)
            }
          },
        }}
        overlayClassName={"custom-dropdown-menu " + (selectedSport === 1 ? "orange-theme-dropdown" : "blue-theme-dropdown")}
        >
        <a onClick={(e) => e.preventDefault()}>
          Actions
          <img src={DownArrow} />
        </a>
      </Dropdown>
      ),
    }
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const tabItems = [
    {
      key: "1",
      label: "All  Users",
      //   children: 'Content of Tab Pane 1',
    },
    // {
    //   key: "2",
    //   label: "Pending Profiles",
    //   //   children: 'Content of Tab Pane 2',
    // },
    // {
    //   key: "3",
    //   label: "Blocked",
    //   //   children: 'Content of Tab Pane 3',
    // },
  ];
  const gotoUserDetail = (record) => {
    navigate({
      // pathname: `./${record.name}`,
      pathname:`./user-accounts`,
      search: `?id=${record.id}`,
    });
  };
  const searchAccount = (e) => {
    if (e.target.value.length >= 3) {
      // setPageNumber(1);
      setAllUsers([]);
      setUserSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setAllUsers((usersList) => []);
      setUserSearch("");
    }
  };
  const info = () => {
    message.destroy();
    message.info("You don't have permissions. Contact to Admin");
  };
  const checkUserPermission = () => {
    if(accountDetail.role !== null){
      for (let item of accountDetail.role?.role_permission) {
        if(item.module.name === "Users" && item.permission.code_name === "ADD"){
          if(item.value === false){
            info();
          }
          else{
            navigate(`./create-user`)         
           }
        }
      }
    }else{
      navigate(`./create-user`)         
    }
    }
  return (
    <div className="user-account-table-main">
      <div style={{ display: "flex", padding: "15px 100px 40px" }}>
        <h1>User Accounts</h1>
        {/* <button className="red-base-btn" style={{ marginLeft: "auto" }}>
          <img src={Exit} />
          Export
        </button> */}
      </div>
      <Tabs
        defaultActiveKey="1"
        items={tabItems}
        onChange={onChange}
        className={"user-account-tabs " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
      />
      <div className="table-div">
        <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchAccount}
          />
          <div className="right-div">
            <RangePicker
              className="range-picker"
              format={dateFormat}
              suffixIcon={<img src={Calendar} />}
            />
            {/* <button className="red-base-btn">
            <img src={RowVertical} />
            Manage Columns
          </button> */}
            <button className="red-base-btn" style={{color : color , background : lightButtonBackground}}>
              <img src={Filter} alt="filter" className={selectedSport === 1 ? "orange-theme-svg" : "blue-theme-svg" }/>
            </button>
            <button
              className="dark-base-btn"
              onClick={checkUserPermission}
              style={{background : darkButtonBackground}}
            >
              Create a User
            </button>
          </div>
        </div>
        <Table
          // rowSelection={rowSelection}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: () => gotoUserDetail(record),
          //   };
          // }}
          loading={userLoading}
          onChange={handleTableChange}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current: userPageNumber,
            pageSize: userPageSize,
            total: totalUsers,
          }}
          columns={columns}
          dataSource={allUsers}
          className={"table-container " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
        />
      </div>
    </div>
  );
};

export default UserAccountTable;
