import React, { useContext, useState  , useEffect} from "react";
import { Tabs } from "antd";
import "./index.scss";
import SubmissionsTable from "./bsa-submission-table/submission-table";
import { UserContext } from "../../context/user-context";
import { useNavigate } from "react-router-dom";
import FeaturedWorkoutsSubmissionsTable from "./featured-workouts-submission-table/featured-workouts-submission-table.jsx";
const SubmissionsMain = () => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const navigate = useNavigate();
  const {selectedSport} = useContext(UserContext)
  const [activeTab, setActiveTab] = useState(selectedSport === 1 ? "bsa" : "featured_workouts");
  const onChange = (key) => {
    setActiveTab(key);
  };
  const items = [
    selectedSport === 1 &&
    {
      key: "bsa",
      label: `BSA`,
      children: <SubmissionsTable activeTab={activeTab} />,
    },
    {
      key: "featured_workouts",
      label: `Featured Workouts`,
      children: <FeaturedWorkoutsSubmissionsTable activeTab={activeTab} />,
    },
  ];
  useEffect(() => {
    if (activeTab === "bsa" 
      ) {
      navigate({
        search: `tabs=bsa`,
      });
    } else {
      setActiveTab("featured_workouts")
      navigate({
        search: `tabs=featured-workouts`,
      });
    }
  }, [activeTab]);
  return (
    <div className="submissions-main">
      <Tabs defaultActiveKey={activeTab} items={items} onChange={onChange} className={selectedSport === 1 ? "orange-theme" : "blue-theme"} destroyInactiveTabPane activeKey={activeTab}/>
    </div>
  );
};

export default SubmissionsMain;
