import React, { useContext, useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";
import { AssignedWorkoutContext } from "../../../context/group-context/assigned_workout_context";
import { UserContext } from "../../../context/user-context";
import { AccountSettingsContext } from "../../../context/setting-context/account_setting_context";
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const ChangePassword = ({}) => {
  const { createSubGroup } = useContext(AssignedWorkoutContext);
  const { changePasswordModal, setChangePasswordModal, changePassword } =
    useContext(AccountSettingsContext);
  const { selectedSport, darkButtonBackground } = useContext(UserContext);
  const [form] = Form.useForm();

  const handleOk = () => {
    setChangePasswordModal(false);
    form.resetFields();
  };
  const handleCancel = () => {
    setChangePasswordModal(false);
  };
  useEffect(() => {
    if (changePasswordModal === false) {
      form.resetFields();
    }
  }, [changePasswordModal]);
  return (
    <Modal
      className="change-password-modal"
      wrapClassName={
        selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"
      }
      open={changePasswordModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Create"}
      cancelText={"Cancel"}
      width={"422px"}
      footer={false}
    >
      <h1>Change Password</h1>
      <Form form={form} name="dynamic_rule" onFinish={changePassword}>
        {" "}
        <Form.Item
          name="current_password"
          rules={[
            {
              required: true,
              message: "Please enter current password",
            },
          ]}
        >
          <Input.Password
            placeholder="Current Password"
            type="password"
            className="custom-input"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter new password",
            },
          ]}
        >
          <Input.Password
            placeholder="Password"
            type="password"
            className="custom-input"
          />
        </Form.Item>
        <Form.Item
          name="confirm_password"
          rules={[
            {
              required: true,
              message: "Please enter confirm password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
          dependencies={["password"]}
        >
          <Input.Password
            placeholder="Confirm Password"
            type="password"
            className="custom-input"
          />
        </Form.Item>
        <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
          <Button
            htmlType="submit"
            className="dark-base-btn"
            style={{ background: darkButtonBackground }}
          >
            Change Password
          </Button>
        </Form.Item>
      </Form>{" "}
    </Modal>
  );
};

export default ChangePassword;
