import React, { useEffect, useState } from "react";
import { message, Form, Button, Modal, Select, Dropdown, Menu } from "antd";
import {
  getData,
  formPostData,
  getDataTraining,
  getDataTest,
} from "../../util/api-calls";
import axios from "axios";
const { confirm } = Modal;
let url;
export const SubmissionContext = React.createContext({});
export const SubmissionContextProvider = ({ children = {} }) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("all");
  const [sort, setSort] = useState(true);
  const [assignmentSort, setAssignmentSort] = useState(true);
  const [search , setSearch] = useState("")
  const [assignmentSearch , setAssignmentSearch] = useState("")
  const [submissionsList, setSubmissionsList] = useState([]);
  const [startDate , setStartDate] =useState("")
  const [endDate , setEndDate] = useState("")
  const [assignmentStartDate , setAssignmentStartDate] =useState("")
  const [assignmentEndDate , setAssignmentEndDate] = useState("")
  const [assigneeModal , setAssigneeModal] = useState(false)
  const [selectedReview , setSelectedReview] = useState("")
  const [showAssignments , setShowAssignmemts] = useState(false)
  const [assignmentsList , setAssignmentsList] = useState([])
  const [totalUploads , setTotalUploads] = useState(0)
  const [uploadsLoading ,setUploadsLoading] = useState(false)
  const [uploadsPageNumber , setUploadsPageNumber] = useState(1)
  const [uploadsPageSize , setUploadPageSize] = useState(10)
  const [uploadSort , setUploadSort] = useState(true)
  const [uploadList , setUploadList] = useState([])
  const [uploadSearch , setUploadSearch ] = useState("")
  const [uploadStatus , setUploadStatus] = useState("")
  const [draft , setDraft] = useState(false)
  let user_id = localStorage.getItem("u_id")
  const getPendingTaskList = () => {
    setLoading(true);
    getDataTest(`admin/pending/task/list/?test_status=${status}&search=${search}&sort=${sort}&start_date=${startDate}&end_date=${endDate}`)
      .then((res) => {
        setSubmissionsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getUploadsProgress = () => {
    if(uploadStatus === "" && draft === false){
      url = `admin/test/upload/progress/?sort=${uploadSort}&search=${uploadSearch}`
    }else if(draft === true){
      url = `admin/test/upload/progress/?sort=${uploadSort}&search=${uploadSearch}&is_draft=${draft}`
    }
    else{
      url = `admin/test/upload/progress/?sort=${uploadSort}&search=${uploadSearch}&status=${uploadStatus}`
    }
    getDataTest(url).then((res)=>{
      setUploadList(res.data)
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{
      setUploadsLoading(false)
    })
  }
  const getAssignmentsList = () => {
    setLoading(true);
    getDataTest(`my/assignments/bsa/test/${user_id}?search=${assignmentSearch}&sort=${assignmentSort}&start_date=${assignmentStartDate}&end_date=${assignmentEndDate}`)
      .then((res) => {
        setAssignmentsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(()=>{
    if(showAssignments)
    getAssignmentsList();
  },[showAssignments , assignmentStartDate , assignmentEndDate , assignmentSort , assignmentSearch])
  useEffect(() => {
    getPendingTaskList();
  }, [status,sort,search,startDate,endDate]);
  const defaultContext = {
    getPendingTaskList,
    submissionsList,
    setStatus,status,
    loading,
    sort,
    assignmentSort,
    setAssignmentSort,
    setSort,
    setSubmissionsList,
    setSearch,
    setAssignmentSearch,
    setStartDate,
    setEndDate,
    setAssignmentEndDate,
    setAssignmentStartDate,
    assigneeModal,
    setAssigneeModal,
    selectedReview,
    setSelectedReview,
    showAssignments , 
    setShowAssignmemts,
    assignmentsList,
    setAssignmentsList,
    assignmentSearch,
    search,
    uploadsPageNumber , uploadsPageSize , totalUploads , uploadsLoading , setUploadSort,setUploadList,setUploadSearch , setUploadStatus,
    uploadList,    getUploadsProgress,
    uploadSearch,
    uploadStatus,uploadSort,setDraft,draft,setUploadsLoading
  };
  return (
    <SubmissionContext.Provider value={defaultContext}>
      {children}
    </SubmissionContext.Provider>
  );
};
