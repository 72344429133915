import React, { useState, useContext, useEffect } from "react";
import {
  getData,
  getDataTraining,
  patchDataTraining,
} from "../../util/api-calls";
import { Form, message } from "antd";
import { UserContext } from "../user-context";
import { useNavigate } from "react-router-dom";
export const EditFeatureWorkoutContext = React.createContext({});
export const EditFeatureWorkoutContextProvider = ({ children = {} }) => {
  const { selectedSport } = useContext(UserContext);
  const [form] = Form.useForm();
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const navigate = useNavigate();
  const [detail, setDetail] = useState();
  const [sponsorsList, setSponsorsList] = useState([]);
  const [newTaskArray, setNewTaskArray] = useState([]);
  const [sponsor, setSponsor] = useState({});
  const [current, setCurrent] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);
  const getFeatureWorkoutDetail = () => {
    getDataTraining(`admin/all/feature/workout/${params.id}/detail`)
      .then((res) => {
        setDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const postFeatureWorkout = (values) => {
    const list_tasks = newTaskArray
      .filter(
        (ele, ind) =>
          ind === newTaskArray.findIndex((elem) => elem.id === ele.id)
      )
      .map((e, index) => ({
        id: e.id,
        order: index + 1,
        //   lock: e.lock,
        lock: current === index || index > current ? true : false,
        num_reps: isNaN(e.num_reps) ? 1 : e.num_reps,
        allow_more_reps: e.allow_more_reps,
        legacy_point: e.legacy_points ? e.legacy_points : 0,
        min_score: e.min_score,
      }));
    setLoading(true);
    let start_date = new Date(Date.parse(values.start_date)).toISOString();
    let expiry_Date = new Date(Date.parse(values.expiry_Date)).toISOString();
    let formData = new FormData();
    formData.append("title", values.title);
    if (typeof imageUrl === "object") {
      formData.append("file", imageUrl);
    }else{
      formData.append("image", imageUrl);
    }
    formData.append("fw_id", params.id);
    formData.append("sports_id", selectedSport);
    formData.append(
      "reward_before",
      values.reward_before ? values.reward_before : null
    );
    formData.append(
      "reward_after",
      values.reward_after ? values.reward_after : null
    );
    if (Object.keys(sponsor).length > 0) {
      formData.append("sponsor_id", sponsor.id);
      formData.append("sponsor_name", sponsor.name);
      formData.append("sponsor_website", sponsor.website);
      formData.append("sponsor_icon", sponsor.icon);
    }
    if (values.start_date) {
      formData.append("start_date", start_date);
    }
    if (values.expiry_Date) {
      formData.append("end_date", expiry_Date);
    }
    formData.append("list_tasks", JSON.stringify(list_tasks));
    if (values.completion_bonus) {
      formData.append("completion_bonus", values.completion_bonus);
    }
    patchDataTraining(`feature/workout`, formData)
      .then((res) => {
        // if (res.status === 200) {
        message.success({
          content: "Feature workout created successfully.",
          duration: 1,
        });
        setTimeout(() => {
          navigate("/home/workouts?tab=featured-workouts");
        }, 1200);
        // }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllSponsors = () => {
    getData(`sponsor`)
      .then((res) => {
        setSponsorsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getFeatureWorkoutDetail();
    getAllSponsors();
  }, []);
  const defaultContext = {
    setImageUrl,
    sponsorsList,
    setSponsor,
    newTaskArray,
    setNewTaskArray,
    setCurrent,
    current,
    loading,
    detail,
    postFeatureWorkout,
    sponsor
  };
  return (
    <EditFeatureWorkoutContext.Provider value={defaultContext}>
      {children}
    </EditFeatureWorkoutContext.Provider>
  );
};
