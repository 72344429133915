import React, { useEffect, useState } from "react";
import { message, Form, Button, Modal, Select, Dropdown, Menu } from "antd";
import { getData, formPostData } from "../../util/api-calls";
const { confirm } = Modal;
export const SettingsContext = React.createContext({});
export const SettingsContextProvider = ({ children = {} }) => {
  const [imgSrc , setImgSrc] = useState()
  const [gender , setGender] = useState()
  const [imageUrl , setImageUrl] = useState()
  const [loading , setLoading] = useState(false)
  const [sportsList , setSportsList] = useState([])
  const getSportList = () => {
    getData(`sport`)
      .then((res) => {
        setSportsList(res.data);
        // sportsList.push(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(()=>{
    getSportList()
  } , [
]) 
  const defaultContext = {
    gender,
    setImageUrl,
    setGender,
    imgSrc,
    setImgSrc,
    loading,
    sportsList
  };
  return (
    <SettingsContext.Provider value={defaultContext}>
      {children}
    </SettingsContext.Provider>
  );
};
