import React, { useEffect, useState ,useCallback} from "react";
import {  theme } from "antd";
import { getDataCoach , jsonPostDataCoach } from "../util/coach-api-calls";
export const ChatAIContext = React.createContext({});
export const ChatAIContextProvider = ({ children = {} }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [messageText, setMessageText] = useState("")
  const [questionsList, setQuestionsList] = useState([])
  const [chatHistory, setChatHistory] = useState([])
  const [profileData , setProfileData] = useState({})
  const [userId , setUserId] = useState("")
  const [sessionId , setSessionId] = useState("")
  const {
      token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [chat, setChat] = useState([
  ])
  let profileId = localStorage.getItem("profile_id")
  const getProfile = () =>{
    getDataCoach(`user/profile/${profileId}`).then((res)=>{
      console.log(res)
      setProfileData(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  useEffect(() => {
      if (messageText?.length) {
          SubmitQuestion()
      }
  }, []);

  const SubmitQuestion = useCallback(async () => {
      const body = {
          msg: messageText,
          type: "Human",
          user_id:userId,
          session_id : sessionId
      };
      setChat((prevChat) => [...prevChat, body, []]);
      setMessageText("")
      jsonPostDataCoach(`user/profile/ai/chatbot/chat`, body).then((res) => {
        setUserId(res.data.details.userId)
        setSessionId(res.data.details.sessionId)
          setChat((prevChat) => [...prevChat.slice(0, -1), res.data.details]);
      }).catch((err) => {
          console.log(err)
      })
  }, [messageText]);


  const getListChatHistory = () => {
      getDataCoach(`user/profile/ai/chatbot/list/${profileId}`).then((res) => {
          setChatHistory(res?.data?.chat_history)
      }).catch((err) => {
          console.log(err)
      })
  }

  useEffect(() => {
      getListChatHistory();
      getQuestionList();
      getProfile();
  }, [])
  const getQuestionList = () => {
      getDataCoach(`user/profile/ai/chatbot/questions/1`).then((res) => {
          setQuestionsList(res.data.details)
      }).catch((err) => {
          console.log(err)
      })
  }
  const recentChat = (user_id, session_id) => {
      getDataCoach(`user/profile/ai/chatbot/chat?user_id=${user_id}&session_id=${session_id}`).then((res) => {
          setChat(res?.data?.chat_history)
      }).catch((err) => {
          console.log(err)
      })
  }
  const defaultContext = {
    collapsed,setCollapsed,profileData,
    setChat,chatHistory,recentChat,colorBgContainer,borderRadiusLG,chat,questionsList,setMessageText,messageText,SubmitQuestion,setUserId , setSessionId

  };
  return (
    <ChatAIContext.Provider value={defaultContext}>
      {children}
    </ChatAIContext.Provider>
  );
};
