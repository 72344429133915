import React, { useContext } from "react";
import { Form, Input, Modal } from "antd";
import { AssignedWorkoutContext } from "../../../coach-context/group-context/assigned_workout_context";
import { UserContext } from "../../../context/user-context";
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const SubgroupModal = ({}) => {
  const {
    setSubGroupName,
    subgroupModal,
    setSubgroupModal,
    createSubGroup,
    subGroupName,
  } = useContext(AssignedWorkoutContext);
  const {selectedSport} = useContext(UserContext)
  const [form] = Form.useForm();

  const handleOk = () => {
    setSubgroupModal(false);
    createSubGroup();
    form.resetFields();
  };
  const handleCancel = () => {
    setSubgroupModal(false);
  };
  return (
    <Modal
      className="subgroup-modal"
      wrapClassName={selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"}
      open={subgroupModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Create"}
      cancelText={"Cancel"}
      width={"624px"}
    >
      <h1>Create Subgroup</h1>
      <p>Give this subgroup a name</p>
      <Form form={form}>
        <Form.Item
          label="Subgroup Name"
          name={"subgroup_name"}
          {...formItemLayout}
        >
          <Input
            placeholder="Please enter subgroup name."
            onChange={(e) => setSubGroupName(e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SubgroupModal;
