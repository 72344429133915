import React, { useEffect, useRef, useState } from "react";
import {
  getData,
  patchData,
  jsonPostData,
  deleteItem,
} from "../../util/api-calls";
import { message } from "antd";
export const MemberContext = React.createContext({});
let selectedList = [];
let updateMember = false;
export const MemberContextProvider = ({ children = {} }) => {
  const [membersTotal, setMembersTotal] = useState(0);
  const [ownerTotal, setOwnersTotal] = useState(0);
  const [ownersList, setOwnersList] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreMembers, setHasMoreMembers] = useState(true);
  const [hasMoreOwners, setHasMoreOwners] = useState(true);
  const [allMembers, setAllMembers] = useState([]);
  const [owner, setOwner] = useState([{ id: "", is_owner: "" }]);
  const [search, setSearch] = useState("");
  const [ownerSearch, setOwnerSearch] = useState("");
  const [memberSearch, setMemberSearch] = useState("");
  const [totalMembers, setTotalMembers] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [ownerPageNumber, setOwnerPageNumber] = useState(1);
  const [memberPageNumber, setMemberPageNumber] = useState(1);
  const [imageLoaded, setImageLoaded] = useState("");
  const [memberModal, setMemberModal] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheckMembers, setIsCheckMembers] = useState([]);
  const [isCheckOwners , setIsCheckOwners] = useState([])
  const [isCheck, setIsCheck] = useState([]);
  const [selectedTab, setSelectedTab] = useState("1");
  const [expiryModal , setExpiryModal] = useState(false)
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // toggle flag after first render/mounting
      return;
    }
    if (
      ownersList.length === 0 &&
      updateMember === true &&
      ownerPageNumber === 1
    ) {
      setIsCheckMembers((isCheckMembers) => []);
      setIsCheckAll();
      getGroupOwners();
    }
    if (
      membersList.length === 0 &&
      updateMember === true &&
      memberPageNumber === 1
    ) {
      setIsCheckMembers((isCheckMembers) => []);
      setIsCheckAll();
      getGroupMembers();
    }
  }, [ownersList, ownerPageNumber, membersList, memberPageNumber]);
  useEffect(() => {
    if(search.length > 0){
    listAllMembers();
    }else{
      setTotalMembers(0);
      setAllMembers(allMembers=>[]);
      setPageNumber(1);
      setHasMore(false);
    }
  }, [search]);
  useEffect(() => {
    getGroupMembers();
  }, [memberSearch]);
  useEffect(() => {
    getGroupOwners();
  }, [ownerSearch]);
  const getGroupOwners = (e) => {
    if (e) {
      setOwnerPageNumber(1);
      setMembersList((membersList) => []);
    }
    getData(
      `admin/group/${
        params.id
      }/profile?is_owner=${true}&search=${ownerSearch}&page=${ownerPageNumber}`
    )
      .then((res) => {
        updateMember = false;
        setOwnersTotal(res.data.count);
        setOwnersList([...ownersList, ...res.data.results.profiles]);
        if (res.data.next !== null) {
          setOwnerPageNumber(ownerPageNumber + 1);
          setHasMoreOwners(true);
        } else {
          setHasMoreOwners(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getGroupMembers = (e) => {
    if (e) {
      setPageNumber(1);
      setMembersList((membersList) => []);
    }
    getData(
      `admin/group/${
        params.id
      }/profile?is_owner=${false}&search=${memberSearch}&page=${
        e ? e : memberPageNumber
      }`
    )
      .then((res) => {
        updateMember = false;
        setMembersTotal(res.data.count);
        setMembersList([...membersList, ...res.data.results.profiles]);

        if (res.data.next !== null) {
          setMemberPageNumber(memberPageNumber + 1);
          setHasMoreMembers(true);
        } else {
          setHasMoreMembers(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateMemberStatus = (e, status) => {
    updateMember = true;
    let status1 = status === true ? "True" : "False";
    let formData = new FormData();
    formData.append("is_owner", status1);
    patchData(`admin/profile/${e.member.id}/group/${e.group}`, formData)
      .then((res) => {
        if (status === false) {
          setOwnersList([]);
          setOwnerPageNumber(1);
        } else {
          setMembersList([]);
          setMemberPageNumber(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteMemberOwner = (e, status) => {
    updateMember = true;
    deleteItem(`admin/profile/${e.member.id}/group/${e.group}`)
      .then((res) => {
        if (e.is_owner === true) {
          setOwnersList([]);
          setOwnerPageNumber(1);
        } else {
          setMembersList([]);
          setMemberPageNumber(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const listAllMembers = (e) => {
    let url;
    if (!search) {
      url = `profiles?page=${pageNumber}`;
    } else {
      url = `profiles?search=${search}&page=${pageNumber}`;
    }
    getData(url)
      .then((res) => {
        setTotalMembers(res.data.count);
        setAllMembers([...allMembers, ...res.data.results]);
        if (res.data.next !== null) {
          setPageNumber(pageNumber + 1);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addMembers = (e) => {
    updateMember = true;
    for (let i = 0; i < isCheck.length; i++) {
      selectedList.push({
        id: isCheck[i],
        is_owner: selectedTab === "1" ? true : false,
      });
    }
    jsonPostData(`admin/group/${params.id}/profile`, selectedList)
      .then((res) => {
        selectedList = [];
        setIsCheck([]);
        setMemberModal(false);
        if (selectedTab === "1") {
          setOwnersList([]);
          setOwnerPageNumber(1);
        } else {
          setMembersList([]);
          setMemberPageNumber(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const profileSearch = (e) => {
    setAllMembers((allMembers) => []);
    let query = e.target.value;
    getData(`admin/group/${params.id}/profile?search=${query}`)
      .then((res) => {
        setAllMembers([...allMembers, ...res.data]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteMembers = () => {
    updateMember = true;
    let formData = {
      group_id: params.id,
      profile_ids: isCheckMembers,
    };
    jsonPostData(`admin/group/profiles/delete`, formData)
      .then((res) => {
        message.success({
          content: "Member(s) deleted successfully",
          duration: 1,
        });
        setIsCheckMembers((isCheckMembers) => []);
        setIsCheckAll();
        setOwnerPageNumber(1)
        setOwnersList([])
        setMemberPageNumber(1);
        setMembersList([]);

      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onChangeTabs = (key) => {
    updateMember = true;
    setSelectedTab(key);
    if (key === "1") {
      setOwnersList((ownersList) => []);
      setOwnerPageNumber(1);
    } else {
      setMembersList((membersList) => []);
      setMemberPageNumber(1);
    }
  };
  const defaultContext = {
    onChangeTabs,
    hasMore,
    totalMembers,
    ownerTotal,
    membersTotal,
    setMembersTotal,
    ownersList,
    membersList,
    search,
    setSearch,
    updateMemberStatus,
    setAllMembers,
    listAllMembers,
    searchResult,
    setSearchResult,
    setOwnersList,
    allMembers,
    owner,
    setOwner,
    setTotalMembers,
    imageLoaded,
    setImageLoaded,
    addMembers,
    memberModal,
    setMemberModal,
    deleteMemberOwner,
    selectedTab,
    setSelectedTab,
    isCheck,
    setIsCheck,
    setOwnerPageNumber,
    setOwnerSearch,
    setMembersList,
    setMemberPageNumber,
    setMemberSearch,
    hasMoreOwners,
    getGroupOwners,
    profileSearch,
    setPageNumber,
    deleteMembers,
    isCheckMembers,
    setIsCheckMembers,
    isCheckAll,
    setIsCheckAll,
    hasMoreMembers,
    setHasMoreMembers,
    getGroupMembers,
    isCheckOwners , 
    setIsCheckOwners,
    expiryModal,
    setExpiryModal,
    setHasMore
  };
  return (
    <MemberContext.Provider value={defaultContext}>
      {children}
    </MemberContext.Provider>
  );
};
