import React, { useState , useContext, useEffect } from "react";
import { getDataTraining , jsonPostDataTraining } from "../../util/api-calls";
import { Form } from "antd";
import { UserContext } from "../user-context";
export const FeatureWorkoutDetailContext = React.createContext({});
export const FeatureWorkoutDetailContextProvider = ({ children = {} }) => {
  const {selectedSport} = useContext(UserContext)
  const [form] = Form.useForm();
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
const [detail , setDetail] = useState()
const [imageLoaded, setImageLoaded] = useState("");
const [search , setSearch] = useState("")
const [sort , setSort] = useState("desc")
const [leaderboards , setLeaderboards] = useState([])
const [selectedTask , setSelectedTask] = useState()
  const getFeatureWorkoutDetail = () => {
    getDataTraining(`admin/all/feature/workout/${params.id}/detail`).then((res)=>{
        setDetail(res.data)
    }).catch((err)=>{
        console.log(err)
    })
  }
  const getFeatureWrkoutLeaderoard = () =>{
    let url = ""
    if(selectedTask && selectedTask !== "all"){
      url = `feature/task/leaderboard?search=${search}&sort=${sort}&task_id=${selectedTask}`
    }else{
      url = `feature/task/leaderboard?workout_id=${params.id}&search=${search}&sort=${sort}`
    }
    getDataTraining(url).then((res)=>{
      setLeaderboards(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    getFeatureWorkoutDetail();
  },[])
  const defaultContext = {
detail,
imageLoaded,
setImageLoaded,
search , 
setSearch , 
setSort , sort,
getFeatureWrkoutLeaderoard,leaderboards , setLeaderboards,
selectedTask,
setSelectedTask
  };
  return (
    <FeatureWorkoutDetailContext.Provider value={defaultContext}>
      {children}
    </FeatureWorkoutDetailContext.Provider>
  );
};
