import * as React from "react";
import { ConfigProvider, Slider } from "antd";
import { format } from "date-fns";
import Expand from "../../assets/images/expand_video.svg";
import screenfull from "screenfull";
import { findDOMNode } from "react-dom";
import Play from "../../assets/images/play.svg";
import {
  PauseOutlined,
  PlayCircleOutlined,
  CaretRightOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BsFillVolumeUpFill, BsFillVolumeMuteFill , BsArrowsAngleExpand } from "react-icons/bs";

const PlayerControls = (props) => {
  const { state, dispatch, wrapperRef, playerRef } = props;
  const handleSound = (newValue) => {
    dispatch({ type: "VOLUME", payload: newValue });
  };

  const handleFullscreen = () => {
    screenfull.toggle(findDOMNode(wrapperRef.current));
  };

  const handleSeek = (newValue) => {
    playerRef.current.seekTo(newValue);
  };
  const renderSeekSlider = () => {
    return (
      <ConfigProvider
        theme={{
          components: {
            Slider: {
              /* here is your component tokens */
              handleSize: 13,
              handleSizeHover: 15,
              handleColor: "#F15A29",
              handleActiveColor: "#F15A29",
            },
          },
        }}
      >
        {" "}
        <Slider
          aria-label="Time"
          className={"video-player__slider video-player__slider--seek"}
          min={0}
          max={state.duration}
          step={0.01}
          value={state.progress.playedSeconds}
          onChange={handleSeek}
        />
      </ConfigProvider>
    );
  };

  const renderPlayButton = () => {
    return (
      <div onClick={() => dispatch({ type: "TOGGLE_PLAY" })}>
        {state.playing ? (
          <PauseOutlined
            style={{
              fontSize: "1rem",
              color: "white",
              display: "flex",
              marginRight: 10,
            }}
          />
        ) : (
          <img
            src={Play}
            style={{
              fontSize: "1rem",
              color: "white",
              width: 16,
              display: "flex",
              marginRight: 10,
            }}
          />
        )}
      </div>
    );
  };

  const renderSoundSlider = () => {
    return (
      <div
        spacing={2}
        style={{ display: "flex", alignItems: "center", width: "50%" }}
      >
        <BsFillVolumeMuteFill style={{ fontSize: 20, color: "white" }} />
        <ConfigProvider
          theme={{
            components: {
              Slider: {
                /* here is your component tokens */
                handleSize: 8,
                handleSizeHover: 10,
                handleColor: "#F15A29",
                handleActiveColor: "#F15A29",
              },
            },
          }}
        >
          <Slider
            aria-label="Volume"
            className={"video-player__slider video-player__slider--sound"}
            max={1}
            step={0.01}
            value={state.volume}
            onChange={handleSound}
          />
        </ConfigProvider>
        <BsFillVolumeUpFill style={{ fontSize: 20, color: "white" }} />
      </div>
    );
  };

  const renderDurationText = () => {
    return (
      <div
        spacing={2}
        style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}
      >
        <div variant="body2" style={{ color: "white", fontSize: 11 }}>
          {format(new Date(state.progress.playedSeconds * 1000), "mm:ss")}
          {" / "}
          {format(new Date(state.duration * 1000), "mm:ss")}
        </div>
      </div>
    );
  };

  const renderFullscreenButton = () => {
    return (
      <div onClick={handleFullscreen}>
        <BsArrowsAngleExpand  style={{ fontSize: "2rem", color: "white" , fontSize: 12,marginLeft: 10}}/>
      </div>
    );
  };
  return (
    <div className={"video-player__controls div2"}>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        {renderSeekSlider()}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }} spacing={2}>
          {renderPlayButton()}
          {renderSoundSlider()}
          {renderDurationText()}
        </div>
        <div style={{ display: "flex", alignItems: "center" }} spacing={2}>
          {renderFullscreenButton()}
        </div>
      </div>
    </div>
  );
};

export default PlayerControls;
