import React, { useRef, useEffect, useState, useContext } from "react";
import "./workouts-detail.scss";
import { Card, Col, Collapse, Progress, Row, Tabs , Table } from "antd";
import Expand from "../../assets/images/expand.svg";
import Thumbnail from "../../assets/images/chatLogo.svg"
import CollapseIcon from "../../assets/images/collapse.svg";
import ApexCharts, { ApexOptions } from "apexcharts";
import Avatar from "react-avatar"
import { LockOutlined } from "@ant-design/icons";
import { FaArrowLeftLong } from "react-icons/fa6";
import Video from "../../assets/Snail.mp4";
import Play from "../../assets/images/play.svg";
import Player from "../Player/Player";
import ReactPlayer from "react-player";
import { getDataTraining } from "../../util/api-calls";
import { getDateWithTimeWithoutDay, getDate, getDateWithoutDay } from "../../util/config";
import Tasks from "../Groups/workout-detail/tasks";
import { UserContext } from "../../context/user-context";
import SubmissionDetailModal from "./submission-detail-modal";
import { UserWorkoutDetailContext, UserWorkoutDetailContextProvider } from "../../context/user-context/workout-detail-context";
let chartColor = "",
  chartHeight = "";
const style = {
  padding: "5px 0",
};
const WorkoutDetailsPage = ({
  setWorkoutDetail,
  workoutDetail,
  selectedWorkout,
  setGroupOwner,
  groupOwner,
  setSelectedWorkout,
}) => {
  const {color , selectedSport} = useContext(UserContext)
  const { Panel } = Collapse;
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  const [ownerWorkoutDetail, setOwnerWorkoutDetail] = useState({});
  const {selectedSubmission , setSubmissionDetail , setSubmissionDetailModal , setSelectedSubmission , getSubmissionDetail} = useContext(UserWorkoutDetailContext)
  // const [selectedSubmission , setSelectedSubmission] = useState("")
  // const [submissionDetailModal, setSubmissionDetailModal] = useState(false)
  // const [ submissionDetail , setSubmissionDetail] = useState({})
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const onChange = (key) => {
  };
  // const getSubmissionDetail = (e) => {
  //   console.log(selectedSubmission , e)
  //   getDataTraining(`admin/task/submission/media/${selectedSubmission === "" ? e : selectedSubmission}`).then((res)=>{
  //     setSubmissionDetail(res.data)
  //   }).catch((err)=>{
  //     console.log(err)
  //   })
  // }
  const getProfileWorkoutDetail = () => {
    getDataTraining(`admin/profile/${params.id}/workout/${selectedWorkout.id}`)
      .then((res) => {
        setWorkoutDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getOwnerWorkouts = () => {
    getDataTraining(`admin/workout/${selectedWorkout.id}`)
      .then((res) => {
        setOwnerWorkoutDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (groupOwner === false) {
      getProfileWorkoutDetail();
    } else {
      getOwnerWorkouts();
    }
  }, [selectedWorkout]);
  const tabItems = [
    {
      key: "1",
      label: `Tasks`,
      children: <Tasks tasks={ownerWorkoutDetail.tasks} />,
    },
  ];
  const trainingTaskcolumns = [
    {
      title: "Completed By",
      dataIndex: "member",
      width: "50%",
      render: (text, record) => (
        <div span={20} className="member-div">
          {record.avatar !== null ? (
            <img src={record.avatar}></img>
          ) : (
            <Avatar
              name={record.name}
              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
              className="user-avatar"
            />
          )}
          <div className="name-div">
            <h3 className="user-name">{record.name}</h3>
            <h4 className="user-handle">@{record.username}</h4>
          </div>
        </div>
      ),
    },
    {
      title: "Reps",
      dataIndex: "reps",
      render: (text, record) => (
        <span>
          {record.reps_done}/{record.total_reps}
        </span>
      ),
    },
    {
      title: "Completion",
      dataIndex: "completion_percentage",
      render: (text, record) => (
        <span>
          {record.completion_percentage}%
        </span>
      ),
    },
    // {
    //   render: (record) => {
    //     console.log(record)
    //     return(
    //     <div style={{ display: "flex" }}>
    //       <div
    //         style={{ cursor: "pointer" ,color : color}}
    //         onClick={() => {
    //           // setSubmissionRecord(record)
    //           // setViewSubmissions(true);
    //           // getSubmissions(record)
    //         }}
    //         className="view-submissions"
    //       >
    //         View submissions
    //       </div>
    //       {record?.recent_upload === true &&
    //         <span className="new-sub"></span>
    //       }
    //     </div>
    //   )
    // }
    // }
  ];
  const scoredTaskColumns = [
    {
      title: "Completed By",
      dataIndex: "member",
      width: "50%",
      render: (text, record) => (
        <div span={20} className="member-div">
          {record.avatar !== null ? (
            <img src={record.avatar}></img>
          ) : (
            <Avatar
              name={record.name}
              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
              className="user-avatar"
            />
          )}
          <div className="name-div">
            <h3 className="user-name">{record.name}</h3>
            <h4 className="user-handle">@{record.username}</h4>
          </div>
        </div>
      ),
    },
    {
      title: "Score",
      dataIndex: "score",
      render: (text, record) => (
        <span>{record.score ? record.score : "N/A"}</span>
      ),
    },
    {
      title: "Completion",
      dataIndex: "completion_percentage",
      render: (text, record) => (
        <span>
          {record.completion_percentage}%
        </span>
      )
    },
    // {
    //   render: (record) => (
    //     <div style={{ display: "flex" }}>
    //       <div
    //         style={{ cursor: "pointer" , color: color }}
    //         onClick={() => {
    //           // setSubmissionRecord(record)
    //           // setViewSubmissions(true);
    //           // getSubmissions(record)
    //         }}
    //         className="view-submissions"
    //       >
    //         View submissions
    //       </div>
    //       {record?.recent_upload === true &&
    //         <span className="new-sub"></span>
    //       }
    //     </div>
    //   )
    // }
  ];
  return (
    <div className="workout-details-main">
      {groupOwner === false ? (
        <>
          <div className="heading-row">
            <FaArrowLeftLong
              className="left-arrow"
              style={{ margin:"33px 0 8px 23px"  , color : color , stroke : color}}
              onClick={() => {
                setWorkoutDetail(false);
                setSelectedWorkout({});
              }}
            />
            <h1>{workoutDetail.title}</h1>
          </div>
          {/* <span className="bread-crumb">Challenges - Shooting License</span> */}
          <hr />
          <Row gutter={16} className="graph-row">
            <Col className="gutter-row right-col" span={12}>
              <div style={style} className="div-1">
                <h2>Workout Information</h2>
                <div className="pending-tasks">
                  {workoutDetail.reps_done}/
                  <span>{workoutDetail.total_reps} reps</span>
                </div>
                <div className="percentage">
                  {workoutDetail.completion_percentage}%
                </div>

                <Progress
                  percent={workoutDetail.completion_percentage}
                  showInfo={false}
                  className={selectedSport === 1 ? "orange-progress-bar" : "blue-progress-bar" }
                />

                <Row
                  gutter={16}
                  className="inner-row"
                  style={{ marginBottom: 10 }}
                >
                  <Col className="gutter-row left-col" span={12}>
                    <div style={style} className="div-1">
                      <h2>{workoutDetail.total_tasks}</h2>
                      <span>Total Tasks</span>
                    </div>
                  </Col>
                  <Col className="gutter-row right-col" span={12}>
                    <div style={style} className="div-1">
                      <h2>{workoutDetail.total_reps}</h2>
                      <span>Total Reps</span>
                    </div>
                  </Col>
                </Row>

                {/* <Row gutter={16} className="inner-row" style={{ marginTop: 10 }}>
              <Col className="gutter-row left-col" span={24}>
                <div style={style} className="div-1">
                  <h2>{workoutDetail.assignee}</h2>
                  <span>Members Doing These Tasks</span>
                </div>
              </Col>
            </Row> */}
                <div className="end-date">
                  Ends:
                  <span>{getDate(workoutDetail.end_date)}</span>
                </div>
              </div>
            </Col>
          </Row>
          <h2 className="task-heading">Tasks</h2>
          <Collapse
            onChange={onChange}
            expandIconPosition="end"
            expandIcon={({ isActive }) =>
              !isActive ? <img src={Expand} /> : <img src={CollapseIcon} className={"minus-icon " + (selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon")}/>
            }
            className="collapsible-rows"
          >
            {workoutDetail.tasks?.map((item, index) => {
              return (
                <Panel
                  key={index}
                  header={
                    <div
                      className="collapse-label"
                      style={{ textTransform: "capitalize" }}
                    >
                      <Row style={{ width: "100%" }}>
                        <Col span={18} style={{ fontSize: 16 }}>
                          {item.title} ({item.skill_level})
                          <span>
                            {item.scored_task ? "Scored Task" : "Training Task"}
                          </span>
                        </Col>
                        <Col span={3}>
                          {" "}
                          {item.is_competed === true ? (
                            <div className="status">Completed</div>
                          ) : item.is_competed === false &&
                            item.completion_percentage === 0 ? (
                            <button
                              className="yellow-tag"
                              style={{ marginTop: "-3px" }}
                            >
                              Pending
                            </button>
                          ) : (
                            <Progress
                              percent={item.completion_percentage}
                              showInfo={false}
                              className={selectedSport === 1 ? "orange-progress-bar" : "blue-progress-bar" }
                            />
                          )}
                        </Col>
                        <Col span={3}>
                          <div className="reps">
                            Reps:{" "}
                            <span>
                              {item.reps_done}/{item.total_reps}
                            </span>
                          </div>
                        </Col>
                        {/* <Col span={2}>
                      <div className="points">
                        Points: <span>{item.score}</span>
                      </div>
                    </Col> */}
                      </Row>
                    </div>
                  }
                >
                  <div className="task-data">
                    <h4>
                      You have completed {item.reps_done} of your{" "}
                      {item.total_reps} reps.
                    </h4>
                    <Row gutter={16} className="task-row submissions">
                    {item.submissions.map((detail, index) => (
                      detail.is_validated !== false && detail.status !== "pending" &&
                      <Col
                        className="gutter-row"
                        span={8}
                        style={{ paddingBottom: 16 }}
                        key={index}
                      >
                        <div style={style} className="div-1">
                          <Card
                            hoverable
                            style={{ cursor: detail.is_reviewed === true || detail.status === "live" ? "pointer" : "default", height: 230 }}
                            className="video-card"
                            onClick={
                              detail.is_reviewed === true || detail.status === "live" ?
                                () => { setSelectedSubmission(detail.id); setSubmissionDetailModal(true); getSubmissionDetail(detail.id) } : null}
                            cover={
                              detail?.is_video_progress === true
                                && detail?.upload_progress < 100
                                && detail.is_draft === false && detail.status !== 2
                                ?
                                <Progress
                                  percent={detail?.upload_progress}
                                  status="active"
                                  className="custom-progress-bar uploading-progress"
                                />
                                :
                                detail?.is_video_progress === true
                                  && detail?.upload_progress !== 0
                                  && detail.is_draft === true && detail.status !== 2 ?
                                      <Progress
                                        percent={detail?.upload_progress}
                                        status="active"
                                        className="custom-progress-bar uploading-progress"
                                      />
                                     : detail?.is_video_progress === true &&
                                      detail?.upload_progress < 100 &&
                                      detail.is_draft === true ? (
                                      <>
                                        <div
                                          className={
                                            "status " +
                                            (detail.is_reviewed === true
                                              ? "green-tag"
                                              : detail.is_video_progress ===
                                                  true &&
                                                detail?.upload_progress === 100
                                              ? "yellow-tag"
                                              : detail.status === "live"
                                              ? "purple-tag"
                                              : detail.is_draft === true &&
                                                detail.status !== 2
                                              ? "orange1-tag"
                                              : detail.status === 2
                                              ? "red-tag"
                                              : "red-tag")
                                          }
                                          style={{
                                            textTransform: "capitalize",
                                            width:
                                              detail.status === "live"
                                                ? 130
                                                : 88,
                                          }}
                                        >
                                          {detail.is_reviewed === true
                                            ? "Completed"
                                            : detail.is_video_progress ===
                                                true &&
                                              detail?.upload_progress === 100
                                            ? "Transcoding"
                                            : detail.status === "live"
                                            ? "Ready for Review"
                                            : detail.is_draft === true &&
                                              detail.status !== 2
                                            ? "Draft"
                                            : detail.status === 2
                                            ? "Failed"
                                            : "Failed"}
                                        </div>

                                        <div
                                          id="video-container"
                                          style={{
                                            // background: "#F9F9F9",
                                            background: "#fff",
                                            borderRadius: 6,
                                          }}
                                        >
                                          {detail?.media_thumbnail ? (
                                            <div
                                              style={{
                                                display: "grid",
                                                placeItems: "center",
                                              }}
                                            >
                                              <img
                                                src={detail.media_thumbnail}
                                                style={{
                                                  width: "100%",
                                                  height: "164px",
                                                }}
                                              />
                                              <img
                                                src={Play}
                                                style={{
                                                  width: 35,
                                                  position: "absolute",
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              style={{
                                                display: "grid",
                                                placeItems: "center",
                                                height: "100%",
                                              }}
                                            >
                                              <img
                                                src={Thumbnail}
                                                style={{
                                                  width: "40%",
                                                  marginTop: "12%",
                                                }}
                                              ></img>
                                            </div>
                                          )}
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          className={
                                            "status " +
                                            (detail.is_reviewed === true
                                              ? "green-tag"
                                              : detail.is_video_progress ===
                                                  true &&
                                                detail?.upload_progress === 100
                                              ? "yellow-tag"
                                              : detail.status === "live"
                                              ? "purple-tag"
                                              : detail.is_draft === true &&
                                                detail.status !== 2
                                              ? "orange1-tag"
                                              : detail.status === 2
                                              ? "red-tag"
                                              : "red-tag")
                                          }
                                          style={{
                                            textTransform: "capitalize",
                                            width:
                                              detail.status === "live"
                                                ? 130
                                                : 88,
                                          }}
                                        >
                                          {detail.is_reviewed === true
                                            ? "Completed"
                                            : detail.is_video_progress ===
                                                true &&
                                              detail?.upload_progress === 100
                                            ? "Transcoding"
                                            : detail.status === "live"
                                            ? "Ready for Review"
                                            : detail.is_draft === true &&
                                              detail.status !== 2
                                            ? "Draft"
                                            : detail.status === 2
                                            ? "Failed"
                                            : "Failed"}
                                        </div>
                                        {/* } */}

                                        <div id="video-container">
                                          {detail?.media_thumbnail ? (
                                            // <ReactPlayer
                                            //   ref={vidRef}
                                            //   url={detail.task_media}
                                            //   controls
                                            //   playing
                                            //   width={"100%"}
                                            //   height={"100%"}
                                            //   light={detail.media_thumbnail}
                                            //   playIcon={false}
                                            // // playIcon={<img src={Play} style={{ width: 35 }} />}
                                            // />
                                            <div
                                              style={{
                                                display: "grid",
                                                placeItems: "center",
                                              }}
                                            >
                                              <img
                                                src={detail.media_thumbnail}
                                                style={{
                                                  width: "100%",
                                                  height: "164px",
                                                }}
                                              />
                                              <img
                                                src={Play}
                                                style={{
                                                  width: 35,
                                                  position: "absolute",
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            // <img src={Thumbnail} className="thumbnail"></img>
                                            <div
                                              style={{
                                                display: "grid",
                                                placeItems: "center",
                                                height: "100%",
                                              }}
                                            >
                                              <img
                                                src={Thumbnail}
                                                style={{
                                                  width: "40%",
                                                  marginTop: "12%",
                                                }}
                                              ></img>
                                            </div>
                                          )}
                                        </div>
                                      </>
                                    )
                                  }
                                >
                                  {item.scored_task === true && (
                                    <div>
                                      <div className="score">
                                        Score:{" "}
                                        {
                                          // detail.score === 0 &&
                                          detail.is_reviewed === true ? (
                                            //  || detail.score
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: 14,
                                                color: "rgba(0, 0, 0, 0.88)",
                                              }}
                                            >
                                              {detail.score}
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: 14,
                                                color: "rgba(0, 0, 0, 0.88)",
                                              }}
                                            >
                                              N/A
                                            </span>
                                          )
                                        }
                                      </div>
                                    </div>
                                  )}
                                  <div
                                    className="date"
                                    style={{
                                      marginTop:
                                        item.scored_task === false ? 20 : 0,
                                    }}
                                  >
                                    Submitted:{" "}
                                    <span style={{ fontWeight: "bold" }}>
                                      {getDateWithoutDay(detail.created_at)}
                                    </span>
                                  </div>
                                </Card>
                              </div>
                            </Col>
                          )
                      )}
                    </Row>
                  </div>
                </Panel>
              );
            })}
          </Collapse>
        </>
      ) : (
        <>
          <div className="heading-row">
            <FaArrowLeftLong
              className="left-arrow"
              style={{ margin: "33px 0 8px 23px", color: color, stroke: color }}
              onClick={() => {
                setWorkoutDetail(false);
                setSelectedWorkout({});
              }}
            />
            <h1>{ownerWorkoutDetail.title}</h1>
          </div>
          {/* <span className="bread-crumb">Challenges - Shooting License</span> */}
          <hr />
          <Row gutter={16} className="graph-row">
            <Col className="gutter-row left-col" span={16}></Col>
            <Col className="gutter-row right-col" span={12}>
              <div style={style} className="div-1">
                <h2>Workout Information</h2>
                {/* <div className="pending-tasks">
                  {workoutDetail.tasks_completed}/
                  <span>{workoutDetail.total_tasks} tasks</span>
                </div>
                <div className="percentage">
                  {workoutDetail.completion_percentage}%
                </div>
    
                <Progress
                  percent={workoutDetail.completion_percentage}
                  showInfo={false}
                  className={selectedSport === 1 ? "orange-progress-bar" : "blue-progress-bar" }
                />
     */}
                <Row
                  gutter={16}
                  className="inner-row"
                  style={{ marginBottom: 10 }}
                >
                  <Col className="gutter-row left-col" span={12}>
                    <div style={style} className="div-1">
                      <h2>{ownerWorkoutDetail.total_tasks}</h2>
                      <span>Total Tasks</span>
                    </div>
                  </Col>
                  <Col className="gutter-row right-col" span={12}>
                    <div style={style} className="div-1">
                      <h2>{ownerWorkoutDetail.total_reps}</h2>
                      <span>Total Reps</span>
                    </div>
                  </Col>
                </Row>

                <Row
                  gutter={16}
                  className="inner-row"
                  style={{ marginTop: 10 }}
                >
                  <Col className="gutter-row left-col" span={24}>
                    <div style={style} className="div-1">
                      <h2>{ownerWorkoutDetail.assignee}</h2>
                      <span>Members Doing These Tasks</span>
                    </div>
                  </Col>
                </Row>
                <div className="end-date">
                  Ends:
                  <span>{getDate(ownerWorkoutDetail.end_date)}</span>
                </div>
              </div>
            </Col>
          </Row>
          {/* <Tabs
            defaultActiveKey="1"
            items={tabItems}
            onChange={onChange}
            className={"workout-detail-tabs " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
          /> */}
          <>
            <h2 className="task-heading">Tasks</h2>
            <Collapse
              onChange={onChange}
              expandIconPosition="end"
              expandIcon={({ isActive }) =>
                !isActive ? (
                  <img src={Expand} />
                ) : (
                  <img
                    src={CollapseIcon}
                    className={
                      "minus-icon " +
                      (selectedSport === 1
                        ? "orange-theme-icon"
                        : "blue-theme-icon")
                    }
                  />
                )
              }
              className="collapsible-rows"
            >
              {ownerWorkoutDetail.tasks?.map((item, index) => {
                return (
                  <Panel
                    key={index}
                    // onClick={() => setTaskDetail(item)}
                    header={
                      <div className="collapse-label">
                        <Row style={{ width: "100%" }}>
                          <Col
                            span={17}
                            style={{ fontSize: 16, display: "flex" }}
                          >
                            {item.title} ({item.skill_level})
                            <span>
                              {item.scored_task
                                ? "Scored Task"
                                : "Training Task"}
                            </span>
                            {item.lock && (
                              <div className="task-lock">
                                <LockOutlined
                                  style={{
                                    fontSize: 12,
                                    color: "rgba(126, 130, 153, 1)",
                                  }}
                                />
                              </div>
                            )}
                          </Col>
                          <Col span={3}>
                            {" "}
                            {item.is_competed === true ? (
                              <div className="status">Completed</div>
                            ) : item.is_competed === false &&
                              item.completion_percentage === 0 ? (
                              <button
                                className="yellow-tag"
                                style={{ marginTop: "-3px" }}
                              >
                                Pending
                              </button>
                            ) : (
                              <Progress
                                percent={item.completion_percentage}
                                showInfo={false}
                                className={
                                  selectedSport === 1
                                    ? "orange-progress-bar"
                                    : "blue-progress-bar"
                                }
                              />
                            )}
                          </Col>
                          <Col span={4}>
                            <div className="points">
                              Completed by: <span>{item.completed_by}</span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    }
                  >
                    <Table
                      rowKey={(obj) => obj.id}
                      columns={
                        item.scored_task === true
                          ? scoredTaskColumns
                          : trainingTaskcolumns
                      }
                      pagination={false}
                      dataSource={item.completed_by_members}
                      className="tasks-table"
                    />
                  </Panel>
                );
              })}
            </Collapse>
          </>
        </>
      )}
            <SubmissionDetailModal
            //  generalDetail={generalDetail}
              submissionData={workoutDetail?.tasks} />
    </div>
  );
};
const WorkoutDetails = ({
  setWorkoutDetail,
  workoutDetail,
  selectedWorkout,
  setGroupOwner,
  groupOwner,
  setSelectedWorkout,
}) => {
  return (
    <UserWorkoutDetailContextProvider>
      <WorkoutDetailsPage
        setWorkoutDetail={setWorkoutDetail}
        workoutDetail={workoutDetail}
        selectedWorkout={selectedWorkout}
        setGroupOwner={setGroupOwner}
        groupOwner={groupOwner}
        setSelectedWorkout={setSelectedWorkout}
      />
    </UserWorkoutDetailContextProvider>
  );
};

export default WorkoutDetails;
