import { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  DatePicker,
  Col,
  Dropdown,
  Row,
  Space,
  Button,
  Skeleton,
  Spin,
  Modal,
  Form,
  Input,
} from "antd";
import Calendar from "../../../assets/images/calendar.svg";
import Plus from "../../../assets/images/plus-red.svg";
import Avatar from "react-avatar";
import DownArrow from "../../../assets/images/down-arrow.svg";
import User from "../../../assets/images/dual-user.svg";
import { getDate } from "../../../util/config";
import InfiniteScroll from "react-infinite-scroll-component";
import "./assigned-feature-workout.scss";
import { UserContext } from "../../../context/user-context";
import {
  FWAssignedContext,
  FWAssignedContextProvider,
} from "../../../context/group-context/fw-assigned-context";
import dayjs from "dayjs";
import EditFeatureSubgroupModal from "../existing-workout/edit-feature-subgroup-modal";
import { useNavigate } from "react-router-dom";
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const FeatureAssignedWorkoutPage = ({
  setShowAllWorkouts,
  createdWorkout,
  setMembersView,
  setNewWorkout,
}) => {
  const navigate = useNavigate();
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const { color, lightButtonBackground, selectedSport, darkButtonBackground } =
    useContext(UserContext);
  const [form] = Form.useForm();
  const {
    setSubgroupModal,
    setCheckedItems,
    getGroupMembers,
    setIsCheckMembers,
    isCheckMembers,
    subGroups,
    setCheckedGroups,
    members,
    loading,
    imageLoaded,
    setImageLoaded,
    assignWorkout,
    setEditSubGroup,
    checkedGroups,
    allProfiles,
    setAllProfiles,
    setAllGroups,
    checkedItems,
    setSubGroupMembers,
    clickedSubgroup,
    setClickedSubGroup,
    allGroupMembers,
    setAllGroupMembers,
    fwData,
    setSubGroupName,
    subgroupModal,
    createSubGroup,
  } = useContext(FWAssignedContext);
  const [allMembers, setAllMembers] = useState(true);
  const showSubGroupModal = () => {
    setSubgroupModal(true);
  };
  const dateFormat = "ddd. MMMM D, YYYY @ h:mm A";
  const items = [
    {
      key: "1",
      label: "Last Name A-Z",
    },
    {
      key: "2",
      label: "Last Name Z-A",
    },
  ];
  const selectAllMembers = (e, item) => {
    subGroups.map((e) => {
      setAllGroups((prevGroup) => [...prevGroup, e.id]);
      e.members.map((e) => {
        setIsCheckMembers((prevMembers) => [...prevMembers, e]);
      });
    });
    setCheckedItems(!checkedItems);
    for (let i = 0; i < subGroups.length; i++) {
      setCheckedGroups((prevMembers) => [...prevMembers, subGroups[i]]);
    }
    for (let i = 0; i < members.length; i++) {
      setIsCheckMembers((prevMembers) => [...prevMembers, members[i]]);
    }
    if (checkedItems) {
      setIsCheckMembers([]);
      setAllGroups([]);
      setCheckedGroups([]);
    }
  };
  useEffect(() => {
    if (isCheckMembers.length === 0) {
      setCheckedItems(false);
    } else {
      setCheckedItems(true);
    }
  }, [isCheckMembers]);
  const handleClick = (e, item) => {
    const { id, checked } = e.target;
    setCheckedItems(true);
    setIsCheckMembers([...isCheckMembers, item]);
    if (!checked) {
      setIsCheckMembers(isCheckMembers.filter((prev) => prev !== item));
    }
    if (isCheckMembers.length === 0) {
      setCheckedItems(false);
    }
  };
  const handleGroup = (e, item) => {
    const { id, checked } = e.target;
    setCheckedGroups([...checkedGroups, item]);
    if (!checked) {
      setCheckedGroups(checkedGroups.filter((prev) => prev !== item));
      // setIsCheckMembers(isCheckMembers.filter((prev) => prev !== item.members));
      setIsCheckMembers(
        isCheckMembers.filter((el) => !item.members.includes(el))
      );
    }
  };
  const profiles = [],
    subGroupId = [];
  const assignAllMembers = (e) => {
    setIsCheckMembers((isCheckMembers) => []);
    if (e?.target?.checked === true || e === true) {
      setAllMembers(true);
      subGroups.map((e) => {
        setAllGroups((prevGroup) => [...prevGroup, e.id]);
        e.members.map((e) => {
          setAllProfiles((prevMembers) => [...prevMembers, e]);
        });
      });
      members.map((e) => {
        setAllProfiles((prevMembers) => [...prevMembers, e]);
      });
      setAllGroupMembers(true);
    } else {
      setAllMembers(false);
      setAllProfiles((allProfiles) => []);
      setAllGroups((allGroups) => []);
      setAllGroupMembers(false);
    }
  };
  useEffect(() => {
    if (members?.length > 0) assignAllMembers(true);
  }, [members]);
  const assignedWorkout = () => {
    // if (
    //   startDate === "" ||
    //   startDate === null ||
    //   endDate === "" ||
    //   endDate === null
    // ) {
    // //   if (startDate === "" || startDate === null) {
    // //     setStartDateError("Please select start date");
    // //   }
    // //   if (endDate === "" || endDate === null) {
    // //     setEndDateError("Please select end date");
    // //   }
    // } else {
    assignWorkout(
      createdWorkout,
      setNewWorkout,
      setMembersView,
      profiles,
      subGroupId
    );
    // }
  };

  const handleOk = () => {
    setSubgroupModal(false);
    createSubGroup();
    form.resetFields();
  };
  const handleCancel = () => {
    setSubgroupModal(false);
  };
  return (
    <div className="fw-assigned-main">
      <div className="fw-assigned">
        <div style={{ display: "flex", marginBottom: "30px" }}>
          <h3>Assign This Workout</h3>
          {/* <span className={selectedSport === 1 ? "orange-tag" : "blue-tag"}>Change</span> */}
        </div>
        <div className="tasks-list">
          <div className="list">
            <div style={{ display: "flex", width: "100%" }}>
              <h3>{fwData?.title}</h3>
              <span
                className="green-tag"
                style={{ textTransform: "capitalize" }}
              >
                {fwData?.skill_level}
              </span>
            </div>
            <div className="task-added">
              Added on:
              <span className="time"> {getDate(fwData?.created_at)} </span>
              by
              <span className="name"> {fwData?.created_by}</span>
            </div>
          </div>
        </div>
        <div className="time-limit">
          <h3>Duration</h3>
          <div className="form-data">
            <div className="date">
              <label htmlFor="start-date">Start Date</label>
              <br />
              <DatePicker
                className={
                  "date-picker " +
                  (selectedSport === 1 ? "orange-picker" : "blue-picker")
                }
                format={dateFormat}
                style={{ height: "38px" }}
                suffixIcon={<img src={Calendar} />}
                disabled
                value={dayjs(fwData?.start_date)}
                rootClassName={
                  selectedSport === 1 ? "orange-picker" : "blue-picker"
                }
              />
            </div>
            <div className="date" style={{ marginLeft: "30px" }}>
              <label htmlFor="end-date">End Date</label>
              <br />
              <DatePicker
                className={
                  "date-picker " +
                  (selectedSport === 1 ? "orange-picker" : "blue-picker")
                }
                format={dateFormat}
                style={{ height: "38px" }}
                suffixIcon={<img src={Calendar} />}
                disabled
                value={dayjs(fwData?.ending_on)}
                rootClassName={
                  selectedSport === 1 ? "orange-picker" : "blue-picker"
                }
              />
            </div>
          </div>
        </div>
        <Checkbox
          className="custom-checkbox"
          onChange={assignAllMembers}
          checked={allMembers}
        >
          <p style={{ margin: 0, fontSize: 16, color: "#000" , fontWeight:600 }}>
            Assign to all group members
          </p>
        </Checkbox>
        <div
          className="assign-to"
          style={{
            opacity: allProfiles && allProfiles.length > 0 ? 0.5 : "unset",
          }}
        >
          <h3 style={{ marginBottom: 10 }}>Assign To Individuals</h3>

          <p>Select the group members you want to assign this task to.</p>
          <p>
            You can also create a subgroup using the selected members by
            clicking on “Create Subgroup”
          </p>
          <Button
            className="red-base-btn"
            disabled={isCheckMembers && isCheckMembers.length < 2}
            onClick={showSubGroupModal}
            style={{
              color: color,
              background: lightButtonBackground,
              marginLeft: 0,
              height: 48,
            }}
          >
            <img
              src={Plus}
              className={
                selectedSport === 1 ? "orange-theme-svg" : "blue-theme-svg"
              }
            />
            Create Subgroup
          </Button>
        </div>
        <div
          className="members-div"
          style={{
            opacity: allProfiles && allProfiles.length > 0 ? 0.5 : "unset",
          }}
        >
          <div style={{ display: "flex", width: "100%", marginBottom: "48px" }}>
            <Checkbox
              className="custom-checkbox"
              onChange={selectAllMembers}
              checked={checkedItems}
            />

            <h2 style={{ marginLeft: 20 }}>
              Members ({subGroups && subGroups.length + members.length}){" "}
            </h2>
            <Dropdown
              className="sort-dropdown"
              menu={{ items }}
              overlayClassName="sort-dropdown-menu"
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Last Name A-Z
                  <img src={DownArrow} />
                </Space>
              </a>
            </Dropdown>
          </div>
          {loading && loading == true ? (
            <Spin
              className={
                "loader " +
                (selectedSport === 1
                  ? "orange-theme-loader"
                  : "blue-theme-loader")
              }
              size="large"
              style={{
                position: "absolute",
                left: "32%",
                // top: "39%",
                marginTop: -16,
              }}
            />
          ) : (
            <Row className="member-row">
              {subGroups &&
                subGroups.map(function (item, index) {
                  return (
                    <>
                      <Col span={2} key={index}>
                        <Checkbox
                          className="custom-checkbox"
                          onChange={(e) => handleGroup(e, item)}
                          disabled={allProfiles && allProfiles.length > 0}
                          checked={checkedGroups.includes(item)}
                        />
                      </Col>
                      <Col span={18} style={{ display: "flex" }}>
                        {item.avatar && imageLoaded === "" && (
                          <Skeleton.Image
                            active={true}
                            className="user-skeleton"
                          />
                        )}
                        {!item.avatar ? (
                          <Avatar
                            name={item.name}
                            color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                            className="user-avatar"
                            initials={1}
                          />
                        ) : (
                          <img
                            src={item.avatar}
                            style={
                              imageLoaded === ""
                                ? { display: "none" }
                                : { display: "block" }
                            }
                            onLoad={() => setImageLoaded("loaded")}
                          ></img>
                        )}
                        <div className="name-div">
                          <div style={{ display: "flex" }}>
                            <h3 className="user-name">{item.name}</h3>{" "}
                            <img src={User} />
                          </div>

                          <h4 className="user-handle">
                            ({item.members.length} members)
                          </h4>
                        </div>
                      </Col>
                      <Col span={4}>
                        <Button
                          className="edit-group"
                          onClick={() => {
                            // getSubGroups(item.id);
                            setEditSubGroup(true);
                            setClickedSubGroup(item);
                            setSubGroupMembers(item.members);
                          }}
                        >
                          Edit Group
                        </Button>
                      </Col>
                      <hr />
                    </>
                  );
                })}

              <Col span={24}>
                <InfiniteScroll
                  dataLength={members && members.length}
                  next={getGroupMembers}
                  loader={<h4>Loading...</h4>}
                >
                  {members &&
                    members.map(function (item, index) {
                      return (
                        <Row className="owner-row" key={index}>
                          <Col span={24} style={{ display: "flex" }}>
                            <Checkbox
                              key={index}
                              type="checkbox"
                              name={item.first_name}
                              id={item.id}
                              className="custom-checkbox"
                              onChange={(e) => handleClick(e, item)}
                              style={{ marginRight: 26 }}
                              disabled={allProfiles && allProfiles.length > 0}
                              checked={isCheckMembers.includes(item)}
                            />
                            {item.avatar !== null && imageLoaded === "" && (
                              <Skeleton.Image
                                active={true}
                                className="user-skeleton"
                              />
                            )}
                            {!item.avatar ? (
                              <Avatar
                                name={item.first_name + " " + item.last_name}
                                color={
                                  selectedSport === 1 ? "#F15A29" : "#4EA7F8"
                                }
                                className="user-avatar"
                              />
                            ) : (
                              <img
                                src={item.avatar}
                                style={
                                  imageLoaded === ""
                                    ? { display: "none" }
                                    : { display: "block" }
                                }
                                onLoad={() => setImageLoaded("loaded")}
                              ></img>
                            )}
                            <div className="name-div">
                              <h3 className="user-name">
                                {item.first_name + " " + item.last_name}
                              </h3>
                              <h4 className="user-handle">@{item.username}</h4>
                            </div>
                          </Col>
                          <hr />
                        </Row>
                      );
                      // }
                    })}
                </InfiniteScroll>
              </Col>
            </Row>
          )}
        </div>
        {/* <WorkoutOverview /> */}
        <div style={{ marginTop: "8px" }}>
          <Button
            className="cancel-btn"
            onClick={() => navigate({   pathname: `../${params.group_name}`,
              search: `id=${params.id}&tab=workouts`})}
          >
            Cancel
          </Button>
          <Button
            className="next-btn"
            onClick={() => assignedWorkout()}
            disabled={
              allProfiles &&
              allProfiles.length === 0 &&
              isCheckMembers &&
              isCheckMembers.length === 0 &&
              !allGroupMembers
            }
            style={{ background: darkButtonBackground }}
          >
            Assign Feature Workout
          </Button>
        </div>
        {Object.keys(clickedSubgroup).length > 0 && (
          <EditFeatureSubgroupModal
            clickedSubgroup={clickedSubgroup}
            setClickedSubGroup={setClickedSubGroup}
          />
        )}
        <Modal
          className="subgroup-modal"
          wrapClassName={
            selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"
          }
          open={subgroupModal}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={"Create"}
          cancelText={"Cancel"}
          width={"624px"}
        >
          <h1>Create Subgroup</h1>
          <p>Give this subgroup a name</p>
          <Form form={form}>
            <Form.Item
              label="Subgroup Name"
              name={"subgroup_name"}
              {...formItemLayout}
            >
              <Input
                placeholder="Please enter subgroup name."
                onChange={(e) => setSubGroupName(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Modal>{" "}
      </div>
    </div>
  );
};

const FeatureAssignedWorkout = ({
  showModal,
  setShowAllWorkouts,
  createdWorkout,
  setMembersView,
  setNewWorkout,
}) => {
  return (
    <FWAssignedContextProvider>
      <FeatureAssignedWorkoutPage
        showModal={showModal}
        setShowAllWorkouts={setShowAllWorkouts}
        createdWorkout={createdWorkout}
        setMembersView={setMembersView}
        setNewWorkout={setNewWorkout}
      />
    </FWAssignedContextProvider>
  );
};

export default FeatureAssignedWorkout;
