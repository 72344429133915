import React, { useContext, useEffect, useState } from "react";
import { message, Form, Button, Modal, Select, Dropdown, Menu } from "antd";
import { deleteItem, getData, jsonPostData, patchData } from "../../util/api-calls";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../user-context";
export const GroupDetailContext = React.createContext({});
export const GroupDetailContextProvider = ({ children = {} }) => {
  const navigate = useNavigate();
  const {selectedSport} = useContext(UserContext)
  const [loading, setLoading] = useState(false);
  const [generalDetail, setGeneralDetail] = useState([]);
  const [type, setType] = useState();
  const [level, setLevel] = useState();
  const [sportsList, setSportsList] = useState([]);
  const [imageUrl, setImageUrl] = useState();
  const [bsaCredit, setBsaCredit] = useState();
  const [seatLimit, setSeatLimit] = useState(false);
  const [memberLimit, setMemberLimit] = useState();
  const [workoutLeaderBoard, setWorkoutLeaderBoard] = useState(true);
  const [combineLeaderBoard, setCombineLeaderBoard] = useState(false);
  const [bsaLeaderBoard, setBsaLeaderBoard] = useState(false);
  const [newSubscription, setNewSubscription] = useState(false);
  const [editSubscription , setEditSubscription] = useState(false)
  const [subscriptionHistory, setSubscriptionHistory] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("none");
  const [workoutDetail, setWorkoutDetail] = useState(false);
  const [existingWorkout, setExistingWorkout] = useState(false);
  const [imageLoaded, setImageLoaded] = useState("");
  const [bsaValue, setBsaValue] = useState();
  const [bsaValueOneTime, setBsaValueOneTime] = useState()
  const [bsaValueEachMonth, setBsaValueEachMonth] = useState()
  const [tags, setTags] = useState();
  const [sport, setSport] = useState("");
  const [imgSrc, setImgSrc] = useState();
  const [showGeneralDetails, setShowGeneralDetail] = useState(true);
  const [newWorkout, setNewWorkout] = useState(false);
  const [subscriptionRecord , setSubscriptionRecord] = useState([])
  const [sort , setSort] = useState(false)
  const [startDate , setStartDate] =useState("")
  const [endDate , setEndDate] = useState("")
  const [curriculum , setCurriculum] = useState(false)
  const [access,setAccess] = useState(false)
  const [selectedWorkout , setSelectedWorkout] = useState({})
  const [ viewSubmissions , setViewSubmissions] = useState(false)
  const [ imageError,setImageError] = useState(false)
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const postEditGroup = (values) => {
    if(type === 2 && imgSrc === undefined){
      setImageError(true)
    }else{
      setImageError(false)
    let start_date = new Date(Date.parse(values.start_date)).toLocaleString(
      "sv-SE"
    );
    let expiry_Date = new Date(Date.parse(values.expiry_Date)).toLocaleString(
      "sv-SE"
    );
    let formData = new FormData();
    if (imageUrl) {
      formData.append("avatar", imageUrl);
    }
    formData.append("name", values.name);
    formData.append("sport_id", selectedSport);
    // if (values.type !== 1) {
    //   formData.append("level", level ? level : values.level);
    // }
    formData.append("free_bsa", values.bsa_credit === "none" || selectedSport !== 1 ? 0 : bsaValue);
    formData.append(
      "bsa_frequency",
      values.bsa_credit === "none"
        ? "none"
        : values.bsa_credit === "one-time"
        ? "one time"
        : "monthly"
    );
    formData.append(
      "member_limit",
      seatLimit === false ? "100000" : memberLimit
    );
    formData.append(
      "display_announcements_section",
      values.display_announcements_section
    );
    formData.append("subscription_required" , values.access)
    formData.append("display_post_section ", values.display_post_section ? values.display_post_section : false);
    formData.append(
      "allow_members_post",
      values.allow_members_post === "owners" ? false : true
    );
    formData.append("display_workout_leaderboard ", workoutLeaderBoard);
    formData.append("display_bsa_leaderboard ", bsaLeaderBoard);
    formData.append(
      "display_combine_measurement_leaderboard ",
      combineLeaderBoard
    );
    formData.append("is_curriculum_group" , curriculum)
    formData.append("tags", tags && tags.toString());
    formData.append("comments", values.comments);
    if (start_date !== "Invalid Date") {
      formData.append("start_date", start_date);
    }
    if (expiry_Date !== "Invalid Date") {
      formData.append("expiry_Date", expiry_Date ? expiry_Date : false);
    }
    if(type === 2){
      formData.append("member_code_expiry", values.expiry)
      if(values.description){
      formData.append("description",values.description)
      }
      }
      formData.append("is_purchased_group" , type === 1 ? false : true)
    formData.append("is_default", false
    // type === 2 ? true : false
  );
    patchData(`admin/group/${params.id}`, formData)
      .then((res) => {
        if (res && res.status === 200) {
          message.success({
            content: "Group updated successfully.",
            duration: 1,
          });
          setTimeout(() => {
            navigate("/home/groups");
          }, 1200);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };
  const getSportList = () => {
    getData(`sport`)
      .then((res) => {
        setSportsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getGroupDetail = (loading) => {
    if(loading !== false){
    setLoading(true);
  }
    getData(`admin/group/${params.id}`)
      .then((res) => {
        setGeneralDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getSubscriptionHistory = () =>{
    getData(`admin/group/subscription?group_id=${params.id}&sort_asc=${sort}&start_date=${startDate}&end_date=${endDate}`)
    .then((res)=>{
      setSubscriptionRecord(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const addNewSubscription = (formData , setNewSubscription) => {
    jsonPostData(`admin/group/subscription`,formData)
    .then((res)=>{
      console.log(res)
      message.success({
        content: "Subscription added successfully.",
        duration: 1,
      });
      setNewSubscription(false)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const updateSubscription = (formData , id , setEditSubscription) => {
    patchData(`admin/group/subscription/${id}`,formData)
    .then((res)=>{
      console.log(res)
      message.success({
        content: "Subscription updated successfully.",
        duration: 1,
      });
      getGroupDetail();
      setEditSubscription(false)
    }).catch((err)=>{
      console.log(err)
    })
  }



   const deleteGroup = () =>{
   deleteItem(`admin/group/${params.id}`).then((res)=>{
    console.log(res)
    navigate("/home/groups");
   }).catch((error)=>{
    console.log(error)
   })
   }

  useEffect(() => {
    getSportList();
    if (params) {
      getGroupDetail();
    }
  }, []);
  useEffect(()=>{
    getSubscriptionHistory();

  },[startDate,endDate,sort])
  const defaultContext = {
    generalDetail,
    type,
    setType,
    level,
    setLevel,
    sportsList,
    setGeneralDetail,
    setSeatLimit,
    seatLimit,
    setMemberLimit,
    memberLimit,
    imgSrc,
    bsaCredit,
    showGeneralDetails,
    setShowGeneralDetail,
    newWorkout,
    setNewWorkout,
    setImgSrc,
    imageLoaded,
    setImageLoaded,
    setImageUrl,
    workoutLeaderBoard,
    bsaLeaderBoard,
    combineLeaderBoard,
    setSport,
    sport,
    workoutDetail,
    setWorkoutDetail,
    existingWorkout,
    setExistingWorkout,
    setBsaCredit,
    subscriptionStatus,
    setSubscriptionStatus,
    subscriptionHistory,
    setSubscriptionHistory,
    postEditGroup,
    bsaValue,
    setBsaValue,
    newSubscription,
    setNewSubscription,
    setWorkoutLeaderBoard,
    setBsaLeaderBoard,
    setCombineLeaderBoard,
    tags,
    setTags,
    loading,
    setLoading,
    deleteGroup,
    addNewSubscription,
    subscriptionRecord,
    editSubscription,
    setEditSubscription,
    updateSubscription,
    access,
    setAccess,
    setSort,
    setStartDate,setEndDate,
    bsaValueOneTime,setBsaValueOneTime,
    bsaValueEachMonth,setBsaValueEachMonth,setCurriculum,curriculum,
    selectedWorkout, setSelectedWorkout,
    viewSubmissions,
    setViewSubmissions,
    imageError,
    setImageError,getGroupDetail
  };
  return (
    <GroupDetailContext.Provider value={defaultContext}>
      {children}
    </GroupDetailContext.Provider>
  );
};
