import React from "react";
import { Outlet } from "react-router-dom";

const GroupLayout = () =>{
    return(
        <Outlet />
    )
}

export default GroupLayout

