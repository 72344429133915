import React, { useContext, useState } from "react";
import { Col, Row, Select, Skeleton, Tabs } from "antd";
import "./challenge-detail.scss";
import Avatar from "react-avatar";
import Tasks from "../tasks/tasks";
import LeaderBoard from "../leaderboard/leaderboard";
import { UserContext } from "../../../context/user-context";
import {
  FeatureWorkoutDetailContext,
  FeatureWorkoutDetailContextProvider,
} from "../../../context/challenge-workout-context/feature-workout-detail";
import { getDateWithoutDay } from "../../../util/config";
const ChallengeDetailPage = () => {
  const { detail, imageLoaded, setImageLoaded } = useContext(
    FeatureWorkoutDetailContext
  );
  const { selectedSport } = useContext(UserContext);
  const onChange = (value) => {};
  function getItem(label, key, children) {
    return {
      key,
      children,
      label,
    };
  }
  const items = [
    getItem("Tasks", "tasks", <Tasks tasks={detail?.tasks} />),
    getItem("Leaderboard", "leaderboard", <LeaderBoard tasks={detail?.tasks}/>),
    // getItem(
    //   "All Members",
    //   "all_members",
    //   <Members />
    // ),
  ];
  return (
    <div className="challenge-detail-main">
      <div className="challenge-detail">
        <div style={{ marginRight: 24 }}>
          {detail?.image !== null && imageLoaded === "" && (
            <Skeleton.Image
              active={true}
              style={{ width: "120px", height: "120px" }}
            />
          )}

          {detail?.image === null ? (
            <Avatar
              name={detail?.title}
              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
              maxInitials={2}
              className="workout-avatar"
            />
          ) : (
            <img
              src={detail?.image}
              className="workout-logo"
              style={
                imageLoaded === "" ? { display: "none" } : { display: "block" }
              }
              onLoad={() => setImageLoaded("loaded")}
            />
          )}
        </div>
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", height: "30px" }}>
            {" "}
            <h2 className="challenge-name">{detail?.title}</h2>
            {/* <span className="purple-tag">${detail?.points}</span> */}
            <span className="green-tag" style={{ textTransform: "capitalize" }}>
              {detail?.skill_level}
            </span>
          </div>
          <div style={{ display: "flex", marginTop: "9px" }}>
            <div className="creator">
              <div style={{ marginBottom: 8 }}>
                Presented by :{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    textTransform: "capitalize",
                  }}
                >
                  {detail?.presented_by ? detail?.presented_by : "N/A"}
                </span>
              </div>
              <div>
                Created by:{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {" "}
                  {detail?.created_by ? detail?.created_by : "N/A"}
                </span>
              </div>
            </div>
            <div className="creator" style={{ marginLeft: "auto" }}>
              <div style={{ marginBottom: 8 }}>
                Created on :{" "}
                <span>{getDateWithoutDay(detail?.created_at)}</span>
              </div>
              <div>
                Ends on: <span>{getDateWithoutDay(detail?.ending_on)}</span>
              </div>
            </div>
          </div>
          <Row gutter={[16, 16]} className="points-row">
            <Col span={4}>
              <span className="points">{detail?.total_tasks}</span>
              <br />
              <span className="category">Tasks</span>
            </Col>
            <Col span={4}>
              <span className="points">{detail?.total_reps}</span>
              <br />
              <span className="category">Total Reps</span>
            </Col>
          </Row>
        </div>
      </div>
      <div className="challenge-data">
        <Tabs
          tabPosition="left"
          onChange={onChange}
          type="card"
          className={
            "challenge-tabs " +
            (selectedSport === 1 ? "orange-theme" : "blue-theme")
          }
          destroyInactiveTabPane
          items={items}
        />
      </div>
    </div>
  );
};

const ChallengeDetail = () => {
  return (
    <FeatureWorkoutDetailContextProvider>
      <ChallengeDetailPage />
    </FeatureWorkoutDetailContextProvider>
  );
};
export default ChallengeDetail;
