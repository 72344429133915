import React, { useContext } from "react";
import { Col, Modal, Progress, Row } from "antd";
import "./view-all-workouts-modal.scss";
import { getDate } from "../../../../util/config";
import { WorkoutsContext } from "../../../../context/workouts-context/workouts-context";
import InfiniteScroll from "react-infinite-scroll-component";
import { UserContext } from "../../../../context/user-context";
const AllWorkoutsModal = ({
  showAllWorkouts,
  setShowAllWorkouts,
  // allWorkoutData
  setWorkoutDetail,
  setSelectedWorkout,
}) => {
  const {selectedSport} = useContext(UserContext)
  const {
    hasMore,
    filteredWorkouts,
    workoutType,
    getFilteredWorkouts,
    setWorkoutType,
    setOffset,
    setFilteredWorkouts,
  } = useContext(WorkoutsContext);
  const handleOk = () => {
    setShowAllWorkouts(false);
  };
  const handleCancel = () => {
    setWorkoutType("");
    setShowAllWorkouts(false);
    setWorkoutType("");
    setOffset(0);
    setFilteredWorkouts((filteredWorkouts) => []);
  };
  return (
    <Modal
      className="all-workouts-modal"
      wrapClassName={"all-workouts-modal " + (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")}
      open={showAllWorkouts}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Report"}
      cancelText={"Cancel"}
      width={"635px"}
      footer={false}
    >
      <h1>{workoutType} Workouts</h1>
      <InfiniteScroll
        dataLength={filteredWorkouts.length}
        next={getFilteredWorkouts}
        height={400}
        hasMore={hasMore}
        loader={hasMore ? <h4>Loading...</h4> : false}
      >
        <div className="workouts-list">
          {filteredWorkouts &&
            filteredWorkouts.map((item, index) => (
              <Row
                className="workouts-card"
                key={index}
                style={item.category === "upcoming" ? { cursor: "default" } : { cursor: "pointer" }}
                onClick={item.category !== "upcoming" ? () => {
                  setWorkoutDetail(true);
                  setSelectedWorkout(item);
                } : null}
              >
                <Col span={12}>
                  <h2 title={item.title}>{item.title}</h2>
                  <h5 style={{ marginBottom: 5 }}>Ends: {getDate(item.end_date)}</h5>
                  {item.recent_upload === true ?
                    <span style={{ color: "#F15A29" }}>{item.recent_upload_count} New Submission(s)</span>
                    : <span style={{ color: "#5e6278" }}>No New Submission(s)</span>
                  }
                </Col>
                <Col span={12}>
                  <div style={{ display: "flex" }}>
                    <div className="percentage" style={{ marginLeft: "auto" }}>
                      {item.completion_percentage}%
                    </div>
                    {/* <div className="progress">4 of 5 tasks</div> */}
                  </div>
                  <Progress
                    percent={item.completion_percentage}
                    status="active"
                    // className="purple-progress-bar"
                    className={selectedSport === 1 ? "orange-progress-bar" : "blue-progress-bar"}
                    showInfo={false}
                  />

                </Col>
              </Row>
            ))}
        </div>
      </InfiniteScroll>
    </Modal>
  );
};

export default AllWorkoutsModal;
