import axios from "axios";
import CryptoJs from "crypto-js";
// import {baseURL} from "./config";
import history from "./history";
import { userBaseURL } from "./config";
const instance = axios.create();

let tokenData = "";
let decryptStr = "";
if (localStorage.getItem("u_info") !== null) {
  decryptStr = CryptoJs.AES.decrypt(
    localStorage.getItem("u_info"),
    "sec"
  ).toString(CryptoJs.enc.Utf8);
}
if (decryptStr !== "") {
  tokenData = JSON.parse(decryptStr);
}
let accessToken = tokenData.access_token;
const refreshToken = tokenData.refresh_token;
instance.interceptors.request.use(
  (baseURL) => {
    if (tokenData) {
      baseURL.headers["Authorization"] = "Bearer " + accessToken;
    }
    // baseURL.headers['Content-Type'] = 'application/json';
    return baseURL;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Add a response interceptor

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if(error.request.responseURL.includes("logout")){
      localStorage.removeItem("u_info");
      localStorage.removeItem("u_id")
      window.location.reload();
      history.push("/login");
    }
    if (error.message == "Request failed with status code 401") {
      localStorage.removeItem("u_info");
      localStorage.removeItem("u_id")
      window.location.reload();
      history.push("/login");

      return Promise.reject(error);
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axios
        .post(`${userBaseURL}/auth/token/refresh`, {
          refresh: refreshToken,
        })
        .then((res) => {
          if (res.status === 200) {
            accessToken = res.data.access_token;
            localStorage.setItem(
              "u_info",
              CryptoJs.AES.encrypt(JSON.stringify(res.data), "sec").toString()
            );
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${res.data.access_token}`;
            window.location.reload();
            return axios(originalRequest);
          }
        }).catch(()=>{
          localStorage.removeItem("u_info");
          localStorage.removeItem("u_id")
          window.location.reload();
          history.push("/login");
        });
    }
    return Promise.reject(error);
  }
);

export default instance;
