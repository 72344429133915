import React, { useEffect, useState } from "react";
import {jsonPostDataTraining , getDataTrainingCoach , patchDataTraining} from "../../util/coach-api-calls"
import { useNavigate } from "react-router-dom";
export const FWAssignedContext = React.createContext({});
let profiles = [];
let subGroupId = [];
export const FWAssignedContextProvider = ({ children = {} }) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const navigate = useNavigate();
  const [subgroupModal, setSubgroupModal] = useState(false);
  const [editSubGroup, setEditSubGroup] = useState(false);
  const [checkedItems, setCheckedItems] = useState(false);
  const [groupDetail, setGroupDetail] = useState({});
  const [subGroupMembers, setSubGroupMembers] = useState([]);
  const [editSubGroupName, setEditSubGroupName] = useState("");
  const [allGroupMembers, setAllGroupMembers] = useState(true);
  const [checkedGroups, setCheckedGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subGroups, setSubGroups] = useState([]);
  const [members, setMembers] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [subGroupName, setSubGroupName] = useState("");
  const [imageLoaded, setImageLoaded] = useState("");
  const [isCheckMembers, setIsCheckMembers] = useState([]);
  const [workoutData, setWorkoutData] = useState({});
  const [allProfiles, setAllProfiles] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [clickedSubgroup, setClickedSubGroup] = useState({});
  const [fwData, setFwData] = useState({});

  const getSubGroups = (id) => {
    getDataTrainingCoach(`subgroup/${params.id}?is_fw=true`)
      .then((res) => {
        setGroupDetail(res.data);
        setSubGroupMembers(res.data.members);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getGroupMembers = (createdWorkout) => {
    setLoading(true);
    getDataTrainingCoach(
      `group/${params.id}/workout/${params.fw_id}/subgroup?is_fw=true`
    )
      .then((res) => {
        if (res && res.status === 200) {
          setSubGroups(res.data.subgroups);
          setMembers(res.data.members);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const createSubGroup = () => {
    let profiles = [];
    isCheckMembers.map((e) => profiles.push(e));
    let data = {
      name: subGroupName,
      profiles: profiles,
      taskgroup_id: fwData.id,
      is_fw: true,
      group_id: params.id,
    };
    jsonPostDataTraining(`subgroup`, data)
      .then((res) => {
        setIsCheckMembers([]);
        setIsCheckMembers((isCheckMembers) => []);
        setSubGroupName("");
        getGroupMembers(workoutData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateSubGroup = () => {
    var profiles = subGroupMembers.map(function (item) {
      return {
        avatar: item.avatar,
        dob: item.dob ? item.dob : item.date_of_birth + " 00:00:00",
        gender: item.gender,
        id: item.id,
        location: item.location,
        name: item.name ? item.name : item.first_name + " " + item.last_name,
        username: item.username,
      };
    });
    let data = {
      name: editSubGroupName === "" ? clickedSubgroup.name : editSubGroupName,
      profiles: subGroupMembers,
      taskgroup_id: fwData.id,
      is_fw: true,
      group_id: params.id,
    };
    patchDataTraining(`subgroup/${clickedSubgroup.id}`, data)
      .then((res) => {
        setSubGroupMembers((subGroupMembers) => []);
        getGroupMembers();
      })
      .catch((err) => {
        console.log(err);
        // message.error({
        // content:err.response.data.details
        // })
      });
  };
  const getFWDetail = () => {
    getDataTrainingCoach(`admin/all/feature/workout/${params.fw_id}/detail`)
      .then((res) => {
        setFwData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const assignWorkout = (setMembersView) => {
    isCheckMembers.map((e) => profiles.push(e));
    if (checkedGroups.length >= 1) {
      checkedGroups.map((e) => {
        subGroupId.push(e.id);
        e.members.map((e) => profiles.push(e));
      });
    }
    subGroupId = subGroupId.filter(function (element, pos) {
      return element !== undefined && subGroupId.indexOf(element) == pos;
    });
    profiles = [...new Map(profiles.map((item) => [item.id, item])).values()];
    let data = {
      start_date:
        startDate && startDate !== "" ? startDate.toISOString() : null,
      end_date: endDate && endDate !== "" ? endDate.toISOString() : null,
      assign_all: allProfiles && allProfiles.length > 0 ? true : false,
      profiles: allProfiles && allProfiles.length > 0 ? allProfiles : profiles,
      sub_group_id:
        allGroups && allGroups.length > 0
          ? allGroups.toString()
          : Object.keys(checkedGroups).length > 0
          ? subGroupId.toString()
          : "",
      fw_id: fwData?.id,
      group_id: params.id,
      sub_group_name:
        Object.keys(checkedGroups).length > 0 ? checkedGroups.name : "",
    };
    jsonPostDataTraining(`feature/workout/assign`, data)
      .then((res) => {
        if (res && res.status === 200) {
          //   setNewWorkout(false);
          // setMembersView(false);
          profiles = [];
          subGroupId = [];
          navigate({
            pathname: `../${params.group_name}`,
            search: `id=${params.id}&tab=workouts`,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (params) {
      getFWDetail();
      getGroupMembers();
    }
  }, []);
  React.useEffect(() => {}, []);
  const defaultContext = {
    setSubgroupModal,
    setCheckedItems,
    getGroupMembers,
    setIsCheckMembers,
    isCheckMembers,
    setStartDate,
    setEndDate,
    subGroups,
    setCheckedGroups,
    checkedGroups,
    members,
    loading,
    imageLoaded,
    setImageLoaded,
    getSubGroups,
    assignWorkout,
    groupDetail,
    editSubGroup,
    setEditSubGroup,
    setSubGroupName,
    subGroupName,
    subgroupModal,
    createSubGroup,
    setEditSubGroupName,
    workoutData,
    setWorkoutData,
    allProfiles,
    setAllProfiles,
    updateSubGroup,
    setSubGroupMembers,
    subGroupMembers,
    allGroups,
    setAllGroups,
    checkedItems,
    clickedSubgroup,
    setClickedSubGroup,
    startDate,
    endDate,
    allGroupMembers,
    setAllGroupMembers,
    fwData,
  };
  return (
    <FWAssignedContext.Provider value={defaultContext}>
      {children}
    </FWAssignedContext.Provider>
  );
};
