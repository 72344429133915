import React, { useEffect, useState } from "react";
import { message, Form, Button, Modal, Select, Dropdown, Menu } from "antd";
import { getData, formPostData, deleteItem, patchData } from "../../util/api-calls";
const { confirm } = Modal;
export const SponsorsContext = React.createContext({});
export const SponsorsContextProvider = ({ children = {} }) => {
  const [imgSrc , setImgSrc] = useState()
  const [gender , setGender] = useState()
  const [imageUrl , setImageUrl] = useState()
  const [loading , setLoading] = useState(false)
  const [addSponsorModal , setAddSponsorModal] = useState(false)
  const [sponsorsList , setSponsorsList] = useState([])
  const [selectedSponsor , setSelectedSponsor] = useState({})
  const [editSponsorModal , setEditSponsorModal] = useState(false)
  
  const getSponsorsList = () => {
    getData(`sponsor`)
      .then((res) => {
        setSponsorsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addSponsor = (values) => {
    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("website", values.website);
    if (imageUrl) {
      formData.append("icon", imageUrl);
    }
    formPostData(`sponsor`, formData)
      .then((res) => {
        setAddSponsorModal(false);
        getSponsorsList();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteSponsor = (item) => {
    deleteItem(`sponsor/${item.id}`)
      .then((res) => {
        getSponsorsList();
      })
      .catch((err) => {
        console.log(err);
      });
  };  
  const updateSponsor = (values, item) => {
    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("website", values.website);
    if (values.avatar && imageUrl) {
      formData.append("icon", imageUrl);
    }
    else{
      formData.append("icon", "");
    }
    patchData(`sponsor/${item.id}`, formData)
      .then((res) => {
        if (res && res.status === 200) {
          setEditSponsorModal(false);
          getSponsorsList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(()=>{
    getSponsorsList();
  } , [
]) 
  const defaultContext = {
    gender,
    setImageUrl,
    setGender,
    imgSrc,
    setImgSrc,
    loading,
    addSponsorModal, setAddSponsorModal,
    sponsorsList,
    addSponsor,
    deleteSponsor,
    setSelectedSponsor,
    selectedSponsor,
    editSponsorModal,
    setEditSponsorModal,
    updateSponsor
  };
  return (
    <SponsorsContext.Provider value={defaultContext}>
      {children}
    </SponsorsContext.Provider>
  );
};
