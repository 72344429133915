import { Button, Table } from "antd";
import React, { useContext, useState } from "react";
import SubscriptionModal from "./subscription-modal";
import PurchaseModal from "./purchase-modal"
import { UserContext } from "../../../context/user-context";

const Challenges = () => {
    const {color , lightButtonBackground , selectedSport} = useContext(UserContext)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [subscriptionModal , setSubscriptionModal] = useState(false)
    const [purchaseModal , setPurchaseModal] = useState(false)
    const columns = [
        {
          title: "ID",
          dataIndex: "id",
        },
        {
          title: "name",
          dataIndex: "name",
        },
        {
          title: "price",
          dataIndex: "price",
        },
        {
          title: "start",
          dataIndex: "start",
        },
        {
          title: "expiry",
          dataIndex: "expiry",
        },
        {
          title: "status",
          dataIndex: "status",
          render: (text) => <span className="green-tag">Active</span>,
        },
        {
            title: "purchased by",
            dataIndex: "purchased_by",
          },
      ];
      const data = [];
      for (let i = 0; i < 46; i++) {
        data.push({
          key: i,
          id: "223",
          name: "Challenges 1",
          price: "$10",
          start: "May 22, 2023 11:00PM",
          expiry: "N/A",
          purchased_by:"Admin"
        });
      }
      const onSelectChange = (newSelectedRowKeys) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
      };
      const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };
    return(
        <div className="purchase-challenges-main">
                  <div className="table-header">
        <h3>Challenges</h3>
        <div className="right-div">
          <Button
            className="red-base-btn"
            style={{ height: 38 , marginRight:8 ,color : color , background : lightButtonBackground}}
            onClick={() => setPurchaseModal(true)}
          >
            Add Purchase
          </Button>
          <Button
            className="red-base-btn"
            style={{ height: 38 , color : color , background : lightButtonBackground}}
            onClick={() => setSubscriptionModal(true)}
          >
            Add Supscription
          </Button>
        </div>
      </div>
      <div className="table-div">
        <Table
          //   rowSelection={rowSelection}
          //   onRow={(record, rowIndex) => {
          //     return {
          //       onClick: () => gotoProfileDetail(record),
          //     };
          //   }}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: false,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            // current: 1,
            pageSize: 10,
            // total: 85,
            showQuickJumper: false,
          }}
          columns={columns}
          dataSource={data}
          className={"table-container " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
        />
      </div>
      <SubscriptionModal subscriptionModal={subscriptionModal} setSubscriptionModal={setSubscriptionModal}/>
      <PurchaseModal purchaseModal={purchaseModal} setPurchaseModal={setPurchaseModal}/>
        </div>
    )
}

export default Challenges