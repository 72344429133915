import React, { useContext } from "react";
import {
  Input,
  Modal,
  Form,
  Select,
  Button,
} from "antd";
import DownArrow from "../../../assets/images/arrows.svg";
import { AdminUserContext } from "../../../context/setting-context/admin_user_context";
import { UserContext } from "../../../context/user-context";
const AddUserModal = ({ addUserModal, setAddUserModal }) => {
  const {darkButtonBackground , selectedSport , color , lightButtonBackground} = useContext(UserContext)
  const { createUser, form, roleList } = useContext(AdminUserContext);
  const handleCancel = () => {
    setAddUserModal(false);
  };
  return (
    <Modal
      wrapClassName={"add-user-modal " + (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")}
      className="add-user-modal"
      open={addUserModal}
      onCancel={handleCancel}
      okText={"Create"}
      cancelText={"Cancel"}
      width={"624px"}
      footer={false}
    >
      <div className="add-user-main">
        <h1>Add New User</h1>
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          className="add-user-form"
          onFinish={createUser}
        >
          <Form.Item
            name="full_name"
            label="Full Name"
            rules={[
              {
                required: true,
                message: "Please enter Full Name",
              },
            ]}
          >
            <Input placeholder="Please enter full name" />
          </Form.Item>
          <Form.Item
            name="username"
            label="User Name"
            rules={[
              {
                required: true,
                message: "Please enter User Name",
              },
            ]}
          >
            <Input placeholder="Please enter user name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email Address"
            rules={[
              {
                type: 'email',
                message: 'Please enter valid Email!',
              },
              {
                required: true,
                message: "Please enter Email",
              },
            ]}
          >
            <Input placeholder="Please enter email" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please enter Password",
              },
            ]}
          >
            <Input.Password placeholder="Please enter password" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please enter Phone Number",
              },
            ]}
          >
            <Input placeholder="Please enter phone number" />
          </Form.Item>
          <Form.Item
            name="role_id"
            label="Role"
            rules={[
              {
                required: true,
                message: "Please select role",
              },
            ]}
          >
            <Select
              placeholder="Please select role"
              size={"large"}
              style={{ width: "100%", height: 38 }}
              suffixIcon={<img src={DownArrow} />}
              className="select-input"
              popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
            >
              {roleList.map((item) => (
                <Select.Option value={item.id}>{item.role_name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item style={{ textAlign: "end" }}>
            <Button
              type="default"
              className="discard-btn"
              onClick={() => {
                setAddUserModal(false);
                form.resetFields();
              }}
              style={{
                margin: "0 8px",  color:color , background : lightButtonBackground
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="dark-base-btn"
              style={{background:darkButtonBackground}}
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddUserModal;
