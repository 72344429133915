import React, { useRef, useEffect, useState, useContext } from "react";
import { Card, Col, Collapse, Progress, Row, Tabs } from "antd";
import { FaArrowLeftLong } from "react-icons/fa6";
import "./FW-submission-detail.scss";
import Tasks from "./tasks";
// import WorkoutProgress from "./workout-progress";
import {
  WorkoutsContext,
  WorkoutsContextProvider,
} from "../../../context/workouts-context/workouts-context";
import { getDate, getDateWithTimeWithoutDay } from "../../../util/config";
import { getDataTraining } from "../../../util/api-calls";
import {
  WorkoutDetailContext,
  WorkoutDetailContextProvider,
} from "../../../context/workouts-context/workout-detail-context";
// import WorkoutSubmissionDetail from "./workout-submission-detail";
import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";
import { UserContext } from "../../../context/user-context";
import TaskDetail from "./single-task-deail";
import FWTaskSubmission from "./task-submission-detail";
import {
  FWSubmissionDetailContext,
  FWSubmissionDetailContextProvider,
} from "../../../context/submission-context/fw-submission-detail-context";
const style = {
  padding: "5px 0",
};
const FWSubmissionDetailPage = ({}) => {
  const navigate = useNavigate();
  const { color, selectedSport } = useContext(UserContext);
  const { FWDetail } = useContext(FWSubmissionDetailContext);
  const location = useLocation();

  const [taskDetailTable, setTaskDetailTable] = useState(false);
  const [FWTaskSubmissionDetail, setFWTaskSubmissionDetail] = useState(false);
  let currentLink = "";
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink += `/${crumb}`;
      if (crumb !== "home" && crumb !== "groups") {
        let newCrumb = crumb.replace("-", " ");
        return (
          <div className="crumb" key={newCrumb}>
            <Link to={currentLink} style={{ cursor: "pointer" }}>
              {decodeURI(newCrumb)}
            </Link>
          </div>
        );
      }
    });
  return (
    <div className="featured-workout-detail-main">
      <div className="heading-row">
        <FaArrowLeftLong
          className="left-arrow"
          style={{ margin: "33px 0 8px 23px", color: color, stroke: color }}
          onClick={() => {
            if (taskDetailTable === false && FWTaskSubmissionDetail === false) {
              navigate({
                pathname: `/home/submissions`,
                search: `tabs=featured-workouts`,
              });
            } else if (
              taskDetailTable === true &&
              FWTaskSubmissionDetail === false
            ) {
              setFWTaskSubmissionDetail(false);
              setTaskDetailTable(false);
            }
            else if (
              taskDetailTable === false &&
              FWTaskSubmissionDetail === true
            ) {
              setFWTaskSubmissionDetail(false);
              setTaskDetailTable(true);
            }
            //   setWorkoutDetail(false);
            //   setViewSubmissions(false);
            //   viewSubmissions === true
            //     ? navigate({
            //         search: `id=${params.id}&tab=submission-detail`,
            //       })
            //     : navigate({
            //         search: `id=${params.id}`,
            //       });
          }}
        />
        <h1>{FWDetail?.title}</h1>
      </div>
      <div className="breadcrumbs">
        <div style={{ display: "flex", cursor: "pointer" }}>{crumbs}</div>
      </div>
      <hr />
      {taskDetailTable === true ? (
        <TaskDetail
          setFWTaskSubmissionDetail={setFWTaskSubmissionDetail}
          setTaskDetailTable={setTaskDetailTable}
        />
      ) : FWTaskSubmissionDetail === true ? (
        <FWTaskSubmission />
      ) : (
        <>
          <Tasks
            setTaskDetailTable={setTaskDetailTable}
            taskDetailTable={taskDetailTable}
            tasks={FWDetail?.list_tasks}
          />
        </>
      )}
    </div>
  );
};

const FWSubmissionDetail = () => {
  return (
    <FWSubmissionDetailContextProvider>
      <FWSubmissionDetailPage />
    </FWSubmissionDetailContextProvider>
  );
};

export default FWSubmissionDetail;
