import React, { useContext } from "react";
import { Form, Input, Modal } from "antd";
import { SubmissionDetailContext } from "../../../context/submission-context/bsa_submission_detail_context";
import { UserContext } from "../../../context/user-context";

const { TextArea } = Input;
const ResultModal = ({ resultModal, setResultModal, postResult }) => {
  const {selectedSport} = useContext(UserContext)
  const [form] = Form.useForm();
  const { setPlayerMessage } = useContext(SubmissionDetailContext);
  const handleOk = () => {
    setResultModal(false);
    postResult();
  };
  const handleCancel = () => {
    setResultModal(false);
    form.resetFields();
  };

  return (
    <Modal
      className="result-modal"
      wrapClassName={"result-modal " + (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")}
      open={resultModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Save"}
      cancelText={"Cancel"}
      width={"624px"}
    >
      <h1>Send Results to Player</h1>
      <p>
        In addition to the verified score, you can provide additional feedback
        (optional)
      </p>
      <Form form={form}>
        <Form.Item name={"message"}>
        <TextArea
        onChange={(e) => setPlayerMessage(e.target.value)}
        placeholder="Your response here..."
        autoSize={{
          minRows: 8,
          maxRows: 9,
        }}
      />
        </Form.Item>
      </Form>
  
    </Modal>
  );
};

export default ResultModal;
