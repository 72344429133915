import React, { useContext } from "react";
import "./tasks-list.scss"
import { Button, Select } from "antd";
import { UserContext } from "../../../context/user-context";
const TaskList = ({setShowAllWorkouts}) =>{
    const {selectedSport} = useContext(UserContext)
    const onSearch = (value) => console.log(value);
    const onChangeSelect = (value) => {
      console.log(`selected ${value}`);
    };
    return(
        <div className="tasks-list" onClick={() => setShowAllWorkouts(false)}>
        <h3>Tasks List</h3>
        <div style={{ display: "flex", marginBottom: "26px" }}>
          <Select
            showSearch
            placeholder="Skill Level"
            optionFilterProp="children"
            onChange={onChangeSelect}
            onSearch={onSearch}
            popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            className="select-input"
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
          <Select
            showSearch
            placeholder="All Workouts"
            optionFilterProp="children"
            onChange={onChangeSelect}
            onSearch={onSearch}
            popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            className="select-input"
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
          <Select
            showSearch
            placeholder="Newest"
            optionFilterProp="children"
            onChange={onChangeSelect}
            onSearch={onSearch}
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
            className="select-input"
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </div>
        <div className="list">
          <div style={{ display: "flex", width: "100%" }}>
            <h3>Shooting License</h3>
            <Button className="green-tag">Beginners</Button>
          </div>
          <div className="task-added">
            Added on:
            <span className="time"> 4:23 PM </span>by
            <span className="name"> Kenny Thompson</span>
          </div>
         <div className="tags">
            Tags:
            <Button className="purple-tag">Shooting</Button>
            <Button className="purple-tag">Dribbling</Button>
            <Button className="purple-tag">Conditioning</Button>
          </div>
        </div>
        <div className="list">
          <div style={{ display: "flex", width: "100%" }}>
            <h3>Shooting License</h3>
            <Button className="green-tag">Beginners</Button>
          </div>
          <div className="task-added">
            Added on:
            <span className="time"> 4:23 PM </span>by
            <span className="name"> Kenny Thompson</span>
          </div>
          <div className="tags">
            Tags:
            <Button className="purple-tag">Shooting</Button>
            <Button className="purple-tag">Dribbling</Button>
            <Button className="purple-tag">Conditioning</Button>
          </div>
        </div>
        <div className="list">
          <div style={{ display: "flex", width: "100%" }}>
            <h3>Shooting License</h3>
            <Button className="green-tag">Beginners</Button>
          </div>
          <div className="task-added">
            Added on:
            <span className="time"> 4:23 PM </span>by
            <span className="name"> Kenny Thompson</span>
          </div>
         <div className="tags">
            Tags:
            <Button className="purple-tag">Shooting</Button>
            <Button className="purple-tag">Dribbling</Button>
            <Button className="purple-tag">Conditioning</Button>
          </div>
        </div>
        <div className="list">
          <div style={{ display: "flex", width: "100%" }}>
            <h3>Shooting License</h3>
            <Button className="green-tag">Beginners</Button>
          </div>
          <div className="task-added">
            Added on:
            <span className="time"> 4:23 PM </span>by
            <span className="name"> Kenny Thompson</span>
          </div>
         <div className="tags">
            Tags:
            <Button className="purple-tag">Shooting</Button>
            <Button className="purple-tag">Dribbling</Button>
            <Button className="purple-tag">Conditioning</Button>
          </div>
        </div>
      </div>
    )
}


export default TaskList