import React, { useContext, useState } from "react";
import { Select, Modal, Form, DatePicker } from "antd";
import DownArrow from "../../../assets/images/down-arrow.svg";
import dayjs from "dayjs";
import Calendar from "../../../assets/images/calendar.svg";
import { UserContext } from "../../../context/user-context";
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const oneTimeOptions = [];
for (let i = 1; i <= 10; i++) {
  oneTimeOptions.push({
    value: i.toString(10),
    label: i.toString(10),
  });
}

const PurchaseModal = ({ purchaseModal, setPurchaseModal }) => {
  const {selectedSport} = useContext(UserContext)
  const dateFormat = "ddd. MMMM D, YYYY";
  const [form] = Form.useForm();
  const handleOk = (e) => {};
  const handleCancel = () => {
    setPurchaseModal(false);
  };
  return (
    <Modal
      wrapClassName={"subscription-modal purchase-modal " + (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")}
      open={purchaseModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Add"}
      cancelText={"Cancel"}
      width={"624px"}
    >
      <div className="header">
        <h1>Add Purchase</h1>
        <span>Select a Challenge to to purchase for this user.</span>
      </div>
      <Form
        form={form}
        name="dynamic_rule"
        style={{
          padding: "0 30px ",
        }}
        className="sport-form"
      >
        <Form.Item
          {...formItemLayout}
          name="challenge"
          label="Select a Challenge"
        >
          <Select
            placeholder="Select"
            optionFilterProp="children"
            // onChange={onChangeSelect}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            className="select-input"
            popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
            style={{ height: 38 }}
            suffixIcon={<img src={DownArrow} />}
            options={oneTimeOptions}
          />
        </Form.Item>
        <Form.Item {...formItemLayout} name="start" label="Subscription Start">
          <DatePicker
            className={"date-picker " + (selectedSport === 1 ? "orange-picker" : "blue-picker")}
            rootClassName={selectedSport === 1 ? "orange-picker" : "blue-picker"}
            defaultValue={dayjs("Jan 01, 2022", dateFormat)}
            format={dateFormat}
            suffixIcon={<img src={Calendar} />}
            style={{ height: 38 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PurchaseModal;
