import React, { useContext, useEffect, useState } from "react";
import { Tabs } from "antd";
import "./index.scss";
import Challenges from "./challenges-home/challenges-home";
import Workouts from "./workout-home/workout-home";
import history from "../../util/history";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/user-context";
const ChallengesMainPage = () => {
  const { selectedSport} = useContext(UserContext);
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("challenges");
  const onChange = (key) => {
    if (key === "workouts") {
      setActiveTab(key);
    } else {
      setActiveTab("featured-workouts");
    }
  };
  const items = [
    {
      key: "featured",
      label: `Featured Workouts`,
      children: <Challenges />,
    },
    {
      key: "workouts",
      label: `Workouts`,
      children: <Workouts />,
      // children: `Content of Tab Pane 2`,
      //   children: <UserAccountTable />
    },
  ];
  useEffect(() => {
    if (activeTab === "workouts") {
      navigate({
        search: `tab=workouts`,
      });
    } else {
      navigate({
        search: `tab=featured-workouts`,
      });
    }
  }, [activeTab]);
  return (
    <div className="challenges-main">
      <Tabs
        defaultActiveKey={params.tab && params.tab}
        items={items}
        onChange={onChange}
        className={selectedSport === 1 ? "orange-theme" : "blue-theme"}
      />
    </div>
  );
};

const ChallengesMain = () => {
  return (
    // <GroupContextProvider>
    <ChallengesMainPage />
    // </GroupContextProvider>
  );
};
export default ChallengesMain;
