import React, { useContext, useEffect, useRef, useState } from "react";
import "./submission-detail.scss";
import ReactPlayer from "react-player";
import {
  Button,
  Card,
  Col,
  Divider,
  Row,
  Skeleton,
  Spin,
  Table,
  message,
} from "antd";
import Video from "../../../assets/Snail.mp4";
import Play from "../../../assets/images/play.svg";
import Check from "../../../assets/images/check.svg";
import Cross from "../../../assets/images/cross.svg";
import WorkoutProgress from "./workout-progress";
import SubmittedTask from "./submitted-task";
import AssigneeModal from "./assignee-modal";
import Instructions from "../instructions/instructions";
import {
  SubmissionDetailContext,
  SubmissionDetailContextProvider,
} from "../../../context/submission-context/bsa_submission_detail_context";
import { getDateWithTime, secondsToTime } from "../../../util/config";
import Avatar from "react-avatar";
import { UserContext } from "../../../context/user-context";
const style = {
  padding: "5px 0",
};
let prevLength;
let current_duration = 0;
const SubmissionDetailPage = () => {
  const {
    getTestData,
    selectedTest,
    exercises,
    exerciseData,
    setExerciseData,
    saveVideoReview,
    loading,
    setLoading,
    createdTest,
    clearButton,
    exitButton,
    setExitButton,
    assigneeModal,
    setAssigneeModal,
  } = useContext(SubmissionDetailContext);
  let user_id = localStorage.getItem("u_id")
  const { accountDetail , darkButtonBackground , color , lightButtonBackground , selectedSport } = useContext(UserContext);
  const [index, setIndex] = useState(0);
  const [newData, setNewData] = useState(exerciseData);
  const [videoLoading, setVideoLoading] = useState(false);
  const [playButton, setPlayButton] = useState(true);
  const [instructionsModal, setInstructionsModal] = useState(false);
  const [played, setPlayed] = useState(0);
  const [videoEnded, setVideoEnded] = useState(false);
  const [videoPaused, setVideoPaused] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [prevDisabled, setPrevDisabled] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [missDisable, setMissDisable] = useState(false);
  const [makeDisable, setMakeDisable] = useState(false);
  const [shot, setShot] = useState();
  const [shotType, setShotType] = useState("");
  const [shotName, setShotName] = useState("");
  const [shotIndex, setShotIndex] = useState();
  const [points, setPoints] = useState();
  const playerRef = useRef();
  const [imageLoaded, setImageLoaded] = useState("");
  const [addedShot , setAddedShot] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(true);
  const vidRef = useRef(null);
  const handlePlayVideo = () => {
    setVideoPaused(false);
    setPlayButton(false);
  };
  useEffect(() => {
    if (selectedTest) {
      if (
        (played >= selectedTest.video_duration &&
          // exerciseData.length > 0) || (exerciseData.length === 40 && clearButton === false) ||
          newData.length > 0) ||
        (newData.length >= 40 && clearButton === false) ||
        total() >= 100
      ) {
        setExitButton(true);
        setVideoPaused(true);
        setStartTime(0);
      }
    }
    if (exitButton === true || clearButton === true) {
      setVideoPaused(true);
      setPlayButton(true);
    }
  }, [exerciseData, played, newData]);
  let searchParam = window.location.search;
  const getExceriseSetNumber = (arrayIndex) => {
    let set_index = [];
    let set_name = [];
    let exercise_name = [];
    let exercise_index = [];
    for (let i = 0; i < exercises.length; i++) {
      for (let j = 0; j < exercises[i]["array"].length; j++) {
        exerciseData.map((item) => {
          if (
            item.exercise_detail ===
              exercises[i]["array"][j].exercise_detail_id &&
            item.primary_action === 1
          ) {
          }
        });
        exercise_index.push(exercises[i]["array"][j].exercise_detail_id);
        set_index.push(i + 1);
        set_name.push(exercises[i]["array"][j].exercise);
        exercise_name.push(exercises[i]["array"][j].position);
      }
    }
    setShotIndex(exercise_index);
    setShotName(exercise_name);
    setShotType(set_name);
    setShot(set_index);
  };
  useEffect(() => {
    if (selectedTest) {
      if (selectedTest.video_duration === Math.floor(current_duration)) {
        setVideoEnded(true);
      }
    }
  }, [current_duration]);
  useEffect(() => {
    getTestData();
    if (exercises.length > 0) {
      getExceriseSetNumber();
    }
  }, [exercises, clearButton]);
  const shotAdded = () => {
    current_duration = playerRef.current?.getCurrentTime();
    if (
      exerciseData.length > 1 &&
      exerciseData[exerciseData.length - 1].shot_end_time >
        Math.round(current_duration * 1000)
    ) {
      for (let i = 0; i < exerciseData.length; i++) {
       if (
          Math.round(current_duration * 1000) >=
            exerciseData[i].shot_start_time &&
          Math.round(current_duration * 1000) <=
            exerciseData[i].shot_end_time &&
          videoPaused === false
        ) {

          let o = i;
          let postion = o;
          let new_shot = {
            timestamp: new Date().toISOString(),
            shot_name: "added",
            primary_action: "added",
            score: 0,
            shot_count: -2,
            shot_start_time: exerciseData[postion].shot_start_time,
            shot_end_time: Math.round(current_duration * 1000),
          };
          const newArray = [...exerciseData];
          newArray.splice(postion, 0, new_shot);
          const updateOldArray = newArray.map((item, index) => {
            if (index === i) {
              return {
                ...item,
                timestamp: new Date().toISOString(),
                shot_name: "added",
                primary_action: "added",
                score: 0,
                shot_count: -2,
                shot_start_time: Math.round(current_duration * 1000),
              };
            }
            if (index === o + 1) {
              return {
                ...item,
                shot_start_time: Math.round(current_duration * 1000),
              };
            } else {
              return item;
            }
          });
          setExerciseData(updateOldArray);
        } else {
          if (
            Math.round(current_duration * 1000) ===
            exerciseData[i].shot_start_time
          ) {
            const updateOldArray = exerciseData.map((item, index) => {
              if (index === i) {
                return {
                  ...item,
                  timestamp: new Date().toISOString(),
                  shot_name: "added",
                  primary_action: "added",
                  score: 0,
                  shot_count: -2,
                };
              } else if (index > i) {
                  return {
                  ...item,
                  set: exerciseData[index - 1].set,
                  set_name: exerciseData[index - 1].set_name,
                  shot_count: exerciseData[index - 1].shot_count,
                  shot_name: exerciseData[index - 1].shot_name,
                  primary_action: exerciseData[index - 1].primary_action,
                  
                };
              }
              else {
                return item;
              }
            });
            setMakeDisable(false);
            setMissDisable(false);            
            setExerciseData(updateOldArray);
            return;
          }
        }
      }
    } else {
      setExerciseData((prevData) => [
        ...prevData,
        {
          timestamp: new Date().toISOString(),
          shot_name: "added",
          primary_action: "added",
          score: 0,
          shot_count: -2,
          shot_start_time: startTime,
          shot_end_time: Math.round(current_duration * 1000),
        },
      ]);
      setStartTime(Math.round(current_duration * 1000));
    }
  };
  useEffect(() => {
    if (selectedTest) {
      setVideoLoading(true);
    }
  }, [selectedTest]);
  const total = () => {
    var total = 0;
    for (var i = 0; i < exerciseData.length; i++) {
      total = total + exerciseData[i].score;
    }
    return total;
  };
  useEffect(() => {
    if (shotIndex + 1 === exerciseData.length) {
    } else {
    }
  }, [shotIndex]);
  const chatbox = useRef(null);
  useEffect(() => {
    if (scrollPosition) {
    }
  }, [exerciseData]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [counter, setCounter] = useState(0);
  const prevShot = () => {
    if (counter < exerciseData.length) {
      setNextDisabled(false);
    }
    let previousShotTime =
      exerciseData[exerciseData.length - 1].shot_start_time;
    if (
      exerciseData.length > 1 &&
      Math.round(current_duration * 1000) >= exerciseData[0].shot_end_time
    ) {
      let test = Math.round(playerRef.current?.getCurrentTime() * 1000);
      for (let i = 0; i < exerciseData.length; ++i) {
        if (
          exerciseData[i].shot_start_time <= test &&
          exerciseData[i].shot_end_time >= test
        ) {
          if (exerciseData[i - 1]) {
            previousShotTime = exerciseData[i - 1].shot_start_time;
          } else {
            previousShotTime = exerciseData[0].shot_start_time;
            setPrevDisabled(true);
          }
        }
      }
      playerRef.current?.seekTo(previousShotTime / 1000);
      setVideoPaused(true);
      for (let i = 0; i < exerciseData.length; i++) {
        if (exerciseData[i].shot_start_time == previousShotTime) {
          if (exerciseData[i].primary_action == 1) {
            exerciseData[i].primary_action = 1;
            setCounter(exerciseData[i].shot_count - 1);
            setMissDisable(true);
            setMakeDisable(false);
            setAddedShot(false)
          }
          if (exerciseData[i].primary_action == 2) {
            exerciseData[i].primary_action = 2;
            setCounter(exerciseData[i].shot_count - 1);
            setMakeDisable(true);
            setMissDisable(false);
            setAddedShot(false)
          }
          if (exerciseData[i].primary_action == "added") {
            exerciseData[i].primary_action = "added";
            setCounter(-2);
            setMakeDisable(true);
            setMissDisable(true);
            setAddedShot(true)
          }
          {
            newData.map((item, indexxx) => {
              if (
                exerciseData[i].primary_action !== "added" &&
                exerciseData[i].shot_start_time === item.shot_start_time
              ) {
                setIndex(indexxx);
              }
            });
          }
        }
      }
    }
  };
  const nextShot = () => {
    let nextShotTime = exerciseData[exerciseData.length - 1].shot_end_time;
    if (
      exerciseData.length > 1 &&
      Math.round(current_duration * 1000) <=
        exerciseData[exerciseData.length - 1].shot_end_time
    ) {
      let test = Math.round(playerRef.current?.getCurrentTime() * 1000);
      for (let i = 0; i < exerciseData.length; ++i) {
        if (
          exerciseData[i].shot_start_time <= test &&
          exerciseData[i].shot_end_time >= test
        ) {
          if (exerciseData[i + 1]) {
            nextShotTime = exerciseData[i + 1].shot_start_time;
            if (
              exerciseData[i + 1].shot_end_time ===
              exerciseData[exerciseData.length - 1].shot_end_time
            ) {
              setNextDisabled(true);
              setPrevDisabled(false);
            }
          } else {
            nextShotTime = exerciseData[exerciseData.length - 1].shot_end_time;
            setNextDisabled(true);
          }
        }
      }
      playerRef.current?.seekTo(nextShotTime / 1000);
      setVideoPaused(true);
      for (let i = 0; i < exerciseData.length; i++) {
        if (exerciseData[i].shot_start_time == nextShotTime) {
          if (exerciseData[i].primary_action == 1) {
            exerciseData[i].primary_action = 1;
            setCounter(exerciseData[i].shot_count - 1);
            setMissDisable(true);
            setMakeDisable(false);
            setAddedShot(false)
          }
          if (exerciseData[i].primary_action == 2) {
            exerciseData[i].primary_action = 2;
            setCounter(exerciseData[i].shot_count - 1);

            setMakeDisable(true);
            setMissDisable(false);
            setAddedShot(false)
          }
          if (exerciseData[i].primary_action == "added") {
            exerciseData[i].primary_action = "added";
            setCounter(-2);
            setMakeDisable(true);
            setMissDisable(true);
            setAddedShot(true)
          }
          {
            newData.map((item, indexxx) => {
              if (
                exerciseData[i].primary_action !== "added" &&
                exerciseData[i].shot_start_time === item.shot_start_time
              ) {
                setIndex(indexxx);
              }
            });
          }
        }
      }
    }
  };
  useEffect(() => {
    if (
      exerciseData.length > 1 &&
      videoPaused === false &&
      Math.round(current_duration * 1000) >
        exerciseData[exerciseData.length - 1].shot_end_time &&
      exerciseData[exerciseData.length - 1].shot_count === -2 &&
      counter === -2
    ) {
      for (var i = exerciseData.length - 1; i >= 0; i--) {
        if (exerciseData[i].shot_count !== -2) {
          setCounter(exerciseData[i].shot_count);
          break;
        }
      }
    }
  }, [current_duration, played]);
  const actionShot = (action) => {
    setAddedShot(false)
    current_duration = playerRef.current?.getCurrentTime();
    let endTime =
      exerciseData.length === 0
        ? current_duration
        : exerciseData[exerciseData.length - 1].shot_end_time;
    if (current_duration * 1000 >= startTime) {
      if (counter >= exerciseData.length) {
        setCounter(counter + 1);
        let shot_index = counter + 1;
        setExerciseData((prevData) => [
          ...prevData,
          {
            set: shot[counter],
            set_name: shotType[counter],
            shot_count: counter + 1,
            shot_name: shotName[counter],
            primary_action: action,
            score:
              action === 2
                ? 0
                : shot_index <= 2 || shot_index >= 38
                ? 1
                : shot_index > 2 && shot_index < 13
                ? 2
                : 3,
            shot_start_time: startTime ? startTime : 0,
            shot_end_time: Math.round(current_duration * 1000),
            timestamp: new Date().toISOString(),
          },
        ]);
        setStartTime(Math.round(current_duration * 1000));
        setCounter(counter + 1);
      } else {
        setExerciseData((prevData) => [
          ...prevData,
          {
            set: shot[counter],
            set_name: shotType[counter],
            shot_count: counter + 1,
            shot_name: shotName[counter],
            primary_action: action,
            score:
              action === 2
                ? 0
                : shot[counter] === 1 || shot[counter] === 5
                ? 1
                : shot[counter] === 3 || shot[counter] === 4
                ? 3
                : 2,
            shot_start_time: startTime ? startTime : 0,
            shot_end_time: Math.round(current_duration * 1000),
            timestamp: new Date().toISOString(),
          },
        ]);
        setStartTime(Math.round(current_duration * 1000));
        setCounter(counter + 1);
      }
    } else {
      if (counter !== -2 && (missDisable === true || makeDisable === true)) {
        if (action === 1) {
          setMissDisable(true);
          setMakeDisable(false);
        } else {
          setMakeDisable(true);
          setMissDisable(false);
        }
        const newArray = exerciseData.map((item, i) => {
          if (item.shot_start_time === exerciseData[counter].shot_start_time) {
            return {
              ...item,
              primary_action: action,
              score:
                action === 2
                  ? 0
                  : counter <= 2 || counter >= 38
                  ? 1
                  : counter > 2 && counter < 13
                  ? 2
                  : 3,
              timestamp: new Date().toISOString(),
            };
          } else {
            return item;
          }
        });
        setExerciseData(newArray);
        setCounter(counter);
      } else {
        current_duration = playerRef.current?.getCurrentTime();
        if (
          exerciseData.length > 0 &&
          exerciseData[exerciseData.length - 1].shot_start_time !==
            Math.round(current_duration * 1000) &&
          counter !== -2
        ) {
          for (let i = 0; i < exerciseData.length; i++) {
            if (
              exerciseData[i].shot_start_time >
                Math.round(current_duration * 1000) &&
              exerciseData[i].primary_action !== "added"
            ) {
              let o = i - 1;
              let postion = o;
              let new_shot = {
                set: shot[postion],
                set_name: shotType[postion],
                shot_count: postion + 1,
                shot_name: shotName[postion],
                primary_action: action,
                score:
                  action === 2
                    ? 0
                    : postion + 1 <= 2 || postion + 1 >= 38
                    ? 1
                    : postion + 1 > 2 && postion + 1 < 13
                    ? 2
                    : 3,
                shot_start_time: exerciseData[postion].shot_start_time,
                shot_end_time: Math.round(current_duration * 1000),
                timestamp: new Date().toISOString(),
              };
              const newArray = [...exerciseData];
              newArray.splice(postion, 0, new_shot);
              const updateOldArray = newArray.map((item, index) => {
                if (index === i) {
                  setCounter(o);
                  return {
                    ...item,
                    set: shot[i],
                    set_name: shotType[i],
                    shot_count: i + 1,
                    shot_name: shotName[i],
                    shot_start_time: Math.round(current_duration * 1000),
                    timestamp: new Date().toISOString(),
                  };
                }
                if (index > i) {
                  return {
                    ...item,
                    set: shot[index],
                    set_name: shotType[index],
                    shot_count: index + 1,
                    shot_name: shotName[index],
                  };
                } else {
                  return item;
                }
              });
              setExerciseData(updateOldArray);
              return;
            } else if (
              exerciseData[i].shot_start_time <
                Math.round(current_duration * 1000) &&
              exerciseData[i].primary_action !== "added"
            ) {
              let o = i;
              let postion = o;
              let new_shot = {
                set: shot[postion],
                set_name: shotType[postion],
                shot_count: postion + 1,
                shot_name: shotName[postion],
                primary_action: action,
                score:
                  action === 2
                    ? 0
                    : postion + 1 <= 2 || postion + 1 >= 38
                    ? 1
                    : postion + 1 > 2 && postion + 1 < 13
                    ? 2
                    : 3,
                shot_start_time: exerciseData[postion].shot_start_time,
                shot_end_time: Math.round(current_duration * 1000),
                timestamp: new Date().toISOString(),
              };
              const newArray = [...exerciseData];
              newArray.splice(postion, 0, new_shot);
              const updateOldArray = newArray.map((item, index) => {
                if (index > postion) {
                  setCounter(postion + 1);
                  return {
                    ...item,
                    set: shot[index],
                    set_name: shotType[index],
                    shot_count: index + 1,
                    shot_name: shotName[index],
                    timestamp: new Date().toISOString(),
                    shot_start_time: new_shot.shot_end_time,
                  };
                } else {
                  return item;
                }
              });
              setExerciseData(updateOldArray);
              // return;
            }
          }
        } else {
          for (let i = 0; i < exerciseData.length; i++) {
            if (
              exerciseData[i].shot_start_time ===
              Math.round(current_duration * 1000)
            ) {
              let nextShot = exerciseData.findIndex(
                (obj) =>
                  obj.shot_start_time > exerciseData[i].shot_start_time &&
                  obj.shot_count !== -2
              );

              if (nextShot !== -1) {
                exerciseData[i].set = exerciseData[nextShot].set;
                exerciseData[i].set_name = exerciseData[nextShot].set_name;
                exerciseData[i].shot_count = exerciseData[nextShot].shot_count;
                exerciseData[i].shot_name = exerciseData[nextShot].shot_name;
                exerciseData[i].primary_action = action;
                exerciseData[i].score =
                  action === 2
                    ? 0
                    : exerciseData[nextShot].set === 1 ||
                      exerciseData[nextShot].set === 5
                    ? 1
                    : exerciseData[nextShot].set === 3 ||
                      exerciseData[nextShot].set === 4
                    ? 3
                    : 2;
                exerciseData[i].timestamp = new Date().toISOString();

                const updatedArray = exerciseData.map((item) => {
                  if (
                    item.shot_start_time >=
                    exerciseData[nextShot].shot_start_time
                  ) {
                    setCounter(item.shot_count + 1);
                    return {
                      ...item,
                      set: shot[item.shot_count],
                      set_name: shotType[item.shot_count],
                      shot_count: item.shot_count + 1,
                      shot_name: shotName[item.shot_count],
                    };
                  } else {
                    return item;
                  }
                });
                setExerciseData(updatedArray);
              } else {
                const lastIndex = exerciseData
                  .map((obj) => obj.shot_count !== -2)
                  .lastIndexOf(true);
                setCounter(exerciseData[lastIndex].shot_count + 1);
                exerciseData[i].set = shot[exerciseData[lastIndex].shot_count];
                exerciseData[i].set_name =
                  shotType[exerciseData[lastIndex].shot_count];
                exerciseData[i].shot_count =
                  exerciseData[lastIndex].shot_count + 1;
                exerciseData[i].shot_name =
                  shotName[exerciseData[lastIndex].shot_count];
                exerciseData[i].primary_action = action;
                exerciseData[i].score =
                  action === 2
                    ? 0
                    : shot[exerciseData[lastIndex].shot_count] === 1 ||
                      shot[exerciseData[lastIndex].shot_count] === 5
                    ? 1
                    : shot[exerciseData[lastIndex].shot_count] === 3 ||
                      shot[exerciseData[lastIndex].shot_count] === 4
                    ? 3
                    : 2;
                exerciseData[i].timestamp = new Date().toISOString();
              }
            }
          }
          if (action === 1) {
            setMissDisable(true);
            setMakeDisable(false);
          } else {
            setMakeDisable(true);
            setMissDisable(false);
          }
        }
      }
    }
  };
  useEffect(() => {
      if(chatbox.current){
        chatbox.current.scrollTop = chatbox.current.scrollHeight ;
      }
  }, [exerciseData]);

  useEffect(() => {
    if (
      exerciseData.length > 0 &&
      played * 1000 > exerciseData[exerciseData.length - 1].shot_end_time &&
      exerciseData[exerciseData.length - 1].shot_name !== "added"
    ) {
      setCounter(exerciseData[exerciseData.length - 1].shot_count);
    }
  }, [current_duration]);
  const info = () => {
    message.info("You don't have permissions. Contact to Admin");
  };
  useEffect(() => {
    handleFilter();
  }, [exerciseData]);
  const handleFilter = (event) => {
    const filtered = exerciseData.filter(
      (user) => user.primary_action !== "added"
    );
    setNewData(filtered);
    filtered.map((item, index) => {
      setIndex(index + 1);
    });
  };
  const checkAssigneePermission = () => {
    if(accountDetail.role !== null){
    for (let item of accountDetail.role?.role_permission) {
      if(item.module.name === "Submissions" && item.permission.code_name === "ASSIGNMENT" && selectedTest.assigned_person?.id.toString() !== user_id){
        if(item.value === false){
          info();
        }
        else{
          setAssigneeModal(true)        
        }
      }
    }
    } else{
      setAssigneeModal(true)        
    }
  }
  return loading === true ? (
    <Spin
      className={"loader " + (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")}
      size="large"
      style={{
        position: "absolute",
        left: "49%",
        top: "39%",
      }}
    />
  ) : (
    selectedTest && (
      <div className="submission-detail-main">
        <div className="right-div">
          {selectedTest.athlete?.avatar !== null &&
            imageLoaded === "" &&
            selectedTest.athlete?.avatar !== "" && (
              <Skeleton.Image
                active={true}
                style={{ width: "40px", height: "40px" }}
              />
            )}
          {selectedTest.athlete?.avatar === "" ||
          selectedTest.athlete?.avatar === null ? (
            <Avatar
              name={
                selectedTest.athlete?.first_name +
                " " +
                selectedTest.athlete?.last_name
              }
              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
              className="initial-avatar"
              style={{ width: "40px", height: "40px" }}
            />
          ) : (
            <>
              <img
                src={selectedTest.athlete?.avatar}
                alt="avatar"
                style={
                  imageLoaded === ""
                    ? { display: "none" }
                    : { display: "block" }
                }
                onLoad={() => setImageLoaded("loaded")}
              />
            </>
          )}
          <div className="name-div">
            <h3 className="user-name">
              {selectedTest.athlete?.first_name +
                " " +
                selectedTest.athlete?.last_name}
            </h3>
            <h4 className="handle">@{selectedTest.athlete?.username}</h4>
          </div>
          <div className="vl"></div>
          <div className="name-div">
            <h3 className="user-name">Test ID# {selectedTest.id}</h3>
            <div style={{ display: "flex" }}>
              <h4 className="handle" style={{ margin: "6px 0" }}>
                {getDateWithTime(selectedTest.created)}
              </h4>
              <span className="purple-tag">BSA</span>
            </div>
          </div>
          {!searchParam.includes("bsa") && (
            <Button
              className="red-base-btn"
              onClick={() => setInstructionsModal(true)}
              style={{color : color , background : lightButtonBackground}}
            >
              View Instructions
            </Button>
          )}
          <Button
            className="red-base-btn"
            onClick={checkAssigneePermission}
            style={{ visibility: user_id === selectedTest.assigned_person?.id.toString() ? "hidden" : "visible" , color : color , background : lightButtonBackground}}
          >
            Assign to
          </Button>
        </div>
        {videoLoading === true && (
          <div className="video-loader">
            <Spin
              className={"loader " + (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")}
              size="large"
              style={{
                position: "absolute",
                left: "48%",
                top: "44%",
              }}
            />{" "}
          </div>
        )}
        <div className="video-div" style={{ marginTop: 0 }}>
          {exitButton ? (
            <button className="save-btn" onClick={saveVideoReview} style={{ background : darkButtonBackground}}>
              Exit and Review Result
            </button>
          ) : clearButton || createdTest.length > 0 ? (
            <button
              className="clear-btn"
              onClick={() => {
                setExerciseData((exerciseData) => []);
                saveVideoReview(0);
              }}
            >
              Clear Results and Review Again
            </button>
          ) : (
            ""
          )}
          {playButton && videoPaused === false && videoLoading === false && (
            <button className="play-btn" onClick={handlePlayVideo}>
              <img src={Play} />
            </button>
          )}
          <ReactPlayer
            url={selectedTest.test_video}
            ref={playerRef}
            controls={
              playButton === false ||
              (videoPaused && exitButton === false && clearButton === false)
            }
            width={"65%"}
            height={"100%"}
            playing={playButton === false && videoPaused === false}
            onProgress={(progress) => {
              current_duration = playerRef.current?.getCurrentTime();
              setPlayed(progress.playedSeconds);
            }}
            style={{ opacity: exitButton || clearButton ? 0.6 : 1 }}
            onPause={() => setVideoPaused(true)}
            onReady={() => {
              setTimeout(() => {
                setVideoLoading(false);
              }, 500);
            }}
            onPlay={() => {
              if (current_duration !== 0) {
                setPrevDisabled(false);
              }
              setNextDisabled(true);
              setPlayButton(false);
              setVideoPaused(false);
              if (videoEnded === true) {
                setVideoEnded(false);
              }
              setMakeDisable(false);
              setMissDisable(false);
            }}
          />
          <div className="record-card " ref={chatbox}>
            <h3>Make/Miss Timestamps</h3>
            <div  className="exercise-div">
              {exerciseData && exerciseData.length === 0 ? (
                <div className="no-content">
                  No timestamps have been recorded yet.
                </div>
              ) : (
                exerciseData.map((item, index) => {
                  return (
                    item.primary_action !== "added" && (
                      <div key={index}>
                        <div className="exercise-record">
                          {item.primary_action === 1 ? "Make" : "Miss"}
                          <span>
                            {new Date(item.shot_end_time)
                              .toISOString()
                              .slice(11, 19)}
                          </span>
                        </div>
                        <Divider />
                      </div>
                    )
                  );
                })
              )}
            </div>
          </div>
        </div>

        {searchParam.includes("bsa") ? (
          <>
            <div className="video-action">
              <div style={{ display: "flex", gap: "40px", width: "30%" }}>
                {counter + 1 === -1 ? (
                  <div className="added-shot">
                    ADDED SHOT (does not affect score)
                  </div>
                ) : (
                  <>
                    <div className="sets" style={{ width: "60%" }}>
                      Sets <br />
                      <br />
                      <span>{shot && shot[index]}/</span>
                      {exercises.length} {shotType && shotType[index]}
                    </div>
                    <div className="shots" style={{ width: "40%" }}>
                      Shots <br />
                      <br />
                      <span>
                        {counter + 1 === -1 ? "Added Shot" : index + 1 === 41 ? "40" : index + 1}/
                      </span>
                      {selectedTest.test.total_shots}{" "}
                      {shotName && shotName[index]}
                    </div>
                  </>
                )}
              </div>
              <div style={{ display: "flex", gap: "15px" }}>
                <Button
                  className="red-base-btn"
                  style={{color : color , background : lightButtonBackground}}
                  onClick={prevShot}
                  disabled={
                    prevDisabled ||
                    exerciseData.length === 0 ||
                    current_duration === selectedTest.video_duration ||
                    exitButton ||
                    clearButton
                  }
                >
                  Previous
                </Button>
                <Button
                  className="dark-base-btn"
                  style={{background : darkButtonBackground}}
                  onClick={nextShot}
                  disabled={
                    nextDisabled ||
                    exerciseData.length === 0 ||
                    current_duration === selectedTest.video_duration ||
                    exitButton ||
                    clearButton
                  }
                >
                  Next
                </Button>
              </div>
              <div style={{ display: "flex", gap: "24px" }}>
                <Button
                  className="green-circle"
                  icon={<img src={Check} />}
                  onClick={() => {
                    actionShot(1);
                  }}
                  disabled={
                    (exerciseData.length === 0 && played === 0) ||
                    videoEnded ||
                    counter >= 40 ||
                    total() >= 100 ||
                    exitButton ||
                    clearButton
                  }
                  style={{ opacity: makeDisable === true && 0.3 }}
                ></Button>
                <Button
                  className="red-circle"
                  icon={<img src={Cross} />}
                  onClick={() => {
                    actionShot(2);
                  }}
                  style={{ opacity: missDisable === true && 0.3 }}
                  disabled={
                    (exerciseData.length === 0 && played === 0) ||
                    videoEnded ||
                    counter >= 40 ||
                    total() >= 100 ||
                    exitButton ||
                    clearButton
                  }
                ></Button>
              </div>
              <Button
                className={"gray-base-btn " + (counter >= 40 || playButton === true ? "" : addedShot === true ? "gray-base-btn-active" : 'gray-base-btn-effect')}
                onClick={()=>shotAdded()}
                disabled={counter >= 40 || playButton === true}
              >
                Added Shot
              </Button>
            </div>
            <Row gutter={16} className="points-row">
              <Col className="gutter-row" span={8}>
                <div style={style} className="div-1">
                  <span className="points">
                    {secondsToTime(selectedTest.video_duration)}
                  </span>
                  <br />
                  <span className="category">Time</span>
                </div>
              </Col>
              <Col className="gutter-row" span={8}>
                <div style={style} className="div-1">
                  <span className="points">
                    {
                      exerciseData.filter((obj) => obj.primary_action === 1)
                        .length
                    }
                    /
                    {
                      exerciseData.filter(
                        (obj) => obj.primary_action !== "added"
                      ).length
                    }
                    (
                    {isNaN(
                      (exerciseData.filter((obj) => obj.primary_action === 1)
                        .length /
                        exerciseData.filter(
                          (obj) => obj.primary_action !== "added"
                        ).length) *
                        100
                    )
                      ? "--"
                      : (
                          (exerciseData.filter(
                            (obj) => obj.primary_action === 1
                          ).length /
                            exerciseData.filter(
                              (obj) => obj.primary_action !== "added"
                            ).length) *
                          100
                        ).toFixed(0)}
                    %)
                  </span>
                  <br />
                  <span className="category">Accuracy</span>
                </div>
              </Col>{" "}
              <Col className="gutter-row" span={8}>
                <div style={style} className="div-1">
                  <span className="points">{total()} pts.</span>
                  <br />
                  <span className="category">Points</span>
                </div>
              </Col>
            </Row>
            <WorkoutProgress
              // makeShot={makeShot} missShot={missShot}
              setScrollPosition={setScrollPosition}
            />
          </>
        ) : (
          <SubmittedTask />
        )}
        <Instructions
          instructionsModal={instructionsModal}
          setInstructionsModal={setInstructionsModal}
        />
        <AssigneeModal
          assigneeModal={assigneeModal}
          setAssigneeModal={setAssigneeModal}
          selectedTest={selectedTest}
        />
      </div>
    )
  );
};
const SubmissionDetail = () => {
  return (
    <SubmissionDetailContextProvider>
      <SubmissionDetailPage />
    </SubmissionDetailContextProvider>
  );
};
export default SubmissionDetail;
