import React, { useEffect, useState } from "react";
import { message, Form, Button, Modal, Select, Dropdown, Menu } from "antd";
import { getDataCoach, formPostData } from "../../util/coach-api-calls";
const { confirm } = Modal;
export const GroupContext = React.createContext({});
export const GroupContextProvider = ({ children = {} }) => {
  const [groups, setGroups] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalGroups, setTotalGroups] = useState(0);
  const [loading, setLoading] = React.useState(true);
  const [search , setSearch] = useState("")
  const getGroupsList = () => {
    getDataCoach(`coach/group/list?page=${pageNumber}&page_size=${pageSize}&search=${search}`)
      .then((response) => {
        setGroups(response.data.results);
        setTotalGroups(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const handleTableChange = (pagination, filters, sorter) => {
    if (pagination.current !== pageNumber) {
      setPageNumber(pagination.current);
    }
    if (pagination.pageSize !== pageSize) {
      setPageSize(pagination.pageSize);
    }
  };
  React.useEffect(() => {
    setLoading(true)
    getGroupsList();
  }, [pageNumber , search , pageSize]);
  const defaultContext = {
    getGroupsList,
    pageNumber,
    pageSize,
    groups,
    totalGroups,
    handleTableChange,
    loading,
    setPageNumber,
    setGroups,setSearch
  };
  return (
    <GroupContext.Provider value={defaultContext}>
      {children}
    </GroupContext.Provider>
  );
};
