import React, { useEffect, useState } from "react";
import { message, Form, Button, Modal, Select, Dropdown, Menu } from "antd";
import { deleteItem, getData, formPostData } from "../../util/api-calls";
const { confirm } = Modal;
export const PostsContext = React.createContext({});
export const PostsContextProvider = ({ children = {} }) => {
  const [postsList , setPostsList ] = useState([])
  const [pageNumber , setPageNumber] = useState(1)
  const [pageSize , setPageSize] = useState(10)
  const [totalPosts , setTotalPosts] = useState(0)
    const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }

const getAllPosts = () =>{
  getData(`group/${params.id}/post`).then((response) => {
    console.log(response)
    // setAnnouncementsList()
}).catch((error) => {
  console.log(error)
})
}
const createPost = () => {
  let formData = new FormData();
  formData.append('title','test post')
  formData.append('text' , 'this is a test post')
  formPostData(`group/${params.id}/post`,formData).then((res)=>{
    console.log(res)
  }).catch((err)=>{
    console.log(err)
  })
}
const DeletePost = () => {
    deleteItem(`group/${params.id}/post/${"post.id"}`).then((res)=>{
        console.log(res)
    }).catch((err)=>{
        console.log(err)
    })
}
  React.useEffect(()=>{
    getAllPosts()
  } , []) 
  const defaultContext = {
    getAllPosts ,
    createPost
  };
  return (
    <PostsContext.Provider value={defaultContext}>
      {children}
    </PostsContext.Provider>
  );
};
