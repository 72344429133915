import HeaderWrapper from './header-wrapper/header-wrapper.jsx';
import { Button, Menu ,Layout  , Dropdown } from 'antd';
import { Outlet } from 'react-router-dom';
import Breadcrumbs from '../coach-module/breadcrumbs/breadcrumb.jsx';
const { Header, Content , Sider , Footer} = Layout;
const AdminModule = () => {
    return (
            <Layout 
             style={{display:"block",background:"#f9f9f9"}}>
              < HeaderWrapper/>
              <Breadcrumbs />

              <Content
                style={{
                  overflow: 'initial',
                  // overflowX:"hidden"
                }}
              >
                <Outlet />
              </Content>
          </Layout>

    );
  }

export default AdminModule
