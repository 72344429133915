import React, { useContext, useEffect, useState } from "react";
import { Table, Tabs, Input, Button, Tooltip, message } from "antd";
import "./group-home.scss";
import Plus from "../../../assets/images/plus.svg";
import Search from "../../../assets/images/search.svg";
import { useNavigate } from "react-router-dom";
import { getDate, getDateWithoutDay } from "../../../util/config";
import {
  GroupContext,
  GroupContextProvider,
} from "../../../context/group-context/group-context";
import { UserContext } from "../../../context/user-context";
const GroupHomePage = () => {
  const {
    pageNumber,
    pageSize,
    groups,
    totalGroups,
    handleTableChange,
    loading,
    setPageNumber,
    setGroups,
    setSearch,
  } = useContext(GroupContext);
  const [showMessage, setShowMessage] = useState(true);
  const navigate = useNavigate();
  const { accountDetail , selectedSport} = useContext(UserContext);
  const info = () => {
    message.destroy();
    message.info("You don't have permissions. Contact to Admin");
  };
  const items = [
    {
      key: "all",
      label: `All`,
      //   children: `Content of Tab Pane 1`,
      // children: <Profile />
    },
    {
      key: "blocked",
      label: `Blocked`,
      // children: `Content of Tab Pane 2`,
      //   children: <UserAccountTable />
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  var indents = [];
  for (var i = 0; i <= totalGroups; i++) {
    indents.push(
      <span className="indent" key={i}>
        {i}
      </span>
    );
  }
  const columns = [
    {
      title: "ID",
      dataIndex: indents,
      key: indents,
      // "id"
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        let id;
        return (
          <Tooltip placement="bottom" title={text} key={index}>
            {record.index}
          </Tooltip>
        );
      },
    },
    {
      title: "GROUP NAME",
      dataIndex: "name",
      key: "name",
      ellipsis: {
        showTitle: false,
      },
      render: (record, index) => {
        return (
          <Tooltip placement="bottom" title={record}>
            {record}
          </Tooltip>
        );
      },
    },
    {
      title: "Sport",
      dataIndex: "sport_name",
      key: "sport_name",
      render: (text, record, index) => {
        return (
          <div key={index}>
            {record.sport === null ? "N/A" : record.sport.name}
          </div>
        );
      },
    },
    {
      title: "Seats",
      dataIndex: "member_limit",
      key: "member_limit",
      render: (text, record, index) => {
        return (
          <div key={index}>
            {record.member_limit === 100000 ? "N/A" : record.member_limit}
          </div>
        );
      },
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (record, index) => {
        return (
          <Tooltip placement="bottom" title={record}>
            {record ? record : "N/A"}
          </Tooltip>
        );
      },
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      render: (text, record, index) => {
        // console.log(record.subscription_required)
        // console.log(record.subscription.expiry_date)
        return (
          <div>
            {record.subscription_required === true &&
            record.subscription === null ? (
              <Button className="pink-tag">Inactive</Button>
            ) : // <span className="green-tag" key={index}>
            //   {record.status}
            // </span>
            record.subscription_required === true &&
              record.subscription !== null ? (
              <Button className="green-tag" key={index}>
                Active
              </Button>
            ) : record.subscription_required === false &&
              record.subscription === null ? (
              <Button className="green-tag" key={index}>
                Active
              </Button>
            ) : (
              "N/A"
            )}
          </div>
        );
      },
    },
    {
      title: "valid till",
      dataIndex: "expiry_date",
      key: "expiry_date",
      width: 200,
      render: (text, record, index) => {
        // console.log(re)
        return (
          <div key={index}>
            {record && record.subscription
              ? getDateWithoutDay(record.subscription.expiry_date)
              : "N/A"}
          </div>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const gotoGroupDetail = (record) => {
    if (accountDetail.role !== null) {
      for (let item of accountDetail.role?.role_permission) {
        if (item.module.name === "Groups") {
          if (
            (item.permission.code_name === "ADD" &&
              item.permission.code_name === "EDIT" &&
              item.permission.code_name === "DELETE") ||
            item.value === false
          ) {
            setShowMessage(false);
            if (item.value === false && showMessage === false) {
              info();
            }
          } else {
            navigate({
              pathname: `./${record.name}`,
              search: `?id=${record.id}`,
            });
          }
        }
      }
    } else {
      navigate({
        pathname: `./${record.name}`,
        search: `?id=${record.id}`,
      });
    }
  }
  const searchGroup = (e) => {
    if (e.target.value.length >= 3) {
      setPageNumber(1);
      setGroups([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setGroups((groups) => []);
      setSearch("");
    }
  };
  return (
    <div className="group-home-main">
      <div style={{ position: "absolute", right: 0, top: "8px" }}>
        {/* <Button
          icon={<img src={Plus} />}
          className="dark-base-btn"
          style={{ marginLeft: "auto" }}
          onClick={() => navigate(`./create-new-group`)}
        >
          Create Group
        </Button> */}
      </div>
      {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} className={selectedSport === 1 ? "orange-theme" : "blue-theme"}/> */}
      <div className="table-div">
        <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchGroup}
          />
          <div className="right-div">
            <h4>Total Groups: </h4>
            <h4 style={{ fontWeight: "700" }}> {totalGroups}</h4>
          </div>
        </div>
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: () => gotoGroupDetail(record),
            };
          }}
          loading={loading}
          onChange={handleTableChange}
          rowKey={(obj) => obj.id}
          // rowSelection={rowSelection}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current: pageNumber,
            pageSize: pageSize,
            total: totalGroups,
          }}
          columns={columns}
          dataSource={groups}
          className={"table-container " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
        />
      </div>
    </div>
  );
};

const GroupHome = () => {
  return (
    <GroupContextProvider>
      <GroupHomePage />
    </GroupContextProvider>
  );
};
export default GroupHome;
