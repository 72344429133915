import React, { useState } from "react";
import {
  getData,
  formPostData,
  deleteItem,
  patchData,
} from "../../util/api-calls";
export const SportContext = React.createContext({});
export const SportsContextProvider = ({ children = {} }) => {
  const [imgSrc, setImgSrc] = useState();
  const [gender, setGender] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [addSportModal, setAddSportModal] = useState(false);
  const [sportsList, setSportsList] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [editSportModal, setEditSportModal] = useState(false);
  const [selectedSport, setSelectedSport] = useState({});
  const profileSportsList = () => {
    getData(`sport`)
      .then((res) => {
        setSportsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addSport = (values) => {
    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    if (selectedColor !== "") {
      formData.append("color", selectedColor.toHexString());
    }
    if (imageUrl) {
      formData.append("icon", imageUrl);
    }
    formPostData(`sport`, formData)
      .then((res) => {
        setAddSportModal(false);
        profileSportsList();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateSport = (values, item) => {
    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    if (selectedColor !== "") {
      formData.append(
        "color",
        typeof selectedColor === "string"
          ? selectedColor
          : selectedColor && selectedColor.toHexString()
      );
    }
    if (imageUrl) {
      formData.append("icon", imageUrl);
    }
    // else{
    //   formData.append("icon", "");
    // }
    patchData(`sport/${item.id}`, formData)
      .then((res) => {
        if (res && res.status === 200) {
          setEditSportModal(false);
          profileSportsList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteSport = (item) => {
    deleteItem(`sport/${item.id}`, {
      name: item.name,
    })
      .then((res) => {
        profileSportsList();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    profileSportsList();
  }, []);
  const defaultContext = {
    gender,
    setImageUrl,
    setGender,
    imgSrc,
    setImgSrc,
    loading,
    updateSport,
    addSportModal,
    setAddSportModal,
    sportsList,
    addSport,
    selectedColor,
    setSelectedColor,
    deleteSport,
    setEditSportModal,
    editSportModal,
    selectedSport,
    setSelectedSport,
  };
  return (
    <SportContext.Provider value={defaultContext}>
      {children}
    </SportContext.Provider>
  );
};
