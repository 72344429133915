import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/user-context";
import { DatePicker, Input, Select, Table, Dropdown } from "antd";
import Search from "../../../assets/images/search.svg";
import Calendar from "../../../assets/images/calendar.svg";
import DownArrow from "../../../assets/images/arrows.svg";
import { getDateWithoutDay } from "../../../util/config";
import "./FW-submission-detail.scss";
import { FWSubmissionDetailContext } from "../../../context/submission-context/fw-submission-detail-context";

const TaskDetail = ({ setFWTaskSubmissionDetail, setTaskDetailTable }) => {
  const { RangePicker } = DatePicker;
  const dateFormat = "ddd. MMMM D, YYYY";
  const { selectedSport } = useContext(UserContext);
  const {
    getSingleSubmission,
    selectedTask,
    taskDetail,
    loading,
    setEndDate,
    setStartDate,
    taskOffset,
    limit,
    setTaskOffset,
    setStatus,
    setTaskSort,
    startDate,
    endDate,
    status,
    taskSort,
    setTaskSearch,
    setTaskDetail,
    taskSearch,setSelectedProfile,
  } = useContext(FWSubmissionDetailContext);
  useEffect(() => {
    if (selectedTask) {
      getSingleSubmission();
    }
  }, [selectedTask, taskSort, status, startDate, endDate, taskSearch]);
  const handleTableChange = (pagination, filters, sorter) => {
    if (pagination.current - 1 !== taskOffset) {
      setTaskOffset(pagination.current - 1);
    }
  };
  const items = [
    {
      key: "details",
      label: "View Details",
    },
  ];
  const columns = [
    {
      title: "Player name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Submission date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <span>{getDateWithoutDay(record.submission_date)}</span>
      ),
    },
    {
      title: "User name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "SUBMISSION STATUS",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return record.status === "pending" ? (
          <span className="yellow-tag" style={{ textTransform: "capitalize" }}>
            {record.status}
          </span>
        ) : (
          <span className="green-tag">Completed</span>
        );
      },
    },
    {
      title: "ACTIONS",
      dataIndex: "member_limit",
      key: "member_limit",
      width: 200,
      elipsis: true,
      render: (text, record, key) => {
        return (
          <Dropdown
            placeholder={
              record.status === "final"
                ? "Review Locked"
                : record.status === "rejected"
                ? "Review Rejected"
                : "Actions"
            }
            className={
              "custom-dropdown " +
              (selectedSport === 1
                ? "orange-theme-dropdown"
                : "blue-theme-dropdown")
            }
            disabled={
              record.status === "final" ||
              record.status === "rejected" ||
              record.status === "review"
            }
            menu={{
              items,
              onClick: ({ key }) => {
                if (key === "details") {
                  setSelectedProfile(record)
                  setTaskDetailTable(false);
                  setFWTaskSubmissionDetail(true);
                  //   if (
                  //     accountDetail.id !== record.assigned_person?.id
                  //      && !accountDetail.is_superuser
                  //     //  accountDetail.role?.role_permission.filter(
                  //     //   (e) =>
                  //     //     e.module.name === "Submissions" &&
                  //     //     e.permission.code_name === "REVIEW"
                  //     // ).length === 0
                  //   ) {
                  //     info();
                  //   } else {
                  //     navigate({
                  //       pathname: `./${record.athlete?.username}`,
                  //       search: `id=${record.id}&tab=${activeTab}`,
                  //     });
                  //   }
                  // } else {
                  //   if(accountDetail.role !== null){
                  //   for (let item of accountDetail.role?.role_permission) {
                  //     if(item.module.name === "Submissions" && item.permission.code_name === "ASSIGNMENT"){
                  //       if(item.value === false){
                  //         info();
                  //       }
                  //       else if(accountDetail.role
                  //         && record.assigned_person.id === accountDetail.id){
                  //        info();
                  //      }
                  //      else{
                  //      setAssigneeModal(true);
                  //      setSelectedReview(record);
                  //      }
                  //     }
                  //   }
                  //   } else{
                  //     setAssigneeModal(true);
                  //     setSelectedReview(record);
                  //     }
                }
              },
            }}
            overlayClassName={
              "submission-dropdown-menu custom-dropdown-menu " +
              (selectedSport === 1
                ? "orange-theme-dropdown"
                : "blue-theme-dropdown")
            }
          >
            <a onClick={(e) => e.preventDefault()}>
              {record.status === "final" ? (
                "Review Locked"
              ) : record.status === "rejected" ? (
                "Rejected"
              ) : (
                <>
                  Actions <img src={DownArrow} />
                </>
              )}
            </a>
          </Dropdown>
        );
      },
    },
  ];
  const searchTask = (e) => {
    if (e.target.value.length >= 3) {
      setTaskDetail((taskDetail) => []);
      setTaskSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setTaskDetail((taskDetail) => []);
      setTaskSearch("");
    }
  };
  const onChangeRangePicker = (e) => {
    if (e) {
      setStartDate(e[0].format("YYYY-MM-DD"));
      setEndDate(e[1].format("YYYY-MM-DD"));
    } else {
      setStartDate("");
      setEndDate("");
    }
  };
  return (
    <div className="FW-tasks">
      <div className="FW-tasks-table">
        <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchTask}
          />
          <div className="right-div">
            <Select
              placeholder="Status"
              optionFilterProp="children"
              suffixIcon={<img src={DownArrow} />}
              className={
                "select-input " +
                (selectedSport === 1
                  ? "orange-theme-select"
                  : "blue-theme-select")
              }
              popupClassName={
                "custom-action-select-dropdown " +
                (selectedSport === 1
                  ? "orange-theme-select"
                  : "blue-theme-select")
              }
              onChange={(e) => setStatus(e)}
              options={[
                {
                  value: "all",
                  label: "All",
                },
                {
                  value: "complete",
                  label: "Completed",
                },
                {
                  value: "pending",
                  label: "Pending",
                },
              ]}
            />
            <Select
              placeholder="Newest"
              optionFilterProp="children"
              suffixIcon={<img src={DownArrow} />}
              className={
                "select-input " +
                (selectedSport === 1
                  ? "orange-theme-select"
                  : "blue-theme-select")
              }
              popupClassName={
                "custom-action-select-dropdown " +
                (selectedSport === 1
                  ? "orange-theme-select"
                  : "blue-theme-select")
              }
              onChange={(e) => setTaskSort(e)}
              options={[
                {
                  value: true,
                  label: "Newest",
                },
                {
                  value: false,
                  label: "Oldest",
                },
              ]}
            />
            <RangePicker
              className="range-picker"
              format={dateFormat}
              suffixIcon={<img src={Calendar} />}
              onChange={onChangeRangePicker}
            />
          </div>
        </div>
        <Table
          onChange={handleTableChange}
          rowKey={(obj) => obj.id}
          // rowSelection={rowSelection}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: false,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current: taskOffset + 1,
            pageSize: limit,
            total: taskDetail?.length,
          }}
          loading={loading}
          columns={columns}
          dataSource={taskDetail}
          className={
            "table-container " +
            (selectedSport === 1 ? "orange-theme" : "blue-theme")
          }
        />
      </div>
    </div>
  );
};

export default TaskDetail;
