import React, { useContext, useEffect, useState } from "react";
import { Modal, Input, Form, Divider, DatePicker, Select, Button } from "antd";
import "./subscription-modal.scss";
import Calendar from "../../../assets/images/calendar.svg";
import { CreateGroupContext } from "../../../context/group-context/create-group-context";
import { GroupDetailContext } from "../../../context/group-context/group-detail-context";
import dayjs from "dayjs";
import DownArrow from "../../../assets/images/arrows.svg";
import { UserContext } from "../../../context/user-context";
// import { SubscriptionContext } from "../../../context/subscription-context";
// import {moment} from 'moment-timezone'
var moment = require("moment-timezone");

let timeZone;
const formItemLayout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 14,
  },
};
const { TextArea } = Input;
let timeZoneStart;
let timeZoneExpire;
const SubscriptionModal = ({ newSubscription, setNewSubscription }) => {
  // const {newSubscription , setNewSubscription} = useContext(SubscriptionContext)
  const {darkButtonBackground , selectedSport , color , lightButtonBackground} = useContext(UserContext)
  const {
    setSubNotes,
    setSubExpire,
    setSubStart,
    setExpireTimeZone,
    setStartTimeZone,
  } = useContext(CreateGroupContext);
  const { addNewSubscription } = useContext(GroupDetailContext);
  const [form] = Form.useForm();
  const [value, setValue] = useState(1);
  // const dateFormat = "ddd. MMMM D, YYYY";
  const dateFormat = "ddd. MMM D, YYYY @ h:mm A";
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const handleOk = (values) => {
    // setNewSubscription(false);
    // setSubscriptionStatus("active");
  };
  const handleCancel = () => {
    form.resetFields();
    setNewSubscription(false);
  };
  const onSearch = (value) => console.log(value);
  const onChangeSelect = (value) => {
    console.log(`selected ${value}`);
  };
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const timeZoneOptions = [];
  moment.tz
    .names()
    .reduce((memo, tz) => {
      memo.push({
        name: tz,
        offset: moment.tz(tz).utcOffset(),
      });

      return memo;
    }, [])
    .sort((a, b) => {
      return a.offset - b.offset;
    })
    .reduce((memo, tz) => {
      let timezone = tz.offset ? moment.tz(tz.name).format("Z") : "";
      if (timezone.toString().length === 0) {
        timezone = "00:00";
      }
      return memo.concat(
        timeZoneOptions.push({
          value: timezone + " " + tz.name,
          label: timezone + " " + tz.name,
        })
      );
    }, "");

  const createSubscription = (values) => {
    let start_date = dayjs(values.start_date).format("YYYY-MM-DDTHH:mm:ss");
    // let start_date = new Date(Date.parse(values.start_date))
    //   .toISOString()
    //   .substring(0, 19);
    let expiry_date = dayjs(values.expiry_date).format("YYYY-MM-DDTHH:mm:ss");
    // let expiry_date = new Date(Date.parse(values.expiry_date))
    //   .toISOString()
    //   .substring(0, 19);
    if (!params.id) {
      setSubStart(start_date);
      setSubExpire(expiry_date);
      setSubNotes(values.notes);
      setNewSubscription(false);
    } else {
      let formData = {
        start_date: start_date + timeZoneStart,
        expiry_date: expiry_date + timeZoneExpire,
        notes: values.notes,
        group_id: params.id,
      };
      addNewSubscription(formData, setNewSubscription);
      form.resetFields();
    }
  };
  const changeStartZone = (e) => {
    if (!params.id) {
      setStartTimeZone(e);
    } else {
      let splitZone = e.split(" ")[0];
      timeZoneStart = splitZone;
    }
  };
  const changeExpireZone = (e) => {
    if (!params.id) {
      setExpireTimeZone(e);
    } else {
      let splitZone = e.split(" ")[0];
      timeZoneExpire = splitZone;
    }
  };
  return (
    <Modal
      className="subscription-modal"
      open={newSubscription}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Create Subscription"}
      cancelText={"Discard"}
      width={"999px"}
        footer={false}
      wrapClassName={"subscription-modal " + (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")}
    >
      <h1>Create Subscription</h1>
      <Form
        form={form}
        // name="dynamic_rule"
        className="subscription-form"
        onFinish={createSubscription}
      >
        <Form.Item
          {...formItemLayout}
          name="start_date"
          label="Subscription Start"
          className="name-row"
          rules={[
            {
              required: true,
              message: "Please select date.",
            },
          ]}
        >
          <DatePicker
            className={"date-picker " + (selectedSport === 1 ? "orange-picker" : "blue-picker")}
            rootClassName={selectedSport === 1 ? "orange-picker" : "blue-picker"}
            format={dateFormat}
            suffixIcon={<img src={Calendar} />}
            showTime
          />
          {/* <Select
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            placeholder="Time Zone"
            size={"large"}
            className="select-input"
            options={[
              {
                value: "beginner",
                label: "Level 1: Beginners (Age 1-5)",
              },
              {
                value: "intermediate",
                label: "Lucy",
              },
              {
                value: "advance",
                label: "Tom",
              },
            ]}
          /> */}
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="start_time_zone"
          label="Subscription Start"
          className="time-zone"
          rules={[
            {
              required: true,
              message: "Please select time zone.",
            },
          ]}
        >
          {/* <DatePicker
            className={"date-picker " + (selectedSport === 1 ? "orange-picker" : "blue-picker")}
            rootClassName={selectedSport === 1 ? "orange-picker" : "blue-picker"}
            format={dateFormat}
            suffixIcon={<img src={Calendar} />}
          /> */}
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
            onChange={changeStartZone}
            placeholder="Time Zone"
            size={"large"}
            className="select-input"
            options={timeZoneOptions}
            suffixIcon={<img src={DownArrow} />}
          />
          {/* <TimezoneSelect
            value={selectedTimezone}
            onChange={setSelectedTimezone}
            className="time-zone-select"
            // placeholder="Time Zone"
            // styles={BackgroundColor:"red"}
          /> */}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          name="expiry_date"
          label="Subscription Expiration "
          className="name-row"
          rules={[
            {
              required: true,
              message: "Please select date.",
            },
          ]}
        >
          <DatePicker
            className={"date-picker " + (selectedSport === 1 ? "orange-picker" : "blue-picker")}
            rootClassName={selectedSport === 1 ? "orange-picker" : "blue-picker"}
            format={dateFormat}
            suffixIcon={<img src={Calendar} />}
            showTime
          />
          {/* <Select
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            placeholder="Time Zone"
            size={"large"}
            className="select-input"
            options={[
              {
                value: "beginner",
                label: "Level 1: Beginners (Age 1-5)",
              },
              {
                value: "intermediate",
                label: "Lucy",
              },
              {
                value: "advance",
                label: "Tom",
              },
            ]}
          /> */}
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="expiry_time_zone"
          label="Subscription Expiration "
          className="time-zone"
          rules={[
            {
              required: true,
              message: "Please select time zone.",
            },
          ]}
          
        >
          {/* <DatePicker
            className={"date-picker " + (selectedSport === 1 ? "orange-picker" : "blue-picker")}
            rootClassName={selectedSport === 1 ? "orange-picker" : "blue-picker"}
            format={dateFormat}
            suffixIcon={<img src={Calendar} />}
          /> */}
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            onChange={changeExpireZone}
            placeholder="Time Zone"
            size={"large"}
            className="select-input"
            options={timeZoneOptions}
            popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
            suffixIcon={<img src={DownArrow} />}

          />
        </Form.Item>
        <Form.Item {...formItemLayout} name="notes" label="Subscription Notes">
          <TextArea
            placeholder="Add notes..."
            autoSize={{
              minRows: 5,
              maxRows: 8,
            }}
          />
        </Form.Item>
        <Divider />
        <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
          <Button type="primary" className="discard-btn" onClick={handleCancel} style={{color:color , background : lightButtonBackground}}          >
            Discard
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              margin: "0 8px",background : darkButtonBackground
            }}
            className="dark-base-btn"
          >
            {params.id ? "Create Subscription" : "Save"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SubscriptionModal;
