import React, { useContext, useEffect, useState } from "react";
import { Table, Tabs, Input, Button, Select, DatePicker, Skeleton } from "antd";
import Avatar from "react-avatar";
// import "../challenges-home/challenges-home.scss";
import "./leaderboard.scss"
import Plus from "../../../assets/images/plus.svg";
import Search from "../../../assets/images/search.svg";
import Profile from "../../../assets/images/avatar.svg";
import { useNavigate } from "react-router-dom";
import { getDate } from "../../../util/config";
import DownArrow from "../../../assets/images/arrows.svg";
import Calendar from "../../../assets/images/calendar.svg";
import RowVertical from "../../../assets/images/row-vertical.svg";
import Filter from "../../../assets/images/filter.svg";
import {
  GroupContext,
  GroupContextProvider,
} from "../../../context/group-context/group-context";
import { UserContext } from "../../../context/user-context";
import { FeatureWorkoutDetailContext } from "../../../context/challenge-workout-context/feature-workout-detail";
const LeaderBoard = ({tasks}) => {
  const dateFormat = "ddd. MMMM D, YYYY";
  const { search, setSort, getFeatureWrkoutLeaderoard, sort, leaderboards, setLeaderboards, setSearch , setSelectedTask , selectedTask } = useContext(FeatureWorkoutDetailContext)
  const { selectedSport } = useContext(UserContext)
  const [imageLoaded, setImageLoaded] = useState("");
  const { pageNumber, pageSize, groups, totalGroups, handleTableChange } =
    useContext(GroupContext);
  const navigate = useNavigate();
  const onChange = (value) => {
    setSort(value);
  };
  const items = [
    {
      key: "all",
      label: `All`,
      //   children: `Content of Tab Pane 1`,
      // children: <Profile />
    },
    {
      key: "archived",
      label: `Archived`,
      // children: `Content of Tab Pane 2`,
      //   children: <UserAccountTable />
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { RangePicker } = DatePicker;
  const onChangeSelect = (value) => {
  };
  useEffect(() => {
    getFeatureWrkoutLeaderoard();
  }, [sort, search,selectedTask])
  const scoredColumns = [
    {
      title: "member",
      dataIndex: "member",
      key: "member",
      width: "50%",
      render: (text, record, index) =>
        <div span={20} style={{ display: "flex" }}>
          {record.avatar !== null && imageLoaded === "" && (
            <Skeleton.Image active={true} className="user-skeleton" />
          )}
          {!record.avatar ? (
            <Avatar
              name={
                record.name
              }
              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
              className="user-avatar"
            />
          ) : (
            <img
              src={record.avatar}
              style={
                imageLoaded === ""
                  ? { display: "none" }
                  : { display: "block" }
              }
              onLoad={() => setImageLoaded("loaded")}
            ></img>
          )}          <div className="name-div">
            <h3 className="user-name">{record.name}</h3>
            <h4 className="user-handle">@{record.username}</h4>
          </div>
        </div>
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      width: "30%",
      render: (text, record, index) =>
        <div span={20} style={{ display: "flex" }} key={index}>
          {record?.score ? record.score : "N/A"}
        </div>
    },
    {
      title: "completion",
      dataIndex: "completion",
      key: "completion",
      width: "20%",
      render: (text, record, index) =>
        <div span={20} style={{ display: "flex" }} key={index}>
          {Math.round(record.reps_done / record.total_reps * 100)}
        </div>
    },
  ];
  const trainingColumns = [
    {
      title: "member",
      dataIndex: "member",
      key: "member",
      width: "50%",
      render: (text, record, index) =>
        <div span={20} style={{ display: "flex" }}>
          {record.avatar !== null && imageLoaded === "" && (
            <Skeleton.Image active={true} className="user-skeleton" />
          )}
          {!record.avatar ? (
            <Avatar
              name={
                record.name
              }
              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
              className="user-avatar"
            />
          ) : (
            <img
              src={record.avatar}
              style={
                imageLoaded === ""
                  ? { display: "none" }
                  : { display: "block" }
              }
              onLoad={() => setImageLoaded("loaded")}
            ></img>
          )}          <div className="name-div">
            <h3 className="user-name">{record.name}</h3>
            <h4 className="user-handle">@{record.username}</h4>
          </div>
        </div>
    },
    {
      title: "reps",
      dataIndex: "reps",
      key: "reps",
      width: "30%",
      render: (text, record, index) =>
        <div span={20} style={{ display: "flex" }} key={index}>
          {record.reps_done + "/" + record.total_reps}
        </div>
    },
    {
      title: "completion",
      dataIndex: "completion",
      key: "completion",
      width: "20%",
      render: (text, record, index) =>
        <div span={20} style={{ display: "flex" }} key={index}>
          {Math.round(record.reps_done / record.total_reps * 100)}
        </div>
    },
  ];
  const data = [];
  for (let i = 0; i < 5; i++) {
    data.push({
      reps: "21/22",
      completion: "96%"
    });
  }
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const gotoChallengeDetail = (record) => {
    navigate({
      pathname: `./${record.name}`,
      search: `?id=${record.id}`,
    });
  };
  const searchLeaderboard = (e) => {
    if (e.target.value.length >= 3) {
      setLeaderboards([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setLeaderboards((leaderboards) => []);
      setSearch("");
    }
  };
  let tasksOptions = [{value:"all" , label:"All Tasks"}];
  for (let i = 0; i < tasks?.length; i++) {
    tasksOptions.push({
      value: tasks[i].id,
      label: tasks[i].title,
    });
  }
  const [scoredTable , setScoredTable] = useState(false)
  const changeTask = (e) => {

    setSelectedTask(e);
    if(e === "all"){
      setScoredTable(false)
    }else{
    for(let i =0 ; i < tasks.length ; i++){
      if(tasks[i].id === e){
        console.log(tasks[i].score_based_test , e)
      if(tasks[i].score_based_test === true){
        setScoredTable(true)
      }else{
        setScoredTable(false)
      }
    }
    }
    }
  }
  return (
    <div className="leaderboard-main">
      <div style={{display:"flex"}}> 
      <h1>Leaderboard</h1>
      <div style={{marginLeft:"auto"}}>
          <label style={{fontWeight:500 , marginRight:20,fontSize:16}}>Tasks:</label>
        <Select
          placeholder="Select Task"
          optionFilterProp="children"
          onChange={(e)=>changeTask(e)}
          suffixIcon={<img src={DownArrow} />}
          className="select-input tasks-dropdown"
          popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
          options={tasksOptions}
          defaultValue={"all"}
        />
        </div>
        </div>
      <div className="table-div">
        <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchLeaderboard}
          />
          <div className="right-div">
            <Select
              placeholder="Most Reps. Completed"
              suffixIcon={<img src={DownArrow} />}
              onChange={onChange}
              className="select-input"
              popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
              options={[
                {
                  value: "desc",
                  label: "Most Reps. Completed",
                },
                {
                  value: "asc",
                  label: "Least Reps. Completed",
                },
              ]}
            />
          </div>
        </div>
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: () => gotoChallengeDetail(record),
            };
          }}
          onChange={handleTableChange}
          rowKey={(obj) => obj.id}
          // rowSelection={rowSelection}
          //   pagination={{
          //     pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
          //     showSizeChanger: true,
          //     locale: { items_per_page: "" },
          //     defaultPageSize: 10,
          //     current: pageNumber,
          //     pageSize: pageSize,
          //     total: totalGroups,
          //   }}
          columns={scoredTable === true ? scoredColumns : trainingColumns}
          dataSource={leaderboards}
          className={"table-container " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
        />
      </div>
    </div>
  );
};
export default LeaderBoard;
