import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  Tabs,
  Input,
  Button,
  Tooltip,
  Select,
  DatePicker,
  Form,
  Dropdown,
  message,
} from "antd";
import DownArrow from "../../assets/images/arrows.svg";
import "./index.scss";
import Plus from "../../assets/images/plus.svg";
import Search from "../../assets/images/search.svg";
import Calendar from "../../assets/images/calendar.svg";
import { useNavigate } from "react-router-dom";
import { getDate, getDateWithTime } from "../../util/config";
import SponsorModal from "./sponsor-modal/sponsor-modal";
// import { HomeRotatorContext, HomeRotatorContextProvider } from "../../context/home-rotator-context";
import {
  HomeRotatorContext,
  HomeRotatorContextProvider,
} from "../../context/home-rotator-context/home-rotator-context";
import { UserContext } from "../../context/user-context";
const { RangePicker } = DatePicker;
const HomeRotatorPage = () => {
  const [form] = Form.useForm();

  const {
    sponsorModal,
    setSponsorModal,
    setSearch,
    loading,
    pageSize,
    pageNumber,
    setPageNumber,
    total,
    allRotators,
    sportsList,
    uploadVideo,
    sport,
    setStatus,
    setSport,
    sort,
    setSort,
    setStartDate,
    setEndDate,
    deleteRotator,
    setAllRotators,
    setPageSize,
  } = useContext(HomeRotatorContext);
  const navigate = useNavigate();
  const dateFormat = "ddd. MMMM D, YYYY";
  const { accountDetail , selectedSport } = useContext(UserContext);
  const onChange = (key, record) => {
    if (key === "sponsor") {
      setSponsorModal(true);
    } else if (key === "delete") {
      deleteRotator(record.id);
    } else if (key === "edit") {
      console.log(record);

      navigate({
        pathname: `./edit-rotator`,
        // pathname: `./${record.name}`,
        search: `?id=${record.id}`,
      });

      // navigate("./edit-rotator")
    } else {
      navigate({
        pathname: `./detail`,
        search: `?id=${record.id}`,
      });
    }
  };
  const items = [
    {
      key: "detail",
      label: "View Detail",
    },
    // {
    //   key: "sponsor",
    //   label: "Sponsor Report",
    // },
    {
      key: "edit",
      label: "Edit",
    },
    {
      key: "delete",
      label: "Delete",
    },
  ];
  //   useEffect(() => {
  //   let sport = form.getFieldValue("sport");
  //   for (let i = 0; i < sportsList.length; i++) {
  //     if (sportsList[i].name === sport) {
  //       setSport(sportsList[i].id);
  //     }
  //   }
  // }, [sportsList]);
  let sportOption = [];
  for (let i = 0; i < sportsList.length; i++) {
    sportOption.push({
      value: sportsList[i].id,
      label: sportsList[i].name,
    });
  }
  const info = () => {
    message.info("You don't have permissions. Contact to Admin");
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const columns = [
    {
      title: "Sport",
      dataIndex: "sport",
      key: "sport",
      // "id"
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        return <div key={index}>{record.sport && record.sport.name}</div>;
      },
    },
    // {
    //   title: "rotator",
    //   dataIndex: "rotator",
    //   key: "rotator",
    //   ellipsis: {
    //     showTitle: false,
    //   },
    //   render: (record, index) => {
    //     return (
    //       <Tooltip placement="bottom" title={record}>
    //         {record}
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      title: "time limit",
      dataIndex: "time_limit",
      key: "time_limit",
      width: "30%",
      render: (text, record, index) => {
        return (
          <div key={index}>
            <div style={{ marginBottom: 8 }}>
              From{" "}
              <span style={{ color: "#3f4254" }}>
                {" "}
                {getDateWithTime(record.start_date)}
                {/* Mon. Jan 1, 2023 6:00 AM*/}
              </span>{" "}
            </div>
            <div>
              {" "}
              To{" "}
              <span style={{ color: "#3f4254" }}>
                {" "}
                {getDateWithTime(record.end_date)}{" "}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      render: (text, record, index) => {
        console.log(record.status);
        return (
          <div key={index}>
            <span
              // className="green-tag"
              style={{ textTransform: "capitalize" }}
              className={
                record.status === "scheduled"
                  ? "yellow-tag"
                  : record.status === "expired"
                  ? "pink-tag"
                  : "green-tag"
              }
            >
              {record.status}
            </span>
          </div>
        );
      },
    },
    {
      title: "Presented by",
      dataIndex: "presented_by",
      key: "presented_by",
      render: (text, record, index) => {
        return (
          <div>
            {/* San Antonio FC */}
            {record.sponsor ? record.sponsor.name : "N/A"}
          </div>
        );
      },
    },
    {
      title: "ACTIONS",
      dataIndex: "member_limit",
      key: "member_limit",
      render: (text, record, key) => (
        <Dropdown
          placeholder="Actions"
          className={"custom-dropdown " + (selectedSport === 1 ? "orange-theme-dropdown" : "blue-theme-dropdown")}
          menu={{
            items,

            onClick: ({ key }) => {
              if (key === "sponsor") {
                setSponsorModal(true);
              } else if (key === "delete") {
                if (accountDetail.role !== null) {
                  for (let item of accountDetail.role?.role_permission) {
                    if (
                      item.module.name === "Rotators" &&
                      item.permission.code_name === "DELETE"
                    ) {
                      if (item.value === false) {
                        info();
                      } else {
                        deleteRotator(record.id);
                      }
                    }
                  }
                } else {
                  deleteRotator(record.id);
                }
              } else if (key === "edit") {
                if (accountDetail.role !== null) {
                  for (let item of accountDetail.role?.role_permission) {
                    if (
                      item.module.name === "Rotators" &&
                      item.permission.code_name === "EDIT"
                    ) {
                      if (item.value === false) {
                        info();
                      } else {
                        navigate({
                          pathname: `./edit-rotator`,
                          // pathname: `./${record.name}`,
                          search: `?id=${record.id}`,
                        });
                      }
                    }
                  }
                } else {
                  navigate({
                    pathname: `./edit-rotator`,
                    // pathname: `./${record.name}`,
                    search: `?id=${record.id}`,
                  });
                }
                // navigate("./edit-rotator")
              } else {
                navigate({
                  pathname: `./detail`,
                  search: `?id=${record.id}`,
                });
              }
            },
          }}
          overlayClassName={"custom-dropdown-menu " + (selectedSport === 1 ? "orange-theme-dropdown" : "blue-theme-dropdown")}
        >
          <a onClick={(e) => e.preventDefault()}>
            Actions
            <img src={DownArrow} />
          </a>
        </Dropdown>
      ),
    },
  ];

  // const data = [];
  // for (let i = 0; i < 46; i++) {
  //   data.push({
  //     key: i,
  //     id:'232123',
  //     username: '@kenthompson',
  //     fullname: 'Kenny Thompson',
  //     dob: 'Aug 9, 2000',
  //     gender:'Male',
  //     sport:'Basketball',
  //     rotator:'3',

  //   });
  // }
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const gotoRotatorDetail = (record) => {
    navigate({
      pathname: `./detail`,
      search: `?id=${record.id}`,
    });
  };
  const searchRotator = (e) => {
    if (e.target.value.length >= 3) {
      setPageNumber(1);
      setAllRotators([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setAllRotators((groups) => []);
      setSearch("");
    }
  };
  const onChangeSort = (value) => {
    if (value === "newest") {
      setSort(true);
    } else {
      setSort(false);
    }
  };
  const onChangeSport = (value) => {
    console.log(value);
    setSport(value);
  };
  const onChangeStatus = (value) => {
    console.log(value);
    setStatus(value);
  };
  const onChangeRangePicker = (e) => {
    if (e) {
      setStartDate(e[0].format("YYYY-MM-DD"));
      setEndDate(e[1].format("YYYY-MM-DD"));
    } else {
      setStartDate("");
      setEndDate("");
    }
  };
  const handleTableChange = (pagination, filters, sorter) => {
    if (pagination.current !== pageNumber) {
      setPageNumber(pagination.current);
    }
    if (pagination.pageSize !== pageSize) {
      setPageSize(pagination.pageSize);
    }
  };
  return (
    <div className="group-home-main">
      {/* <div style={{ position: "absolute", right: 0, top: "8px" }}>
        <Button
          icon={<img src={Plus} />}
          className="dark-base-btn"
          style={{ marginLeft: "auto" }}
          onClick={() => navigate(`./create-new-group`)}
        >
          Create Group
        </Button>
      </div> */}
      {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} className={selectedSport === 1 ? "orange-theme" : "blue-theme"}/> */}
      <div className="table-div">
        <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchRotator}
          />
          <div className="right-div">
            <Select
              placeholder="Newest"
              optionFilterProp="children"
              onChange={onChangeSort}
              className={"custom-action-select " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
              popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
              suffixIcon={<img src={DownArrow} />}
              style={{ height: 40, width: 135 }}
              options={[
                {
                  value: "newest",
                  label: "Newest",
                },
                {
                  value: "oldest",
                  label: "Oldest",
                },
              ]}
            />
            {/* <Select
              placeholder="Sport"
              optionFilterProp="children"
              onChange={onChangeSport}
              className={"custom-action-select " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
              popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
              suffixIcon={<img src={DownArrow} />}
              style={{ height: 40, width: 135 }}
              options={sportOption}
            /> */}
            <Select
              placeholder="Status"
              optionFilterProp="children"
              onChange={onChangeStatus}
              className={"custom-action-select " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
              popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
              suffixIcon={<img src={DownArrow} />}
              style={{ height: 40, width: 135 }}
              options={[
                {
                  value: "active",
                  label: "Active",
                },
                {
                  value: "expired",
                  label: "Expired",
                },
                {
                  value: "scheduled",
                  label: "Scheduled",
                },
              ]}
            />
            <RangePicker
              className="range-picker"
              format={dateFormat}
              suffixIcon={<img src={Calendar} />}
              onChange={onChangeRangePicker}
            />
          </div>
        </div>
        <Table
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: () => gotoGroupDetail(record),
          //   };
          // }}
          loading={loading}
          onChange={handleTableChange}
          rowKey={(obj) => obj.id}
          // rowSelection={rowSelection}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current: pageNumber,
            pageSize: pageSize,
            total: total,
          }}
          columns={columns}
          dataSource={allRotators}
          className={"table-container " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
        />
      </div>
      <SponsorModal
        sponsorModal={sponsorModal}
        setSponsorModal={setSponsorModal}
      />
    </div>
  );
};

const HomeRotator = () => {
  return (
    <HomeRotatorContextProvider>
      <HomeRotatorPage />
    </HomeRotatorContextProvider>
  );
};
export default HomeRotator;
