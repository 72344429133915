import React, { useEffect, useState } from "react";
import { message, Form, Button, Modal, Select, Dropdown, Menu } from "antd";
import { getData, formPostData  , jsonPostData, deleteItem, putData} from "../../util/api-calls";
const { confirm } = Modal;
export const RolesPermissionsContext = React.createContext({});
export const RolesPermissionsContextProvider = ({ children = {} }) => {
  const [form] = Form.useForm();
  const [key, setKey] = useState(0);
  const [imgSrc , setImgSrc] = useState()
  const [gender , setGender] = useState()
  const [imageUrl , setImageUrl] = useState()
  const [loading , setLoading] = useState(false)
  const [addRoleModal,setAddRoleModal] = useState(false)
  const [permissionList , setPermissionList] = useState([])
  const [permissions , setPermissions] = useState([])
  const [updatedPermissions , setUpdatedPermissions] = useState([])
  const [roles , setRoles] = useState([])
  const [search , setSearch] = useState("")
  const [sort , setSort] = useState("created_at")
  const [editRoleModal , setEditRoleModal] = useState(false)
  const [selectedRole , setSelectedRole] = useState({})

  const createNewRole = (e) => {
   let data =  {
      "role_name": e.role_name,
      "description": e.description,
      "role_permission": permissions
  }
    jsonPostData(`admin/role`,data).then((res)=>{
      message.success({
        content:"Role added successfully."
      })
      form.resetFields();
      setPermissions(permissions=>[])
      setAddRoleModal(false);
      getRolesList()
      setKey((k) => k + 1)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const updateRole = (e) => {
    let data = {
      "role_name": e.role_name,
      "description": e.description,
      "role_permission": updatedPermissions
    }
    putData(`admin/role/${selectedRole.id}` , data).then((res)=>{
      message.success({
        content:"Role updated successfully."
      })
      form.resetFields();
      setUpdatedPermissions(updatedPermissions=>[])
      setEditRoleModal(false);
      getRolesList()
      setKey((k) => k + 1)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const deleteRole = (id) => {
    deleteItem(`admin/role/${id}`).then((res)=>{
      message.success({
        content:"Role deleted successfully"
      })
      getRolesList();
    }).catch((err)=>{
      console.log(err)
    })
  }
  const getPermissions = () => {
    getData(`admin/permissions`).then((res)=>{
      setPermissionList(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const getRolesList = () => {
    setLoading(true)
    getData(`admin/role?&search=${search}&sort=${sort}`).then((res)=>{
      setRoles(res.data.results)
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{
      setLoading(false)
    })
  }
  React.useEffect(()=>{
    getPermissions();
  } , []) 
useEffect(()=>{
  getRolesList();
} ,[search , sort ] )
  const defaultContext = {
    gender,
    setImageUrl,
    setGender,
    imgSrc,
    setImgSrc,
    loading,
    addRoleModal,
    setAddRoleModal,
    permissionList,
    permissions,
    setPermissions,
    roles,
    createNewRole,
    form,
    key,
    setRoles,
    setSearch,
    deleteRole,
    setKey,
    editRoleModal,
    setEditRoleModal,
    selectedRole,
    setSelectedRole,
    updateRole,
    updatedPermissions,
    setUpdatedPermissions,
    sort,
    setSort
  };
  return (
    <RolesPermissionsContext.Provider value={defaultContext}>
      {children}
    </RolesPermissionsContext.Provider>
  );
};
