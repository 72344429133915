import React, { useContext, useEffect, useState } from "react";
import {
  Input,
  Row,
  Col,
  Modal,
  Skeleton,
  Checkbox,
  Form,
  Select,
  Upload,
  Radio,
  Button,
  message,
} from "antd";
import Search from "../../../assets/images/search.svg";
import DownArrow from "../../../assets/images/arrows.svg";
import { MemberContext } from "../../../context/group-context/members-context";
import Edit from "../../../assets/images/edit.svg";
import Close from "../../../assets/images/close.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { SponsorsContext } from "../../../context/setting-context/sponsors_context";
import { UserContext } from "../../../context/user-context";
const { TextArea } = Input;

const EditSponsor = ({ editSponsorModal, setEditSponsorModal }) => {
  const {darkButtonBackground , selectedSport , color , lightButtonBackground} = useContext(UserContext)
  const {selectedSponsor , imgSrc , setImgSrc , setImageUrl , updateSponsor} = useContext(SponsorsContext)
  const [form] = Form.useForm();
  const handleCancel = () => {
    setEditSponsorModal(false);
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleBeforeUpload = (file) => {
    const isPNG = file.type === 'image/png';
    const isJPEG = file.type === 'image/jpeg'
    const isJPG = file.type === 'image/jpg'
    if (!isPNG && !isJPEG && !isJPG) {
      message.error(`Only PNG, JPG, or JPEG files are allowed.`);
    }
    else if(file.size > 10000000){
      message.error(`Maximum file size should be 10MB or less.`);
    }
    else{
    setImageUrl(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImgSrc(reader.result);
    };
    console.log(file);
    reader.readAsDataURL(file);
    // Prevent upload
    return false;
  }
  };
  const removeImage = () => {
    form.setFieldValue("avatar" , undefined)
    setImageUrl();
    setImgSrc();
  };
  useEffect(() => {
    form.setFieldsValue({
      name : selectedSponsor.name !== "undefined"
      ? selectedSponsor.name
      : "",
      website:
        selectedSponsor.website !== "undefined"
          ? selectedSponsor.website
          : "",
      avatar:selectedSponsor.icon
    });
    setImgSrc(selectedSponsor.icon);
    if(!editSponsorModal){
      form.resetFields();
    }
  }, [selectedSponsor , editSponsorModal]);

  return (
    <Modal
      wrapClassName={"add-sponsor-modal " + (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")}
      className="add-sponsor-modal"
      open={editSponsorModal}
      onOk={updateSponsor}
      onCancel={handleCancel}
      okText={"Create"}
      cancelText={"Cancel"}
      width={"624px"}
      footer={false}
    >
      <div className="add-sponsor-main">
        <h1>Edit Sponsor</h1>
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          className="add-sponsor-form"
          onFinish={(e) => updateSponsor(e, selectedSponsor)}

        >
          <Form.Item name="avatar" label="Profile Photo" className="img-div">
            <div className="image-previewer">
              {!imgSrc ? (
                <Upload
                  className="uploader"
                  showUploadList={false}
                  type="file"
                  customRequest={dummyRequest}
                  beforeUpload={handleBeforeUpload}
                >
                  Upload pic
                </Upload>
              ) : (
                <>
                  <img
                    src={imgSrc}
                    alt="avatar"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    className="img"
                  />
                  <Upload
                    className="avatar-uploader"
                    showUploadList={false}
                    type="file"
                    customRequest={dummyRequest}
                    beforeUpload={handleBeforeUpload}
                  >
                    <img src={Edit} alt="edit" className="edit"></img>
                  </Upload>
                  <img
                    src={Close}
                    alt="close"
                    className="delete"
                    onClick={removeImage}
                  />
                </>
              )}
            </div>
          </Form.Item>
          <span className="img-cond" style={{marginBottom:0}}>Allowed file types: PNG, JPG, JPEG.</span>
          <span className="img-type">Max Size:10MB</span>
          <Form.Item
            name="name"
            label="Presented by"
            rules={[
              {
                required: true,
                message:"Please enter sponsor name"
              },
            ]}
          >
            <Input placeholder="Please enter presenter"/>
          </Form.Item>
          <Form.Item
            name="website"
            label="Sponsor URL"
            rules={[
              {
                required: false,
                message:"Please enter sponsor url"
              },
              {
                type: 'url',
                message: "Please enter valid url.",
              },
            ]}
          >
            <Input placeholder="Please enter sponsor url"/>
          </Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            <Button type="default" onClick={() => handleCancel()} style={{color:color , background : lightButtonBackground}}>
              Cancel
            </Button>
            <Button
              className="dark-base-btn"
              type="primary"
              style={{ marginInlineStart: 8 , background : darkButtonBackground }}
              htmlType="submit"
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditSponsor;
