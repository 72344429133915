import React, { useState, useRef, useContext, useEffect } from "react";
import { Row, Col, Progress, Checkbox, Radio, Select, Form, Input } from "antd";
import DownArrow from "../../../assets/images/arrows.svg";
import "./sport-settings.scss";
import { UserContext } from "../../../context/user-context";
import { getData, patchData } from "../../../util/api-calls";
const formItemLayout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
};
const SportSettings = ({ profileDetail }) => {
  const { selectedSport } = useContext(UserContext)
  const [sportDetail, setSportDetail] = useState()
  const [shootingHand, setShootingHand] = useState("")
  const [level , setLevel] = useState("")
  const [form] = Form.useForm();
  const style = {
    padding: "5px 0",
  };
  const selectedSportDetail = () => {
    getData(`sport/${selectedSport}`).then((res) => {
      setSportDetail(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }
  useEffect(() => {
    selectedSportDetail()
  }, [])
  useEffect(()=>{
    setLevel(profileDetail?.selected_sport?.skill_level)
    setShootingHand(profileDetail?.selectedSport?.shooting_hand)
  } , [profileDetail])
  const onChangeCgList = (e) => {
    //   if(cgListGroup.includes(e.target.value)) {
    //     setCgListGroup(cgListGroup.filter((item) => item !== e.target.value));
    //   }else{
    //  setCgListGroup([...cgListGroup, e.target.value]);
    //   }
  };
  const onChangeSkillLevel = (e) => {
    setLevel(e);
    updateSportProfile();
    // profileDetail.selected_sport.skill_level
  };
  const updateSportProfile = () => {
    let formData = new FormData();
    formData.append("sport", [{"id" : selectedSport , "positions" : [] , "shooting_hand" : shootingHand }]);
    // sports:[{"id":1,"positions":[1,2],"shooting_hand":"right"}
    patchData(`test/${profileDetail.id}`).then((res)=>{
    }).catch((err)=>{
      console.log(err)
    })
  }
  // useEffect(()=>{
  //   updateSportProfile();
  // },[level])
  return (
    <div className="sport-settings-main">
      <h1>Sports Details</h1>
      <hr />
      {profileDetail?.sports?.map((item , index)=>{
        return(
          <Form
          key={index}
          form={form}
          name="dynamic_rule"
          style={{
            padding: "22px 30px ",
          }}
          className="sport-form"
        >
                    <Form.Item
            {...formItemLayout}
            className="skills-div"
            label="Sport"
            rules={[
              {
                required: true,
                message: "Please input your name",
              },
            ]}
          >
            <Input value={item.sport.name} disabled style={{height:48}}/ >
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            className="skills-div"
            label="Skill Level"
            rules={[
              {
                required: true,
                message: "Please input your name",
              },
            ]}
          >
            <Select
              optionFilterProp="children"
              onChange={onChangeSkillLevel}
              suffixIcon={<img src={DownArrow} />}
              // defaultValue={"beginner"}
              defaultValue={item?.skill_level}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder="Level 2: Intermediate (age 11-15)"
              popupClassName={"sport-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
              size={"large"}
              disabled
              style={{ height: 48 }}
              className="skill-seclect"
              options={[
                {
                  value: "beginner",
                  label: "Level 1: Beginners (age < 10)",
                },
                {
                  value: "intermediate",
                  label: "Level 2: Intermediate (age 11-15)",
                },
                {
                  value: "advanced",
                  label: "Level 3: Advance (age 16+)",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="shooting-hand"
            label="Shooting Hand"
            className="shooting-div"
          >
            <Radio.Group
              value={shootingHand}
              defaultValue={item?.shooting_hand}
              className="custom-radio"
              disabled
            >
              <Radio value={"right"}>Right</Radio>
              <Radio value={"left"}>Left</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="positions"
            label="Positions"
            className="sport-div position-div"
          >
            {item.sport?.positions?.map((item, i) => {
              return (
                <div key={i} style={{ marginBottom: 5 }}>
                  <Checkbox defaultChecked={item.status} onChange={onChangeCgList} value={item.id} className="custom-checkbox" key={i} disabled>
                    {item.position}
                  </Checkbox>
                </div>
              );
            })}
          </Form.Item>
        </Form>      
        )
        }
        )}

    </div>
  );
};
export default SportSettings;
