import dayjs from "dayjs";

export const userBaseURL = process.env.REACT_APP_USER_BASE_URL;
export const trainingBaseURL = process.env.REACT_APP_TRAINING_BASE_URL;
export const testBaseURL = process.env.REACT_APP_TEST_BASE_URL;

export const getDateWithoutDay = (epoch) => {
    const timestamp = typeof epoch === 'string' ? new Date(epoch).getTime() : epoch * 1000
    return dayjs(timestamp).format('MMM D, YYYY');
}
export const getDate = (epoch) => {
    const timestamp = typeof epoch === 'string' ? new Date(epoch).getTime() : epoch * 1000
    return dayjs(timestamp).format('ddd. MMMM D, YYYY');
}
export const getDateWithTime = (epoch) => {
    const timestamp = typeof epoch === 'string' ? new Date(epoch).getTime() : epoch * 1000
    return dayjs(timestamp).format('ddd. MMM D, YYYY @ h:mm A');
}

export const getDateWithTimeWithoutDay = (epoch) => {
  const timestamp = typeof epoch === 'string' ? new Date(epoch).getTime() : epoch * 1000
  return dayjs(timestamp).format('MMM D, YYYY @ h:mm A');
}

export const getDateWithoutMonth = (epoch) => {
    const timestamp = typeof epoch === 'string' ? new Date(epoch).getTime() : epoch * 1000
    return dayjs(timestamp).format('ddd D, YYYY h:mm A');
}
export const getTime = (epoch) => {
    const timestamp = typeof epoch === 'string' ? new Date(epoch).getTime() : epoch * 1000
    return dayjs(timestamp).format('h:mm A');
}
export const graphDate = (epoch) => {
    const timestamp = typeof epoch === 'string' ? new Date(epoch).getTime() : epoch * 1000
    return dayjs(timestamp).format('DD/MM/YY');
}

export const secondsToTime = (e) => {
    const h = Math.floor(e / 3600).toString().padStart(2, '0'),
        m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
        s = Math.floor(e % 60).toString().padStart(2, '0');

    return h + ':' + m + ':' + s;
}
export const convertSeconds = (sec) => {
    const minutes = Math.floor((sec % 3600) / 60);
    const seconds = sec % 60;
    if (minutes > 0 && seconds > 0) {
        return `${minutes} min  ${seconds} sec`;
    } else if (minutes > 0 && seconds <= 0) {
        return `${minutes} min`;
    } else if (seconds > 0) {
        return `${seconds} sec`;
    }
};