import React, { useContext, useState } from "react";
import { Modal, Input, Radio, Form, Upload, message, Select } from "antd";
import UploadFile from "../../../assets/images/upload.svg";
import "./delete-task-modal.scss";
import { deleteItemTraining } from "../../../util/api-calls";
import { TaskContext } from "../../../context/task-context/task-context";
import { UserContext } from "../../../context/user-context";
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const DeleteTaskModal = ({
  deleteTaskModal,
  setDeleteTaskModal,
  selectedTask,
  setSelectedTask,
}) => {
  const {selectedSport} = useContext(UserContext)
  const { getTasksList } = useContext(TaskContext);
  const [form] = Form.useForm();
  const [value, setValue] = useState(1);

  const handleOk = () => {
    setDeleteTaskModal(false);
  };

  const deleteTask = () => {
    deleteItemTraining(`library/task/${selectedTask.id}`)
      .then((res) => {
        setDeleteTaskModal(false);
        setSelectedTask({});
        getTasksList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    setDeleteTaskModal(false);
  };
  const onSearch = (value) => console.log(value);
  const onChangeSelect = (value) => {
    console.log(`selected ${value}`);
  };
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  return (
    <Modal
      className="delete-task-modal"
      wrapClassName={selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"}
      open={deleteTaskModal}
      onCancel={handleOk}
      onOk={deleteTask}
      cancelText={"No, Keep This Task"}
      okText={"Yes, Delete This Task"}
      width={"608px"}
      //   footer={false}
    >
      <h1>Delete Task</h1>
      <p>Are you sure you want to delete this task? </p>
      {/* <p>  This task is in progress in 3 groups. By deleting, they will no longer be available to the members of those groups. */}
      {/* </p> */}
    </Modal>
  );
};

export default DeleteTaskModal;
