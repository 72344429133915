import { Button, Divider, Modal, Input } from "antd";
import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import Profile from "../../../assets/images/avatar.svg";
import ReactPlayer from "react-player";
import { WorkoutDetailContext } from "../../../context/workouts-context/workout-detail-context";
import { getDateWithTimeWithoutDay } from "../../../util/config";
import Avatar from "react-avatar";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { TwilioService } from "../../../context/twilio-service";
import dayjs from "dayjs";
import { UserContext } from "../../../context/user-context";
import { Popconfirm, Spin } from "antd/es";
import { FWSubmissionDetailContext } from "../../../context/submission-context/fw-submission-detail-context";
const { TextArea } = Input;
let reviewed = false;
const SubmissionDetailModal = ({ submissionData }) => {
  const { accountDetail , darkButtonBackground  , color , lightButtonBackground , selectedSport} = useContext(UserContext)
  const { submissionDetailModal, setSubmissionDetailModal, submissionDetail, setSubmissionDetail,
     invalidateSubmission, score, setScore, gradeInput, setGradeInput,   setSelectedSubmission, videoPlayed , deleteVideo , submissionLoading} = useContext(FWSubmissionDetailContext)
  const [imageLoaded, setImageLoaded] = useState("");


  useEffect(() => {
    if (submissionDetail) {
      setScore(submissionDetail.score)
      if(submissionDetail.media_required === false){
      if(submissionDetail.is_reviewed === true || submissionDetail.self_reviewed === true){
        reviewed = true
      }else{
        reviewed = false
      }
    }
    }
  }, [submissionDetail])
  const cancelModal = () => {
    setSubmissionDetailModal(false);
     setSubmissionDetail({});
      setSelectedSubmission("")
    if(submissionDetail.media_required === false && submissionDetail.is_reviewed !==  true){
      videoPlayed(true)
    }
  }
  return (
    <Modal
      className="submission-detail-modal"
      rootClassName={"submission-detail-modal " + (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")}
      open={submissionDetailModal}
      // onOk={handleOk}
      onCancel={() => cancelModal()}
      okText={"Add & Next"}
      cancelText={"Cancel"}
      width={"1000px"}
      destroyOnClose={true}
      footer={false}
    >
      {Object.keys(submissionDetail).length > 0 ?
        <div>
          {submissionDetail?.reported === true &&
              <div className={"info-div " + (selectedSport === 1 ? "orange-info" : "blue-info")}>
          <ExclamationCircleTwoTone
          twoToneColor={color}
          style={{ fontSize: 20 }}
          />
          <p className="status">
          This video was reported as invalid by the user_123
          </p>
          <Button           className="dark-base-btn"
          style={{background : darkButtonBackground}} onClick={()=>deleteVideo()}>Delete Video</Button>
        </div>}
          <div style={{ display: "flex" }}>
            {submissionDetail?.profile?.avatar === null ? (
              <Avatar
                name={submissionDetail?.profile?.name}
                color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                maxInitials={2}
                className="profile-avatar"
              />
            ) : (
              <img
                src={submissionDetail?.profile?.avatar}
                className="profile-logo"
                style={
                  imageLoaded === ""
                    ? { display: "none" }
                    : { display: "block" }
                }
                onLoad={() => setImageLoaded("loaded")}
              />
            )}
            <div className="name-div">
              <h3 className="user-name">
                {submissionDetail.profile?.name}
              </h3>
              <h4 className="user-handle">@{submissionDetail.profile?.username}
              </h4>
            </div>
            <div style={{ marginLeft: "auto" }} className="submitted-date">
              <h3 className="user-name">
                Submitted
              </h3>
              <h4 className="user-handle">
                {getDateWithTimeWithoutDay(submissionDetail?.created_at)}
              </h4>
            </div>
          </div>
          {submissionDetail?.media_required === true &&
            <ReactPlayer
              url={submissionDetail?.task_media}
              controls
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "cover", textAlign: "center" }}
              className="submission-video"
              // light={submissionDetail.media_thumbnail}
              onPlay={() => submissionDetail.is_scored_task === false ? videoPlayed(true) : false}
            />
          }

          <div className="submitted-task-detail" style={{ marginTop: 30 }}>
            <div className="task-header">
              {!submissionDetail.is_scored_task ? <h2>Training Task</h2> :
                <h2>Scored Task</h2>}
              {submissionDetail.is_scored_task === true && gradeInput === true && submissionDetail.is_validated !== false ?
                <div className="grade-form" style={{ margin: "6px 0" }}>
                  <Input className="grade-input" placeholder="Enter Score" value={score} onChange={(e) => setScore(e.target.value)} />
                  <Button className="dark-base-btn" style={{background:darkButtonBackground}} onClick={() => invalidateSubmission(true, submissionDetail?.profile)}>Submit</Button>
                  <Button className="gray-base-btn" onClick={() => { setGradeInput(false); setScore() }}>Cancel</Button>
                </div>
                :
                submissionDetail.score === 0 && reviewed === false && submissionDetail.is_scored_task && submissionDetail.is_validated !== false &&
                <Button className="dark-base-btn" style={{background:darkButtonBackground}} onClick={() => setGradeInput(true)}>Grade This Submission</Button>

              }
              {submissionDetail.is_scored_task === true && reviewed === true && gradeInput === false && submissionDetail.is_validated !== false ?
                <div className="score">
                  <h2 style={{ color: "#5e6278" }}>Score:</h2>
                  <span className="score-value">{submissionDetail.score}</span>
                  {submissionDetail?.media_required === true &&
                  <Button className="gray-base-btn" onClick={() => setGradeInput(true)} style={{ margin: "6px 0" }}>Edit Score</Button> }
                </div>
                // :
                // submissionDetail.score_based_test === true && submissionDetail.is
                : submissionData?.media_required === false && submissionData?.score_based_test === true && submissionDetail.self_reviewed === true
                && gradeInput === false &&
                <div className="score">
                  <h2 style={{ color: "#5e6278" }}>Score:</h2>
                  <span className="score-value">{submissionDetail.score}</span>
                  {/* <Button className="gray-base-btn" onClick={() => setGradeInput(true)} style={{ margin: "6px 0" }}>Edit Score</Button> */}
                </div>
              }
              <div className="btn-div">
                <Popconfirm
                rootClassName={selectedSport === 1 ? "orange-popconfirm" : "blue-popconfirm"}
                  icon={false}
                  title="Are you sure?"
                  description={<p>Invalidating this submission will
                    remove it   <br />  and (if applicable) delete any accompanying   <br />
                    video.Are you sure you want to continue?</p>}
                  onConfirm={() => invalidateSubmission(false, submissionDetail?.profile)}
                  okText="Invalidate"
                  cancelText="Cancel"
                >
                  <Button className="red-base-btn"
                    disabled={submissionDetail.is_validated === false} style={{color : color , background : lightButtonBackground}}>Invalidate This Submission</Button>
                </Popconfirm>
              </div>

            </div>
            {submissionDetail?.is_validated === false &&
            <span style={{color:"red" , fontWeight:"bold"}}>          
            This submission was considered invalid and is not counted as a completed Task rep.</span>
            }
            {/* <div className="conversation">
              <h1>Private Conversation</h1>
              <Divider />
              {commentList.length > 0 && commentList?.toReversed().map((item, index) => {
                return (
                  item?.profile?.id === accountDetail?.id ?
                    <div className="posts" key={index}>

                      <div style={{ display: "flex", justifyContent: "end" }}>

                        <div className="name-div" style={{ marginRight: 20, textAlign: "end" }}>
                          <h3 className="user-name">{generalDetail?.name}</h3>
                          <h4 className="date"> Delivered {dayjs(item?.created_at).format('M/DD/YY @ hh:mma')}</h4>
                        </div>
                        {
                          item?.profile.avatar === null ?
                            <Avatar
                              name={generalDetail?.name}
                              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                              className="user-avatar"
                            /> :
                            <img src={generalDetail?.avatar}></img>
                        }
                      </div>
                      <div className="content" style={{ marginLeft: "inherit" }}>
                        {item?.text}
                      </div>
                    </div> :
                    <div className="posts" key={index}>
                      <div style={{ display: "flex" }}>
                        {
                          item?.profile.avatar === null ?
                            <Avatar
                              name={item?.profile.name}
                              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                              className="user-avatar"
                            /> :
                            <img src={item?.profile.avatar}></img>
                        }
                        <div className="name-div">
                          <h3 className="user-name">{item?.profile.name}</h3>
                          <h4 className="date"> Delivered {dayjs(item?.created_at).format('M/DD/YY @ hh:mma')}</h4>
                        </div>
                      </div>
                      <div className="content">
                        {item?.text}
                      </div>
                    </div>
                )
              }
              )}
              <div className="write-comment">Write your comments</div>
              <TextArea
                value={commentValue}
                onChange={handleCommentValue}
                placeholder="Your response here..."
                autoSize={{
                  minRows: 5,
                  maxRows: 6,
                }}
              />
              <div style={{ textAlign: "end" }}>
                <Button
                  className="dark-base-btn"
                  onClick={() => addComment(commentValue
                  )}
                  style={{ opacity: commentValue.length === 0 ? 0.5 : 1, cursor: commentValue.length === 0 ? "not-allowed" : "pointer" , background:darkButtonBackground }}
                  disabled={commentValue.length === 0}
                >
                  Send
                </Button>
              </div>
            </div> */}
          </div>

        </div>
        : Object.keys(submissionDetail).length === 0 && submissionLoading === false? 
        <span className="deleted">This submission has been deleted</span> 
        : submissionLoading === true &&
        <Spin
          className={"loader " + (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")}
          size="large"
          style={{
            position: "absolute",
            left: "49%",
          }}
        />}
    </Modal>
  )
}
export default SubmissionDetailModal;