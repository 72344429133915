import React, { useRef, useEffect, useState, useContext } from "react";
import { Card, Col, Collapse, Progress, Row, Tabs } from "antd";
import { FaArrowLeftLong } from "react-icons/fa6";
import "./workout-detail.scss";
import Tasks from "./tasks";
import WorkoutProgress from "./workout-progress";
import {
  WorkoutsContext,
  WorkoutsContextProvider,
} from "../../../context/workouts-context/workouts-context";
import {
  getDate,
  getDateWithTimeWithoutDay,
} from "../../../util/config";
import { getDataTraining } from "../../../util/api-calls";
import {
  WorkoutDetailContext,
  WorkoutDetailContextProvider,
} from "../../../context/workouts-context/workout-detail-context";
import WorkoutSubmissionDetail from "./workout-submission-detail";
import { useLocation , Link } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";
import { UserContext } from "../../../context/user-context";
const style = {
  padding: "5px 0",
};
const WorkoutDetailPage = ({ setWorkoutDetail, selectedWorkout, setViewSubmissions, viewSubmissions , generalDetail,setSelectedWorkout }) => {
  const navigate = useNavigate();
  const {color,selectedSport} = useContext(UserContext)
  const { workoutDetail , setActiveTab , params} = useContext(WorkoutDetailContext);
  const location = useLocation();
  const tabItems = [
    {
      key: "1",
      label: `Tasks`,
      children: <Tasks tasks={workoutDetail.tasks} setViewSubmissions={setViewSubmissions} />,
    },
    {
      key: "2",
      label: `Workout Progress`,
      children: <WorkoutProgress tasks={workoutDetail.tasks} setViewSubmissions={setViewSubmissions} setWorkoutDetail={setWorkoutDetail} />,
    },
  ];
  const onChange = (key) => {
    setActiveTab(key)
  };
  let currentLink = "";
  const crumbs = location.pathname
  .split("/")
  .filter((crumb) => crumb !== "")
  .map((crumb) => {
    currentLink += `/${crumb}`;
    if(crumb !== "home" && crumb !== "groups"){
    let newCrumb = crumb.replace("-", " ");
    return (
      <div className="crumb" key={newCrumb}>
        <Link 
        // to={currentLink}
        style={{cursor:"text"}}
        >{decodeURI(newCrumb)}</Link>
      </div>
    );
  }
  });
  return (
    <div className="workout-detail-main">
      <div className="heading-row">
      <FaArrowLeftLong
        className="left-arrow"
          style={{ margin:"33px 0 8px 23px" , color :color , stroke: color }}
          onClick={() => { setWorkoutDetail(false); 
            setViewSubmissions(false) ;
            viewSubmissions === true ? navigate({
              search: `id=${params.id}&tab=submission-detail`,
            }): navigate({
              search: `id=${params.id}`,
            })
          }}
        />
        <h1>{workoutDetail.title}</h1>
      </div>
      <div className="breadcrumbs">
      {/* <span className="bread-crumb">Workouts - {workoutDetail.title}</span> */}
      <div style={{ display: "flex" }}>{crumbs}
      <div className="crumb"         style={{cursor:"text"}}
      ><a         style={{cursor:"text"}}
>Workouts - {workoutDetail?.title}</a></div>
        </div> 
      </div>
      <hr />
      {viewSubmissions ?
        <WorkoutSubmissionDetail generalDetail={generalDetail}/> :
        <>
          <Row gutter={16} className="graph-row">
            <Col className="gutter-row left-col" span={16}></Col>
            <Col className="gutter-row right-col" span={12}>
              <div style={style} className="div-1">
                <h2>Workout Information</h2>
                {/* <div className="pending-tasks">
              {workoutDetail.tasks_completed}/
              <span>{workoutDetail.total_tasks} tasks</span>
            </div>
            <div className="percentage">
              {workoutDetail.completion_percentage}%
            </div>

            <Progress
              percent={workoutDetail.completion_percentage}
              showInfo={false}
              className={selectedSport === 1 ? "orange-progress-bar" : "blue-progress-bar" }
            />
 */}
                <Row gutter={16} className="inner-row" style={{ marginBottom: 10 }}>
                  <Col className="gutter-row left-col" span={12}>
                    <div style={style} className="div-1">
                      <h2>{workoutDetail.total_tasks}</h2>
                      <span>Total Tasks</span>
                    </div>
                  </Col>
                  <Col className="gutter-row right-col" span={12}>
                    <div style={style} className="div-1">
                      <h2>{workoutDetail.total_reps}</h2>
                      <span>Total Reps</span>
                    </div>
                  </Col>
                </Row>

                <Row gutter={16} className="inner-row" style={{ marginTop: 10 }}>
                  <Col className="gutter-row left-col" span={24}>
                    <div style={style} className="div-1">
                      <h2>{workoutDetail.assignee}</h2>
                      <span>Members Doing These Tasks</span>
                    </div>
                  </Col>
                </Row>
                <div className="end-date">
                  Ends:
                  <span>{workoutDetail.end_date ?  getDate(workoutDetail.end_date) : ""}</span>
                </div>
              </div>
            </Col>
          </Row>
          <Tabs
            defaultActiveKey="1"
            items={tabItems}
            onChange={onChange}
            className={"workout-detail-tabs " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
          />
        </>
      }
    </div>
  );
};

const WorkoutDetail = ({ setWorkoutDetail, selectedWorkout, setViewSubmissions, viewSubmissions , generalDetail ,  setSelectedWorkout}) => {
  return (
    <WorkoutDetailContextProvider selectedWorkout={selectedWorkout}>
      <WorkoutDetailPage setWorkoutDetail={setWorkoutDetail} setViewSubmissions={setViewSubmissions} viewSubmissions={viewSubmissions} generalDetail={generalDetail} setSelectedWorkout={setSelectedWorkout}/>
    </WorkoutDetailContextProvider>
  );
};

export default WorkoutDetail;
