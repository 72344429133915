import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Checkbox,
  DatePicker,
  Radio,
  Select,
  Space,
  Upload,
  message,
  Modal,
  Divider,
  InputNumber,
} from "antd";
import Search from "../../../assets/images/search.svg";
import Avatar from "react-avatar";
import "./edit-profile-modal.scss";
import DownArrow from "../../../assets/images/arrows.svg";
// import "./owners-members.scss";
import { MemberContext } from "../../../context/group-context/members-context";
import { jsonPostData } from "../../../util/api-calls";
import InfiniteScroll from "react-infinite-scroll-component";
import Calendar from "../../../assets/images/calendar.svg";
import Profile from "../../../assets/images/avatar.svg";
import dayjs from "dayjs";
import Edit from "../../../assets/images/edit.svg";
import Close from "../../../assets/images/close.svg";
import { UserDetailContext } from "../../../context/user-module-context/user-detail-context";
import { UserContext } from "../../../context/user-context";
const formItemLayout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 14,
  },
};
const formTailLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 15,
    //   offset: 4,
  },
};
const EditProfileModal = ({ editProfile, showEditProfile }) => {
  const {darkButtonBackground , selectedSport} = useContext(UserContext)
  const {
    gender,
    setGender,
    shootingHand,
    setShootingHand,
    imageUpload,
    profileType,
    setProfileType,
    updateProfile,
    sportsList,
    selectedProfile,
    setPositionPg,
    positionPg,
    positionSg,
    setPositionSg,
    positionSf,
    setPositionSf,
    positionPf,
    setPositionPf,
    setImageUrl,
    positionC,
    setPositionC,
    imgSrc,
    setImgSrc,
    setImageUpload,
  } = useContext(UserDetailContext);
  const [form] = Form.useForm();
  const [checkNick, setCheckNick] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feet, setFeet] = useState("");
  const [inch, setInch] = useState("");

  const dateFormat = "ddd. MMMM D, YYYY";
  const [value, setValue] = useState(1);
  useEffect(() => {
    if (Object.keys(selectedProfile).length > 0) {
      setImgSrc(selectedProfile.avatar);
      form.setFieldsValue({
        avatar: selectedProfile.avatar,
        first_name: selectedProfile.first_name,
        last_name: selectedProfile.last_name,
        username: selectedProfile.username,
        dob: dayjs(selectedProfile.date_of_birth),
        weight_lbs: selectedProfile.weight !== null && selectedProfile.weight,
        location: selectedProfile.location,
        shooting_hand: selectedProfile.shooting_hand,
        gender: selectedProfile.gender,
        school: selectedProfile.school,
        job_title: selectedProfile.job_title,
        years_experience: selectedProfile.years_experience,
        profile_type: selectedProfile.profile_type,
        height_feet:
          selectedProfile.height !== null && selectedProfile.height !== "" ?
          parseInt(selectedProfile.height.split("′")[0])
           : null,
        height_inch:
          selectedProfile?.height !== null  && selectedProfile.height !== ""?
          parseInt(
            selectedProfile.height.substring(
              selectedProfile.height.indexOf("′") + 1,
              selectedProfile.height.lastIndexOf("″")
            )
          )  : null ,
      });
      setFeet(
        selectedProfile?.height !== null ? selectedProfile.height.split("′")[0] : null
      );
      setInch(
        selectedProfile?.height !== null ?
          selectedProfile.height.substring(
            selectedProfile.height.indexOf("′") + 1,
            selectedProfile.height.lastIndexOf("″")
          ) : null
      );
      setProfileType(selectedProfile.profile_type);
      setShootingHand(selectedProfile.shooting_hand);
      setGender(selectedProfile.gender);
      setPositionPg(selectedProfile.position_pg);
      setPositionPf(selectedProfile.position_pf);
      setPositionSf(selectedProfile.position_sf);
      setPositionSg(selectedProfile.position_sg);
      setPositionC(selectedProfile.position_c);
    }
  }, [selectedProfile]);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  let sportOption = [];
  if (sportsList?.length > 0) {
    for (let i = 0; i < sportsList.length; i++) {
      sportOption.push({
        value: sportsList[i].id,
        label: sportsList[i].name,
      });
    }
  }
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  // useEffect(() => {
  //   form.validateFields(["nickname"]);
  // }, [checkNick, form]);
  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log("Success:", values);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  const onSearch = (value) => console.log(value);
  const onChangeSelect = (value) => {
    console.log(`selected ${value}`);
  };
  const onCheckboxChange = (e) => {
    setCheckNick(e.target.checked);
  };
  const handleOk = (e) => {};
  const handleCancel = () => {
    showEditProfile(false);
  };
  const handleBeforeUpload = (file) => {
    const isPNG = file.type === 'image/png';
    const isJPEG = file.type === 'image/jpeg'
    const isJPG = file.type === 'image/jpg'
    if (!isPNG && !isJPEG && !isJPG) {
      message.error(`Only PNG, JPG, or JPEG files are allowed.`);
    }
    else if(file.size > 10000000){
      message.error(`Maximum file size should be 10MB or less.`);
    }
    else{
    setImageUpload(false);
    setImageUrl(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImgSrc(reader.result);
    };
    reader.readAsDataURL(file);
    // Prevent upload
    return false;
  }
  };
  const removeImage = () => {
    setImageUrl();
    setImgSrc();
  };
  return (
    <Modal
      wrapClassName={"edit-profile-modal " + (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")}
      open={editProfile}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Finish Adding"}
      cancelText={"Cancel"}
      width={"999px"}
    >
      <div className="edit-general-details-main">
        <h1>Edit Profile</h1>
        <Form
          form={form}
          name="dynamic_rule"
          className="profile-form"
          onFinish={updateProfile}
          style={{ padding: "22px 30px" }}
          initialValues={
            {
              // height_feet: selectedProfile?.height?.split('′')[0],
              // height_inch:selectedProfile?.height?.substring(
              // selectedProfile?.height?.indexOf("′") + 1,
              // selectedProfile?.height?.lastIndexOf("″")),
              //   type: type,
              //   level: "Level 1: Beginners (age < 10)",
              //   sport: 1,
              //   bsa_credit: "none",
              //   "members-limit": false,
              //   display_announcements_section: true,
              //   display_post_section: true,
              //   allow_members_post: "owners",
              //   accessible_leaderboards: ["display_workout_leaderboard"],
            }
          }
        >
          <Form.Item
            {...formItemLayout}
            name="avatar"
            label="Display Photo"
            className="img-div"
          >
            <div className="image-previewer">
              {!imgSrc ? (
                <Upload
                  className="uploader"
                  showUploadList={false}
                  type="file"
                  customRequest={dummyRequest}
                  beforeUpload={handleBeforeUpload}
                >
                  Upload pic
                </Upload>
              ) : (
                <>
                  <img
                    src={imgSrc}
                    alt="avatar"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    className="img"
                  />
                  <Upload
                    className="avatar-uploader"
                    showUploadList={false}
                    type="file"
                    customRequest={dummyRequest}
                    beforeUpload={handleBeforeUpload}
                  >
                    <img src={Edit} alt="edit" className="edit"></img>
                  </Upload>
                  <img
                    src={Close}
                    alt="close"
                    className="delete"
                    onClick={removeImage}
                  />
                </>
              )}
            </div>

            <span className="img-type" style={{marginBottom:0}}>
              Allowed file types: PNG, JPG, JPEG.
            </span>
            <span className="img-type">Max Size:10MB</span>
            {imageUpload === true ? (
              <span className="error-message" style={{ color: "red" }}>
                Please upload image
              </span>
            ) : (
              ""
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="profile_type"
            label="Profile Type"
            className="shooting-div"
          >
            <Radio.Group
              onChange={(e) => setProfileType(e.target.value)}
              value={profileType}
              // defaultValue={"player"}
              className="custom-radio"
            >
              <Radio value={"player"}>Player</Radio>
              <Radio value={"coach"}>Coach</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="first_name"
            label="First Name"
            className="name-row"
            rules={[
              {
                required: true,
                message: "Please enter first name.",
              },
            ]}
          >
            <Input
              placeholder="Please input first name"
              className="custom-input"
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="last_name"
            label="Last Name"
            className="name-row"
            rules={[
              {
                required: true,
                message: "Please enter last name.",
              },
            ]}
          >
            <Input
              placeholder="Please input last name"
              className="custom-input"
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="username"
            label="User Name"
            className="name-row"
            rules={[
              {
                required: true,
                message: "Please enter username.",
              },
            ]}
          >
            <Input
              placeholder="Please input username"
              className="custom-input"
              disabled
            />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="dob"
            label="Date of Birth"
            rules={[
              {
                required: true,
                message: "Please input date of birth",
              },
            ]}
          >
            <DatePicker
              className={"date-picker " + (selectedSport === 1 ? "orange-picker" : "blue-picker")}
              rootClassName={selectedSport === 1 ? "orange-picker" : "blue-picker"}
              // defaultValue={dayjs("Jan 01, 2022", dateFormat)}
              format={dateFormat}
              suffixIcon={<img src={Calendar} />}
              style={{ height: "48px" }}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="gender"
            label="Gender"
            className="gender-div"
            rules={[
              {
                required: false,
                message: "Please input your name",
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => setGender(e.target.value)}
              value={gender}
              // defaultValue={"male"}
              // defaultChecked={"male"}

              className="custom-radio"
            >
              <Radio value={"male"}>Male</Radio>
              <Radio value={"female"}>Female</Radio>
              <Radio value={"undisclosed"}>Undisclosed</Radio>
            </Radio.Group>
          </Form.Item>
          {profileType === "player" ? (
            <>
              <Form.Item
                {...formItemLayout}
                label="Measurements"
                className="measurements-input"
              >
                <div style={{ display: "flex", gap: 8 }}>
                  <label style={{ padding: "15px 0" }}>Height:</label>
                  <Form.Item
                    name="height_feet"
                    rules={[
                      {
                        type: "number",
                        min: 0,
                        max: 8,
                        pattern: new RegExp("^[0-9]*$"),
                        message: "Feet must be between ${min} to ${max}.",
                      },
                    ]}
                  >
                    <InputNumber
                      addonBefore={"ft"}
                      placeholder="Feet"
                      type="number"
                      value={feet}
                      onKeyDown={(e) => e.key === "." && e.preventDefault()}
                    />
                  </Form.Item>
                  <Form.Item
                    name="height_inch"
                    rules={[
                      {
                        type: "number",
                        min: 0,
                        max: 11,
                        message: "Inches must be between ${min} to ${max}.",
                      },
                    ]}
                  >
                    <InputNumber
                      addonBefore={"in"}
                      placeholder="Inch"
                      type="number"
                      value={inch}
                      onKeyDown={(e) => e.key === "." && e.preventDefault()}
                    />
                  </Form.Item>
                  {/* <Form.Item
                  name="height_dec"
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      max: 75,
                      message: "Decimal must be between ${min} to ${max}.",
                    },
                  ]}
                >
                  <InputNumber
                    addonBefore={"dec"}
                    placeholder="Decimal"
                    type="number"
                    onKeyDown={(e) => e.key === "." && e.preventDefault()}
                  />
                </Form.Item> */}
                </div>
                <div className="weight-div">
                  <Form.Item
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    <div style={{ display: "flex", gap: 8 }}>
                      <label style={{ padding: "15px 0" }}>Weight:</label>
                      <Form.Item
                        name="weight_lbs"
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input weight",
                          },
                        ]}
                      >
                        <InputNumber
                          addonBefore={"lbs"}
                          placeholder="Lbs"
                          className="weight-input"
                          type="number"
                          onKeyDown={(e) => e.key === "." && e.preventDefault()}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                </div>
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                {...formItemLayout}
                name="school"
                label="School/Program"
                className="name-row"
                rules={[
                  {
                    required: false,
                    message: "Please enter school/program.",
                  },
                ]}
              >
                <Input
                  placeholder="Please input school/program"
                  className="custom-input"
                  // onChange={(e)=>setUsername(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="job_title"
                label="Job Title"
                className="name-row"
                rules={[
                  {
                    required: false,
                    message: "Please enter job title.",
                  },
                ]}
              >
                <Input
                  placeholder="Please input job title"
                  className="custom-input"
                  // onChange={(e)=>setUsername(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="years_experience"
                label="Year"
                className="name-row"
                rules={[
                  {
                    required: false,
                    message: "Please enter year.",
                  },
                ]}
              >
                <Input
                  placeholder="Years"
                  className="weight-input"
                  // type="number"
                  style={{ width: "100%", height: 48 }}
                />
              </Form.Item>
            </>
          )}
          <Form.Item
            {...formItemLayout}
            name="location"
            className="location-div"
            label="Location"
            style={{ padding: 0 }}
            rules={[
              {
                required: false,
                message: "Please input your location",
              },
            ]}
          >
            <Input
              placeholder="Please input location"
              className="custom-input"
            />
          </Form.Item>
          <Divider />

          {/* <h2>Sports</h2>
            <Form.Item
              {...formItemLayout}
              name="sport"
              label="Sport"
              className="sport-div"
            >
              <Select
                placeholder="Select sport"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                suffixIcon={<img src={DownArrow} />}
                className="location-select"
                options={sportOption}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name= "sport_level"
              className="location-select"
              label="Skill Level"
              rules={[
                {
                  required: false,
                  message: "Please input your name",
                },
              ]}
            >
              <Select
                placeholder="Level 2: Intermediate (age 11-15)"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                suffixIcon={<img src={DownArrow} />}
                className="location-select"
                options={[
                  {
                    value: "beginner",
                    label: "Level 1: Beginners (age < 10)",
                  },
                  {
                    value: "intermediate",
                    label: "Level 2: Intermediate (age 11-15)",
                  },
                  {
                    value: "advanced",
                    label: "Level 3: Advance (age 16+)",
                  },
                ]}
              />
            </Form.Item> */}
          {profileType === "player" && (
            <Form.Item
              {...formItemLayout}
              name="shooting_hand"
              label="Shooting Hand"
              className="shooting-div"
            >
              <Radio.Group
                onChange={(e) => setShootingHand(e.target.value)}
                value={shootingHand}
                // defaultValue={"right"}
                className="custom-radio"
              >
                <Radio value={"right"}>Right</Radio>
                <Radio value={"left"}>Left</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          <Form.Item
            {...formItemLayout}
            name="positions"
            label="Positions"
            className="sport-div position-div custom-checkbox"
          >
            {/* <Checkbox.Group className="custom-checkbox" defaultValue={selectedProfile.sg}> */}
            <Checkbox
              value="pg"
              checked={positionPg}
              onChange={(e) => setPositionPg(e.target.checked)}
            >
              PG
            </Checkbox>
            <Checkbox
              value="sg"
              checked={positionSg}
              onChange={(e) => setPositionSg(e.target.checked)}
            >
              SG
            </Checkbox>
            <Checkbox
              value="sf"
              checked={positionSf}
              onChange={(e) => setPositionSf(e.target.checked)}
            >
              SF
            </Checkbox>
            <Checkbox
              value="pf"
              checked={positionPf}
              onChange={(e) => setPositionPf(e.target.checked)}
            >
              PF
            </Checkbox>
            <Checkbox
              value="c"
              checked={positionC}
              onChange={(e) => setPositionC(e.target.checked)}
            >
              C
            </Checkbox>
            {/* </Checkbox.Group> */}
          </Form.Item>
          {/* {newSport && (
              <>
                <h3>Sport 2</h3>
                <Form.Item
                  {...formItemLayout}
                  name="sport"
                  label="Sport"
                  className="sport-div"
                >
                  <Select
                    placeholder="Select a city"
                    optionFilterProp="children"
                    // onChange={onChangeSelect}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    suffixIcon={<img src={DownArrow} />}
                    className="location-select"
                    options={[
                      {
                        value: "jack",
                        label: "Jack",
                      },
                      {
                        value: "lucy",
                        label: "Lucy",
                      },
                      {
                        value: "tom",
                        label: "Tom",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  className="location-select"
                  label="Skill Level"
                  rules={[
                    {
                      required: false,
                      message: "Please input your name",
                    },
                  ]}
                >
                  <Form.Item
                    rules={[
                      {
                        required: false,
                        message: "Please input your name",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Level 2: Intermediate (age 11-15)"
                      optionFilterProp="children"
                      // onChange={onChangeSelect}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      suffixIcon={<img src={DownArrow} />}
                      className="location-select"
                      options={[
                        {
                          value: "jack",
                          label: "Jack",
                        },
                        {
                          value: "lucy",
                          label: "Lucy",
                        },
                        {
                          value: "tom",
                          label: "Tom",
                        },
                      ]}
                    />
                  </Form.Item>
                </Form.Item>

                <Form.Item
                  {...formItemLayout}
                  name="shooting-hand"
                  label="Shooting Hand"
                  className="shooting-div"
                >
                  <Radio.Group
                    // onChange={onChange}
                    // value={value}
                    // defaultValue={value}
                    className="custom-radio"
                  >
                    <Radio value={1}>Right</Radio>
                    <Radio value={2}>Left</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  name="positions"
                  label="Positions"
                  className="sport-div position-div"
                >
                  <Checkbox.Group className="custom-checkbox">
                    <Checkbox value="pg">PG</Checkbox>
                    <Checkbox value="sg">SG</Checkbox>
                    <Checkbox value="sf">SF</Checkbox>
                    <Checkbox value="pf">PF</Checkbox>
                    <Checkbox value="c">C</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </>
            )} */}
          {/* <span className="add-another" onClick={() => setNewSport(true)}>
              Add Another Sport
            </span> */}
          <Divider />
          <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
            {/* <Button
              htmlType="submit"
              type="primary"
              className="discard-btn"
              onClick={() => form.resetFields()}
            >
              Discard
            </Button> */}
            <Button
              type="primary"
              htmlType="submit"
              style={{
                margin: "0 8px", background:darkButtonBackground
              }}
              className="dark-base-btn"
            >
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditProfileModal;
