import React, { useContext, useEffect, useState } from "react";
import {
  getData,
  formPostData,
  formPostDataTraining,
  jsonPostDataTraining,
} from "../../util/api-calls";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { UserContext } from "../user-context";
// import { SubscriptionContext } from "../subscription-context";
export const CreateFeatureWorkoutContext = React.createContext({});
export const CreateFeatureWorkoutContextProvider = ({ children = {} }) => {
  // const {subNotes , subStart , subExpire} = useContext(SubscriptionContext)
  const { selectedSport } = useContext(UserContext);
  const navigate = useNavigate();
  const [sportsList, setSportsList] = useState([]);
  const [type, setType] = useState(1);
  const [level, setLevel] = useState();
  const [seatLimit, setSeatLimit] = useState(false);
  const [memberLimit, setMemberLimit] = useState();
  const [workoutLeaderBoard, setWorkoutLeaderBoard] = useState(true);
  const [combineLeaderBoard, setCombineLeaderBoard] = useState(false);
  const [bsaLeaderBoard, setBsaLeaderBoard] = useState(false);
  const [sport, setSport] = useState("");
  const [imageUrl, setImageUrl] = useState();
  const [imgSrc, setImgSrc] = useState();
  const [newSubscription, setNewSubscription] = useState(false);
  const [editSubscription, setEditSubscription] = useState(false);
  const [subscriptionHistory, setSubscriptionHistory] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("none");
  const [subStart, setSubStart] = useState("");
  const [startTimeZone, setStartTimeZone] = useState("");
  const [expiryTimeZone, setExpireTimeZone] = useState("");
  const [subExpire, setSubExpire] = useState("");
  const [subNotes, setSubNotes] = useState("");
  const [bsaValue, setBsaValue] = useState();
  const [bsaValueoneTime, setBsaValueOneTime] = useState();
  const [bsaValueEachMonth, setBsaValueEachMonth] = useState();
  const [loading, setLoading] = useState(false);
  const [access, setAccess] = useState(false);
  const [curriculum, setCurriculum] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [sponsorsList, setSponsorsList] = useState([]);
  const [sponsor, setSponsor] = useState({});
  const [newTaskArray, setNewTaskArray] = useState([]);
  const [current, setCurrent] = useState();
  const [taskListError, setTaskListError] = useState(false);
  const getSportList = () => {
    getData(`sport`)
      .then((res) => {
        setSportsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllSponsors = () => {
    getData(`sponsor`)
      .then((res) => {
        console.log(res);
        setSponsorsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllSponsors();
  }, []);
  const createFeatureWorkout = (values) => {
    if (newTaskArray.length === 0) {
      setTaskListError(true);
    } else {
      const list_tasks = newTaskArray
        .filter(
          (ele, ind) =>
            ind === newTaskArray.findIndex((elem) => elem.id === ele.id)
        )
        .map((e, index) => ({
          id: e.id,
          order: index + 1,
          //   lock: e.lock,
          lock: current === index || index > current ? true : false,
          num_reps: isNaN(e.num_reps) ? 1 : e.num_reps,
          allow_more_reps: e.allow_more_reps,
          legacy_point: e.legacy_point,
          min_score: e.min_score,
        }));
      // if (imageUrl === undefined) {
      //   setImageError(true);
      // } else {
        setLoading(true);
        setImageError(false);
        let start_date = new Date(Date.parse(values.start_date)).toISOString();
        let expiry_Date = new Date(
          Date.parse(values.expiry_Date)
        ).toISOString();
        let formData = new FormData();
        formData.append("title", values.title);
        if (imageUrl) {
          formData.append("file", imageUrl);
        }
        formData.append("sports_id", selectedSport);
        formData.append("reward_before", values.reward_before ? values.reward_before : null);
        formData.append("reward_after", values.reward_after ? values.reward_after : null);
        if (Object.keys(sponsor).length > 0) {
          formData.append("sponsor_id", sponsor.id);
          formData.append("sponsor_name", sponsor.name);
          formData.append("sponsor_website", sponsor.website);
          formData.append("sponsor_icon", sponsor.icon);
        }
        console.log(start_date, expiry_Date);
        if (values.start_date) {
          formData.append("start_date", start_date);
        }
        if (values.expiry_Date) {
          formData.append("end_date", expiry_Date);
        }
        formData.append("list_tasks", JSON.stringify(list_tasks));
        if (values.completion_bonus) {
          formData.append("completion_bonus", values.completion_bonus);
        }
        formPostDataTraining(`feature/workout`, formData)
          .then((res) => {
            // if (res.status === 200) {
            message.success({
              content: "Feature workout created successfully.",
              duration: 1,
            });
            setTimeout(() => {
              navigate("/home/workouts?tab=featured-workouts");
            }, 1200);
            // }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    // }
  };

  useEffect(() => {
    // getSportList();
  }, []);
  const defaultContext = {
    sportsList,
    createFeatureWorkout,
    combineLeaderBoard,
    setCombineLeaderBoard,
    bsaLeaderBoard,
    setBsaLeaderBoard,
    workoutLeaderBoard,
    setWorkoutLeaderBoard,
    type,
    setMemberLimit,
    seatLimit,
    setSeatLimit,
    setType,
    setLevel,
    memberLimit,
    level,
    sport,
    setSport,
    imageUrl,
    setImageUrl,
    imgSrc,
    setImgSrc,
    bsaValue,
    setBsaValue,
    subscriptionStatus,
    setNewSubscription,
    editSubscription,
    setEditSubscription,
    setSubscriptionHistory,
    newSubscription,
    setSubscriptionStatus,
    subscriptionHistory,
    loading,
    access,
    setAccess,
    setSubStart,
    setSubNotes,
    setSubExpire,
    subStart,
    subExpire,
    subNotes,
    setStartTimeZone,
    setExpireTimeZone,
    startTimeZone,
    bsaValueEachMonth,
    setBsaValueEachMonth,
    bsaValueoneTime,
    setBsaValueOneTime,
    expiryTimeZone,
    setCurriculum,
    imageError,
    setImageError,
    sponsorsList,
    setSponsor,
    newTaskArray,
    setNewTaskArray,
    setCurrent,
    current,
    taskListError,
    setTaskListError
  };
  return (
    <CreateFeatureWorkoutContext.Provider value={defaultContext}>
      {children}
    </CreateFeatureWorkoutContext.Provider>
  );
};
