import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Button,
  Input,
  Checkbox,
  Radio,
  Select,
  Upload,
  message,
  Divider,
  DatePicker,
  Skeleton,
  InputNumber,
} from "antd";
import "./edit-group-general-details.scss";
import DownArrow from "../../../assets/images/down-arrow.svg";
import Edit from "../../../assets/images/edit.svg";
import Close from "../../../assets/images/close.svg";
import Calendar from "../../../assets/images/calendar.svg";
import Avatar from "react-avatar";
import {
  GroupDetailContextProvider,
  GroupDetailContext,
} from "../../../context/group-context/group-detail-context";
// import { SubscriptionContext } from "../../../context/subscription-context";
import dayjs from "dayjs";
import SubscriptionModal from "../subscription-modal/subscription-modal";
import SubscriptionHistory from "../subscription-history/subscription-history";
import { UserContext } from "../../../context/user-context";
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 15,
  },
};
const EditGroupGeneralDetails = ({ setGeneralDetail }) => {
  const {darkButtonBackground, selectedSport} = useContext(UserContext)
  const [form] = Form.useForm();
  // const {newSubscription,setNewSubscription,subscriptionHistory,setSubscriptionHistory,subscriptionStatus,setSubscriptionStatus} = useContext(SubscriptionContext)
  const {
    generalDetail,
    type,
    setSeatLimit,
    seatLimit,
    setMemberLimit,
    memberLimit,
    imgSrc,
    bsaCredit,
    setImgSrc,
    setImageUrl,
    setSport,
    setBsaCredit,
    setType,
    level,
    setLevel,
    sportsList,
    postEditGroup,
    workoutLeaderBoard,
    bsaLeaderBoard,
    combineLeaderBoard,
    bsaValue,
    setBsaValue,
    bsaValueOneTime,
    bsaValueEachMonth,
    setWorkoutLeaderBoard,
    setBsaLeaderBoard,
    setCombineLeaderBoard,
    setSubscriptionStatus,
    subscriptionStatus,
    setNewSubscription,
    setSubscriptionHistory,
    subscriptionHistory,
    newSubscription,
    tags,
    setTags,
    setLoading,
    access,
    setAccess,
    setBsaValueEachMonth,
    setBsaValueOneTime,setCurriculum,curriculum,
    imageError,setImageError
  } = useContext(GroupDetailContext);
  const [imageLoaded, setImageLoaded] = useState("");
  const dateFormat = "ddd. MMMM D, YYYY";
  const onChangeSkillLevel = (e) => {
    setLevel(e);
  };
  const onChangeTags = (e) => {
    setTags(e);
  };
  useEffect(() => {
    if (generalDetail.is_purchased_group === true) {
      setType(2);
    }
    form.setFieldsValue({
      name: generalDetail.name,
      display_announcements_section:
        generalDetail.display_announcements_section,
      allow_members_post:
        generalDetail.allow_members_post === false ? "owners" : "all",
      display_post_section: generalDetail.display_post_section,
      type: generalDetail.is_purchased_group === true ? 2 : 1,
      level: generalDetail.level === null ? "beginner" : generalDetail.level,
      access: generalDetail.subscription_required,
      // start_date:
      //    generalDetail.start_date !== null && dayjs(generalDetail.start_date),
      // expiry_date:
      //   generalDetail.expiry_date !== null && dayjs(generalDetail.expiry_date),
      sport: generalDetail.sport && generalDetail.sport.name,
      bsa_credit:
        generalDetail.bsa_frequency === "one time"
          ? "one-time"
          : generalDetail.bsa_frequency,
      members_limit: generalDetail.member_limit >= 10000 ? false : true,
      // tags: generalDetail.tags !== null && generalDetail.tags.split(",") ,
      comments: generalDetail.comments,
      description:generalDetail.description,
      expiry: generalDetail.member_code_expiry
    });
    if (generalDetail.display_workout_leaderboard === true) {
      setWorkoutLeaderBoard(true);
    }
    if (generalDetail.display_bsa_leaderboard === true) {
      setBsaLeaderBoard(true);
    }
    if (generalDetail.display_combine_measurement_leaderboard) {
      setCombineLeaderBoard(true);
    }
    if (generalDetail.sport !== null) {
      for (let i = 0; i < sportsList.length; i++) {
        if (sportsList[i].name === generalDetail.sport.name) {
          setSport(sportsList[i].id);
        }
      }
    }
    if (generalDetail.tags !== null) {
      setTags(generalDetail.tags && generalDetail.tags.split(","));
    }
    if (generalDetail.member_limit < 10000) {
      setSeatLimit(true);
    }
    setBsaValue(generalDetail.free_bsa);
    if (generalDetail.avatar) {
      setImgSrc(generalDetail.avatar);
    }
    if(generalDetail.is_curriculum_group){
      setCurriculum(generalDetail.is_curriculum_group)
    }
    if (generalDetail.bsa_frequency === "one time") {
      setBsaValueOneTime(generalDetail.free_bsa)
      setBsaCredit(2);
    } else if (generalDetail.bsa_frequency === "monthly") {
      setBsaValueEachMonth(generalDetail.free_bsa)
      setBsaCredit(3);
    } else {
      setBsaCredit(1);
    }
    setMemberLimit(
      generalDetail.member_limit === 100000 ? "" : generalDetail.member_limit
    );
  }, [generalDetail]);
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleBeforeUpload = (file) => {
    setImageError(false)
    setImageUrl(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImgSrc(reader.result);
    };
    reader.readAsDataURL(file);
    // Prevent upload
    return false;
  };
  const changeSport = (e) => {
    setSport(e.id);
  };
  const onChangeLimit = (e) => {
    if (e.target.value === true) {
      setSeatLimit(true);
    } else {
      setSeatLimit(false);
    }
  };
  const onChangeType = (e) => {
    setType(e.target.value);
  };
  const onChangeBsa = (e) => {
    if (e.target.value === "none") {
      setBsaCredit(1);
    } else if (e.target.value === "one-time") {
      setBsaCredit(2);
    } else {
      setBsaCredit(3);
    }
  };

  const removeImage = () => {
    setImageUrl();
    setImgSrc();
  };
  const monthlyOptions = [];
  for (let i = 1; i <= 10; i++) {
    monthlyOptions.push({
      value: i.toString(10),
      label: i.toString(10),
    });
  }
  const oneTimeOptions = [];
  for (let i = 1; i <= 10; i++) {
    oneTimeOptions.push({
      value: i.toString(10),
      label: i.toString(10),
    });
  }
  const onChangeBsaSelect = (e) => {
    if(bsaCredit === 2){
      setBsaValueOneTime(e)
    }else{
      setBsaValueEachMonth(e)
    }
    setBsaValue(e);
  };
  const onChangeSubscription = () => {
    if (access) {
      setAccess(false);
    } else {
      setAccess(true);
    }
  };
    const onChangeCurriculum = (e) => {
      setCurriculum(e.target.checked)    
  };
  return (
    <div className="edit-group-general-details-main">
      <h1>General Details</h1>
      <Divider />
      <Form
        form={form}
        name="dynamic_rule"
        className="details-form"
        onFinish={postEditGroup}
      >
        {/* <h2>Sports</h2>
        <Form.Item
          {...formItemLayout}
          name="sport"
          label="Sport"
          className="sport-div"
          rules={[
            {
              required: true,
              message: "Please select sport",
            },
          ]}
        >
          <Radio.Group className="custom-radio">
            <>
              {sportsList.map(function (item, index) {
                return (
                  <Radio
                    key={item.id}
                    value={item.name}
                    defaultChecked="Basketball"
                    onChange={() => changeSport(item)}
                  >
                    {item.name}
                  </Radio>
                );
              })}
            </>
          </Radio.Group>
        </Form.Item>
        <Divider style={{ margin: "56px 0" }} /> */}
        <Form.Item {...formItemLayout} name="curriculum_group" label="Make a curriculum group">
            <Checkbox onChange={onChangeCurriculum} className="custom-checkbox" checked={curriculum}/>
            {/* Curriculum Group</Checkbox> */}
            </Form.Item>
        <Form.Item {...formItemLayout} name="type" label="Type">
          <Radio.Group
            onChange={onChangeType}
            value={type}
            className="custom-radio"
          >
            <Radio value={1}>Team Training Group</Radio>
            <Radio value={2}>Paid Public Training Group</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="avatar"
          label="Display Photo"
          className="img-div"
        >
          <div className="image-previewer">
            {generalDetail.avatar !== null && imageLoaded === "" && (
              <Skeleton.Image
                active={true}
                style={{ width: "90px", height: "90px" }}
              />
            )}
            {!imgSrc ? (
              <Avatar
                name={generalDetail.name}
                style={{ marginRight: 10 }}
                color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                className="initial-avatar"
              />
            ) : (
              <>
                <img
                  src={imgSrc}
                  alt="avatar"
                  className="edit-logo"
                  style={
                    imageLoaded === ""
                      ? { display: "none" }
                      : { display: "block" }
                  }
                  onLoad={() => setImageLoaded("loaded")}
                />
              </>
            )}
            <Upload
              className="avatar-uploader"
              showUploadList={false}
              type="file"
              customRequest={dummyRequest}
              beforeUpload={handleBeforeUpload}
            >
              <img src={Edit} alt="edit" className="edit"></img>
            </Upload>
            <img
              src={Close}
              alt="close"
              className="delete"
              onClick={removeImage}
            />
          </div>
          <span className="img-cond" style={{ marginTop: 10 }}>
            Allowed file types: PNG, JPG, JPEG.
          </span>
          {imageError &&
               <span style={{color:"red"}}> Please upload group image
                </span>}
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="name"
          label="Group Name"
          className="name-row"
          rules={[
            {
              required: true,
              message: "Please enter group name",
            },
          ]}
        >
          <Input
            placeholder="Please input your name"
            className="custom-input"
          />
        </Form.Item>
        {type === 2 && (
          // <Form.Item
          //   {...formItemLayout}
          //   name="level"
          //   label="Skill Level"
          //   className="skill"
          //   value={level}
          // >
          //   <Select
          //     optionFilterProp="children"
          //     onChange={onChangeSkillLevel}
          //     filterOption={(input, option) =>
          //       (option?.label ?? "")
          //         .toLowerCase()
          //         .includes(input.toLowerCase())
          //     }
          //     size={"large"}
          //     className="select-input"
          //     options={[
          //       {
          //         value: "beginner",
          //         label: "Level 1: Beginners (age < 10)",
          //       },
          //       {
          //         value: "intermediate",
          //         label: "Level 2: Intermediate (age 11-15)",
          //       },
          //       {
          //         value: "advanced",
          //         label: "Level 3: Advance (age 16+)",
          //       },
          //     ]}
          //   />
          // </Form.Item>
          <>
        <Form.Item
        {...formItemLayout}
                  name="expiry"
                  label="Member Access Code Expiry"
                  rules={[
                    {
                      type: "number",
                      min: 1,
                      max: 10000,
                      message: "Month(s) must be between ${min} to ${max}.",
                    },
                    {
                      required:true,
                      message:"Please enter valid expiry"
                    }
                  ]}
                >
                  <InputNumber
                    placeholder="Enter expiry in months"
                    min={1}
                    max={10000}
                    style={{width:"100%"}}
                    className="number-input"
                    onKeyDown={(e) => e.key === "." && e.preventDefault()}
                  />
                </Form.Item>           
                <Form.Item {...formItemLayout} name="description" label="Short Description">
          <TextArea
            placeholder="Short description for group"
            autoSize={{
              minRows: 3,
              maxRows: 5,
            }}
          />
        </Form.Item> 
                </>
        )}
        {/* <Form.Item
          {...formItemLayout}
          name="start_date"
          label="Starting on"
          rules={[
            {
              required: false,
              message: "Please input your name",
            },
          ]}
        >
          <DatePicker
            className={"date-picker " + (selectedSport === 1 ? "orange-picker" : "blue-picker")}
            rootClassName={selectedSport === 1 ? "orange-picker" : "blue-picker"}
            format={dateFormat}
            suffixIcon={<img src={Calendar} />}
            style={{width:"100%",height:"38px"}}
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="expiry_date"
          label="Ending on"
          rules={[
            {
              required: false,
              message: "Please input your name",
            },
          ]}
        >
          <DatePicker
            className={"date-picker " + (selectedSport === 1 ? "orange-picker" : "blue-picker")}
            rootClassName={selectedSport === 1 ? "orange-picker" : "blue-picker"}
            format={dateFormat}
            suffixIcon={<img src={Calendar} />}
            style={{width:"100%",height:"38px"}}
          />
        </Form.Item> */}

        {/* <Divider />
        <h2>Sports</h2>
        <Form.Item
          {...formItemLayout}
          name="sport"
          label="Sport"
          className="sport-div"
          rules={[
            {
              required: true,
              message: "Please select sport",
            },
          ]}
        >
          <Radio.Group className="custom-radio">
            <>
              {sportsList.map(function (item, index) {
                return (
                  <Radio
                    key={item.id}
                    value={item.name}
                    defaultChecked="Basketball"
                    onChange={() => changeSport(item)}
                  >
                    {item.name}
                  </Radio>
                );
              })}
            </>
          </Radio.Group>
        </Form.Item> */}
        <Divider />
        <h2>Subscription</h2>
        <Form.Item
          {...formItemLayout}
          name="access"
          label="Access"
          className="access-div"
        >
          <Radio.Group className="custom-radio" onChange={onChangeSubscription}>
            <Radio value={false}>No Subscription Required</Radio>
            <Radio value={true}>Subscription Required</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label="Subscription Status"
          className="subscription-div"
        >
          {/* <span className="green-tag">Active</span>
          <span className="manage-subscription">Manage Subscription</span> */}
          {generalDetail.subscription_required === true &&
          generalDetail.subscription === null ? (
            <Button className="pink-tag">Inactive</Button>
          ) : generalDetail.subscription_required === true &&
            generalDetail.subscription !== null ? (
            <Button className="green-tag">Active</Button>
          ) : generalDetail.subscription_required === false &&
            generalDetail.subscription === null ? (
            <Button className="green-tag">Active</Button>
          ) : (
            <Button className="yellow-tag">none</Button>
          )}
          {/* {subscriptionStatus === "none" ? (
            <Button className="yellow-tag">None</Button>
          ) : subscriptionStatus === "active" ? (
            <Button className="green-tag">Active</Button>
          ) : (
            <Button className="pink-tag">Inactive</Button>
          )} */}
          <br />
          <div style={{ marginTop: "16px", display: "flex", gap: "8px" }}>
            {/* <Button
              className={selectedSport === 1 ? "orange-tag" : "blue-tag"}
              onClick={() => setNewSubscription(true)}
              disabled={subscriptionStatus === "none" ? false : true}
            >
              Create New Subscription
            </Button>
            <Button
              className={selectedSport === 1 ? "orange-tag" : "blue-tag"}
              disabled={
                subscriptionStatus === "none" ||
                subscriptionStatus === "inactive"
                  ? true
                  : false
              }
            >
              Edit Current Subscription
            </Button> */}
            <Button
              className={selectedSport === 1 ? "orange-tag" : "blue-tag"}
              // disabled={subscriptionStatus === "none" ? true : false}
              onClick={() => setSubscriptionHistory(true)}
            >
              Subscription History
            </Button>
          </div>
        </Form.Item>
        {selectedSport === 1 &&
        <Form.Item
          {...formItemLayout}
          name="bsa_credit"
          label="BSA Credit"
          className="bsa-credit-div"
        >
          <Radio.Group
            onChange={onChangeBsa}
            className="custom-radio"
            style={{ display: "block" }}
          >
            <Radio value={"none"}>No BSA Credit</Radio>
            <div style={{ display: "flex" }}>
              <Radio
                value={"one-time"}
                style={{ padding: "11px 0", width: 309 }}
              >
                Add one-time credits{" "}
              </Radio>
              <Select
                placeholder="Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                defaultValue={
                  generalDetail.bsa_frequency === "one time" ? bsaValue : null
                }
                popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
                value={bsaValueOneTime}
                style={{ marginBottom: "16px", width: 212 }}
                onChange={onChangeBsaSelect}
                disabled={bsaCredit !== 2}
                suffixIcon={<img src={DownArrow} />}
                size={"large"}
                className="select-input"
                options={oneTimeOptions}
              />
            </div>
            {/* {bsaCredit === 2 ? (
              <Select
                placeholder="Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                defaultValue={
                  generalDetail.bsa_frequency === "one time" ? bsaValue : null
                }
                value={bsaValue}
                style={{ marginBottom: "16px" }}
                onChange={onChangeBsaSelect}
                size={"large"}
                className="select-input"
                options={oneTimeOptions}
              />
            ) : null} */}
            <div style={{ display: "flex" }}>
              <Radio
                value={"monthly"}
                style={{ padding: "11px 0", width: 309 }}
              >
                Add credit each month (on “selected” start day)
              </Radio>
              {/* {bsaCredit === 3 ? ( */}
              <Select
                placeholder="Select"
                optionFilterProp="children"
                size={"large"}
                onChange={onChangeBsaSelect}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
                suffixIcon={<img src={DownArrow} />}
                disabled={bsaCredit !== 3}
                style={{ marginBottom: "16px", width: 212 }}
                defaultValue={
                  generalDetail.bsa_frequency === "monthly" ? bsaValue : null
                }
                value={bsaValueEachMonth}
                className="select-input"
                options={monthlyOptions}
              />
            </div>
            {/* ) : null} */}
          </Radio.Group>
        </Form.Item>
        }
        <Form.Item
          {...formItemLayout}
          name="members_limit"
          label="Members Limit"
          className="members-limit-div"
        >
          <Radio.Group
            onChange={onChangeLimit}
            value={seatLimit}
            className="custom-radio"
            style={{ width: "100%", display: "block" }}
          >
            <Radio value={false}>No Limit</Radio>
            <div style={{ display: "flex" }}>
              <Radio value={true}>Seat Limit</Radio>
              {/* {
              seatLimit === true && ( */}
              {/* // (generalDetail.member_limit < 10000 && */}
              <Input
                placeholder="Please input seat limit"
                className="custom-input"
                value={memberLimit}
                onChange={(e) => setMemberLimit(e.target.value)}
                disabled={seatLimit === false}
                style={{ height: 38 }}
              />
              {/* ) */}
              {/* // ) */}
              {/* } */}
            </div>
          </Radio.Group>
        </Form.Item>
        <Divider />
        <h2>Announcements</h2>
        <Form.Item
          {...formItemLayout}
          name="display_announcements_section"
          label="Display Announcements Section"
          className="type-div"
        >
          <Radio.Group className="custom-radio">
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        {/* <Form.Item
          {...formItemLayout}
          name="display_post_section"
          label="Display Posts Section"
          className="type-div"
        >
          <Radio.Group className="custom-radio">
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="allow_members_post"
          label="Who can post to the group?"
          className="type-div"
        >
          <Radio.Group className="custom-radio">
            <Radio value={"owners"}>Only Group Owners</Radio>
            <Radio value={"all"}>All (Group Owners and Members)</Radio>
          </Radio.Group>
        </Form.Item> */}
        <Form.Item
          {...formItemLayout}
          // name="accessible_leaderboards"
          label="Accessible Leaderboards"
          className="sport-div"
        >
          <Checkbox
            style={{ fontSize: "13px", marginRight: "32px" }}
            className="custom-checkbox"
            checked={workoutLeaderBoard}
            onChange={(e) => setWorkoutLeaderBoard(e.target.checked)}
          >
            Workouts/Challenges
          </Checkbox>
          {selectedSport === 1 &&
          <Checkbox
            style={{ fontSize: "13px", marginRight: "32px" }}
            className="custom-checkbox"
            checked={bsaLeaderBoard}
            onChange={(e) => setBsaLeaderBoard(e.target.checked)}
          >
            BSA
          </Checkbox>
          }
          {/* <Checkbox
            style={{ fontSize: "13px" }}
            className="custom-checkbox"
            checked={combineLeaderBoard}
            onChange={(e) => setCombineLeaderBoard(e.target.checked)}
          >
            Combine Measurements
          </Checkbox> */}
        </Form.Item>
        {/* <Form.Item
          {...formItemLayout}
          // name={"tags"}
          label="Tags"
        >
          <Select
            mode="tags"
            placeholder="Add tags..."
            className="select-input tag-select"
            value={tags}
            onChange={onChangeTags}
            suffixIcon={false}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item {...formItemLayout} name="comments" label="Comments">
          <TextArea
            placeholder="e.g. something about the group"
            autoSize={{
              minRows: 3,
              maxRows: 5,
            }}
          />
        </Form.Item> */}
        <Divider style={{ marginBottom: "30px" }} />

        <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
          <Button
            type="primary"
            onClick={() => setGeneralDetail(true)}
            className="discard-btn"
          >
            Discard
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              margin: "0 8px",background:darkButtonBackground
            }}
            className="dark-base-btn"
          >
            Save Changes
          </Button>
        </Form.Item>
      </Form>
      <SubscriptionModal
        setNewSubscription={setNewSubscription}
        setSubscriptionStatus={setSubscriptionStatus}
        newSubscription={newSubscription}
      />
      <SubscriptionHistory
        setSubscriptionHistory={setSubscriptionHistory}
        subscriptionHistory={subscriptionHistory}
      />
    </div>
  );
};
export default EditGroupGeneralDetails;
