import { Input, Progress, Select, Table, RangePicker } from "antd";
import React, { useContext, useEffect } from "react";
import { SubmissionContext } from "../../../context/submission-context/bsa_submission_context";
import DownArrow from "../../../assets/images/arrows.svg";
import Calendar from "../../../assets/images/calendar.svg";
import Search from "../../../assets/images/search.svg";
import { getDateWithoutDay } from "../../../util/config";
import { UserContext } from "../../../context/user-context";

const PendingUploads = () => {
  const {selectedSport} = useContext(UserContext)
  const {
    uploadsPageNumber,
    uploadsPageSize,
    uploadList,
    totalUploads,
    uploadsLoading,
    setUploadSort,
    setUploadList,
    setUploadSearch,
    setUploadStatus,
    getUploadsProgress,
    uploadSearch,
    uploadStatus,uploadSort,
    handleTableChange,setDraft,draft,setUploadsLoading
  } = useContext(SubmissionContext);
  const columns = [
    {
      title: "Player name",
      dataIndex: "username",
      key: "username",
      width: "30%",
      ellipsis: true,
      render: (text, record) => <span>{record?.username}</span>,
    },
    {
      title: "Submission date",
      dataIndex: "date",
      key: "date",
      width: "30%",
      render: (text, record) => (
        <span>{getDateWithoutDay(record.test_time)}</span>
      ),
    },
    {
      title: "Submission Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div style={{ width: "40%" }}>
            {record.status === 0 && record.is_draft === true && record.upload_progress === 0 ?
            <span className="orange1-tag" style={{marginLeft:0}} title="Created draft">Draft</span> 
            
            :
            record.status === 1 ? (
              <span
                className="yellow-tag"
                style={{ textTransform: "capitalize" }}
              >
                Transcoding
              </span>
            ) 
            // : record.status === 2 && record.is_draft === true && record.remarks === 'Days limit reached' ? 
            // (
            //   <span className="red-tag" style={{marginLeft:0,color:"red"}} title="Draft pending for more than three days">Draft</span>
            // )
            : 
            record.status === 2 ? (
              <span className="red-tag" style={{ textTransform: "capitalize" }} title={record.remarks === "Account logged out" ? "Draft deleted due to logout" : decodeURI("Video has not uploaded for more than three days.%0APlease relaunch the application to begin the video uploading.") }>
                Failed
              </span>
            ) : record.status === 0 ? (
              <Progress
                percent={record.upload_progress}
                className={selectedSport === 1 ? "orange-progress-bar" : "blue-progress-bar" }
                showInfo
              />
            ) : (
              <span className="orange1-tag" style={{marginLeft:0}} title="Created draft">Draft</span>
            )
          }
        </div>
      ),
    },
  ];
  useEffect(() => {
    const intervalCall = setInterval(() => {
      getUploadsProgress();
    }, 10000);
    return () => {
      clearInterval(intervalCall);
    };
  }, );

  useEffect(()=>{
    setUploadsLoading(true);
    getUploadsProgress()
  },[uploadSearch,uploadStatus,uploadSort,draft])
  const rowSelection = {
    // selectedRowKeys,
    // onChange: onSelectChange,
  };
  const searchVideo = (e) => {
    if (e.target.value.length >= 3) {
      // setPageNumber(1)
      setUploadList([]);
      setUploadSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setUploadList((submissionsList) => []);
      setUploadSearch("");
    }
  };
  return (
    <div className="table-div">
      <div style={{ display: "flex" }}>
        <Input
          addonBefore={<img src={Search} />}
          placeholder="Search"
          className="search-input"
          onChange={searchVideo}
        />
        <div className="right-div">
          <Select
            placeholder="Newest"
            optionFilterProp="children"
            suffixIcon={<img src={DownArrow} />}
            className="select-input"
            popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
            onChange={(e) => setUploadSort(e)}
            options={[
              {
                value: true,
                label: "Newest",
              },
              {
                value: false,
                label: "Oldest",
              },
            ]}
          />
          <Select
            placeholder="Status"
            optionFilterProp="children"
            suffixIcon={<img src={DownArrow} />}
            className="select-input"
            popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
            onChange={(e) => {
              if(e === true){
                setDraft(e)
              }else{
                setDraft(false)
              setUploadStatus(e)
              }
            }
          }
            options={[
              {
                value: "",
                label: "All",
              },
              {
                value: "0",
                label: "Progress",
              },
              {
                value: "2",
                label: "Failed",
              },
              // {
              //   value: "1",
              //   label: "Transcoding",
              // },
              {
                value : true,
                label : "Draft"
              }
            ]}
          />
        </div>
      </div>
      <Table
        onChange={handleTableChange}
        rowKey={(obj) => obj.id}
        // rowSelection={rowSelection}
        pagination={{
          pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
          showSizeChanger: true,
          locale: { items_per_page: "" },
          defaultPageSize: 10,
          // current: uploadsPageNumber,
          // pageSize: uploadsPageSize,
          // total: totalUploads,
        }}
        loading={uploadsLoading}
        columns={columns}
        dataSource={uploadList}
        className="table-container uploads-table"
      />
    </div>
  );
};

export default PendingUploads;
