import React, { useContext } from "react";
import Logo from "../../assets/images/logo.svg";
import { Row, Col, Form, Input, Button } from "antd";
import "./signIn.scss";
import {
  SigninContext,
  SigninContextProvider,
} from "../../context/signin-context";
import { UserContext } from "../../context/user-context";
import { useNavigate } from "react-router-dom";
const ChangePasswordPage = () => {
  const { darkButtonBackground } = useContext(UserContext);
  const [form] = Form.useForm();
  const { changePassword } = useContext(SigninContext);
  return (
    <Row
      className="signin-main"
      style={{
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "#F7F8FA",
      }}
    >
      <div className="form-div">
        <img src={Logo} />
        <h1>Choose a new Password</h1>
        <Form form={form} name="dynamic_rule" onFinish={changePassword}>
          {" "}
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter password",
              },
            ]}
          >
            <Input.Password
              placeholder="Password"
              type="password"
              className="custom-input"
            />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            rules={[
              {
                required: true,
                message: "Please enter confirm password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
            dependencies={["password"]}
          >
            <Input.Password
              placeholder="Confirm Password"
              type="password"
              className="custom-input"
            />
          </Form.Item>
          <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
            <Button
              htmlType="submit"
              className="dark-base-btn"
              style={{ background: darkButtonBackground }}
            >
              Change Password
            </Button>
          </Form.Item>
        </Form>{" "}
      </div>
    </Row>
  );
};

const ChangePassword = () => {
  return (
    <SigninContextProvider>
      <ChangePasswordPage />
    </SigninContextProvider>
  );
};
export default ChangePassword;
