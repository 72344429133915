import React, { useEffect, useState } from "react";
import { message, Form, Button, Modal, Select, Dropdown, Menu } from "antd";
import { getData, formPostData, getDataTraining } from "../../util/api-calls";
const { confirm } = Modal;
export const TaskContext = React.createContext({});
export const TaskContextProvider = ({ children = {} }) => {
const [loading , setLoading] = useState(false)
const [taskType, setTaskType] = useState("ballogy");
const [skillLevel, setSkillLevel] = useState("beginner,intermediate,advanced");
const [sort, setSort] = useState(true);
const [selectedCategory, setSelectedCategory] = useState("");
const [allTasks , setAllTasks] = useState([])
const [search , setSearch] = useState("")
const getTasksList = () =>{
    setLoading(true);
    let url = ""
    if(skillLevel.length === 0){
      url =  `admin/task?tag=${
        selectedCategory === null ? "" : selectedCategory
      }&task_filter=${taskType}&sort_desc=${sort}&search=${search}`
    }else{
      url = `admin/task?skill_level=${skillLevel}&tag=${
        selectedCategory === null ? "" : selectedCategory
      }&task_filter=${taskType}&sort_desc=${sort}&search=${search}`
    }
    getDataTraining(url)
      .then((res) => {
        setAllTasks(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
}
  React.useEffect(()=>{
    // getTasksList();
  } , [sort
]) 
  const defaultContext = {
    getTasksList,
    allTasks,
    setSort,loading,
    sort,
    setAllTasks,
    search,
    setSearch
  };
  return (
    <TaskContext.Provider value={defaultContext}>
      {children}
    </TaskContext.Provider>
  );
};
