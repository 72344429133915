import React, { useContext } from "react";
import { Row, Form, Input, Button } from "antd";
import CryptoJs from "crypto-js";
import "./signIn.scss";
import Logo from "../../assets/images/logo.svg";

import { jsonPostDataCoach } from "../../util/coach-api-calls";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/user-context";
import { message } from "antd/es";
import {
  SigninContext,
  SigninContextProvider,
} from "../../context/signin-context";
const OtpVerificationPage = () => {
  const { verifyOtp } = useContext(SigninContext);
  const { setUserAccessToken, setUser, darkButtonBackground } =
    useContext(UserContext);
  const navigate = useNavigate();
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }

  const [form] = Form.useForm();
  return (
    <Row
      className="verification-main"
      style={{
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="form-div">
        <img src={Logo} />
        <h1 style={{ padding: "25px 0" }}>Reset Password</h1>
        <span className="span" style={{ padding: 0 }}>
          An email with an OTP has been sent to
        </span>
        {params.email && (
          <span className="mail">
            {params.email.replace(
              /^(.)(.*)(.@.*)/,
              (...a) => a[1] + a[2].replace(/./g, "*") + a[3]
            )}
          </span>
        )}
        <span className="span" style={{ padding: 0 }}>
          Please enter it to reset your password.
        </span>
        <Form form={form} name="dynamic_rule" onFinish={verifyOtp}>
          {" "}
          <Form.Item
            name="otp"
            label=""
            className="name-row"
            rules={[
              {
                required: true,
                message: "Please enter otp.",
              },
            ]}
          >
            <Input.OTP formatter={(str) => str.toUpperCase()} />
          </Form.Item>
          <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
            <Button
              htmlType="submit"
              className="dark-base-btn"
              style={{ background: darkButtonBackground }}
            >
              Next
            </Button>
          </Form.Item>
        </Form>{" "}
      </div>
    </Row>
  );
};
const OtpVerification = () => {
  return (
    <SigninContextProvider>
      <OtpVerificationPage />
    </SigninContextProvider>
  );
};
export default OtpVerification;
