import React, { useContext, useEffect, useState } from "react";
import { deleteItemTraining, getDataTraining, jsonPostDataTraining } from "../../util/api-calls";
import { UserContext } from "../user-context";
import { message } from "antd";
export const FWSubmissionDetailContext = React.createContext({});
export const FWSubmissionDetailContextProvider = ({ children = {} }) => {
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    const entries = urlParams.entries();
    const params = paramsToObject(entries);
    function paramsToObject(entries) {
      const result = {};
      for (const [key, value] of entries) {
        result[key] = value;
      }
      return result;
    }
  
    const { selectedNotification  , notificationProfileId , setNotificationProfileId} = useContext(UserContext);
    const [sort, setSort] = useState(false);
    const [search, setSearch] = useState("");
    const [FWDetail , setFWDetail] = useState({})
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedTask , setSelectedTask] = useState({})
    const [selectedProfile , setSelectedProfile] = useState({})
    const [taskDetail , setTaskDetail] = useState([])
    const [loading , setLoading] = useState(false)
    const [submissionLoading , setSubmissionLoading] = useState(false)
    const [status , setStatus] = useState("all")
    const [taskSort , setTaskSort] = useState(true)
    const [taskOffset , setTaskOffset] = useState(0);
    const [taskSearch, setTaskSearch] = useState("");
    const [submission , setSubmission] = useState({})
    const [selectedSubmission , setSelectedSubmission] = useState("")
    const [submissionDetail , setSubmissionDetail] = useState({})
    const [submissionDetailModal , setSubmissionDetailModal ] = useState(false)
    const [score , setScore] = useState()
    const [gradeInput, setGradeInput] = useState(false)
    const [pageNumber,setPageNumber] = useState(1)
    const FWSubmissionDetail = () => {
        getDataTraining(`admin/feature/workout/${params.id}?search=${search}&order_desc=${sort}&offset=${offset}&limit=${limit}`)
        .then((res)=>{
            setFWDetail(res.data)
        }).catch((err)=>{
            console.log(err)
        })
    }
    const getSingleSubmission = (id) => {
        setLoading(true)
        getDataTraining(`admin/feature/task/${selectedTask.id ? selectedTask.id : selectedNotification.feature_task_id}?search=${taskSearch}&order_desc=${taskSort}&status_filter=${status}&start_date=${startDate}&end_date=${endDate}&offset=${taskOffset}&limit=${limit}`)
        .then((res)=>{
            setTaskDetail(res.data)
            if(selectedNotification){
              setNotificationProfileId(res.data[0].profile_id)
              getProfileSubmissions(res.data[0].profile_id)
            }
        }).catch((err)=>{
            console.log(err)
        }).finally(()=>{
            setLoading(false)
            
        })
    }
    const getProfileSubmissions = (id) => {
      getDataTraining(`admin/feature/task/${selectedTask.id ? selectedTask.id : selectedNotification.feature_task_id}/profile/${selectedProfile.profile_id ? selectedProfile.profile_id : notificationProfileId ? notificationProfileId :  id}/submissions`)
      .then((res)=>{
        setSubmission(res.data)
      }).catch((err)=>{
        console.log(err)
      })
    }
    const getSubmissionDetail = (e) => {
      setSubmissionLoading(true)
      getDataTraining(`feature/task/submission/media/${selectedSubmission === "" ? e : selectedSubmission}`)
      .then((res)=>{
        setSubmissionDetail(res.data)
      }).catch((err)=>{
        console.log(err)
      }).finally(()=>{
        setTimeout(() => {
          setSubmissionLoading(false)
        }, 3000);
      })
    }
    const invalidateSubmission = (valid ,profileId) => {
      let data= {
        "fw_task_id": submissionDetail.feature_task_id,
        "fw_task_media_id": submissionDetail.id,
        "is_validate": valid,
        "score": valid === false ? 0 : score,
    }
      jsonPostDataTraining(`review/feature/task`,data).then((res)=>{
        getSubmissionDetail()
        setSelectedSubmission("")
        setGradeInput(false)
        setSubmissionDetailModal(false);
        getProfileSubmissions();
      //   getSubmissions(profileId,taskDetail.id)
        setScore()
      }).catch((err)=>{
        console.log(err)
      }) 
    }
    const videoPlayed = (valid) => {
      let data= {
        "fw_task_id": submissionDetail.feature_task_id,
        "fw_task_media_id": submissionDetail.id,
        "is_validate": valid,
        "score": submissionDetail?.score ? submissionDetail?.score : 0,
    }
      jsonPostDataTraining(`review/feature/task`,data).then((res)=>{
        getSubmissionDetail()
        getProfileSubmissions();
      }).catch((err)=>{
        console.log(err)
      }) 
    }
    useEffect(()=>{
        FWSubmissionDetail()
    },[sort,search ,  limit  , offset])
    const deleteVideo = () => {
      deleteItemTraining(`delete/feature/task/${submissionDetail.feature_task_id}/submission/${submissionDetail.id}`).then((res)=>{
        console.log(res)
        message.success({
          content:"Video deleted successfully."
        })
        setSubmissionDetailModal(false);
        getProfileSubmissions();
      }).catch((err)=>{
        console.log(err)
      })
    }
    useEffect(()=>{
      if(selectedNotification?.feature_task_id){
        getSingleSubmission()
        setSubmissionDetailModal(true);
        getSubmissionDetail(selectedNotification?.submission_id)
      }
    },[selectedNotification])
    const defaultContext = {
        setSearch,
        setSort,
        FWDetail,
        setFWDetail,
        setStartDate,
        setEndDate,
        setOffset,
        limit,
        offset,
        getSingleSubmission,
        params,
        setSelectedTask,
        selectedTask,
        taskDetail,
        loading,
        taskSort,
        taskOffset,
        setTaskOffset,
        setStatus,
        setTaskSort,
        status,
        taskSearch,
        setTaskSearch,
        setTaskDetail,
        startDate,
        endDate,
        getProfileSubmissions,
        setSelectedProfile,
        selectedProfile,
        submission,
        selectedSubmission , 
        setSelectedSubmission,
        submissionDetail,
        getSubmissionDetail,
        submissionDetailModal ,
        setSubmissionDetailModal,
        score,
        setScore,
        gradeInput,
        setGradeInput,
        invalidateSubmission,
        videoPlayed,
        setSubmissionDetail,
        deleteVideo,
        pageNumber,
        setPageNumber,
        submissionLoading

    };
  return (
    <FWSubmissionDetailContext.Provider value={defaultContext}>
      {children}
    </FWSubmissionDetailContext.Provider>
  );
};
