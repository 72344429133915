import React, { useContext, useState } from "react";
import { Button, Form, Input, Modal, Radio } from "antd";
import { SubmissionDetailContext } from "../../../context/submission-context/bsa_submission_detail_context";
import { UserContext } from "../../../context/user-context";

const { TextArea } = Input;
const ReportModal = ({ reportModal, setReportModal }) => {
  const {darkButtonBackground , selectedSport} = useContext(UserContext)
  const [form] = Form.useForm();

  const { setInvalidMessage, postInvalidReview, invalidMessage } = useContext(
    SubmissionDetailContext
  );
  const [invalidReason, setInvalidReason] = useState();
  const handleOk = () => {
    postInvalidReview();
    setReportModal(false);
  };
  const handleCancel = () => {
    form.resetFields();
    setReportModal(false);
  };
  const onChange = (e) => {
    if (e.target.value !== 4) {
      form.setFieldValue("message", "");
      setInvalidMessage(e.target.value.toString());
    }
    setInvalidReason(e.target.value);
  };
  return (
    <Modal
      className="report-modal"
      wrapClassName={"report-modal " + (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")}
      open={reportModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Report"}
      cancelText={"Cancel"}
      footer={false}
      width={"624px"}
    >
      <h1>Report as Invalid</h1>
      <p>
        By reporting this test invalid, the player will receive a notification
        the justification you choose below
      </p>
      <Form form={form}>
        <Form.Item name="radio">
          <Radio.Group
            onChange={onChange}
            // value={value}
            // defaultValue={value}
            className="custom-radio"
          >
            <Radio value={1}>
              Could not determine the make/miss for all the shots attempted in
              the video.
            </Radio>
            <Radio value={2}>
              Player did not shoot from appropriate locations/distances
            </Radio>
            <Radio value={3}>
              Player did not perform the correct sequence or movements as
              described in test instructions
            </Radio>
            <Radio value={4}>Custom response</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name={"message"}>
          <TextArea
            // value={commentValue[[item.id]]}
            // onChange={handleCommentValue}
            onChange={(e) => setInvalidMessage(e.target.value)}
            placeholder="Your response here..."
            autoSize={{
              minRows: 8,
              maxRows: 9,
            }}
            disabled={invalidReason !== 4}
            // onKeyPress={enterCommentLine}
          />
        </Form.Item>
        <Form.Item style={{ textAlign: "end" }}>
          <Button
            htmlType="submit"
            type="default"
            className="discard-btn"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              margin: "0 8px",
              opacity: invalidMessage === "" && 0.3,background : darkButtonBackground
            }}
            onClick={() => handleOk()}
            className="dark-base-btn"
            disabled={invalidMessage === ""}
          >
            Report
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReportModal;
