import React from "react";


const Dashboard = () =>{
    return(
        <div>
            Hello
        </div>
    )
}

export default Dashboard;