import React, { useContext } from "react";
import { jsonPostData } from "../util/api-calls";
import CryptoJs from "crypto-js";
import { UserContext } from "./user-context";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

export const SigninContext = React.createContext({});
export const SigninContextProvider = ({ children = {} }) => {
  const navigate = useNavigate();
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const { setUserAccessToken, setUser } = useContext(UserContext);
  const signIn = (e) => {
    let formData = new FormData();
    formData.append("email", e.email);
    formData.append("password", e.password);
    jsonPostData(`admin/login`, formData)
      .then((res) => {
        setUser(res.data.user);
        setUserAccessToken(res.data.access_token);
        localStorage.setItem(
          "u_info",
          CryptoJs.AES.encrypt(JSON.stringify(res.data), "sec").toString()
        );
        localStorage.setItem("u_id", res.data.user.id);
        navigate("/home/users");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err)
        message.error({
          content:err.response.data.message
        })
      });
  };

  const resetPassword = (e) => {
    let data = {
      email: e.email,
      send_otp: true,
    };
    jsonPostData(`admin/reset/password`, data)
      .then((res) => {
        message.success({
          content: res.data.details,
        });
        navigate({
          pathname: `/otp-verification`,
          search: `?email=${e.email}`,
        });
      })
      .catch((err) => {
        console.log(err);
        message.error({
          content: err.response.data.message,
        });
      });
  };
  const verifyOtp = (e) => {
    let data = {
      email: params.email,
      send_otp: false,
      otp: e.otp,
    };
    jsonPostData(`admin/reset/password`, data)
      .then((res) => {
        message.success({
          content: res.data.details,
        });
        navigate({
          pathname: `/change-password`,
          search: `?email=${params.email}`,
        });
      })
      .catch((err) => {
        console.log(err);
        message.error({
          content: err.response.data.message,
        });
      });
  };
  const changePassword = (e) => {
    let data = {
      email: params.email,
      send_otp: false,
      password: e.password,
      confirm_password: e.confirm_password,
    };
    jsonPostData(`admin/reset/password`, data)
      .then((res) => {
        message.success({
          content: res.data.details,
        });
        navigate({
          pathname: `/`,
        });
      })
      .catch((err) => {
        console.log(err);
        message.error({
          content: err.response.data.message,
        });
      });
  };
  React.useEffect(() => {}, []);
  const defaultContext = {
    signIn,
    resetPassword,
    verifyOtp,
    changePassword,
  };
  return (
    <SigninContext.Provider value={defaultContext}>
      {children}
    </SigninContext.Provider>
  );
};
