import ReactPlayer from "react-player";
import { UserContext } from "../../../context/user-context";
import React, { useContext, useEffect, useState } from "react";
import "./fw-detail-modal.scss";
import SortableList, { SortableItem } from "react-easy-sort";
import { FaArrowLeftLong } from "react-icons/fa6";
import {
  Button,
  Input,
  Checkbox,
  Switch,
  Modal,
  Col,
  Row,
  Card,
  Image,
  Skeleton,
  Spin
} from "antd";
import Avatar from "react-avatar";
import { convertSeconds, getDate } from "../../../util/config";
import { useNavigate } from "react-router-dom";
import { FWDetailContext } from "../../../context/group-context/FW-detail-context";
const FWDetailModal = ({ fWDetailModal, setFWDetailModal, generalDetail }) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }

  const { selectedSport, color, darkButtonBackground } =
    useContext(UserContext);
  const { fwData, setFwData , loading } = useContext(FWDetailContext);
  const [imageLoaded, setImageLoaded] = useState("");
  const [membersView, setMembersView] = useState(false);
  const handleOk = () => {
    setFWDetailModal(false);
  };
  const handleCancel = () => {
    setFwData({});
    setFWDetailModal(false);
    setSelectedTask({});
  };
  useEffect(()=>{
    if(fWDetailModal === false){
      setFwData({});
      setSelectedTask({});
    }
  } , [fWDetailModal])
  const navigate = useNavigate();
  const [selectedTask, setSelectedTask] = useState({});
  const [url, setUrl] = useState("");
  return (
    <Modal
      className="task-detail-modal"
      wrapClassName={
        "task-detail-modal " +
        (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")
      }
      open={fWDetailModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Report"}
      cancelText={"Cancel"}
      width={"1300px"}
      footer={false}
    >
            {loading && loading == true ? (
          <Spin
            className={"loader " + (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")}
            size="large"
            style={{
              position: "absolute",
              left: "49%",
              top:"50%"
            }}
          />
        ) : (
      <div className="fw-main">
        <Row style={{ flexFlow: "nowrap" }}>
          <div style={{ width: "55%", borderRight: "3px solid #f1f1f2" }}>
            <div className="heading-row">
              <FaArrowLeftLong
                className="left-arrow"
                alt=""
                style={{ stroke: color, color: color }}
              />
              <h1 className="workout-title">{fwData?.title}</h1>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: 24 }}>
                {fwData.image !== null && imageLoaded === "" && (
                  <Skeleton.Image
                    active={true}
                    style={{ width: "120px", height: "120px" }}
                  />
                )}

                {fwData.image === null ? (
                  <Avatar
                    name={fwData.title}
                    color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                    maxInitials={2}
                    className="group-avatar"
                  />
                ) : (
                  <img
                    src={fwData.image}
                    className="group-logo"
                    style={
                      imageLoaded === ""
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    onLoad={() => setImageLoaded("loaded")}
                  />
                )}
              </div>
              <div className="presented-by">
                Presented by
                <h3>{fwData?.presented_by ? fwData.presented_by : "N/A"}</h3>
                <h4>
                  {fwData?.presented_by_link ? fwData.presented_by_link : "N/A"}
                </h4>
              </div>
            </div>
            <div className="workout-overview">
              <h3>Workout Overview</h3>
              {/* } */}
              <div className="total">
                Total Tasks
                <span>{fwData?.total_tasks}</span>
                Total Reps
                <span>{fwData?.total_reps}</span>
              </div>
              <div className="total">
                Ends <span>{getDate(fwData?.ending_on)}</span>
              </div>
              <div className="total">
                Earn upto{" "}
                <span style={{ marginRight: 0 }}>{fwData?.points}</span> Legacy
                Points
              </div>

              <SortableList className="list" draggedItemClassName="dragged">
                {fwData.tasks &&
                  fwData.tasks
                    .filter(
                      (ele, ind) =>
                        ind ===
                        fwData.tasks.findIndex((elem) => elem.id === ele.id)
                    )
                    .map((item, index) => {
                      console.log(item.media_thumbnail);
                      return (
                        <SortableItem key={index}>
                          <div
                            className="overview-card"
                            style={{ listStyleType: "none" }}
                          >
                            <div style={{ display: "flex" }}>
                              <h3
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setSelectedTask(item);
                                  setUrl(item.media_url);
                                }}
                              >
                                {item.title}
                              </h3>
                            </div>
                            <div className="form-data" style={{ margin: 0 }}>
                              <div className="reps">
                                <Input
                                  addonBefore={"Reps"}
                                  defaultValue={item.num_reps}
                                  className="reps-input"
                                  name={"num_reps"}
                                  min={1}
                                  value={item.num_reps > 0 ? item.num_reps : ""}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="extra">
                              {index !== 0 && (
                                <div className="lock-div">
                                  Lock this task
                                  <Switch
                                    defaultChecked={item.lock}
                                    checked={item.lock}
                                    name="lock"
                                    disabled
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </SortableItem>
                      );
                    })}
              </SortableList>
            </div>
          </div>
          <div
            style={{
              paddingLeft: 50,
              width: "45%",
            }}
          >
            <Button
              style={{ background: darkButtonBackground, color: "#fff" }}
              className="dark-base-btn"
              onClick={() =>
                navigate({
                  pathname: `../assigned-workout`,
                  search: `id=${params.id}&fw_id=${fwData?.id}&group_name=${generalDetail.name}`,
                })
              }
            >
              Select Workout
            </Button>
            {Object.keys(selectedTask).length > 0 && (
              <div className="task-detail">
                <Row style={{ gap: 24, marginTop: 85 }}>
                  <span className="span">Task Details</span>
                  <h1 className="task-title">
                    {selectedTask.title} ({selectedTask.skill_level})
                  </h1>
                  <Row>
                    <Col span={10} className="label-col">
                      <h3>Task type</h3>
                    </Col>
                    <Col span={14} className="data-col">
                      <h3>
                        {selectedTask.score_based_test === true
                          ? "Score Task"
                          : "Training Task"}
                      </h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10} className="label-col">
                      <h3>Time limit</h3>
                    </Col>
                    <Col span={14} className="data-col">
                      <h3>{convertSeconds(selectedTask.time_limit)}</h3>
                    </Col>
                  </Row>
                  {/* )} */}
                  <Row>
                    <Col span={10} className="label-col">
                      <h3>Rewards</h3>
                    </Col>
                    <Col span={14} className="data-col">
                      <h3>
                        1 rep earns{" "}
                        <span style={{ color: "#181C32" }}>
                          {selectedTask.legacy_points}
                        </span>{" "}
                        Legacy Points
                      </h3>
                    </Col>
                  </Row>
                  {selectedTask.media_type && (
                    <>
                      <Col span={24} className="data-col">
                        <h3
                          style={{
                            fontSize: 18,
                            fontWeight: 600,
                            marginBottom: 8,
                          }}
                        >
                          Media
                        </h3>
                      </Col>
                      <Col span={24} className="label-col">
                        <Card hoverable className="media-card">
                          {selectedTask.media_type === "image" ? (
                            <div
                              style={{
                                overflow: "hidden",
                                height: "150px",
                                borderRadius: 8,
                              }}
                            >
                              <Image
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                                className="img"
                                src={selectedTask.image}
                              />
                            </div>
                          ) : (
                            <ReactPlayer url={url} controls key={url} />
                          )}
                        </Card>
                      </Col>
                    </>
                  )}
                  <Col span={24} className="data-col">
                    <h3 style={{ fontSize: 14 }}>
                      Watch the video above and follow the instructions exactly
                      as presented{" "}
                    </h3>
                  </Col>
                  {/* <Row>
                  <Col span={6} className="label-col">
                    <h3>Step 1</h3>
                  </Col>
                  <Col span={18} className="data-col">
                    <h3>Watch/read the instructions above</h3>
                  </Col>
                </Row>
                {selectedTask.media_required === true && (
                  <Row>
                    <Col span={6} className="label-col">
                      <h3>Step 2</h3>
                    </Col>
                    <Col span={18} className="data-col">
                      <h3>
                        Record yourself completing the task and upload your
                        video.*
                      </h3>
                    </Col>
                  </Row>
                )} */}
                </Row>
              </div>
            )}
          </div>
        </Row>
      </div>
        )}
    </Modal>
  );
};

export default FWDetailModal;
