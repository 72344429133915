// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sport-modal h1 {
  margin: 0;
  color: var(--gray-gray-900, #181c32);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.22px;
  margin-left: 16px;
  text-align: center;
  margin: 20px 0 30px;
}
.sport-modal .ant-col-24 {
  text-align: left;
}
.sport-modal .ant-col-24 label {
  color: var(--gray-gray-700, #5E6278);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.sport-modal .ant-modal-footer .ant-btn-default, .sport-modal .ant-modal-footer .ant-btn-default:hover {
  width: 84px;
  border-radius: 6px;
  padding: 12px 16px;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  height: 38px;
  border: none;
}
.sport-modal .ant-modal-footer .ant-btn-primary, .sport-modal .ant-modal-footer .ant-btn-primary:hover {
  border-radius: 6px;
  padding: 12px 16px;
  height: 38px;
  width: 120px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/admin-module/Groups/owners-members/expiry-modal/expiry-modal.scss"],"names":[],"mappings":"AACI;EACI,SAAA;EACA,oCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AAAR;AAII;EACI,gBAAA;AAFR;AAIQ;EACI,oCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAFZ;AAOQ;EACI,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,YAAA;AALZ;AAQQ;EACI,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;AANZ","sourcesContent":[".sport-modal {\n    h1 {\n        margin: 0;\n        color: var(--gray-gray-900, #181c32);\n        font-size: 22px;\n        font-style: normal;\n        font-weight: 600;\n        line-height: 22px;\n        letter-spacing: -0.22px;\n        margin-left: 16px;\n        text-align: center;\n        margin: 20px 0 30px;\n\n    }\n\n    .ant-col-24 {\n        text-align: left;\n\n        label {\n            color: var(--gray-gray-700, #5E6278);\n            font-size: 16px;\n            font-style: normal;\n            font-weight: 500;\n            line-height: 16px;\n        }\n    }\n\n    .ant-modal-footer {\n        .ant-btn-default , .ant-btn-default:hover {\n            width: 84px;\n            border-radius: 6px;\n            padding: 12px 16px;\n            font-size: 13px;\n            font-style: normal;\n            font-weight: 700;\n            line-height: 14px;\n            height: 38px;\n            border: none;\n        }\n\n        .ant-btn-primary , .ant-btn-primary:hover {\n            border-radius: 6px;\n            padding: 12px 16px;\n            height: 38px;\n            width: 120px;\n            font-size: 13px;\n            font-style: normal;\n            font-weight: 600;\n            line-height: 14px;\n            border: none;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
