import { Button, Divider, Modal, Input } from "antd";
import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import Profile from "../../../assets/images/avatar.svg";
import ReactPlayer from "react-player";
import { WorkoutDetailContext } from "../../../coach-context/workouts-context/workout-detail-context";
import { getDateWithTimeWithoutDay } from "../../../util/config";
import Avatar from "react-avatar";
import { TwilioService } from "../../../context/twilio-service";
import dayjs from "dayjs";
import { Popconfirm, Spin } from "antd/es";
import { UserContext } from "../../../context/user-context";
const { TextArea } = Input;
const SubmissionDetailModal = ({ generalDetail , submissionData}) => {
  const { accountDetail , darkButtonBackground , color , lightButtonBackground , selectedSport} = useContext(UserContext)
  const chatClientChannel = useRef(null)
  const { submissionDetailModal , setSubmissionDetailModal , submissionDetail, invalidateSubmission, score, setScore, gradeInput, setGradeInput, getChat, commentsData, addComment, commentValue, setCommentValue, setSelectedSubmission, setSubmissionDetail , videoPlayed , selectedWorkout } = useContext(WorkoutDetailContext)
  const [commentList, setCommentList] = useState([])
  const [newListnerMessage, setNewListnerMessage] = useState(null);
  const [imageLoaded, setImageLoaded] = useState("");

  const handleCommentValue = (e) => {
    setCommentValue(e.target.value);
  };

  useEffect(() => {
    if (submissionDetail) {
      setScore(submissionDetail.score)
    }
  }, [submissionDetail])

  useEffect(() => {
    const isExist = commentList?.findIndex(
      element => element?.id == newListnerMessage?.id
    )
    if (isExist == -1 && submissionDetail && submissionDetailModal) {
      let commentListArray = [...commentList, newListnerMessage]
      getChat();
      if (commentList?.length > 0) {
        setCommentList(commentListArray)
      }
    }
  }, [newListnerMessage, submissionDetail])
  const setChannelEvents = useCallback(channel => {
    chatClientChannel.current = channel
    chatClientChannel?.current.on("messageAdded", message => {
      const newMessage = TwilioService.getInstance().parseMessage(message)
      // console.log("New ,essages", message , newMessage)
      setNewListnerMessage({
        text: message?.state?.body,
        created_at: message?.state?.timestamp,
        id: message?.state?.attributes?.comment_id,
        announcement: message?.state?.attributes?.announcement_id,
        profile: {
          id: message?.state?.attributes?.sender_id,
          name: message?.state?.attributes?.sender_username,
          avatar: message?.state?.attributes?.sender_avatar
        }
      })
    })
    return chatClientChannel.current
  }, [])
  useEffect(() => {
    if (commentsData) {
      configureChannelEvent(commentsData.access_token, commentsData.twilio_sid)
      setCommentList(commentsData?.comments)
    }
  }, [commentsData])



  const configureChannelEvent = async (token, twilioSid) => {
    try {
      const client = await TwilioService.getInstance().getChatClient(token)
      try {
        const channel = await client.getChannelBySid(twilioSid)
        const currentChannel = await setChannelEvents(channel)
        console.log("Response", channel);
      } catch (error) {
        console.log("Inner Error", error)
      } finally {
        // setLoading(false);
      }
    } catch (error) {
      console.log("Error", error)
    }
  }
  const handleMessageAdded = () => {
    // Alert.
    // alert("Remove")
  }

  useEffect(() => {
    return () => {
      if (chatClientChannel?.current) {
        chatClientChannel?.current?.removeListener(
          "messageAdded",
          handleMessageAdded
        )
        TwilioService.getInstance().clientShutdown()
      }
    }
  }, [])
  const cancelModal = () => {
    setSubmissionDetailModal(false);
     setSubmissionDetail({});
      setSelectedSubmission("")
    if(submissionDetail.media_required === false && selectedWorkout?.is_fw === false){
      videoPlayed(true)
    }
  }
  return (
    <Modal
      className="submission-detail-modal"
      rootClassName={"submission-detail-modal "+ (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")}
      open={submissionDetailModal}
      // onOk={handleOk}
      onCancel={() =>cancelModal()}
      okText={"Add & Next"}
      cancelText={"Cancel"}
      width={"1000px"}
      footer={false}
      destroyOnClose={true}
    >
      {Object.keys(submissionDetail)?.length > 0 ?
        <div>
          <div style={{ display: "flex" }}>
          {submissionDetail?.profile?.avatar === null ? (
              <Avatar
                name={submissionDetail?.profile?.name}
                color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                maxInitials={2}
                className="profile-avatar"
              />
            ) : (
              <img
                src={submissionDetail?.profile?.avatar}
                className="profile-logo"
                style={
                  imageLoaded === ""
                    ? { display: "none" }
                    : { display: "block" }
                }
                onLoad={() => setImageLoaded("loaded")}
              />
            )}
            <div className="name-div">
              <h3 className="user-name">
                {submissionDetail.profile?.name}
              </h3>
              <h4 className="user-handle">@{submissionDetail.profile?.username}
              </h4>
            </div>
            <div style={{ marginLeft: "auto" }} className="submitted-date">
              <h3 className="user-name">
                Submitted
              </h3>
              <h4 className="user-handle">
                {getDateWithTimeWithoutDay(submissionDetail?.created_at)}
              </h4>
            </div>
          </div>
          {submissionDetail?.media_required === true &&
            <ReactPlayer
              url={submissionDetail?.task_media}
              controls
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "cover", textAlign: "center" }}
              className="submission-video"
              // light={submissionDetail.media_thumbnail}
              onPlay={()=>submissionDetail.is_scored_task === false && selectedWorkout?.is_fw === false ? videoPlayed(true) : false}
            />
          }
          <div className="submitted-task-detail" style={{ marginTop: 30 }}>
            <div className="task-header">
              {!submissionDetail.is_scored_task ? <h2>Training Task</h2> :
                <h2>Scored Task</h2>}
              {submissionDetail.is_scored_task === true && gradeInput === true && submissionDetail.is_validated !== false && selectedWorkout?.is_fw === false ?
                <div className="grade-form" style={{ margin: "6px 0" }}>
                  <Input className="grade-input" placeholder="Enter Score" value={score} onChange={(e) => setScore(e.target.value)} />
                  <Button className="dark-base-btn" style={{background : darkButtonBackground}} onClick={() => invalidateSubmission(true,submissionDetail?.profile)}>Submit</Button>
                  <Button className="gray-base-btn" onClick={() => { setGradeInput(false); setScore() }}>Cancel</Button>
                </div>
                :
                submissionDetail.score === 0 && submissionDetail.is_reviewed === false && submissionDetail.is_scored_task && submissionDetail.is_validated !== false && selectedWorkout?.is_fw === false &&
                <Button className="dark-base-btn" style={{background : darkButtonBackground}} onClick={() => setGradeInput(true)}>Grade This Submission</Button>

              }
              {submissionDetail.is_scored_task === true && submissionDetail.is_reviewed === true && gradeInput === false && submissionDetail.is_validated !== false && selectedWorkout?.is_fw === false?
                <div className="score">
                  <h2 style={{ color: "#5e6278" }}>Score:</h2>
                  <span className="score-value">{submissionDetail.score}</span>
                  <Button className="gray-base-btn" onClick={() => setGradeInput(true)} style={{ margin: "6px 0" }}>Edit Score</Button>

                </div>
                // :
                // submissionDetail.score_based_test === true && submissionDetail.is
              : submissionData?.media_required === false && submissionData?.score_based_test === true && submissionDetail.self_reviewed === true 
              && gradeInput === false && selectedWorkout?.is_fw === false &&
               <div className="score">
               <h2 style={{ color: "#5e6278" }}>Score:</h2>
               <span className="score-value">{submissionDetail.score}</span>
               {/* <Button className="gray-base-btn" onClick={() => setGradeInput(true)} style={{ margin: "6px 0" }}>Edit Score</Button> */}
             </div>
              }
              {submissionDetail?.is_reviewed === true && selectedWorkout?.is_fw === true && submissionDetail?.score > 0 && submissionDetail?.is_scored_task &&
               <div className="score">
               <h2 style={{ color: "#5e6278" }}>Score:</h2>
               <span className="score-value">{submissionDetail.score}</span>
               {/* <Button className="gray-base-btn" onClick={() => setGradeInput(true)} style={{ margin: "6px 0" }}>Edit Score</Button> */}
             </div>
              }
              {selectedWorkout?.is_fw === false &&
              <div className="btn-div">
              <Popconfirm
                  icon={false}
                  rootClassName={selectedSport === 1 ? "orange-popconfirm" : "blue-popconfirm"}
                  title="Are you sure?"
                  description={<p>Invalidating this submission will
                    remove it   <br />  and (if applicable) delete any accompanying   <br />
                    video.Are you sure you want to continue?</p>}
                  onConfirm={() => invalidateSubmission(false,submissionDetail?.profile)}
                  okText="Invalidate"
                  cancelText="Cancel"
                >
                  <Button className="red-base-btn"
                    disabled={submissionDetail.is_validated === false}
                    style={{color : color , background : lightButtonBackground}}>Invalidate This Submission</Button>
                </Popconfirm>              </div>
}

            </div>
            <div className="conversation">
              <h1>Private Conversation</h1>
              <Divider />
              {commentList?.length > 0 && commentList?.toReversed().map((item, index) => {
                return (
                  item?.profile?.id === accountDetail?.id ?
                    <div className="posts" key={index}>

                      <div style={{ display: "flex", justifyContent: "end" }}>

                        <div className="name-div" style={{ marginRight: 20, textAlign: "end" }}>
                          <h3 className="user-name">{generalDetail?.name}</h3>
                          <h4 className="date"> Delivered {dayjs(item?.created_at).format('M/DD/YY @ hh:mma')}</h4>
                        </div>
                        {
                          item?.profile.avatar === null ?
                            <Avatar
                              name={generalDetail?.name}
                              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                              className="user-avatar"
                            /> :
                            <img src={generalDetail?.avatar}></img>
                        }
                      </div>
                      <div className="content" style={{ marginLeft: "inherit" }}>
                        {item?.text}
                      </div>
                    </div> :
                    <div className="posts" key={index}>
                      <div style={{ display: "flex" }}>
                        {
                          item?.profile.avatar === null ?
                            <Avatar
                              name={item?.profile.name}
                              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                              className="user-avatar"
                            /> :
                            <img src={item?.profile.avatar}></img>
                        }
                        <div className="name-div">
                          <h3 className="user-name">{item?.profile.name}</h3>
                          <h4 className="date"> Delivered {dayjs(item?.created_at).format('M/DD/YY @ hh:mma')}</h4>
                        </div>
                      </div>
                      <div className="content">
                        {item?.text}
                      </div>
                    </div>
                )
              }
              )}
              <div className="write-comment">Write your comments</div>
              <TextArea
                value={commentValue}
                onChange={handleCommentValue}
                placeholder="Your response here..."
                autoSize={{
                  minRows: 5,
                  maxRows: 6,
                }}
              />
              <div style={{ textAlign: "end" }}>
                <Button
                  className="dark-base-btn"
                  onClick={() => addComment(commentValue
                  )}
                  style={{opacity:commentValue?.length === 0 ? 0.5 : 1 , cursor: commentValue?.length === 0 ? "not-allowed" : "pointer" , background : darkButtonBackground }}
                  disabled={commentValue?.length === 0}
                >
                  Send
                </Button>
              </div>
            </div>
          </div>

        </div>
        : <Spin
          className={"loader " + (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")}
          size="large"
          style={{
            position: "absolute",
            left: "49%",
          }}
        />}
    </Modal>
  )
}
export default SubmissionDetailModal;