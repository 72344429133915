import * as React from "react";
import "./styling.scss";
import ReactPlayer from "react-player";
import PlayerControls from "./PlayerControls";
import { INITIAL_STATE, reducer } from "./Player.reducer";
import Play from "../../assets/images/play.svg";
const Player = (props) => {
  const { url, light, percentage, wrapperRef, fromUrl , key } = props;
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
  const playerRef = React.useRef(null);
  // const wrapperRef = React.useRef(null);

  const handlePreview = () => {
    dispatch({ type: "PLAY" });
    dispatch({ type: "LIGHT", payload: false });
  };

  const handlePause = () => {
    dispatch({ type: "PAUSE" });
  };

  const handlePlay = () => {
    dispatch({ type: "PLAY" });
    setPlayButton(false);
  };

  const handleEnded = () => {
    setPlayButton(true);
    dispatch({ type: "LIGHT", payload: true });
    if (fromUrl === true) {
      playerRef.current?.showPreview();
    }
  };

  const handleProgress = (progress) => {
    dispatch({ type: "SEEK", payload: progress.playedSeconds });
  };

  const handleDuration = (duration) => {
    dispatch({ type: "DURATION", payload: duration });
  };
  const [playButton, setPlayButton] = React.useState(false);
  React.useEffect(() => {
    if (percentage === 100) {
      setPlayButton(true);
    }
  }, [percentage]);
  return (
    <div
      ref={wrapperRef}
      className="uploader-preview"
      style={{ zIndex: percentage === 100 ? "999" : "0" }}
    >
      {fromUrl === true ? (
        <>
          <ReactPlayer
            ref={playerRef}
            url={url}
            width="100%"
            height="100%"
            controls={state.controls}
            loop={state.loop}
            muted={state.muted}
            playing={state.playing}
            playbackRate={state.playbackRate}
            volume={state.volume}
            onPlay={handlePlay}
            onEnded={handleEnded}
            onPause={handlePause}
            onDuration={handleDuration}
            onProgress={handleProgress}
            light={light}
            onClickPreview={handlePreview}
            key={key}
            playIcon={
              <img
                src={Play}
                style={{
                  color: "white",
                  fontSize: "6rem",
                  width: 32,
                  position: "absolute",
                  top: "40%",
                  left: "43%",
                }}
              />
            }
          />
          {playButton === false && !state.controls && (
            <PlayerControls
              state={state}
              dispatch={dispatch}
              playerRef={playerRef}
              wrapperRef={wrapperRef}
            />
          )}
        </>
      ) : (
        <>
          <ReactPlayer
            ref={playerRef}
            url={url}
            width="100%"
            height="100%"
            controls={state.controls}
            loop={state.loop}
            muted={state.muted}
            playing={state.playing}
            playbackRate={state.playbackRate}
            volume={state.volume}
            onPlay={handlePlay}
            onEnded={handleEnded}
            onPause={handlePause}
            onDuration={handleDuration}
            onProgress={handleProgress}
            onClickPreview={handlePreview}
            key={key}
          />
          {playButton === false && !state.controls ? (
            <PlayerControls
              state={state}
              dispatch={dispatch}
              playerRef={playerRef}
              wrapperRef={wrapperRef}
            />
          ) : (
            <img
              src={Play}
              style={{
                color: "white",
                fontSize: "6rem",
                width: 32,
                position: "absolute",
                top: "40%",
                left: "43%",
              }}
              onClick={handlePlay}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Player;
