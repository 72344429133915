import axios from "./coach-axios";
import {userBaseURL , trainingBaseURL , testBaseURL} from "./config";


///////////////////////////User Service API Calls/////////////////////////


export const getDataCoach = (path) => {
    return axios.get(`${userBaseURL}/${path}`);
}

export const jsonPostDataCoach = (path, data) => {
  return axios.post(`${userBaseURL}/${path}`, data,{
  headers: {
  'Content-Type':'application/json'
  }
  })
  }
  export const formPostData = (path, data) => {
  return axios.post(`${userBaseURL}/${path}`, data,{
  headers: {
  'Content-Type':'multipart/form-data'
  }
  })
  }

export const putData = (path, data) => {
    return axios.put(`${userBaseURL}/${path}`, data);
}
export const patchData = (path, data) => {
  return axios.patch(`${userBaseURL}/${path}`, data);
}
export const deleteItem = (path, data={}) => {
    return axios.delete(`${userBaseURL}/${path}`, {data: data})
}

///////////////////////////Training Service API Calls/////////////////////////


export const getDataTrainingCoach = (path) => {
  return axios.get(`${trainingBaseURL}/${path}`);
}

export const jsonPostDataTraining = (path, data) => {
return axios.post(`${trainingBaseURL}/${path}`, data,{
headers: {
'Content-Type':'application/json'
}
})
}
export const formPostDataTraining = (path, data) => {
return axios.post(`${trainingBaseURL}/${path}`, data,{
headers: {
'Content-Type':'multipart/form-data'
}
})
}

export const putDataTraining = (path, data) => {
  return axios.put(`${trainingBaseURL}/${path}`, data);
}
export const patchDataTraining = (path, data) => {
return axios.patch(`${trainingBaseURL}/${path}`, data);
}
export const deleteItemTraining = (path, data={}) => {
  return axios.delete(`${trainingBaseURL}/${path}`, {data: data})
}




///////////////////////////Test Service API Calls/////////////////////////


export const getDataTest = (path) => {
  return axios.get(`${testBaseURL}/${path}`);
}

export const jsonPostDataTest = (path, data) => {
return axios.post(`${testBaseURL}/${path}`, data,{
headers: {
'Content-Type':'application/json'
}
})
}
export const formPostDataTest = (path, data) => {
return axios.post(`${testBaseURL}/${path}`, data,{
headers: {
'Content-Type':'multipart/form-data'
}
})
}

export const putDataTest = (path, data) => {
  return axios.put(`${testBaseURL}/${path}`, data);
}
export const patchDataTest = (path, data) => {
return axios.patch(`${testBaseURL}/${path}`, data);
}
export const deleteItemTest = (path, data={}) => {
  return axios.delete(`${testBaseURL}/${path}`, {data: data})
}
