import React, { useContext, useEffect, useState } from "react";
import { getData, formPostData } from "../../util/api-calls";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { UserContext } from "../user-context";
// import { SubscriptionContext } from "../subscription-context";
export const CreateGroupContext = React.createContext({});
export const CreateGroupContextProvider = ({ children = {} }) => {
  // const {subNotes , subStart , subExpire} = useContext(SubscriptionContext)
  const {selectedSport} = useContext(UserContext)
  const navigate = useNavigate();
  const [sportsList, setSportsList] = useState([]);
  const [type, setType] = useState(1);
  const [level, setLevel] = useState();
  const [seatLimit, setSeatLimit] = useState(false);
  const [memberLimit, setMemberLimit] = useState();
  const [workoutLeaderBoard, setWorkoutLeaderBoard] = useState(true);
  const [combineLeaderBoard, setCombineLeaderBoard] = useState(false);
  const [bsaLeaderBoard, setBsaLeaderBoard] = useState(false);
  const [sport, setSport] = useState("");
  const [imageUrl, setImageUrl] = useState();
  const [imgSrc, setImgSrc] = useState();
  const [newSubscription, setNewSubscription] = useState(false);
  const [editSubscription , setEditSubscription] = useState(false)
  const [subscriptionHistory, setSubscriptionHistory] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("none");
  const [subStart , setSubStart] = useState("")
  const [startTimeZone , setStartTimeZone] = useState("")
  const [expiryTimeZone , setExpireTimeZone] = useState("")
  const [subExpire , setSubExpire] = useState("")
  const [subNotes , setSubNotes] = useState("")
  const [bsaValue, setBsaValue] = useState();
  const [bsaValueoneTime, setBsaValueOneTime] = useState()
  const [bsaValueEachMonth , setBsaValueEachMonth] = useState()
  const [loading, setLoading] = useState(false);
  const [access , setAccess] = useState(false)
  const [curriculum , setCurriculum] = useState(false)
  const [imageError , setImageError] = useState(false)
  const getSportList = () => {
    getData(`sport`)
      .then((res) => {
        setSportsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const createGroup = (values) => {
    if(type === 2 && imageUrl === undefined){
      setImageError(true)
    }else{
      setLoading(true);
      setImageError(false)
    let startZone = startTimeZone.split(" ")[0];
    let expireZone = expiryTimeZone.split(" ")[0];

    let start_date = new Date(Date.parse(values.start_date)).toLocaleString(
      "sv-SE"
    );
    let expiry_Date = new Date(Date.parse(values.expiry_Date)).toLocaleString(
      "sv-SE"
    );
    let formData = new FormData();
    if (imageUrl) {
      formData.append("avatar", imageUrl);
    }
    formData.append("name", values.name);
    formData.append("sport_id", selectedSport);
    // if (type !== 1) {
    //   formData.append("level", level);
    // }
    formData.append("free_bsa", values.bsa_credit === "none"  || selectedSport !== 1 ? 0 : bsaValue);
    formData.append(
      "bsa_frequency",
      values.bsa_credit === "none"
        ? "none"
        : values.bsa_credit === "one-time"
        ? "one time"
        : "monthly"
    );
    // if(seatLimit){
    formData.append(
      "member_limit",
      seatLimit === false ? "100000" :
       memberLimit
    );
    // }
    formData.append(
      "display_announcements_section",
      values.display_announcements_section
    );
    formData.append("display_post_section ", values.display_post_section ? values.display_post_section : false);
    formData.append(
      "allow_members_post",
      values.allow_members_post === "owners" ? false : true
    );
    formData.append("subscription_required" , subStart !== "" && subExpire !== "" ? true : false)
    formData.append("display_workout_leaderboard ", workoutLeaderBoard);
    formData.append("display_bsa_leaderboard ", bsaLeaderBoard);
    formData.append("is_curriculum_group" , curriculum);
    formData.append(
      "display_combine_measurement_leaderboard ",
      combineLeaderBoard
    );
    if (values.tags) {
      formData.append("tags", values.tags.toString());
    }

    if (values.comments) {
      formData.append("comments", values.comments);
    }
    if (access === true) {
      formData.append("start_date", subStart+startZone);
    }
    if (access === true) {
      formData.append("expiry_date", subExpire+expireZone);
    }
    if (access === true){
      formData.append("notes" , subNotes)
    }
    if(type === 2){
    formData.append("member_code_expiry", values.expiry)
    if(values.description){
    formData.append("description",values.description)
    }
    }
    formData.append("is_purchased_group" , type === 1 ? false : true)
    formData.append("is_default", false
    // type === 1 ? false : true
  );
    formPostData(`admin/group`, formData)
      .then((res) => {
        if (res.status === 200) {
          message.success({
            content: "Group created successfully.",
            duration: 1,
          });
          setTimeout(() => {
            navigate("/home/groups");
          }, 1200);
        }
    })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);

      });
  }
  };

  useEffect(() => {
    getSportList();
  }, []);
  const defaultContext = {
    sportsList,
    createGroup,
    combineLeaderBoard,
    setCombineLeaderBoard,
    bsaLeaderBoard,
    setBsaLeaderBoard,
    workoutLeaderBoard,
    setWorkoutLeaderBoard,
    type,
    setMemberLimit,
    seatLimit,
    setSeatLimit,
    setType,
    setLevel,
    memberLimit,
    level,
    sport,
    setSport,
    imageUrl,
    setImageUrl,
    imgSrc,
    setImgSrc,
    bsaValue,
    setBsaValue,
    subscriptionStatus,
    setNewSubscription,
    editSubscription,
    setEditSubscription,
    setSubscriptionHistory,
    newSubscription,
    setSubscriptionStatus,
    subscriptionHistory,
    loading,
    access,
    setAccess,
    setSubStart,
    setSubNotes,
    setSubExpire,
    subStart,
    subExpire,
    subNotes,
    setStartTimeZone,
    setExpireTimeZone,
    startTimeZone,
    bsaValueEachMonth,
    setBsaValueEachMonth,
    bsaValueoneTime,
    setBsaValueOneTime,
    expiryTimeZone,
    setCurriculum,
    imageError , 
    setImageError
  };
  return (
    <CreateGroupContext.Provider value={defaultContext}>
      {children}
    </CreateGroupContext.Provider>
  );
};
