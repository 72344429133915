import { Button, Divider , Input} from "antd";
import React, { useContext, useState } from "react";
import Profile from "../../../assets/images/avatar.svg";
import { UserContext } from "../../../context/user-context";
const { TextArea } = Input;

const SubmittedTask = () => {
  const {darkButtonBackground , color , lightButtonBackground} = useContext(UserContext)
    const [gradeInput , setGradeInput] = useState(false)
    return(
        <div className="submitted-task-main">
                  <div className="task-header">
        <h2>Training Task</h2>
        {/* <h2>Scored Task</h2> */}
        {/* {!gradeInput ? 
        <Button className="dark-base-btn" onClick={()=>setGradeInput(true)}>Grade This Submission</Button>:
        <div className="grade-form">
            <Input  className="grade-input" placeholder="Enter Score"/>
            <Button className="dark-base-btn">Submit</Button>
            <Button className="gray-base-btn" onClick={()=>setGradeInput(false)}>Cancel</Button>
        </div>
} */}
{/* <div className="score">
    <h2 style={{color:"#5e6278"}}>Score:</h2>
    <span className="score-value">41</span>
    <Button className="gray-base-btn" onClick={()=>setGradeInput(false)}>Edit Score</Button>

</div> */}
        <div className="btn-div">
         <Button className="red-base-btn" style={{color : color , background : lightButtonBackground}}>Invalidate This Submission</Button>
        </div>
      
      </div> 
      <div className="conversation">
        <h1>Private Conversation</h1>
        <Divider />
        <div className="posts">
        <div style={{ display: "flex" }}>
          <img src={Profile}></img>
          <div className="name-div">
            <h3 className="user-name">Jacob Jones</h3>
            <h4 className="date">Yestarday at 5:06 PM</h4>
          </div>
        </div>
        <div className="content">
          Outlines keep you honest. They stop you from indulging in poorly
          thought-out metaphors about driving and keep you focused on the
          overall structure of your post
        </div>
      </div>
      <div className="write-comment">Write your comments</div>
              <TextArea
                // value={commentValue[[item.id]]}
                // onChange={handleCommentValue}
                placeholder="Your response here..."
                autoSize={{
                  minRows: 5,
                  maxRows: 6,
                }}
                // onKeyPress={enterCommentLine}
              />
              <div style={{ textAlign: "end" }}>
                <Button
                  className="dark-base-btn"
                  style={{background : darkButtonBackground}}
                //   onClick={() => addComment(commentValue, item.id)}
                >
                  Send
                </Button>
              </div>
      </div>
        </div>
    )
}
export default SubmittedTask