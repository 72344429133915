import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Input, Popconfirm, Spin, Table, Typography } from "antd";
import Check from "../../../assets/images/check.svg";
import Cross from "../../../assets/images/cross.svg";
import ResultModal from "./result-modal";
import ReportModal from "./report-modal";
import { SubmissionDetailContext } from "../../../context/submission-context/bsa_submission_detail_context";
import { UserContext } from "../../../context/user-context";
const WorkoutProgress = ({ setScrollPosition }) => {
  const {darkButtonBackground , color , lightButtonBackground , selectedSport} = useContext(UserContext)
  const {
    selectedTest,
    postResult,
    createdTest,
    setCreatedTest,
    loading,
    setReportSendDisable,
    reportSendDisable,
    saveVideoReview,
    exerciseData,
    exercises,
    setExerciseData,
    clearButton,
    setClearButton,
    setExitButton,
    exitButton,
  } = useContext(SubmissionDetailContext);
  const [showEdit, setShowEdit] = useState(true);
  const [resultModal, setResultModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [shot, setShot] = useState();
  const [shotType, setShotType] = useState("");
  const [shotName, setShotName] = useState("");
  const [shotIndex, setShotIndex] = useState();
  const [shotCount, setShotCount] = useState();
  const [save, setSave] = useState(false);
  const updateMake = (index) => {
    setScrollPosition(false);
    setSave(true);
    setReportSendDisable(false);
    const newArray = updatedArray.map((item, i) => {
      if (index === i && item.primary_action !== 1) {
        return {
          ...item,
          primary_action: 1,
          // score: index + 1 === 1 || index + 1 === 5 ? 1 : index + 1 === 2 ? 2 : 3,
          score:
            index < 2 || index >= 37 ? 1 : index > 11 && index < 37 ? 3 : 2,
          timestamp: new Date().toISOString(),
        };
      } else {
        return item;
      }
    });
    setExerciseData(newArray);
    setEditingKey();
  };
  const updateMiss = (index) => {
    setScrollPosition(false);
    setReportSendDisable(false);
    setSave(true);
    const newArray = updatedArray.map((item, i) => {
      if (index === i && item.primary_action !== 2) {
        return {
          ...item,
          primary_action: 2,
          score: 0,
          timestamp: new Date().toISOString(),
        };
      } else {
        return item;
      }
    });
    setExerciseData(newArray);
    setEditingKey();
  };
  const [editingKey, setEditingKey] = useState("");
  const [form] = Form.useForm();

  const isEditing = (record) => record === editingKey;
  const edit = (record) => {
    setEditingKey(record);
  };
  let updatedArray = exerciseData.filter((item)=> item.primary_action !== "added" )
  const getExceriseSetNumber = (arrayIndex) => {
    let index = arrayIndex === undefined ? exerciseData.length : arrayIndex;
    let test_exercise = exercises;
    let new_test_exercise = [];
    let new_exercise_type = [];
    let new_exercise_name = [];
    let new_shots_count = [];
    let score = 0;
    for (let i = 0; i < exercises.length; i++) {
      for (let j = 0; j < exercises[i]["array"].length; j++) {
        exerciseData.length > 0 &&
          exerciseData.map((item) => {
            if (
              item.exercise_detail ===
                exercises[i]["array"][j].exercise_detail_id &&
              item.primary_action === 1
            ) {
              if (exercises[i]["array"][j].exercise_id !== 5) {
                score = score + exercises[i]["array"][j].total_score;
              } else {
                score = score + 1;
              }
            }
          });
        new_shots_count.push(exercises[i]["array"]);
        new_test_exercise.push(i + 1);
        new_exercise_type.push(exercises[i]["array"][j].exercise);
        new_exercise_name.push(exercises[i]["array"][j].position);
      }
    }
    setShotCount(new_shots_count);
    setShotIndex(index);
    setShotName(new_exercise_name);
    setShotType(new_exercise_type);
    setShot(new_test_exercise);
  };
  useEffect(() => {
    if (exercises.length > 0) {
      getExceriseSetNumber(0);
    }
  }, []);
  const columns = [
    {
      title: "set description",
      dataIndex: "set_description",
      key: "set_description",
      render: (text, record, index) => {
        return (
          <div key={index}>
            <span className="span-1">
              Set {record.set} of{" "} 
              {shot && shot[updatedArray.length > 0 && updatedArray.length - 1]}
            </span>
            <br />
            <span className="span-2">
              {shotType[index]} ( {shotCount &&   shotCount[index] && shotCount[index].length} Shots)
            </span>
          </div>
        );
      },
    },
    {
      title: "shot description",
      dataIndex: "shot_description",
      key: "shot_description",
      render: (text, record, index) => {
        return (
          <div key={index}>
            <span className="span-1">
              Shot {index + 1} 
              of {exerciseData.filter((obj)=>obj.primary_action !== "added").length}
            </span>
            <br />
            <span className="span-2">
              {shotName[index]}
              </span>
          </div>
        );
      },
    },
    {
      title: "recorded result",
      dataIndex: "result",
      key: "result",
      render: (text, record, index) => {
        return (
          <span
            className={record.primary_action === 1 ? "green-tag" : "red-tag"}
          >
            {record.primary_action === 1 ? "Make" : "Miss"}
          </span>
        );
      },
    },
    {
      title: "actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record, index) => {
        const editable = isEditing(index);
        return editable ? (
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              className="green-circle"
              icon={<img src={Check} />}
              onClick={() => {
                updateMake(index);
                // setEditingKey()
              }}
              style={{ opacity: record.primary_action === 2 && 0.3 }}
            ></Button>
            <Button
              className="red-circle"
              icon={<img src={Cross} />}
              onClick={() => updateMiss(index)}
              style={{ opacity: record.primary_action === 1 && 0.3 }}
            ></Button>
          </div>
        ) : (
          <Button
            className="red-base-btn"
            style={{color : color , background : lightButtonBackground}}
            onClick={() => {
              edit(index);
              setShowEdit(false);
            }}
          >
            Edit
          </Button>
        );
      },
    },
  ];
  return loading ? (
    <Spin
      className={"loader " + (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")}
      size="large"
      style={{
        position: "absolute",
        left: "49%",
        top: "39%",
      }}
    />
  ) : (
    <div className="workout-progress-main">
      <div className="progress-header">
        {/* <h2>Workout Progress</h2> */}
        <div className="btn-div">
          <Button className="red-base-btn" onClick={() => setReportModal(true)} style={{color : color , background : lightButtonBackground}}>
            Report as Invalid
          </Button>
          {reportSendDisable === true && createdTest.length > 0 ? (
            <Button
              className="dark-base-btn"
              style={{background : darkButtonBackground}}
              onClick={() => setResultModal(true)}
              disabled={createdTest.length === 0}
            >
              Send Results to Player
            </Button>
          ) : (
            save === true && (
              <Button
                className="dark-base-btn"
                style={{background : darkButtonBackground}}
                onClick={() => saveVideoReview()}
                // disabled={createdTest.length === 0 || reportSendDisable}
              >
                Save
              </Button>
            )
          )}
        </div>
      </div>
      <Table
        rowKey={(obj) => exerciseData.indexOf(obj)}
        // pagination={{
        //   pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
        //   showSizeChanger: true,
        //   locale: { items_per_page: "" },
        //   defaultPageSize: 10,
        //   // current: pageNumber,
        //   // pageSize: pageSize,
        //   // total:totalGroups,
        // }}
        // scroll={{
        //   y: 400,
        // }}
        pagination={false}
        columns={columns}
        // dataSource={createdTest}
        // dataSource={exerciseData.filter(
        //   (item) => item.primary_action !== "added"
        // )}
        dataSource={updatedArray}
        className={"table-container " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
        />
      <ReportModal
        reportModal={reportModal}
        setReportModal={setReportModal}
        postResult={postResult}
      />
      <ResultModal
        resultModal={resultModal}
        setResultModal={setResultModal}
        postResult={postResult}
      />
    </div>
  );
};
export default WorkoutProgress;
