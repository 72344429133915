import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getData, getDataTraining } from "../../util/api-calls";

export const WorkoutsContext = React.createContext({});
export const WorkoutsContextProvider = ({ children = {} , workoutData }) => {
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    const entries = urlParams.entries();
    const params = paramsToObject(entries);
    function paramsToObject(entries) {
      const result = {};
      for (const [key, value] of entries) {
        result[key] = value;
      }
      return result;
    }
    const [allWorkouts , setAllWorkouts] = useState([]);
    const [workoutType , setWorkoutType] = useState("active")
    const [limit , setLimit] = useState(10)
    const [offset , setOffset] = useState(0)
    const [showAllWorkouts, setShowAllWorkouts] = useState(false);
    const [loading , setLoading] = useState(false)
    const [searches, setSearches] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [groupWorkouts , setGroupWorkouts ]= useState( {})
    const [filteredWorkouts , setFilteredWorkouts] = useState([])
  const navigate = useNavigate()
  const getAllWorkouts = () => {
    setLoading(true)
    getDataTraining(`group/${params.id}/workout
    `).then((res)=>{
        setAllWorkouts(res.data)
    }).catch((err)=>{
        console.log(err)
    }).finally(()=>{
      setLoading(false)
    })
  }
  const getGroupWorkout = () => {
    getData(`admin/group/${params.id}`).then((res)=>{
      setGroupWorkouts(res.data.workouts)
    }).catch((err)=>console.log(err))

  }
  const getFilteredWorkouts = () => {
    setLoading(true)
    getDataTraining(`group/${params.id}/workout?workout_type=${workoutType}&offset=${offset}&limit=${limit}`)
    .then((res)=>{
        setFilteredWorkouts([...filteredWorkouts, ...res.data.results]);
        if(offset === 0){
        if(workoutType === "active" && groupWorkouts.active >= res.data.results.length){
          setHasMore(true);
          setOffset(offset + limit)
        }else if(workoutType === "upcoming" && groupWorkouts.upcoming >= res.data.results.length){
          setHasMore(true);
          setOffset(offset + limit)
        }else if(workoutType === "archive" && groupWorkouts.archive >= res.data.results.length){
          setHasMore(true);
          setOffset(offset + limit)
        }
      }else{
        if(workoutType === "active" && groupWorkouts.active >= filteredWorkouts.length){
            
          setHasMore(true);
          setOffset(offset + limit)
        }else if(workoutType === "upcoming" && groupWorkouts.upcoming >= filteredWorkouts.length){
          setHasMore(true);
          setOffset(offset + limit)
        }else if(workoutType === "archive" && groupWorkouts.archive >= filteredWorkouts.length){
          setHasMore(true);
          setOffset(offset + limit)
        }
      }
      if(res.data.results.length === 0){
        setHasMore(false)
      }
    }).catch((err)=>{
        console.log(err)
    }).finally(()=>{
      setLoading(false)
    })
  }
  React.useEffect(()=>{
    getGroupWorkout()
    getAllWorkouts();
  } , []) 
  useEffect(()=>{
    if(showAllWorkouts){
      getFilteredWorkouts()
    }
  },[
    showAllWorkouts , 
    // offset
  ])
  const defaultContext = {
    workoutType,
    allWorkouts,
    showAllWorkouts,
    setShowAllWorkouts,
    setWorkoutType,
    setAllWorkouts,
    loading,
    setLoading,
    setOffset,
    offset,
    searches,
    hasMore,
    setHasMore,
    getAllWorkouts,
    filteredWorkouts,
    getFilteredWorkouts,
    setWorkoutType,
    setOffset,
    setFilteredWorkouts
  };
  return (
    <WorkoutsContext.Provider value={defaultContext}>
      {children}
    </WorkoutsContext.Provider>
  );
};
