import React, { useState , useContext } from "react";
import { getDataTraining , jsonPostDataTraining } from "../../util/api-calls";
import { Form } from "antd";
import { UserContext } from "../user-context";
export const EditWorkoutTaskContext = React.createContext({});
export const EditWorkoutTaskContextProvider = ({ children = {} }) => {
  const {selectedSport} = useContext(UserContext)
  const [form] = Form.useForm();
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  let groupName = window.location.pathname.slice(
    window.location.pathname.lastIndexOf("/") + 1,
    window.location.pathname.length
  );
  const [detailVideoId, setDetailVideoId] = useState("");
  const [mediaType, setMediaType] = useState("upload");
  const [title, setTitle] = useState("");
  const [privacyModal, setPrivacyModal] = useState(false);
  const [embeddedUrl, setEmbeddedUrl] = useState("");
  const [data, setData] = useState();
  const [timeLimitSeconds, setTimeLimitSeconds] = useState("");
  const [newTaskModal, setNewTaskModal] = useState(false);
  const [detailImgSrc, setDetailImgSrc] = useState();
  const [detailImageUrl, setDetailImageUrl] = useState();
  const [existingTasks, setExistingTasks] = useState([]);
  const [skillLevel, setSkillLevel] = useState("");
  const [uploadError, setUploadError] = useState(false);
  const [taskType, setTaskType] = useState(true);
  const [selectedTask, setSelectedTask] = useState({});
  const [tags, setTags] = useState([]);
  const [sort, setSort] = useState(true);
  const [value, setValue] = useState("");
  const [timeLimit, setTimeLimit] = useState(false);
  const [taskStatus, setTaskStatus] = useState(true);
  const [level, setLevel] = useState("beginner");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [updateTask , setUpdateTask] = useState(false)
  const [videoRequired, setVideoRequired] = useState(true);

  const postUpdateTask = (
    selectedTask,
    setNewTaskArray,
    newTaskArray,
    setEditTaskModal,
  ) => {
    let data = JSON.stringify({
      title: title !== "" ? title : selectedTask.title,
      order:
        newTaskArray === undefined
          ? selectedTask.order
          : newTaskArray.length + 1,
      type: selectedTask.type,
      media: null,
      media_url:
        mediaType === "url" && embeddedUrl
          ? embeddedUrl
          : selectedTask.media_url,
      media_uri: detailVideoId !== "" ? detailVideoId : selectedTask.media_uri,
      media_type: detailVideoId
        ? "vimeo"
        : embeddedUrl !== ""
        ? "embedded"
        : detailImageUrl
        ? "image"
        : selectedTask.media_type,
      media_thumbnail: detailVideoId === "" && selectedTask.media_thumbnail,
      image: detailImageUrl ? detailImageUrl : selectedTask.image,
      status: selectedTask.status,
      instruction: value !== "" ? value : selectedTask.instruction,
      is_public: taskStatus,
      best_score: selectedTask.best_score,
      skill_level: level,
      is_time_limit: timeLimit,
      score_based_test: taskType,
      num_completion: selectedTask.num_completion,
      legacy_point: selectedTask.legacy_point,
      allow_more_reps: selectedTask.allow_more_reps,
      lock: selectedTask.lock,
      group_id: params.id,
      group_name: decodeURI(groupName),
      tags: tags.toString() === "" ? selectedTask.tags : tags.toString(),
      update_library: updateTask,
      create_existing: true,
      id: selectedTask.task_id,
      media_required : videoRequired
    });
    jsonPostDataTraining(`admin/existing/task/library`, data)
      .then((res) => {
        setNewTaskArray((prevTask) => [...prevTask, res.data]);
        setEditTaskModal(false);
        form.resetFields();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const defaultContext = {
    detailVideoId,
    setDetailVideoId,
    setTags,
    mediaType,
    setMediaType,
    timeLimitSeconds,
    setTimeLimitSeconds,
    tags,
    value,
    setValue,
    timeLimit,
    setTimeLimit,
    taskStatus,
    setTaskStatus,
    level,
    setLevel,
    setEmbeddedUrl,
    embeddedUrl,
    newTaskModal,
    setNewTaskModal,
    // existingTaskModal,
    // setExistingTaskModal,
    form,
    detailImgSrc,
    setDetailImgSrc,
    detailImageUrl,
    setDetailImageUrl,
    setTitle,
    data,
    setData,
    uploadError,
    existingTasks,
    setSkillLevel,
    setTaskType,
    postUpdateTask,
    setSort,
    setSelectedCategory,
    params,
    setPrivacyModal,
    privacyModal,
    updateTask,
    setUpdateTask,
    selectedTask,
    setSelectedTask,
    setUpdateTask,
    videoRequired,
    setVideoRequired
  };
  return (
    <EditWorkoutTaskContext.Provider value={defaultContext}>
      {children}
    </EditWorkoutTaskContext.Provider>
  );
};
