import React, { useContext } from "react";
import { Modal } from "antd";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { ExistingWorkoutContext } from "../../../context/existing-workout-context";
import { UserContext } from "../../../context/user-context";

const WorkoutModal = ({ workoutUpdateModal, setWorkoutUpdateModal , generalDetail}) => {
  const { createExistingWorkout } = useContext(
    ExistingWorkoutContext
  );
  const {selectedSport , color} = useContext(UserContext)
  const handleOk = () => {
    setWorkoutUpdateModal(false);
    createExistingWorkout(false , generalDetail.is_curriculum_group);
  };
  const handleCancel = () => {
    setWorkoutUpdateModal(false);
    createExistingWorkout(true , generalDetail.is_curriculum_group);
  };
  return (
    <Modal
      className="privacy-modal"
      wrapClassName={selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"}
      open={workoutUpdateModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Just This Assignment"}
      cancelText={"Update The Workout Template"}
      width={"608px"}
    >
      <h1>Update Workout</h1>
      <p>You made modifications to this Workout. </p>
      <p>
        {" "}
        Would you like to use them just this once, or save the Workout template
        for future use?
      </p>
      <div className={"info-div " + (selectedSport === 1 ? "orange-info" : "blue-info")}>
        <ExclamationCircleTwoTone
          twoToneColor={color}
          style={{ fontSize: 20 }}
        />
        <p className="status">
          Current and previous assignments of this workout will NOT be affected
        </p>
      </div>
    </Modal>
  );
};

export default WorkoutModal;
