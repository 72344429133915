import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, InputNumber } from "antd";
import "./expiry-modal.scss";
import { jsonPostData, patchData } from "../../../../util/api-calls";
import { MemberContext } from "../../../../context/group-context/members-context";
import { UserContext } from "../../../../context/user-context";
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const ExpiryModal = ({
  expiryModal,
  setExpiryModal,
  generalDetail,
  getGroupDetail,
}) => {
  const {selectedSport} = useContext(UserContext)
  const { selectedTab } = useContext(MemberContext);
  const [form] = Form.useForm();
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }

  const handleOk = (e) => {
    jsonPostData(`user/access-code/refresh`, {
      access_code: generalDetail.member_code,
      month_expiry: form.getFieldValue("expiry"),
    })
      .then((res) => {
        setExpiryModal(false);
        getGroupDetail(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCancel = () => {
    setExpiryModal(false);
  };
  useEffect(() => {
    form.setFieldValue("expiry", generalDetail.member_code_expiry);
  }, []);
  return (
    <Modal
      wrapClassName={"sport-modal " + (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")}
      open={expiryModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Update"}
      cancelText={"Cancel"}
      width={"609px"}
    >
      <h1>Update Access Code Expiry</h1>
      <Form
        form={form}
        name="dynamic_rule"
        // style={{
        //   padding: "22px 30px ",
        // }}
        className="sport-form"
      >
        <Form.Item
          {...formItemLayout}
          name="expiry"
          label={"Member Access Code Expiry"}
          rules={[
            {
              type: "number",
              min: 1,
              max: 10000,
              message: "Inches must be between ${min} to ${max}.",
            },
          ]}
        >
          <InputNumber
            placeholder="Enter expiry in months"
            type="number"
            style={{ width: "100%" }}
            className="number-input"
            onKeyDown={(e) => e.key === "." && e.preventDefault()}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ExpiryModal;
