import React, { useEffect, useState } from "react";
import { getData, formPostData } from "../../util/api-calls";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import dayjs from "dayjs";
export const CreateProfileContext = React.createContext({});
export const CreateProfileContextProvider = ({ children = {} }) => {
  const navigate = useNavigate();
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [sportsList, setSportsList] = useState([]);
  const [type, setType] = useState(1);
  const [level, setLevel] = useState();
  const [seatLimit, setSeatLimit] = useState(false);
  const [memberLimit, setMemberLimit] = useState();
  const [workoutLeaderBoard, setWorkoutLeaderBoard] = useState(true);
  const [combineLeaderBoard, setCombineLeaderBoard] = useState(false);
  const [bsaLeaderBoard, setBsaLeaderBoard] = useState(false);
  const [sport, setSport] = useState("");
  const [imageUrl, setImageUrl] = useState();
  const [imgSrc, setImgSrc] = useState();
  const [newSubscription, setNewSubscription] = useState(false);
  const [subscriptionHistory, setSubscriptionHistory] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("none");
  const [bsaValue, setBsaValue] = useState();
  const [loading, setLoading] = useState(false);
  const [ profileType , setProfileType] = useState("player")
  const [gender , setGender] = useState("male")
  const [shootingHand , setShootingHand ] = useState("right")
  const [imageUpload , setImageUpload] = useState(false)
  const [nameError , setNameError] = useState(false)
  const [username ,setUsername] = useState("")
  const getSportList = () => {
    setLoading(true);
    getData(`sport`)
      .then((res) => {
        setSportsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const checkUserName = (e) => {
    getData(`user/username/${username}`).then((res)=>{
      setNameError(false)
    }).catch((err)=>{
      console.log(err)
      if(err.response.status === 404){
        setNameError(true)
      }
    })
  }

  useEffect(()=>{
    if(username !== ""){
    checkUserName()
    }
  },[username])
  const createProfile = (values) => {
    // if(!imageUrl){
      // setImageUpload(true)
    // }else{
      // setImageUpload(false)
      if(nameError === false){
let sports = [{
  "id" : JSON.stringify(values.sport),
  "level" : values.sport_level
}]
    setLoading(true);
    let formData = new FormData();
    formData.append('username', values.username);
    formData.append('first_name', values.first_name);
    if(profileType === "coach"){
    if(values.school !== undefined && values.school !== ""){
    formData.append('school', values.school);
    }
    if(values.job_title !== undefined && values.job_title !== ""){
    formData.append('job_title', values.job_title);
    }
    if(values.years_experience !== undefined && values.years_experience !== ""){
    formData.append('years_experience' , parseInt(values.years_experience));
    }
    }else{
      formData.append('shooting_hand', shootingHand);
      formData.append('height',   (values.height_feet ? values.height_feet + "′"  : "") +(values.height_inch ? values.height_inch + "″" : ""));
      if(values.weight_lbs !== undefined){
      formData.append('weight',  values.weight_lbs ? values.weight_lbs : "");
      }
    }
    if(values.location !== undefined && values.location !== ""){
      formData.append('location', values.location);
      }
    formData.append('last_name', values.last_name);
    if(values.dob !== undefined && values.dob !== ""){
    formData.append('date_of_birth', dayjs(values.dob).format("YYYY-MM-DD"));
    }
    formData.append('gender', gender);
    formData.append('sports', JSON.stringify(sports));
    formData.append('position_pg', values.positions?.includes("pg") ? true : false);
    formData.append('position_sg', values.positions?.includes("sg") ? true : false);
    formData.append('position_sf', values.positions?.includes("sf") ? true : false);
    formData.append('position_pf', values.positions?.includes("pf") ? true : false);
    formData.append('position_c', values.positions?.includes("c") ? true : false);
    formData.append('profile_type', profileType);
    if(imageUrl){
    formData.append('avatar', imageUrl);
    }
    formData.append('user_id', params.id);
    formPostData(`admin/app/user/profile`, formData)
      .then((res) => {
        if (res.status === 200) {
          message.success({
            content: "Profile created successfully.",
            duration: 1,
          });
          
          navigate({
            pathname: `/home/users/user-accounts`,
            search: `?id=${params.id}`,
          });        }
      })
      .catch((err) => {
        console.log(err);
        message.error({
          content:err.response.data.details
        })
      })
      .finally(() => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    getSportList();
  }, []);
  const defaultContext = {
    sportsList,
    createProfile,
    combineLeaderBoard,
    setCombineLeaderBoard,
    bsaLeaderBoard,
    setBsaLeaderBoard,
    workoutLeaderBoard,
    setWorkoutLeaderBoard,
    type,
    setMemberLimit,
    seatLimit,
    setSeatLimit,
    setType,
    setLevel,
    memberLimit,
    level,
    sport,
    setSport,
    imageUrl,
    setImageUrl,
    imgSrc,
    setImgSrc,
    bsaValue,
    setBsaValue,
    subscriptionStatus,
    setNewSubscription,
    setSubscriptionHistory,
    newSubscription,
    setSubscriptionStatus,
    subscriptionHistory,
    loading,
    profileType , setProfileType,
    gender , setGender,
    shootingHand , setShootingHand ,
    imageUpload , setImageUpload,checkUserName,nameError,
    setUsername
  };
  return (
    <CreateProfileContext.Provider value={defaultContext}>
      {children}
    </CreateProfileContext.Provider>
  );
};
