import React, { useState } from "react";
import { Modal, Input, Radio, Form, Upload, message, Select } from "antd";
import { useContext } from "react";
import { AnnouncementsContext } from "../../../coach-context/group-context/announcements-context";
import { UserContext } from "../../../context/user-context";
const DeleteAnnouncementModal = ({ deleteModal,setDeleteModal }) => {
    const {deleteAnnouncement} = useContext(AnnouncementsContext)
    const {selectedSport} = useContext(UserContext)
  const handleCancel = () => {
    setDeleteModal(false);
  };
  return (
    <Modal
      className="delete-modal"
      wrapClassName={selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"}
      open={deleteModal}
      onOk={deleteAnnouncement}
      onCancel={handleCancel}
      okText={"Yes"}
      cancelText={"No"}
      width={"608px"}
      //   footer={false}
    >
      <h1>Delete Announcement</h1>
      <p>Are you sure you want to delete this announcement? </p>
    </Modal>
  );
};

export default DeleteAnnouncementModal;
