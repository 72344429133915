// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.replace-task-modal .ant-modal-footer .ant-btn-default,
.replace-task-modal .ant-modal-footer .ant-btn-default:hover {
  width: 84px;
  border-radius: 6px;
  padding: 12px 16px;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  height: 38px;
  border: none;
}
.replace-task-modal .ant-modal-footer .ant-btn-primary,
.replace-task-modal .ant-modal-footer .ant-btn-primary:hover {
  border-radius: 6px;
  padding: 12px 16px;
  height: 38px;
  width: 120px;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  border: none;
  box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/admin-module/Challenges/replace-task-modal/replace-task-modal.scss"],"names":[],"mappings":"AAEI;;EAEI,WAAA;EACA,kBAAA;EAEA,kBAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,YAAA;AAHR;AAMI;;EAEI,kBAAA;EAEA,kBAAA;EACA,YAAA;EACA,YAAA;EAEA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,gBAAA;AANR","sourcesContent":[".replace-task-modal{\n    .ant-modal-footer{\n    .ant-btn-default,\n    .ant-btn-default:hover {\n        width: 84px;\n        border-radius: 6px;\n        // background: var(--primary-primary-light, #fff1ed) !important;\n        padding: 12px 16px;\n        // color: #f15a29 !important;\n        font-size: 13px;\n        font-style: normal;\n        font-weight: 700;\n        line-height: 16px;\n        height: 38px;\n        border: none;\n    }\n\n    .ant-btn-primary,\n    .ant-btn-primary:hover {\n        border-radius: 6px;\n        // background: var(--primary-primary, #f15a29);\n        padding: 12px 16px;\n        height: 38px;\n        width: 120px;\n        // color: var(--white-dark-white, #fff) !important;\n        font-size: 13px;\n        font-style: normal;\n        font-weight: 700;\n        line-height: 16px;\n        border: none;\n        box-shadow: none;\n\n    }\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
