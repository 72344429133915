import React, { useState, useRef, useContext, useEffect } from "react";
import {
  Collapse,
  Col,
  Row,
  Card,
  Button,
  Progress,
  Table,
  Dropdown,
  Input,
  Select,
  DatePicker,
} from "antd";
import { LockOutlined } from "@ant-design/icons";
import Expand from "../../../assets/images/expand.svg";
import DownArrow from "../../../assets/images/arrows.svg";
import Search from "../../../assets/images/search.svg";
import Calendar from "../../../assets/images/calendar.svg";
import CollapseIcon from "../../../assets/images/collapse.svg";
import Video from "../../../assets/Snail.mp4";
import Play from "../../../assets/images/play.svg";
import Profile from "../../../assets/images/avatar.svg";
import Avatar from "react-avatar";
import { render } from "@testing-library/react";
import { WorkoutDetailContext } from "../../../context/workouts-context/workout-detail-context";
import { UserContext } from "../../../context/user-context";
import { getDateWithoutDay } from "../../../util/config";
import { FWSubmissionDetailContext } from "../../../context/submission-context/fw-submission-detail-context";
import { useNavigate } from "react-router-dom";
const Tasks = ({ setTaskDetailTable, taskDetailTable, tasks }) => {
  const {
    setSort,
    setEndDate,
    setStartDate,
    setSearch,
    setFWDetail,
    setOffset,
    limit,
    offset,
    params,
    setSelectedTask
  } = useContext(FWSubmissionDetailContext);
  const navigate = useNavigate();
  const dateFormat = "ddd. MMMM D, YYYY";
  const { RangePicker } = DatePicker;
  const { selectedSport, color } = useContext(UserContext);
  const onChangeRangePicker = (e) => {
    if (e) {
      setStartDate(e[0].format("YYYY-MM-DD"));
      setEndDate(e[1].format("YYYY-MM-DD"));
    } else {
      setStartDate("");
      setEndDate("");
    }
  };
  const items = [
    {
      key: "details",
      label: "View Details",
    },
  ];
  const columns = [
    {
      title: "Task name",
      dataIndex: "title",
    },
    {
      title: "Last submission",
      dataIndex: "last_submission",
      render: (text, record, key) => {
        return (
          <div>
            {record?.last_submission_date === null
              ? "N/A"
              : getDateWithoutDay(record.last_submission_date)}
          </div>
        );
      },
    },
    {
      title: "ACTIONS",
      dataIndex: "member_limit",
      key: "member_limit",
      width: 200,
      elipsis: true,
      render: (text, record, key) => {
        return (
          <div style={{ display: "flex" }}>
            <Dropdown
              placeholder={
                record.status === "final"
                  ? "Review Locked"
                  : record.status === "rejected"
                  ? "Review Rejected"
                  : "Actions"
              }
              className={
                "custom-dropdown " +
                (selectedSport === 1
                  ? "orange-theme-dropdown"
                  : "blue-theme-dropdown")
              }
              disabled={
                record.status === "final" ||
                record.status === "rejected" ||
                record.status === "review"
              }
              menu={{
                items,
                onClick: ({ key }) => {
                  if (key === "details") {
                    setTaskDetailTable(true);
                    setSelectedTask(record)
                    navigate({
                      // pathname: `./${record.title}`,
                      search: `id=${params.id}&tabs=featured-workouts`,
                    });
                    //   if (
                    //     accountDetail.id !== record.assigned_person?.id
                    //      && !accountDetail.is_superuser
                    //     //  accountDetail.role?.role_permission.filter(
                    //     //   (e) =>
                    //     //     e.module.name === "Submissions" &&
                    //     //     e.permission.code_name === "REVIEW"
                    //     // ).length === 0
                    //   ) {
                    //     info();
                    //   } else {
                    //     navigate({
                    //       pathname: `./${record.athlete?.username}`,
                    //       search: `id=${record.id}&tab=${activeTab}`,
                    //     });
                    //   }
                    // } else {
                    //   if(accountDetail.role !== null){
                    //   for (let item of accountDetail.role?.role_permission) {
                    //     if(item.module.name === "Submissions" && item.permission.code_name === "ASSIGNMENT"){
                    //       if(item.value === false){
                    //         info();
                    //       }
                    //       else if(accountDetail.role
                    //         && record.assigned_person.id === accountDetail.id){
                    //        info();
                    //      }
                    //      else{
                    //      setAssigneeModal(true);
                    //      setSelectedReview(record);
                    //      }
                    //     }
                    //   }
                    //   } else{
                    //     setAssigneeModal(true);
                    //     setSelectedReview(record);
                    //     }
                  }
                },
              }}
              overlayClassName={
                "submission-dropdown-menu custom-dropdown-menu " +
                (selectedSport === 1
                  ? "orange-theme-dropdown"
                  : "blue-theme-dropdown")
              }
            >
              <a onClick={(e) => e.preventDefault()}>
                {record.status === "final" ? (
                  "Review Locked"
                ) : record.status === "rejected" ? (
                  "Rejected"
                ) : (
                  <>
                    Actions <img src={DownArrow} />
                  </>
                )}
              </a>
            </Dropdown>
            {record?.recent_submission === true && (
              <span className="new-sub"></span>
            )}
          </div>
        );
      },
    },
  ];
  const handleTableChange = (pagination, filters, sorter) => {
    if (pagination.current - 1 !== offset) {
      setOffset(pagination.current - 1);
    }
  };
  const searchFeatureTask = (e) => {
    if (e.target.value.length >= 3) {
      setFWDetail({});
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setFWDetail({});
      setSearch("");
    }
  };
  return (
    <div className="FW-tasks">
      <h2 className="task-heading">Tasks</h2>
      <div className="FW-tasks-table">
        <div style={{ display: "flex" }}>
          <Input
            key={"assignment"}
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchFeatureTask}
          />
          {/* <div className="right-div">
            <Select
              key={"assignment_select"}
              placeholder="Newest"
              optionFilterProp="children"
              suffixIcon={<img src={DownArrow} />}
              className={
                "custom-action-select " +
                (selectedSport === 1
                  ? "orange-theme-select"
                  : "blue-theme-select")
              }
              popupClassName={
                "custom-action-select-dropdown " +
                (selectedSport === 1
                  ? "orange-theme-select"
                  : "blue-theme-select")
              }
              style={{marginRight:0}}
              onChange={(e) => setSort(e)}
              options={[
                {
                  value: true,
                  label: "Newest",
                },
                {
                  value: false,
                  label: "Oldest",
                },
              ]}
            />
            {/* <RangePicker
              key={"assignment_picker"}
              className="range-picker"
              format={dateFormat}
              suffixIcon={<img src={Calendar} />}
              onChange={onChangeRangePicker}
            /> 
          </div> */}
        </div>
        <Table
          onChange={handleTableChange}
          rowKey={(obj) => obj.id}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: false,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current: offset + 1,
            pageSize: limit,
            total: tasks?.length,
          }}
          columns={columns}
          dataSource={tasks}
          className={
            "table-container tasks-table " +
            (selectedSport === 1 ? "orange-theme" : "blue-theme")
          }
        />
      </div>
    </div>
  );
};

export default Tasks;
