import React, { useContext, useState } from "react";
import { Col, Collapse, Progress, Row, Tabs, Card, Table, } from "antd";
import Expand from "../../../assets/images/expand.svg";
import CollapseIcon from "../../../assets/images/collapse.svg"
import Thumbnail from "../../../assets/images/chatLogo.svg"
import Play from "../../../assets/images/play.svg";
import { FaArrowLeftLong } from "react-icons/fa6";
import { getDateWithoutDay, getDateWithTimeWithoutDay } from "../../../util/config"
import "./workouts-detail.scss"
import {
  getDate,
} from "../../../util/config";
import {
  WorkoutDetailContext,
  WorkoutDetailContextProvider,
} from "../../../coach-context/workouts-context/profile-workout-detail-context";
import SubmissionDetailModal from "./submission-detail-modal";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";
import { UserContext } from "../../../context/user-context";
const { Panel } = Collapse;
const style = {
  padding: "5px 0",
};
const WorkoutDetailPage = ({ setWorkoutDetail , generalDetail }) => {
  const {color , selectedSport} = useContext(UserContext)
  const navigate = useNavigate();
  const { workoutDetail, setActiveTab, params, submissionData, setSubmissionDetailModal, getSubmissionDetail, setSelectedSubmission, setTaskDetail } = useContext(WorkoutDetailContext);
  const [submissionDetail, setSubmissionDetail] = useState({})
  const location = useLocation();
  const onChange = (key) => {
    console.log(key)
    setActiveTab(key)
  };
  let currentLink = "";

  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink += `/${crumb}`;
      if (crumb !== "home" && crumb !== "groups") {
        let newCrumb = crumb.replace("-", " ");
        return (
          <div className="crumb" key={newCrumb}>
            <Link
              // to={currentLink}
              style={{ cursor: "text" }}
            >{decodeURI(newCrumb)}</Link>
          </div>
        );
      }
    });
    const trainingTaskColumns = [
      {
        title: "Submission",
        dataIndex: "member",
        width: "50%",
        render: (text, record, index) => (
          <div span={20} className="member-div">
            {record.title}
            <span className="task-type">
              Submission {index + 1}
            </span>
          </div>
        ),
      },
      {
        title: "Completed",
        dataIndex: "completion_percentage",
        render: (text, record) => (
          <span>
            {getDateWithTimeWithoutDay(record.created_at)}
          </span>
        ),
      },
      {
        render: (record) => (
          <div style={{ display: "flex", cursor: "pointer" ,color : color}}>
            <div
              onClick={() => { setSelectedSubmission(record.id); setSubmissionDetailModal(true); getSubmissionDetail(record.id) }}
              className="view-submissions"
            >
              View submissions
            </div>
          </div>
        )
      },
    ];
    const scoredTaskColumns = [
      {
        title: "Submission",
        dataIndex: "member",
        width: "30%",
        render: (text, record, index) => (
          <div span={20} className="member-div">
            {record.title}
            <span className="task-type">
              Submission {index + 1}
            </span>
          </div>
        ),
      },
      {
        title: "Completed",
        dataIndex: "completion_percentage",
        render: (text, record) => (
          <span>
            {getDateWithTimeWithoutDay(record.created_at)}
          </span>
        ),
      },
      {
        title: "Score",
        dataIndex: "score",
        render: (text, record) => (
          <span>
            {record.score}
          </span>
        ),
      },
      {
        render: (record) => (
          <div style={{ display: "flex", cursor: "pointer" , color : color }}>
            <div
              onClick={() => { setSelectedSubmission(record.id); setSubmissionDetailModal(true); getSubmissionDetail(record.id) }}
              className="view-submissions"
            >
              View submissions
            </div>
          </div>
        )
      },
    ];
  return (
    <div className="workout-details-main">
      {/* {groupOwner === false ? ( */}
      <>
        <div className="heading-row">
          <FaArrowLeftLong
            className="left-arrow"
            style={{ margin:"33px 0 8px 23px" , color : color , stroke : color }}
            onClick={() => {
              setWorkoutDetail(false);
              // setSelectedWorkout({});
            }}
          />
          <h1>{workoutDetail.title}</h1>
        </div>
        {/* <span className="bread-crumb">Challenges - Shooting License</span> */}
        <hr />
        <Row gutter={16} className="graph-row">
          <Col className="gutter-row right-col" span={12}>
            <div style={style} className="div-1">
              <h2>Workout Information</h2>
              <div className="pending-tasks">
                {workoutDetail.reps_done}/
                <span>{workoutDetail.total_reps} reps</span>
              </div>
              <div className="percentage">
                {workoutDetail.completion_percentage}%
              </div>

              <Progress
                percent={workoutDetail.completion_percentage}
                showInfo={false}
                className={selectedSport === 1 ? "orange-progress-bar" : "blue-progress-bar" }
              />

              <Row
                gutter={16}
                className="inner-row"
                style={{ marginBottom: 10 }}
              >
                <Col className="gutter-row left-col" span={12}>
                  <div style={style} className="div-1">
                    <h2>{workoutDetail.total_tasks}</h2>
                    <span>Total Tasks</span>
                  </div>
                </Col>
                <Col className="gutter-row right-col" span={12}>
                  <div style={style} className="div-1">
                    <h2>{workoutDetail.total_reps}</h2>
                    <span>Total Reps</span>
                  </div>
                </Col>
              </Row>
              <div className="end-date">
                Ends:
                <span>{getDate(workoutDetail.end_date)}</span>
              </div>
            </div>
          </Col>
        </Row>
        <h2 className="task-heading">Tasks</h2>
        <Collapse
          onChange={onChange}
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            !isActive ? <img src={Expand} /> : <img src={CollapseIcon} className={"minus-icon " + (selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon")}/>
          }
          className="collapsible-rows"
        >
          {workoutDetail.tasks?.map((item, index) => {
            return (
              <Panel
                key={index}
                onClick={() => setTaskDetail(item.id)}
                header={
                  <div
                    className="collapse-label"
                    style={{ textTransform: "capitalize" }}
                  >
                    <Row style={{ width: "100%" }}>
                      <Col span={18} style={{ fontSize: 16 }}>
                        {item.title} ({item.skill_level})
                        <span>
                          {item.scored_task ? "Scored Task" : "Training Task"}
                        </span>
                      </Col>
                      <Col span={3}>
                        {" "}
                        {item.is_competed === true ? (
                          <div className="status">Completed</div>
                        ) : item.is_competed === false &&
                          item.completion_percentage === 0 ? (
                          <button
                            className="yellow-tag"
                            style={{ marginTop: "-3px" }}
                          >
                            Pending
                          </button>
                        ) : (
                          <Progress
                            percent={item.completion_percentage}
                            showInfo={false}
                            className={selectedSport === 1 ? "orange-progress-bar" : "blue-progress-bar" }
                          />
                        )}
                      </Col>
                      <Col span={3}>
                        <div className="reps">
                          Reps:{" "}
                          <span>
                            {item.reps_done}/{item.total_reps}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                }
              >
                <div className="task-data">
                  <h4>
                    You have completed {item.reps_done} of your{" "}
                    {item.total_reps} reps.
                  </h4>
                  {item?.media_required === false
                   ?
                  <Table
                    columns={item.media_required === false && item.scored_task === false ? trainingTaskColumns : scoredTaskColumns}
                    pagination={false}
                    dataSource={item?.submissions?.filter((obj) => obj.is_validated !== false)}
                    className="tasks-table"
                  /> :
                  <Row gutter={16} className="task-row submissions">
                    {item.submissions.map((detail, index) => (
                      detail.is_validated !== false && detail.status !== "pending" &&
                      <Col
                        className="gutter-row"
                        span={8}
                        style={{ paddingBottom: 16 }}
                        key={index}
                      >
                        <div style={style} className="div-1">
                          <Card
                            hoverable
                            style={{ cursor: detail.is_reviewed === true || detail.status === "live" ? "pointer" : "default", height: 230 }}
                            className="video-card"
                            onClick={
                              detail.is_reviewed === true || detail.status === "live" ?
                                () => { setSelectedSubmission(detail.id); setSubmissionDetailModal(true); getSubmissionDetail(detail.id) } : null}
                            cover={
                              detail?.is_video_progress === true
                                && detail?.upload_progress < 100
                                && detail.is_draft === false && detail.status !== 2
                                ?
                                <Progress
                                  percent={detail?.upload_progress}
                                  status="active"
                                  className="custom-progress-bar uploading-progress"
                                />
                                :
                                detail?.is_video_progress === true
                                  && detail?.upload_progress !== 0
                                  && detail.is_draft === true && detail.status !== 2 ?
                                  <Progress
                                    percent={detail?.upload_progress}
                                    status="active"
                                    className="custom-progress-bar uploading-progress"
                                  />
                                  :
                                  detail?.is_video_progress === true && detail?.upload_progress < 100 && detail.is_draft === true ?
                                    <>
                                      <div
                                        className={
                                          "status " +
                                          (
                                            detail.is_reviewed === true ? "green-tag"
                                              : detail.is_video_progress === true && detail?.upload_progress === 100 ? "yellow-tag" :
                                                detail.status === "live" ? "purple-tag" : detail.is_draft === true && detail.status !== 2 ? "orange1-tag" :
                                                  detail.status === 2 ? "red-tag" :
                                                    "red-tag")
                                        }
                                        style={{ textTransform: "capitalize", width: detail.status === "live" ? 130 : 88 }}
                                      >
                                        {detail.is_reviewed === true ?
                                          "Completed" :
                                          detail.is_video_progress === true && detail?.upload_progress === 100 ? "Transcoding" :
                                            detail.status === "live" ? "Ready for Review"
                                              : detail.is_draft === true && detail.status !== 2 ? "Draft" :
                                                detail.status === 2 ? "Failed" :
                                                  "Failed"}
                                      </div>

                                      <div id="video-container" style={{
                                        // background: "#F9F9F9",
                                        background: "#fff",
                                        borderRadius: 6
                                      }}>
                                        {detail?.media_thumbnail ?
                                          <div style={{
                                            display: "grid",
                                            placeItems: "center"
                                          }}>
                                            <img src={detail.media_thumbnail} style={{ width: "100%", height: "164px" }} />
                                            <img src={Play} style={{ width: 35, position: "absolute" }} />
                                          </div>
                                          :
                                          <div style={{
                                            display: "grid",
                                            placeItems: "center",
                                            height: "100%"
                                          }}>
                                            <img src={Thumbnail} style={{ width: "40%", marginTop: "12%" }}></img>
                                          </div>
                                        }
                                      </div>
                                    </>
                                    :
                                    <>
                                      <div
                                        className={
                                          "status " +
                                          (
                                            detail.is_reviewed === true ? "green-tag"
                                              : detail.is_video_progress === true && detail?.upload_progress === 100 ? "yellow-tag" :
                                                detail.status === "live" ? "purple-tag" : detail.is_draft === true && detail.status !== 2 ? "orange1-tag" :
                                                  detail.status === 2 ? "red-tag" :
                                                    "red-tag")
                                        }
                                        style={{ textTransform: "capitalize", width: detail.status === "live" ? 130 : 88 }}
                                      >
                                        {detail.is_reviewed === true ?
                                          "Completed" :
                                          detail.is_video_progress === true && detail?.upload_progress === 100 ? "Transcoding" :
                                            detail.status === "live" ? "Ready for Review"
                                              : detail.is_draft === true && detail.status !== 2 ? "Draft" :
                                                detail.status === 2 ? "Failed" :
                                                  "Failed"}
                                      </div>
                                      {/* } */}

                                      <div id="video-container">
                                        {detail?.media_thumbnail ?

                                          // <ReactPlayer
                                          //   ref={vidRef}
                                          //   url={detail.task_media}
                                          //   controls
                                          //   playing
                                          //   width={"100%"}
                                          //   height={"100%"}
                                          //   light={detail.media_thumbnail}
                                          //   playIcon={false}
                                          // // playIcon={<img src={Play} style={{ width: 35 }} />}
                                          // />
                                          <div style={{
                                            display: "grid",
                                            placeItems: "center"
                                          }}>
                                            <img src={detail.media_thumbnail} style={{ width: "100%", height: "164px" }} />
                                            <img src={Play} style={{ width: 35, position: "absolute" }} />
                                          </div>
                                          :
                                          // <img src={Thumbnail} className="thumbnail"></img>
                                          <div style={{
                                            display: "grid",
                                            placeItems: "center",
                                            height: "100%"
                                          }}>
                                            <img src={Thumbnail} style={{ width: "40%", marginTop: "12%" }}></img>
                                          </div>
                                        }
                                      </div>


                                    </>
                            }
                          >
                            {item.scored_task === true &&

                              <div>
                                <div className="score" >
                                  Score: {
                                    // detail.score === 0 &&
                                    detail.is_reviewed === true
                                      //  || detail.score 
                                      ?
                                      <span style={{ fontWeight: "bold", fontSize: 14, color: "rgba(0, 0, 0, 0.88)" }}>{detail.score}</span> : <span style={{ fontWeight: "bold", fontSize: 14, color: "rgba(0, 0, 0, 0.88)" }}>N/A</span>}
                                </div>

                              </div>
                            }
                            <div className="date" style={{ marginTop: item.scored_task === false ? 20 : 0 }}>
                              Submitted:{" "}
                              <span style={{ fontWeight: "bold" }}>{getDateWithoutDay(detail.created_at)}</span>
                            </div>
                          </Card>
                        </div>
                      </Col>
                    ))}
                  </Row>
          }
                </div>
              </Panel>
            );
          })}
        </Collapse>
      </>

      <SubmissionDetailModal generalDetail={generalDetail} submissionData={workoutDetail?.tasks} />
    </div>
  );
};

const WorkoutDetail = ({ setWorkoutDetail, selectedWorkout, setViewSubmissions, viewSubmissions, generalDetail }) => {
  return (
    <WorkoutDetailContextProvider selectedWorkout={selectedWorkout}>
      <WorkoutDetailPage setWorkoutDetail={setWorkoutDetail} setViewSubmissions={setViewSubmissions} viewSubmissions={viewSubmissions} generalDetail={generalDetail} />
    </WorkoutDetailContextProvider>
  );
};

export default WorkoutDetail;