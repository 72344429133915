import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  Tabs,
  Input,
  Button,
  Tooltip,
  Select,
  DatePicker,
  Form,
  message,
  Dropdown,
} from "antd";
import DownArrow from "../../../assets/images/arrows.svg";
import "./roles-permissions.scss";
import Plus from "../../../assets/images/plus.svg";
import Search from "../../../assets/images/search.svg";
import { useNavigate } from "react-router-dom";
import {
  RolesPermissionsContext,
  RolesPermissionsContextProvider,
} from "../../../context/setting-context/roles_permissions_context";
import AddNewRole from "./add-new-role";
import EditRole from "./edit-role";
import { UserContext } from "../../../context/user-context";
const { RangePicker } = DatePicker;
const RolesPermissionsPage = () => {
  const {darkButtonBackground , selectedSport} = useContext(UserContext)
  const [form] = Form.useForm();
  const {
    setSponsorModal,
    loading,
    pageSize,
    pageNumber,
    setPageNumber,
    total,
    setStatus,
    setSport,
    sort,
    setSort,
    setStartDate,
    setEndDate,
    addRoleModal,
    setAddRoleModal, editRoleModal, setEditRoleModal,
    roles,setSearch,setRoles,deleteRole,setSelectedRole,selectedRole
  } = useContext(RolesPermissionsContext);
  const navigate = useNavigate();
  const items = [
    {
      key: "edit",
      label: "Edit",
    },
    {
      key: "delete",
      label: "Delete",
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const columns = [
    {
      title: "role",
      dataIndex: "role_name",
      key: "role_name",
      width:"20%"
    },

    {
      title: "description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "ACTIONS",
      dataIndex: "member_limit",
      key: "member_limit",
      render: (text,record, key) => (
        <Dropdown
        placeholder="Actions"
        className={"custom-dropdown " + (selectedSport === 1 ? "orange-theme-dropdown" : "blue-theme-dropdown")}
        menu={{
          items,

          onClick: ({ key }) => {
            if (key === "delete") {
              deleteRole(record.id);
            } else {
              setSelectedRole(record)
              setEditRoleModal(true)
            }
          },
        }}
        overlayClassName={"custom-dropdown-menu " + (selectedSport === 1 ? "orange-theme-dropdown" : "blue-theme-dropdown")}
        >
        <a onClick={(e) => e.preventDefault()}>
          Actions
          <img src={DownArrow} />
        </a>
      </Dropdown>
      ),
    },
  ];

  const data = [];
  for (let i = 0; i < 46; i++) {
    data.push({
      role: "San Antonio FC",
      description:
        "The user who can view the content and manage the content they have access to.",
    });
  }
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const searchGroup = (e) => {
    if (e.target.value.length >= 3) {
      // setPageNumber(1);
      setRoles([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setRoles((roles) => []);
      setSearch("");
    }
  };
  const onChangeSort = (value) => {
    if (value === "newest") {
      setSort("created_at");
    } else {
      setSort("-created_at");
    }
  };
  return (
    <div className="roles-permissions-main">
      <div className="table-div">
        <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchGroup}
          />
          <div className="right-div">
            <Select
              placeholder="Newest"
              optionFilterProp="children"
              onChange={onChangeSort}
              className={"custom-action-select " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
              popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
              suffixIcon={<img src={DownArrow} />}
              style={{ height: 40, width: 135 }}
              options={[
                {
                  value: "newest",
                  label: "Newest",
                },
                {
                  value: "oldest",
                  label: "Oldest",
                },
              ]}
            />
            <Button
              icon={<img src={Plus} />}
              className="dark-base-btn"
              style={{ marginLeft: "auto" , background : darkButtonBackground }}
              onClick={()=>{form.resetFields();setAddRoleModal(true)}}
            >
              Create New
            </Button>
          </div>
        </div>
        <Table
          loading={loading}
          rowKey={(obj) => obj.id}
          // rowSelection={rowSelection}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current: pageNumber,
            pageSize: pageSize,
            total: total,
          }}
          columns={columns}
          dataSource={roles}
          className={"table-container " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
        />
      </div>
      {Object.keys(selectedRole).length > 0 &&
      <EditRole editRoleModal={editRoleModal} setEditRoleModal={setEditRoleModal} /> }
      <AddNewRole addRoleModal={addRoleModal} setAddRoleModal={setAddRoleModal}/>
    </div>
  );
};

const RolesPermissions = () => {
  return (
    <RolesPermissionsContextProvider>
      <RolesPermissionsPage />
    </RolesPermissionsContextProvider>
  );
};
export default RolesPermissions;
