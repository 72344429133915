import React, { useContext, useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Modal,
  Skeleton,
  Checkbox,
  Radio,
  Form,
} from "antd";
import Search from "../../../assets/images/search.svg";
import Avatar from "react-avatar";
import DownArrow from "../../../assets/images/arrows.svg";
import "./sport-modal.scss";
import { MemberContext } from "../../../context/group-context/members-context";
import InfiniteScroll from "react-infinite-scroll-component";
import { UserContext } from "../../../context/user-context";
const formItemLayout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
};
const SportModal = ({ sportModal, setSportModal }) => {
  const {selectedSport} = useContext(UserContext)
  const [form] = Form.useForm();
  const [value, setValue] = useState(1);
  const handleOk = (e) => {
  };
  const handleCancel = () => {
    setSportModal(false);
  };
  return (
    <Modal
      wrapClassName={"sport-modal " + (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")}
      open={sportModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Finish Adding"}
      cancelText={"Cancel"}
      width={"989px"}
    >
      <h1>Add Another Sport</h1>
      <Form
        form={form}
        name="dynamic_rule"
        style={{
          padding: "22px 30px ",
        }}
        className="sport-form"
      >
                <Form.Item
          {...formItemLayout}
          name="sport"
          label="Sport"
          className="sport-div"
        >
          <Select
            placeholder="Select a city"
            optionFilterProp="children"
            popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            style={{ height: "48px" }}
            suffixIcon={<img src={DownArrow} />}
            className="location-select"
            options={[
              {
                value: "jack",
                label: "Jack",
              },
              {
                value: "lucy",
                label: "Lucy",
              },
              {
                value: "tom",
                label: "Tom",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          className="skills-div"
          label="Skill Level"
          rules={[
            {
              required: true,
              message: "Please input your name",
            },
          ]}
        >
            <Select
              showSearch
              placeholder="Level 2: Intermediate (age 11-15)"
              optionFilterProp="children"
              popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
              style={{ height: "48px" }}
              suffixIcon={<img src={DownArrow} />}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              className="skill-select"
              options={[
                {
                  value: "jack",
                  label: "Jack",
                },
                {
                  value: "lucy",
                  label: "Lucy",
                },
                {
                  value: "tom",
                  label: "Tom",
                },
              ]}
            />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="shooting-hand"
          label="Shooting Hand"
          className="shooting-div"
        >
          <Radio.Group
            // value={value}
            // defaultValue={value}
            className="custom-radio"
          >
            <Radio value={1}>Right</Radio>
            <Radio value={2}>Left</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="positions"
          label="Positions"
          className="sport-div position-div"
        >
          <Checkbox.Group className="custom-checkbox">
            <Checkbox value="pg">PG</Checkbox>
            <Checkbox value="sg">SG</Checkbox>
            <Checkbox value="sf">SF</Checkbox>
            <Checkbox value="pf">PF</Checkbox>
            <Checkbox value="c">C</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SportModal;
