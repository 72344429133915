import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  Tabs,
  Input,
  Button,
  Tooltip,
  Select,
  DatePicker,
  Form,
  Dropdown,
} from "antd";
import DownArrow from "../../../assets/images/arrows.svg";
import "./sponsors.scss";
import Plus from "../../../assets/images/plus.svg";
import Search from "../../../assets/images/search.svg";
import Calendar from "../../../assets/images/calendar.svg";
import { useNavigate } from "react-router-dom";
import { getDate, getDateWithTime } from "../../../util/config";
import Avatar from "react-avatar";
import { SponsorsContext , SponsorsContextProvider } from "../../../context/setting-context/sponsors_context";
import AddSponsorModal from "./add-new-sponsor";
import EditSponsor from "./edit-sponsor";
import { UserContext } from "../../../context/user-context";
const { RangePicker } = DatePicker;
const SponsorsPage = () => {
  const {darkButtonBackground , selectedSport} = useContext(UserContext)
  const [form] = Form.useForm();

  const {
    setSponsorModal,
    loading,
    pageSize,
    pageNumber,
    setPageNumber,
    total,
    allRotators,
    sportsList,
    uploadVideo,
    sport,
    setStatus,
    setSport,
    sort,
    setSort,
    setStartDate,
    setEndDate,
    addSponsorModal, setAddSponsorModal,sponsorsList,deleteSponsor,setSelectedSponsor,setEditSponsorModal , editSponsorModal
  } = useContext(SponsorsContext);
  const navigate = useNavigate();
  const dateFormat = "ddd. MMMM D, YYYY";
  console.log(sponsorsList)
  const onChange = (key) => {
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const items = [
    // {
    //   key: "sponsor",
    //   label: "Sponsor Report",
    // },
    {
      key: "edit",
      label: "Edit",
    },
    {
      key: "delete",
      label: "Delete",
    },
  ]
  const columns = [

    {
        title: "logo",
        dataIndex: "icon",
        key: "icon",
          render: (text, record, index) => {
            return record.icon ? (
              <img src={record.icon} className="logo-avatar" />
            ) : (
              <Avatar
                name={record.name}
                style={{ marginRight: 10 }}
                color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                className="logo-avatar"
                maxInitials={2}
              />
            );
          },
      },
  
      {
        title: "presented by",
        dataIndex: "name",
        key: "name",
        render:(text , record , index )=>{
          return(
            <div>{record.name && record.name !== "undefined" ? record.name : "N/A"}</div>
          )
        }
      },
      {
        title: "sponsor url",
        dataIndex: "website",
        key: "website",
        render:(text , record , index )=>{
          return(
            <div>{record.website && record.website !== "undefined" ? record.website : "N/A"}</div>
          )
        }
      },
    {
      title: "ACTIONS",
      dataIndex: "member_limit",
      key: "member_limit",
      render: (text, record,key) => (
        <Dropdown
        placeholder="Actions"
        className={"custom-dropdown " + (selectedSport === 1 ? "orange-theme-dropdown" : "blue-theme-dropdown")}
        menu={{
          items,

          onClick: ({ key }) => {
            if (key === "delete") {
              deleteSponsor(record);
            }else if(key === "edit"){
              setSelectedSponsor(record);
              setEditSponsorModal(true);
            } 
            else {
              setSelectedSponsor(record);
              setEditSponsorModal(true);
            }
          },
        }}
        overlayClassName={"custom-dropdown-menu " + (selectedSport === 1 ? "orange-theme-dropdown" : "blue-theme-dropdown")}
        >
        <a onClick={(e) => e.preventDefault()}>
          Actions
          <img src={DownArrow} />
        </a>
      </Dropdown>
        // <Select
        //   showSearch
        //   index={key}
        //   placeholder="Actions"
        //   optionFilterProp="children"
        //   onChange={onChange}
        //   suffixIcon={<img src={DownArrow} style={{ marginTop: 6 }} />}
        //   className="custom-action-select"
        //   popupClassName="custom-action-select-dropdown"
        //   options={[
        //     {
        //       value: "sponsor",
        //       label: "Sponsor Report",
        //     },
        //     {
        //       value: "edit",
        //       label: "Edit",
        //     },
        //     {
        //       value: "delete",
        //       label: "Delete",
        //     },
        //   ]}
        // />
      ),
    },
  ];

  const data = [];
  for (let i = 0; i < 46; i++) {
    data.push({
      sponsor: "Sponsor 1",
      presented_by:
        "Sponsor 1",
    });
  }
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const gotoGroupDetail = (record) => {
    navigate({
      pathname: `./${record.name}`,
      search: `?id=${record.id}`,
    });
  };
  const searchGroup = (e) => {
    // if (e.target.value.length >= 3) {
    //   setPageNumber(1);
    //   setGroups([]);
    //   setSearch(e.target.value);
    // } else if (e.target.value.length === 0) {
    //   setGroups((groups) => []);
    //   setSearch("");
    // }
  };
  console.log(allRotators);
  const onChangeSort = (value) => {
    if (value === "newest") {
      setSort(true);
    } else {
      setSort(false);
    }
  };
  const onChangeSport = (value) => {
    console.log(value);
    setSport(value);
  };
  const onChangeStatus = (value) => {
    console.log(value);
    setStatus(value);
  };
  const onChangeRangePicker = (e) => {
    if (e) {
      setStartDate(e[0].format("YYYY-MM-DD"));
      setEndDate(e[1].format("YYYY-MM-DD"));
    } else {
      setStartDate("");
      setEndDate("");
    }
  };
  return (
    <div className="sports-main">
      <div className="table-div">
        <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchGroup}
          />
          <div className="right-div">
            <Button
              icon={<img src={Plus} />}
              className="dark-base-btn"
              style={{ marginLeft: "auto", background : darkButtonBackground }}
              //   onClick={() => navigate(`./create-new-group`)}
              onClick={() => setAddSponsorModal(true)}
            >
              Add New
            </Button>
          </div>
        </div>
        <Table
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: () => gotoGroupDetail(record),
          //   };
          // }}
          loading={loading}
          // onChange={handleTableChange}
          rowKey={(obj) => obj.id}
          // rowSelection={rowSelection}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current: pageNumber,
            pageSize: pageSize,
            total: total,
          }}
          columns={columns}
          dataSource={sponsorsList}
          className={"table-container " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
        />
      </div>
      <AddSponsorModal  addSponsorModal={addSponsorModal} setAddSponsorModal={setAddSponsorModal}/>
      <EditSponsor editSponsorModal={editSponsorModal} setEditSponsorModal={setEditSponsorModal} />
    </div>
  );
};

const Sponsors = () => {
  return (
    <SponsorsContextProvider>
      <SponsorsPage />
    </SponsorsContextProvider>
  );
};
export default Sponsors;
