import React, { useContext, useState } from "react";
import Logo from "../../assets/images/logo.svg";
// import CoachLogo from "../../assets/images/coach-logo.svg";
import { Row, Form, Input, Button, Tabs, message } from "antd";
import PhoneInput from "react-phone-number-input";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { userBaseURL } from "../../util/config";
import { UserContext } from "../../context/user-context";
const LogIn = () => {
  const {darkButtonBackground} = useContext(UserContext)
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [phoneNumber, setPhoneNumber] = useState();
  const [activeTab, setActiveTab] = useState("1")
  const tabItems = [
    {
      key: "1",
      label: `Phone No.`,
    },
    {
      key: "2",
      label: `Email`,
    },
  ];
  const changeTabs = (e) => {
    setActiveTab(e)
  }
  const signIn = (e) => {
    let data;
    if (e.phone) {
      data = {
        "phone": e.phone,
        "resend": false
      }
    } else {
      data = {
        "email": e.email,
        "resend": false
      }
    }
    axios.post(`${userBaseURL}/coach/send-otp`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoyMDIyMzg5NDc2LCJpYXQiOjE3MTEzNDk0NzYsImp0aSI6ImJiZTA0OWY4ZDRhZjRkNjI5YjY5M2FiNTY1MDE5MDNmIiwidXNlcl9pZCI6MSwidXNlciI6eyJpZCI6MSwiZmlyc3RfbmFtZSI6bnVsbCwibGFzdF9uYW1lIjpudWxsLCJwaG9uZSI6IjAwMDAwMDAwMDAwIiwiZW1haWwiOiJhZG1pbkBiYWxsb2d5LmNvbSIsImRvYiI6bnVsbCwidHlwZSI6bnVsbCwiaXNfYWN0aXZlIjp0cnVlLCJpc19zdGFmZiI6dHJ1ZSwiaXNfYWRtaW4iOnRydWUsImlzX3N1cGVydXNlciI6dHJ1ZX0sInNlbGVjdGVkX3Byb2ZpbGUiOnsiaWQiOjEsInVzZXJuYW1lIjoiYmFsbG9neSIsImF2YXRhciI6bnVsbCwiZmlyc3RfbmFtZSI6IkJhbGxvZ3kiLCJsYXN0X25hbWUiOm51bGwsImRhdGVfb2ZfYmlydGgiOiIxOTkwLTA4LTExIiwiZ2VuZGVyIjoidW5kaXNjbG9zZWQiLCJsb2NhdGlvbiI6bnVsbCwicG9zaXRpb25fcGciOm51bGwsInBvc2l0aW9uX3NnIjpudWxsLCJwb3NpdGlvbl9zZiI6bnVsbCwicG9zaXRpb25fcGYiOm51bGwsInBvc2l0aW9uX2MiOm51bGwsInRlYW1fZ3JvdXBzIjpbXX19._DhjiRKHEniqmxbhlemc-7jeypc9tyHzSGZpor6OWQQ`,
      },
    }).then((res) => {
      console.log(res)
      if (e.phone) {
        navigate({
          pathname: `/coach/verification`,
          search: `?phone=${e.phone}`,
        });
        form.resetFields()

      } else {
        navigate({
          pathname: `/coach/verification`,
          search: `?email=${e.email}`,
        });
      }
    }).catch((err) => {
      if(err?.response?.status === 404){
      message.error({
        content : err.response.data.details
      })
    }
    })
  }
  return (
    <Row
      className="login-main"
      style={{
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="form-div">
        <img src={Logo} />
        {/* <img src={CoachLogo} /> */}
        <h1>Sign in as a Coach</h1>
        <span className="span">
          Enter your email or phone number to login.{" "}
        </span>
        <Tabs
          defaultActiveKey="1"
          centered
          items={tabItems}
          className="login-tabs"
          onChange={changeTabs}
        />
        <Form form={form} name="dynamic_rule" onFinish={signIn}>
          {" "}
          {activeTab === "1" ?
            <Form.Item
              name="phone"
              label=""
              className="name-row"
              rules={[
                {
                  required: true,
                  message: "Please enter phone number.",
                },
              ]}
            >
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="US"
                value={phoneNumber}
                onChange={setPhoneNumber}
                placeholder="Enter your phone no"
              />
            </Form.Item>
            :
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
              ]}
            >
              <Input placeholder="Enter you email" className="custom-input" style={{ height: "47px" }} />
            </Form.Item>
          }
          <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
            <Button htmlType="submit" className="dark-base-btn" style={{background : darkButtonBackground}}>
              Sign in
            </Button>
          </Form.Item>
        </Form>{" "}
        {activeTab === "1" ?
          <span style={{ color: "#A1A5B7", fontWeight: 500 }}>
            An OTP will be sent to the provided phone number.
          </span>
          :
          <span style={{ color: "#A1A5B7", fontWeight: 500 }}>An OTP will be sent to the provided email.</span>
        }
      </div>
    </Row>
  );
};
export default LogIn;
