import { useContext } from "react";
import { Modal } from "antd";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
// import { EditTaskContext } from "../../../context/edit-task-context";
import { EditFeatureWorkoutTaskContext } from "../../../context/challenge-workout-context/edit-feature-workout-context";
import { UserContext } from "../../../context/user-context";
const UpdateFeatureTaskModal = ({
  updateTaskModal,
  setUpdateTaskModal,
  selectedTask,
  setNewTaskArray,
  setEditTaskModal,
  newTaskArray,
  setExistingTaskModal,
  curriculum_group
}) => {
  const { setUpdateTask, postUpdateTask } = useContext(EditFeatureWorkoutTaskContext);
  const {selectedSport , color} = useContext(UserContext)
  const handleOk = () => {
    setUpdateTask(false);
    setUpdateTaskModal(false);
    setEditTaskModal(false);
    setExistingTaskModal(false)
    postUpdateTask(
      selectedTask,
      setNewTaskArray,
      newTaskArray,
      setEditTaskModal,
    );
  };
  const handleCancel = () => {
    setUpdateTaskModal(false);
    setUpdateTask(true);
    setExistingTaskModal(false)
    postUpdateTask(
      selectedTask,
      setNewTaskArray,
      newTaskArray,
      setEditTaskModal,
    );
    setEditTaskModal(false);
  };
  return (
    <Modal
      className="privacy-modal"
      open={updateTaskModal}
      onOk={handleOk}
      wrapClassName={selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"}
      onCancel={handleCancel}
      okText={"Just This Assignment"}
      cancelText={"Update This Task Template"}
      width={"608px"}
    >
      <h1>Update Task Options</h1>
      <p>You made modifications to this Task.</p>
      <p>
        Would you like to use them just this once, or save the Task template for
        future use?
      </p>
      <div className={"info-div " + (selectedSport === 1 ? "blue-theme" : "orange-theme")}>
        {/* <img src={Support} className={"loader " + (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")}/> */}
        <ExclamationCircleTwoTone
          twoToneColor={color}
          style={{ fontSize: 20 }}
        />
        <p className="status">
          Current and previous assignments of this task will NOT be affected
        </p>
      </div>
    </Modal>
  );
};
export default UpdateFeatureTaskModal;
