import React, { useEffect, useState } from "react";
import { message, Form, Button, Modal, Select, Dropdown, Menu } from "antd";
import { getDataCoach, formPostData, patchData, deleteItem } from "../../util/coach-api-calls";
import { getDateWithTime } from "../../util/config";
import dayjs from "dayjs";
const { confirm } = Modal;
export const AnnouncementsContext = React.createContext({});
export const AnnouncementsContextProvider = ({ children = {} }) => {
  const [announcementsList , setAnnouncementsList ] = useState([])
  const [pageNumber , setPageNumber] = useState(1)
  const [pageSize , setPageSize] = useState(10)
  const [commentValue, setCommentValue] = useState("");
  const [totalAnnouncements , setTotalAnnouncements] = useState(0)
  const [allComments , setAllComments] = useState({})
  const [comment , setComment] = useState([])
  const [modal, setModal] = useState(false);
  const [editAnnouncementModal , setEditAnnouncementModal] = useState(false)
  const [deleteModal , setDeleteModal] = useState(false)
  const [clickedAnnouncement , setClickedAnnouncement] = useState({})
  const [loading , setLoading] = useState(false)
  const [sort, setSort] = useState(true);
  const [postId , setPostId] = useState()
  const [commentsData , setCommentsData] = useState()
  const [accessToken , setAccessToken] = useState("")
  const [twillioId , setTwillioId] = useState("")
    const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }

const getAnnouncementsList = (loading) =>{
  if(loading !== false){
  setLoading(true)
  }
  getDataCoach(`group/${params.id}/announcement`).then((response) => {
    setAnnouncementsList(response.data)
}).catch((error) => {
  console.log(error)
}).finally(()=>{
  setLoading(false)
})
}

const getPostComments = (e) => {
  getDataCoach(`twilio/generate/token?room_id=${e}`).then((res)=>{
    setAccessToken(res.data.access_token)
    getDataCoach(`twilio/get-chat-room-messages/${e}`,{headers: {
      // Accept: "application/vnd.vimeo.*+json;version=3.4",
      "Content-Type": "application/json",
      Authorization: `Bearer ${res.data.access_token}`,
    }},).then((res)=>{
      console.log(res.data)
      setCommentsData(res.data)
      getAnnouncementsList(false);
    }).catch((err)=>{
      console.log(err)
    })
  }).catch((err)=>{
    console.log(err)
  })
}


const createAnnouncement = (e) => {
  setLoading(true)
  let schedule_start = new Date(Date.parse(e.schedule_start)).toISOString(
    "sv-SE"
  );
  let schedule_end = new Date(Date.parse(e.schedule_end)).toISOString(
    "sv-SE"
  );
  let formData = new FormData;
  formData.append('text' , e.text)
  formData.append('title' , e.title)
  formData.append('schedule_start' , schedule_start)
  formData.append('schedule_end' , schedule_end)
  formPostData(`group/${params.id}/announcement` , formData).then((res)=>{
    setModal(false)
    getAnnouncementsList();
  }).catch((err)=>{
    console.log(err)
    message.error({
      content:err.response.data.details
    })
  }).finally(()=>{
    setLoading(false)
  })
}
const addComment = (e , id) => {
  let formData = new FormData;
  formData.append('comment' , e)
  formPostData(`post/${id}/engagement` , formData).then((res)=>{
    setCommentValue("")
    setComment(res.data)
    getPostComments(postId)
  }).catch((err)=>{
    console.log(err)
  })
}
const getAllEngagement = () => {
  getDataCoach(`post/${params.id}/engagement`).then((res)=>{
  }).catch((err)=>{
    console.log(err)
  })
}


const getComments = (e) =>{
  // http://54.218.14.209/api/v1/group/64ddfc9c0581bb1dc325b474/announcement/64e5a51a1834a22ba0c10c1c
  setComment([])
  getDataCoach(`group/${params.id}/announcement/${e}`).then((res)=>{
    setAllComments(res.data)

  }).catch((err)=>{
    console.log(err)
  })
}
const updateAnnouncement = (value) => {
  let schedule_start = new Date(Date.parse(value.schedule_start)).toISOString(
    "sv-SE"
  );
  let schedule_end = new Date(Date.parse(value.schedule_end)).toISOString(
    "sv-SE"
  );
  let formData = new FormData;
  formData.append('text' , value.text)
  formData.append('title' , value.title)
  formData.append('schedule_start' , schedule_start)
  formData.append('schedule_end' , schedule_end)
  patchData(`group/${params.id}/announcement/${clickedAnnouncement.id}` , formData).then((res)=>{
    setEditAnnouncementModal(false)
    getAnnouncementsList();
  }).catch((err)=>{
    // message.error({
    //   content:err.response.data.details
    // })
  })
}
const deleteAnnouncement = () => {
  deleteItem(`group/${params.id}/announcement/${clickedAnnouncement.id}`).then((res)=>{
    if(res && res.status === 200){
      setDeleteModal(false)
      message.success({
        content:"Announcement deleted successfully."
      })
      getAnnouncementsList()
    }
  }).catch((err)=>{
    console.log(err)
  })
}
  React.useEffect(()=>{
    getAnnouncementsList()
    // getAllEngagement();
  } , [sort]) 
  const defaultContext = {
    deleteAnnouncement,getPostComments,postId,setPostId,commentsData , setCommentsData,setTwillioId,accessToken,twillioId,
    getAnnouncementsList ,totalAnnouncements,announcementsList,createAnnouncement,sort,setSort,clickedAnnouncement,setClickedAnnouncement,updateAnnouncement,
    addComment,getComments,allComments,comment,commentValue,setCommentValue,modal,setModal,loading,editAnnouncementModal,setEditAnnouncementModal,deleteModal,setDeleteModal
  };
  return (
    <AnnouncementsContext.Provider value={defaultContext}>
      {children}
    </AnnouncementsContext.Provider>
  );
};
