import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getDataTraining,
  jsonPostDataTraining,
} from "../../util/api-calls";

export const WorkoutDetailContext = React.createContext({});
export const WorkoutDetailContextProvider = ({
  children = {},
  selectedWorkout,
}) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [taskDetail, setTaskDetail] = useState({});
  const [workoutDetail, setWorkoutDetail] = useState({});
  const [sort, setSort] = useState("desc");
  const [search, setSearch] = useState("");
  const [selectedTask, setSelectedTask] = useState("");
  const [workoutProgress, setWorkoutProgress] = useState([]);
  const [graphModal, setGraphModal] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [submissionData, setSubmissionData] = useState({});
  const [submissionDetail, setSubmissionDetail] = useState({});
  const [score, setScore] = useState();
  const [gradeInput, setGradeInput] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState("");
  const [commentsData, setCommentsData] = useState();
  const [commentValue, setCommentValue] = useState("");
  const [profileId, setProfileId] = useState("");
  const [submissionRecord, setSubmissionRecord] = useState({});
  const [activeTab, setActiveTab] = useState("");
  const [submissionDetailModal, setSubmissionDetailModal] = useState(false);
  const getWorkoutDetail = () => {
    getDataTraining(`admin/workout/${selectedWorkout.id}`)
      .then((res) => {
        setWorkoutDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getFWDetail = () => {
    getDataTraining(
      `admin/group/${params.id}/feature-workout/${selectedWorkout?.id ? selectedWorkout?.id : params.workout_id}`
    )
      .then((res) => {
        setWorkoutDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getWorkoutProgress = () => {
    let url = "";
    if (selectedTask === "" && search === "") {
      url = `admin/workout/${selectedWorkout.id}/progress?sort=${sort}`;
    } else if (search !== "" && selectedTask === "") {
      url = `admin/workout/${selectedWorkout.id}/progress?sort=${sort}&search=${search}`;
    } else if (search === "" && selectedTask !== "") {
      url = `admin/workout/${selectedWorkout.id}/progress?sort=${sort}&task_id=${selectedTask}`;
    } else {
      url = `admin/workout/${selectedWorkout.id}/progress?sort=${sort}&task_id=${selectedTask}&search=${search}`;
    }
    getDataTraining(url)
      .then((res) => {
        setWorkoutProgress(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getFWProgress = () => {
    let url = "";
    if (selectedTask === "" && search === "") {
      url = `admin/group/${params.id}/feature-workout/${selectedWorkout?.id ? selectedWorkout?.id : params.workout_id}/progress?sort=${sort}`;
    } else if (search !== "" && selectedTask === "") {
      url = `admin/group/${params.id}/feature-workout/${selectedWorkout?.id ? selectedWorkout?.id : params.workout_id}/progress?sort=${sort}&search=${search}`;
    } else if (search === "" && selectedTask !== "") {
      url = `admin/group/${params.id}/feature-workout/${selectedWorkout?.id ? selectedWorkout?.id : params.workout_id}/progress?sort=${sort}&task_id=${selectedTask}`;
    } else {
      url = `admin/group/${params.id}/feature-workout/${selectedWorkout?.id ? selectedWorkout?.id : params.workout_id}/progress?sort=${sort}&task_id=${selectedTask}&search=${search}`;
    }
    getDataTraining(url)
      .then((res) => {
        setWorkoutProgress(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (selectedWorkout?.is_fw === true || params?.workout_id) {
      getFWProgress();
    } else {
      getWorkoutProgress();
    }
  }, [sort, selectedTask, search]);
  useEffect(() => {
    if (selectedWorkout?.is_fw === true || params?.workout_id) {
      getFWDetail();
    } else {
      getWorkoutDetail();
    }
  }, []);
  const getSubmissions = (e, taskId) => {
    let url = "";
    if (selectedWorkout?.is_fw === true || selectedWorkout?.is_assigned) {
      url = `admin/feature/task/${
        taskDetail.id ? taskDetail.id : taskId
      }/profile/${e.id}/submissions`;
    } else {
      url = `admin/task/${taskDetail.id ? taskDetail.id : taskId}/profile/${
        e.id
      }/submissions`;
    }
    getDataTraining(url)
      .then((res) => {
        setSubmissionData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubmissionDetail = (e) => {
    let url = "";
    if (selectedWorkout?.is_fw === true  || selectedWorkout?.is_assigned) {
      url = `feature/task/submission/media/${
        selectedSubmission === "" ? e : selectedSubmission
      }`;
    } else {
      url = `admin/task/submission/media/${
        selectedSubmission === "" ? e : selectedSubmission
      }`;
    }
    getDataTraining(url)
      .then((res) => {
        setSubmissionDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const invalidateSubmission = (valid, profileId) => {
    let data = {
      is_validate: valid,
      score: valid === false ? 0 : score,
      task_submission_id: submissionDetail.task_submission_id,
      task_submission_media_id: submissionDetail.id,
    };
    let featureData = {
      fw_task_id: submissionDetail.feature_task_id,
      fw_task_media_id: submissionDetail.id,
      is_validate: valid,
      score: valid === false ? 0 : score,
    };
    let url = "";
    if (selectedWorkout?.is_fw === true) {
      url = `review/feature/task`;
    } else {
      url = `review/task`;
    }
    jsonPostDataTraining(
      url,
      selectedWorkout?.is_fw === true ? featureData : data
    )
      .then((res) => {
        getSubmissionDetail();
        setGradeInput(false);
        setSubmissionDetailModal(false);
        getSubmissions(profileId, taskDetail.id);
        setScore();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const videoPlayed = (valid) => {
    let data = {
      is_validate: valid,
      score: submissionDetail?.score ? submissionDetail?.score : 0,
      task_submission_id: submissionDetail.task_submission_id,
      task_submission_media_id: submissionDetail.id,
    };
    let featureData = {
      fw_task_id: submissionDetail.feature_task_id,
      fw_task_media_id: submissionDetail.id,
      is_validate: valid,
      score: submissionDetail?.score ? submissionDetail?.score : 0,
    };
    let url = "";
    if (selectedWorkout?.is_fw === true) {
      url = `review/feature/task`;
    } else {
      url = `review/task`;
    }
    jsonPostDataTraining(
      url,
      selectedWorkout?.is_fw === true ? featureData : data
    )
      .then((res) => {
        getSubmissionDetail();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addComment = (e, id) => {
    let data = {
      text: commentValue,
    };
    jsonPostDataTraining(`task/${submissionDetail.id}/feedback`, data)
      .then((res) => {
        setCommentValue("");
        getChat();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getChat = () => {
    getDataTraining(`task/${submissionDetail.id}/feedback`)
      .then((res) => {
        setCommentsData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const defaultContext = {
    workoutDetail,
    sort,
    setSort,
    setSelectedTask,
    search,
    setSearch,
    workoutProgress,
    setWorkoutProgress,
    setGraphModal,
    graphModal,
    graphData,
    setGraphData,
    getSubmissions,
    setTaskDetail,
    submissionData,
    getSubmissionDetail,
    submissionDetail,
    setSubmissionDetail,
    invalidateSubmission,
    score,
    setScore,
    gradeInput,
    setGradeInput,
    setSelectedSubmission,
    getChat,
    commentsData,
    addComment,
    commentValue,
    setCommentValue,
    submissionRecord,
    setSubmissionRecord,
    profileId,
    setProfileId,
    activeTab,
    setActiveTab,
    submissionDetailModal,
    setSubmissionDetailModal,
    params,
    videoPlayed,
    selectedWorkout,
    // viewSubmissions,
    // setViewSubmissions
  };
  return (
    <WorkoutDetailContext.Provider value={defaultContext}>
      {children}
    </WorkoutDetailContext.Provider>
  );
};
