// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-main .button-div {
  display: flex;
  gap: 16px;
}
.login-main .button-div button {
  padding: 32px;
  border-color: #e1e3ea;
  font-weight: 600;
  display: grid;
  height: 134px;
  width: 187px;
  color: #7E8299 !important;
  border: none !important;
  border-bottom: 3px solid #F15A29 !important;
}
.login-main .button-div button .ant-btn-icon {
  font-size: 40px;
}
.login-main .button-div button:hover {
  background: #FFF1ED !important;
  color: #F15A29 !important;
}`, "",{"version":3,"sources":["webpack://./src/Main-screen/main-screen.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,SAAA;AADJ;AAEI;EACI,aAAA;EACA,qBAAA;EACA,gBAAA;EACA,aAAA;EACA,aAAA;EACA,YAAA;EACA,yBAAA;EACA,uBAAA;EACA,2CAAA;AAAR;AACQ;EACI,eAAA;AACZ;AAEI;EACI,8BAAA;EACA,yBAAA;AAAR","sourcesContent":[".login-main {\n\n.button-div{\n    display: flex;\n    gap: 16px;\n    button{\n        padding: 32px;\n        border-color: #e1e3ea;\n        font-weight: 600;\n        display: grid;\n        height: 134px;\n        width: 187px;\n        color: #7E8299 !important;\n        border: none !important;\n        border-bottom: 3px solid #F15A29 !important;\n        .ant-btn-icon{\n            font-size: 40px;\n        }\n    }\n    button:hover{\n        background: #FFF1ED !important;\n        color: #F15A29 !important;\n    }\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
