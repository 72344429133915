import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Tabs, Input, Table, Select, DatePicker } from "antd";
import "./user-detail.scss";
import UserOverview from "./user-overview";
import UserProfile from "./user-profile";
import { UserDetailContext, UserDetailContextProvider } from "../../../context/user-module-context/user-detail-context";
import { getDateWithoutDay } from "../../../util/config";
import { UserContext } from "../../../context/user-context";

const UserDetailPage = () => {
  const {selectedSport} = useContext(UserContext)
  const {getSingleUser , userData} = useContext(UserDetailContext)
  useEffect(()=>{
    getSingleUser();
  },[])
  const items = [
    {
      key: "1",
      label: "Overview",
      children: <UserOverview userData={userData}/>,
    },
    {
      key: "2",
      label: "Profiles",
      children: <UserProfile />,
    },
  ];
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <div className="user-detail-main">
      <div className="div-1">
        <h1 style={{paddingBottom:10}}>{userData?.first_name + " " + userData?.last_name}</h1>
        <span className="creation-date">Account created {getDateWithoutDay(userData?.created_at)}</span>
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className={"user-detail-tabs " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
        />
      </div>
    </div>
  );
};
const UserDetail = () => {
  return(
    <UserDetailContextProvider>
      <UserDetailPage />
    </UserDetailContextProvider>
  )
}
export default UserDetail;
