import React, { useEffect, useState } from "react";
import { deleteItem, getData, jsonPostData } from "../../util/api-calls";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
export const UserModuleContext = React.createContext({});
export const UserModuleContextProvider = ({ children = {} }) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const navigate = useNavigate()
  const [allProfiles , setAllProfiles] = useState([])
  const [pageNumber, setPageNumber] = useState(1);
  const [userPageNumber, setUserPageNumber] = useState(1);
  const [userPageSize , setUserPageSize] = useState(10)
  const [profilePageSize , setProfilePageSize] = useState(10)
  const [search , setSearch] = useState("")
  const [totalProfile , setTotalProfiles] = useState(0)
  const [profileGender , setProfileGender] = useState("male,female")
  const [profileSort , setProfileSort] = useState(true)
  const [profileStartDate , setProfileStartDate] = useState("") 
  const [profileEndDate , setProfileEndDate] = useState("") 
  const [userSearch , setUserSearch] = useState("")
  const [userSort , setUserSort] = useState(true)
  const [userStartDate , setUserStartDate] = useState("") 
  const [userEndDate , setUserEndDate] = useState("") 
  const [allUsers , setAllUsers] = useState([])
  const [totalUsers , setTotalUsers] = useState(0)
  const [profileLoading , setProfileLoading] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState()
  const [userLoading,setUserLoading] = useState(false)
  const [profileType , setProfileType] = useState("player")
  const getAllProfiles = () => {
    setProfileLoading(true)
    getData(`profiles?search=${search}&order_desc=${profileSort}&gender=${profileGender}&start_date=${profileStartDate}&end_date=${profileEndDate}&page=${pageNumber}&page_size=${profilePageSize}`).then((res)=>{
      setAllProfiles(res.data.results)
      setTotalProfiles(res.data.count)
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{
      setProfileLoading(false)
    })
  }
  const getAllUsers = () => {
    getData(`admin/app/user/account?search=${userSearch}&order_desc=${userSort}&start_date=${userStartDate}&end_date=${userEndDate}&page=${userPageNumber}&page_size=${userPageSize}`).then((res)=>{
      setAllUsers(res.data.results)
      setTotalUsers(res.data.count)
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{
      setUserLoading(false)
    })
  }
  const handleTableChange = (pagination, filters, sorter) => {
    if (pagination.current !== pageNumber) {
      setPageNumber(pagination.current);
    }
    if (pagination.pageSize !== profilePageSize) {
      setProfilePageSize(pagination.pageSize);
    }  };
  const createUser = (e) => {
    let data = {
        first_name: e.first_name,
        last_name: e.last_name,
        phone: phoneNumber,
        email: e.email,
        dob:e.dob !== undefined && e.dob !== "" && dayjs(e.dob).format("YYYY-MM-DD"),
        type: profileType
    }
    jsonPostData(`admin/app/user/account` , data).then((res)=>{
      message.success({
        content:"User Account created successfully."
      })
      navigate("/home/users")
    }).catch((err)=>{
      console.log(err)
      if(err.response.data.details.includes("dob")){
        message.error({
          content:"Age must be greater than 13"
        })
      }else{
      message.error({
        content:err.response.data.details
      })
    }
    })
  }
  const getSingleUser = () => {
  }
  useEffect(()=>{
    getAllProfiles()
  },[pageNumber,search,profileStartDate,profileEndDate,profileSort , profilePageSize])
  // useEffect(()=>{
  //   getAllUsers();
  // },[])
  const deleteUser = (id) => {
    deleteItem(`delete/user/${id}`).then((res)=>{
      message.success({
        content:"User deleted successfully."
      })
      getAllUsers();
    }).catch((err)=>{
      console.log(err)
    })
  }
  const defaultContext = {
    allProfiles,
    pageNumber,
    totalProfile,
    handleTableChange,
    setPageNumber,setSearch,setAllProfiles,
    getAllProfiles , getAllUsers,
    allUsers , totalUsers,profileLoading,userPageNumber,getSingleUser,
    createUser,setProfileEndDate,setProfileStartDate,setProfileSort,profileSort,userPageNumber,setUserPageNumber,totalUsers,
    userStartDate , userEndDate , userSearch , 
    setAllUsers , setUserSearch,phoneNumber,setPhoneNumber,
    userPageSize,
    setUserPageSize,
    userLoading,
    setUserLoading,
    profilePageSize,
    setProfilePageSize,
    deleteUser,
    profileType,
    setProfileType
  };

  return (
    <UserModuleContext.Provider value={defaultContext}>
      {children}
    </UserModuleContext.Provider>
  );
};
