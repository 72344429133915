import React, { useEffect, useState } from "react";
import { deleteItem, formPostData, getData, getDataTest, getDataTraining, jsonPostData, patchData } from "../../util/api-calls";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
export const UserDetailContext = React.createContext({});
export const UserDetailContextProvider = ({ children = {} }) => {
  const navigate = useNavigate()
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [userData , setUserData] = useState({})
  const [editUserModal , setEditUserModal] = useState(false)
  const [loading , setLoading] = useState(false)
  const [profileLoading , setProfileLoading] = useState(false)
  const [profileGender , setProfileGender] = useState("male,female")
  const [profileSort , setProfileSort] = useState(true)
  const [profileStartDate , setProfileStartDate] = useState("") 
  const [profileEndDate , setProfileEndDate] = useState("")
  const [search , setSearch] = useState("")
  const [allProfiles , setAllProfiles] = useState([])
  const [pageNumber, setPageNumber] = useState(1);
  const [totalProfile , setTotalProfiles] = useState(0)
  const [ profileType , setProfileType] = useState("player")
  const [gender , setGender] = useState("male")
  const [shootingHand , setShootingHand ] = useState("right")
  const [imageUpload , setImageUpload] = useState(false)
  const [sportsList, setSportsList] = useState([]);
  const [editProfile, showEditProfile] = useState(false);
  const [selectedProfile , setSelectedProfile] = useState({})
  const [positionPg , setPositionPg] = useState(false)
  const [positionSg , setPositionSg] = useState(false)
  const [positionSf , setPositionSf] = useState(false)
  const [positionPf , setPositionPf] = useState(false)
  const [positionC , setPositionC] = useState(false)
  const [imageUrl, setImageUrl] = useState();
  const [imgSrc, setImgSrc] = useState();
  const [bsaSubmissions , setBsaSubmissions] = useState([])
  const [profileGroups , setProfileGroups] = useState([])
  const [phoneNumber, setPhoneNumber] = useState()
  const [ selectedGroup ,  setSelectedGroup] = useState("")
  const [groupWorkouts , setGroupWorkouts] = useState([])
  const [workoutType , setWorkoutType] = useState("active")
  const [showAllWorkouts, setShowAllWorkouts] = useState(false);
  const [status , setStatus ] = useState("")
  const [sort , setSort] = useState("desc")
  const getSingleUser = () => {
    getData(`admin/app/user/account/${params.id}`).then((res)=>{
      setUserData(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const getSingleProfile = (id) => {
    getData(`admin/app/user/profile/${id}`)
    .then((res)=>{
      setSelectedProfile(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
    const deleteUser = () => {
    deleteItem(`delete/user/${params.id}`).then((res)=>{
      message.success({
        content:"User deleted successfully."
      })
      navigate("/home/users")
    }).catch((err)=>{
      console.log(err)
    })
  }
  const deleteProfile = (id) => {
    deleteItem(`admin/app/user/profile/${id}`).then((res)=>{
      message.success({
        content:"User deleted successfully."
      })
      navigate({
        pathname: `/home/users/user-accounts`,
        search: `?id=${params.id}`,
      });
      getAllProfiles();
    }).catch((err)=>{
      console.log(err)
    })
  }
  const updateUser = (e) => {
      let data = {
        first_name: e.first_name,
        last_name: e.last_name,
        phone: phoneNumber,
        email: e.email,
        dob:dayjs(e.dob).format("YYYY-MM-DD"),
        type : profileType
    }
    patchData(`admin/app/user/account/${params.id}` , data).then((res)=>{
      message.success({
        content:"User updated successfully."
      })
      navigate("/home/users")
    }).catch((err)=>{
      if(err.response.data.details.includes("dob")){
        message.error({
          content:"Age must be greater than 13"
        })
      }
      console.log(err)
    })
  }
  const getAllProfiles = () => {
    setProfileLoading(true)
    getData(`admin/app/user/profile?search=${search}&order_desc=${profileSort}&gender=${profileGender}&start_date=${profileStartDate}&end_date=${profileEndDate}&page=${pageNumber}&user_id=${params.id}`).then((res)=>{
      setAllProfiles(res.data.results)
      setTotalProfiles(res.data.count)
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{
      setProfileLoading(false)
    })
  }
  const updateProfile = (values) =>{
    // if(!imgSrc){
    //   setImageUpload(true)
    // }else{
    //   setImageUpload(false)
let sports = [{
  "id" : JSON.stringify(values.sport),
  "level" : values.sport_level
}]
    setLoading(true);
    let formData = new FormData();
    formData.append('username', values.username);
    formData.append('first_name', values.first_name);
    formData.append('last_name', values.last_name);
    if(profileType === "coach"){
      if(values.school !== undefined && values.school !== ""){
      formData.append('school', values.school);
      }
      if(values.job_title !== undefined && values.job_title !== ""){
      formData.append('job_title', values.job_title);
      }
      if(values.years_experience !== undefined && values.years_experience !== ""){
      formData.append('years_experience', parseInt(values.years_experience));
      }
      }else{
        formData.append('height',  (values.height_feet ? values.height_feet + "′"  : "") +(values.height_inch ? values.height_inch + "″" : ""));
        formData.append('weight',  values.weight_lbs ? values.weight_lbs : "");
        if(shootingHand !== null){
          formData.append('shooting_hand', shootingHand);
          }
      }
      if(values.location !== null && values.location !== undefined && values.location !== ""){
        formData.append('location', values.location);
        }
    if(values.dob !== undefined && values.dob !== "" && values.dob !== null && values.dob.$d !== "Invalid Date" && dayjs(values.dob).format("YYYY-MM-DD") !== "Invalid Date" ){
    formData.append('date_of_birth', dayjs(values.dob).format("YYYY-MM-DD"));
  }
    formData.append('gender', gender);
    // formData.append('sports', JSON.stringify(sports));
    formData.append('position_pg', positionPg);
    formData.append('position_sg',positionSg);
    formData.append('position_sf', positionSf);
    formData.append('position_pf',positionPf);
    formData.append('position_c', positionC);
    formData.append('profile_type', profileType);
    if (imageUrl) {
      formData.append("avatar", imageUrl);
    }
    formData.append('user_id', params.id);
    patchData(`admin/app/user/profile/${selectedProfile.id}`, formData)
      .then((res) => {
        if (res.status === 200) {
          message.success({
            content: "Profile updated successfully.",
            duration: 1,
          });
          getAllProfiles()
        showEditProfile(false)      
        }
      })
      .catch((err) => {
        console.log(err);
        message.error({
          content:err.response.data.details
        })
      })
      .finally(() => {
        setLoading(false);
      });
    // }
  }
  const getSportList = () => {
    setLoading(true);
    getData(`sport`)
      .then((res) => {
        setSportsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getProfileGroups = () => {
    getData(`admin/joined/groups/profile/${params.id}`).then((res)=>{
      setProfileGroups(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const getProfileWorkouts = (groupOwner) => {
    setLoading(true)
    getDataTraining(`admin/profile/${params.id}/group/${selectedGroup}/all/workouts?is_owner=${groupOwner}`).then((res)=>{
      setGroupWorkouts(res.data)
    }).catch((err)=>{
      console.log(err)
    }).finally(()=>{
      setLoading(false)
    })
  }
  const getProfileBsa = () => {
    getDataTest(`admin/profile/${params.id}/bsa/list/?test_status=${status}&sort=${sort}`).then((res)=>{
      setBsaSubmissions(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  useEffect(()=>{
    getSportList();
    getProfileGroups();
  },[])

  const defaultContext = {
    getSingleUser,
    userData,
    editUserModal , 
    setEditUserModal,
    updateUser,
    deleteUser,
    allProfiles,
    getAllProfiles,
    totalProfile,
    profileEndDate,profileStartDate,search,pageNumber,setPageNumber,
    deleteProfile,profileLoading,
    gender , setGender , shootingHand , setShootingHand , imageUpload , profileType , setProfileType , updateProfile,
    sportsList ,editProfile ,showEditProfile,getSingleProfile , selectedProfile,setPositionPg , positionPg,getProfileGroups,
    positionSg,setPositionSg ,
    positionSf , setPositionSf , 
    positionPf , setPositionPf,
    positionC,setPositionC,setImageUrl,
    imgSrc,setImgSrc , selectedGroup ,  setSelectedGroup,
    setImageUpload,phoneNumber , setPhoneNumber,
    setProfileStartDate,setProfileEndDate,setSearch,setAllProfiles,    getProfileBsa,bsaSubmissions,profileGroups,
    groupWorkouts , setShowAllWorkouts , setWorkoutType , showAllWorkouts , workoutType,loading , 
    status , sort , 
    setStatus , setSort,getProfileWorkouts
  };

  return (
    <UserDetailContext.Provider value={defaultContext}>
      {children}
    </UserDetailContext.Provider>
  );
};
