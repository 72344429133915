import React, { useContext, useState, useRef, useEffect } from "react";
import { WorkoutDetailContext } from "../../../context/workouts-context/workout-detail-context";
import {
  Col,
  Row,
  Image,
  Spin,
  Card,
  Skeleton,
  Select,
  Empty,
  Table,
  Progress,
} from "antd";
import ReactPlayer from "react-player";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import DownArrow from "../../../assets/images/arrows.svg";
import SubmissionDetailModal from "./submission-detail-modal";
import Check from "../../../assets/images/check-circle.svg";
import Cross from "../../../assets/images/cross-circle.svg";
// import SubmissionDetailModal from "../../workouts-detail/submission-detail-modal";
import {
  getDateWithTime,
  getDateWithTimeWithoutDay,
  getDateWithoutDay,
} from "../../../util/config";
import Play from "../../../assets/images/play.svg";
// import Thumbnail from "../../../assets/images/Thumbnail.svg"
import Thumbnail from "../../../assets/images/chatLogo.svg";
import GraphModal from "./graph-modal";
import { UserContext } from "../../../context/user-context";
import { FWSubmissionDetailContext } from "../../../context/submission-context/fw-submission-detail-context";
const FWTaskSubmissionDetail = ({ generalDetail }) => {
  const { color, selectedSport } = useContext(UserContext);
  const vidRef = useRef(null);
  const style = {
    padding: "5px 0",
    textAlign: "left",
  };
  const {
    submission,
    selectedProfile,
    getProfileSubmissions,
    setSelectedSubmission,
    getSubmissionDetail,
    setSubmissionDetailModal,
  } = useContext(FWSubmissionDetailContext);
  function secondsToHms(d) {
    d = Number(d);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var mDisplay = m > 0 ? m + (m == 1 ? " min " : " min ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";
    return mDisplay + sDisplay;
  }
  const trainingTaskColumns = [
    {
      title: "Submission",
      dataIndex: "member",
      width: "30%",
      render: (text, record, index) => (
        // record.is_video_progress === false &&
        <div span={20} className="member-div">
          {record.title}
          <span className="task-type">Submission {index + 1}</span>
        </div>
      ),
    },
    {
      title: "Completed",
      dataIndex: "completion_percentage",
      render: (text, record) => (
        // record.is_video_progress === false &&
        <span>{getDateWithTimeWithoutDay(record.created_at)}</span>
      ),
    },
    {
      render: (record) => (
        <div style={{ display: "flex", cursor: "pointer" }}>
          <div
            style={{ color: color }}
            onClick={() => {
              setSelectedSubmission(record.id);
              setSubmissionDetailModal(true);
              getSubmissionDetail(record.id);
            }}
            className="view-submissions"
          >
            View submission
          </div>
          {record.is_reviewed === true && record.is_failed === true ? (
            <img src={Cross} className="status" style={{ width: 24 }} />
          ) : record.is_reviewed === true && record.is_failed === false ? (
            <img src={Check} className="status" style={{ width: 24 }} />
          ) : (
            record.is_reviewed === false && (
              <div className="status purple-tag" style={{ width: 120 }}>
                Ready for Review
              </div>
            )
          )}
        </div>
      ),
    },
  ];
  const scoredTaskColumns = [
    {
      title: "Submission",
      dataIndex: "member",
      width: "30%",
      render: (text, record, index) => (
        // record.is_video_progress === false &&
        <div span={20} className="member-div">
          {record.title}
          <span className="task-type">Submission {index + 1}</span>
        </div>
      ),
    },
    {
      title: "Completed",
      dataIndex: "completion_percentage",
      render: (text, record) => (
        // record.is_video_progress === false &&
        <span>{getDateWithTimeWithoutDay(record.created_at)}</span>
      ),
    },
    {
      title: "Score",
      dataIndex: "score",
      render: (text, record) => (
        // record.is_video_progress === false &&
        <span>{record.score}</span>
      ),
    },
    {
      render: (record) => (
        <div style={{ display: "flex", cursor: "pointer" }}>
          <div
            style={{ color: color }}
            // style={{ display: record.recent_upload === true ? "block" : "none", cursor: "pointer" }}
            onClick={() => {
              setSelectedSubmission(record.id);
              setSubmissionDetailModal(true);
              getSubmissionDetail(record.id);
            }}
            className="view-submissions"
          >
            View submission
          </div>
          {record.is_reviewed === true && record.is_failed === true ? (
            <img src={Cross} className="status" style={{ width: 24 }} />
          ) : record.is_reviewed === true && record.is_failed === false ? (
            <img src={Check} className="status" style={{ width: 24 }} />
          ) : (
            record.is_reviewed === false && (
              <div className="status purple-tag" style={{ width: 120 }}>
                Ready for Review
              </div>
            )
          )}
          {/* {record?.recent_upload === true &&
          <span className="new-sub"></span>
        } */}
        </div>
      ),
    },
  ];
  useEffect(() => {
    if (selectedProfile) {
      getProfileSubmissions();
    }
  }, [selectedProfile]);
  useEffect(() => {
    const intervalCall = setInterval(() => {
      // if (activeTab) {
      //   getSubmissions(profileId, submissionRecord.id)
      // } else {
      //   getSubmissions(submissionRecord)
      // }
      getProfileSubmissions()
    }, 5000);
    return () => {
      clearInterval(intervalCall);
    };
  },);
  return (
    <div className="workout-submission-detail">
      {submission.score_based_test === true &&
        submission?.submissions.length > 0 && (
          <GraphModal graphData={submission.submissions} />
        )}
      <Row className="creator">
        <Col span={4} className="label" style={{ marginBottom: 24 }}>
          <h3 className="label">Submitted by</h3>
        </Col>
        <Col span={20} className="value">
          <div className="name-div">
            <h3 className="user-name">{submission?.submitted_by?.name}</h3>
            <h4 className="user-handle">
              @{submission?.submitted_by?.username}
            </h4>
          </div>
        </Col>
        <Col span={4} className="label">
          <h3 className="label">Task Level</h3>
        </Col>
        <Col
          span={20}
          className="value"
          style={{ textTransform: "capitalize" }}
        >
          <h3 className="user-name">{submission.skill_level}</h3>
        </Col>
        <Col span={4} className="label">
          <h3 className="label">Task Type</h3>
        </Col>
        <Col span={20} className="value">
          <h3 style={{ margin: 0 }} className="user-name">
            {submission.media_required === true &&
            submission.score_based_test === true
              ? "Video Scored Task"
              : submission.media_required === true &&
                submission.score_based_test === false
              ? "Video Training Task"
              : submission.media_required === false &&
                submission.score_based_test === true
              ? "No Video Scored Task"
              : "No Video Training Task"}
          </h3>
        </Col>{" "}
        <Col span={4} className="label">
          <h3 className="label">Time Limit</h3>
        </Col>
        <Col span={20} className="value">
          <h3 className="user-name">
            {" "}
            {submission.is_time_limit === true
              ? secondsToHms(submission?.time_limit_seconds)
              : "No time limit"}
          </h3>
        </Col>
        <Col span={4} className="label">
          <h3 className="label">Task Instructions</h3>
        </Col>
        <Col span={20} className="value">
          <h3 className="user-name">
            {submission.media_url !== null ? (
              <Card hoverable className="media-card">
                <>
                  <div id="video-container">
                    {/* <Spin
                    className={"loader " + (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")}
                    size="large"
                    style={{
                      position: "absolute",
                      left: "0%",
                      top: "45%",
                    }}
                  /> */}

                    <ReactPlayer
                      ref={vidRef}
                      url={submission.media_url}
                      controls
                      playing
                      width={"100%"}
                      height={"100%"}
                      light={submission.thumbnail}
                      // playIcon={false}
                      playIcon={<img src={Play} style={{ width: 35 }} />}
                    />
                  </div>
                </>
              </Card>
            ) : submission.instruction !== null ? (
              submission.instruction
            ) : (
              "N/A"
            )}
          </h3>
        </Col>
        {/* <div className={"info-div " + (selectedSport === 1 ? "orange-info" : "blue-info")}>
          <ExclamationCircleTwoTone
          twoToneColor={color}
          style={{ fontSize: 20 }}
          />
          <p className="status">
            As group owner, your task results will display on leaderboard but will NOT factor into your group’s completion percentage.
          </p>
        </div> */}
        <div className="submissions">
          <div style={{ display: "flex" }}>
            <h3>Submissions</h3>
            <div style={{ marginLeft: "auto" }}>
              {/* <span className="status-heading">Status</span>
              <Select
                placeholder="All"
                optionFilterProp="children"
                suffixIcon={<img src={DownArrow} />}
                className="select-1"
                style={{ width: 120 }}
                options={[
                  {
                    value: "",
                    label: "All"
                  },
                  {
                    value: "final",
                    label: "Completed",
                  },
                  {
                    value: "rejected",
                    label: "Rejected",
                  },
                ]}
              /> */}
            </div>
          </div>
          {submission.media_required === false ? (
            <Table
              columns={
                submission.media_required === false &&
                submission.score_based_test === false
                  ? trainingTaskColumns
                  : scoredTaskColumns
              }
              pagination={false}
              dataSource={submission?.submissions}
              // dataSource={submission?.submissions?.filter((obj) => obj.is_validated !== false)}
              className="tasks-table"
            />
          ) : (
            <Row
              style={{ display: "flex" }}
              className="groups-row submission-video"
              gutter={[34, 34]}
            >
              {submission.submissions?.length > 0 ? (
                submission.submissions.map((item, index) => {
                  return (
                    // item.is_validated !== false &&
                    <Col
                      className="gutter-row"
                      span={6}
                      style={{ paddingBottom: 16 }}
                      key={index}
                    >
                      <div style={style} className="div-1">
                        <Card
                          hoverable
                          className="video-card"
                          style={{
                            cursor:
                              item.is_reviewed === true ||
                              item.status === "live"
                                ? "pointer"
                                : "default",
                          }}
                          onClick={
                            // item.is_reviewed === true ||
                            item.status === "live"
                              ? () => {
                                  setSelectedSubmission(item.id);
                                  setSubmissionDetailModal(true);
                                  getSubmissionDetail(item.id);
                                }
                              : null
                          }
                          cover={
                            item?.is_video_progress === true &&
                            item?.upload_progress < 100 &&
                            item.is_draft === false &&
                            item.status !== 2 ? (
                              <Progress
                                percent={item?.upload_progress}
                                status="active"
                                className="custom-progress-bar uploading-progress"
                              />
                            ) : item?.is_video_progress === true &&
                              item?.upload_progress !== 0 &&
                              item.is_draft === true &&
                              item.status !== 2 ? (
                              <Progress
                                percent={item?.upload_progress}
                                status="active"
                                className="custom-progress-bar uploading-progress"
                              />
                            ) : item?.is_video_progress === true &&
                              item?.upload_progress < 100 &&
                              item.is_draft === true ? (
                              <>
                                <div
                                  className={
                                    "status " +
                                    (item.is_reviewed === true
                                      ? "green-tag"
                                      : item.is_video_progress === true &&
                                        item?.upload_progress === 100
                                      ? "yellow-tag"
                                      : item.status === "live"
                                      ? "purple-tag"
                                      : item.is_draft === true &&
                                        item.status !== 2
                                      ? "orange1-tag"
                                      : item.status === 2
                                      ? "red-tag"
                                      : "red-tag")
                                  }
                                  // className="status purple-tag"
                                  style={{
                                    textTransform: "capitalize",
                                    width: item.status === "live" ? 130 : 120,
                                  }}
                                >
                                  {item.is_reviewed === true
                                    ? "Completed"
                                    : item.is_video_progress === true &&
                                      item?.upload_progress === 100
                                    ? "Transcoding"
                                    : item.status === "live"
                                    ? "Ready for Review"
                                    : item.is_draft === true &&
                                      item.status !== 2
                                    ? "Draft"
                                    : item.status === 2
                                    ? "Failed"
                                    : "Failed"}
                                </div>

                                <div
                                  id="video-container"
                                  style={{
                                    // background: "#F9F9F9",
                                    background: "#FFF",
                                    borderRadius: 6,
                                  }}
                                >
                                  {
                                    item?.media_thumbnail ? (
                                      // <ReactPlayer
                                      //   ref={vidRef}
                                      //   url={item.task_media}
                                      //   controls
                                      //   playing
                                      //   width={"100%"}
                                      //   height={"100%"}
                                      //   light={item.media_thumbnail}
                                      //   playIcon={false}
                                      // // playIcon={<img src={Play} style={{ width: 35 }} />}
                                      // />
                                      <div
                                        style={{
                                          display: "grid",
                                          placeItems: "center",
                                        }}
                                      >
                                        <img
                                          src={item.media_thumbnail}
                                          style={{
                                            width: "100%",
                                            height: "164px",
                                          }}
                                        />
                                        <img
                                          src={Play}
                                          style={{
                                            width: 35,
                                            position: "absolute",
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: "grid",
                                          placeItems: "center",
                                          height: "100%",
                                        }}
                                      >
                                        <img
                                          src={Thumbnail}
                                          style={{
                                            width: "40%",
                                            marginTop: "12%",
                                          }}
                                        ></img>
                                      </div>
                                    )
                                    // <img src={Thumbnail} className="thumbnail"></img>
                                  }
                                </div>
                              </>
                            ) : (
                              <>
                                {item.is_reviewed === true &&
                                item.is_failed === true || item.is_validated === false ? (
                                  <img
                                    src={Cross}
                                    className="status"
                                    style={{ width: 24 }}
                                  />
                                ) : item.is_reviewed === true &&
                                  item.is_failed === false ? (
                                  <img
                                    src={Check}
                                    className="status"
                                    style={{ width: 24 }}
                                  />
                                ) : (
                                  item.is_reviewed === false && (
                                    <div
                                      className={
                                        "status " +
                                        (item.is_reviewed === true
                                          ? "green-tag"
                                          : item.is_video_progress === true &&
                                            item?.upload_progress === 100
                                          ? "yellow-tag"
                                          : item.status === "live"
                                          ? "purple-tag"
                                          : item.is_draft === true &&
                                            item.status !== 2
                                          ? "orange1-tag"
                                          : item.status === 2
                                          ? "red-tag"
                                          : "red-tag")
                                      }
                                      // className="status purple-tag"
                                      style={{
                                        textTransform: "capitalize",
                                        width:
                                          item.status === "live" ? 130 : 120,
                                      }}
                                    >
                                      {item.is_reviewed === true
                                        ? "Completed"
                                        : item.is_video_progress === true &&
                                          item?.upload_progress === 100
                                        ? "Transcoding"
                                        : item.status === "live"
                                        ? "Ready for Review"
                                        : item.is_draft === true &&
                                          item.status !== 2
                                        ? "Draft"
                                        : item.status === 2
                                        ? "Failed"
                                        : "Failed"}
                                    </div>
                                  )
                                )}
                                {/* } */}

                                <div id="video-container">
                                  {
                                    item?.media_thumbnail ? (
                                      // <ReactPlayer
                                      //   ref={vidRef}
                                      //   url={item.task_media}
                                      //   controls
                                      //   playing
                                      //   width={"100%"}
                                      //   height={"100%"}
                                      //   light={item.media_thumbnail}
                                      //   playIcon={false}
                                      // // playIcon={<img src={Play} style={{ width: 35 }} />}
                                      // />
                                      <div
                                        style={{
                                          display: "grid",
                                          placeItems: "center",
                                        }}
                                      >
                                        <img
                                          src={item.media_thumbnail}
                                          style={{
                                            width: "100%",
                                            height: "164px",
                                          }}
                                        />
                                        <img
                                          src={Play}
                                          style={{
                                            width: 35,
                                            position: "absolute",
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: "grid",
                                          placeItems: "center",
                                          height: "100%",
                                        }}
                                      >
                                        <img
                                          src={Thumbnail}
                                          style={{
                                            width: "40%",
                                            marginTop: "12%",
                                          }}
                                        ></img>
                                      </div>
                                    )
                                    // <img src={Thumbnail} className="thumbnail"></img>
                                  }
                                </div>
                              </>
                            )
                          }
                        >
                          {submission.score_based_test === true && (
                            <div>
                              <div className="score">
                                Score:{" "}
                                {
                                  // item.score === 0 &&
                                  item.is_reviewed === true ? (
                                    //  || item.score
                                    <span style={{ fontWeight: "bold" }}>
                                      {item.score}
                                    </span>
                                  ) : (
                                    <span style={{ fontWeight: "bold" }}>
                                      N/A
                                    </span>
                                  )
                                }
                              </div>
                            </div>
                          )}
                          <div
                            className="date"
                            style={{
                              marginTop:
                                submission.score_based_test === false ? 20 : 0,
                            }}
                          >
                            Submitted:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {getDateWithoutDay(item.created_at)}
                            </span>
                          </div>
                        </Card>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  style={{ margin: "70px auto" }}
                />
              )}
            </Row>
          )}
        </div>
      </Row>
      <SubmissionDetailModal
        // generalDetail={generalDetail}
        submission={submission}
      />
    </div>
  );
};

export default FWTaskSubmissionDetail;
